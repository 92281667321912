
/*탭*/
.js-box {display:none;position:relative;min-height: 20px;}
.js-box.on {display:block;}
.js-box2 {display:none;position:relative;min-height: 100px;}
.js-box2.on {display:block;}

.sub-tab { //sub-tab 디자인때문에 아래와 같이 1픽셀 밀림 수정 필요
	.js-box {margin-left:1px;}
	.js-box2 {margin-left:1px;}
}



/*메인탭*/

.main-tab {
	position: relative;
	&__ul {
		margin-bottom:20px;
		@media screen and (min-width:1px) and (max-width:768px) {
			margin-bottom:10px;
		}
		&__li {
			float:left;
			a {
				height: 40px;
				line-height: 40px;
				display:block;
				font-size: 20px;
				text-align: center;
				padding-left: 20px;
				padding-right: 20px;
				color: #333;
				opacity: 0.7;
				@media screen and (min-width:1px) and (max-width:768px) {
					font-size:16px;
					padding:0 7px;
					height:25px;
					line-height:25px;
				}
			}
			&.on a{
				color: #000;
				font-weight: 500;
				opacity: 1;
				border-bottom:2px solid #db4651;
			}
			a:hover,
			a:focus {
				border-bottom:2px solid #e86770;
			}
		}
	}

	&__cont {
		position: relative;
		&__ul {
			display: flex;
			margin-left:-40px;
			@media screen and (min-width:1px) and (max-width:1050px) {
				margin-left:-15px;
			}
			@media screen and (min-width:1px) and (max-width:768px) {
				margin-left:-5px;
			}
			&__li {
				width:33.33%;
				@media screen and (min-width:1px) and (max-width:768px) {
					width:50%;
					display: none;
					&:nth-child(1),
					&:nth-child(2){
						display: block;
					}
				}
				a {
					margin-left:40px;
					display: block;
					position: relative;
					padding:25px 30px;
					border:1px solid #ddd;
					background-color: #fff;
					box-sizing: border-box;
					@media screen and (min-width:1px) and (max-width:1050px) {
						margin-left:15px;
						padding:15px;
					}
					@media screen and (min-width:1px) and (max-width:768px) {
						margin-left:5px;
					}
					@media screen and (min-width:1px) and (max-width:480px) {
						padding:10px;
					}
				}

				a:hover,
				a:focus {
					border:1px solid #000;
				}
			}
		}

	}
}


.main-tab__cont__more {
	@include moreBtn;
	position: absolute;
	top:-50px;
	right:0;
	@media screen and (min-width:1px) and (max-width:768px) {
		top:-40px;
		font-size:0;
		color:#fafafa;
		&:after {
			color:#000;
		}
	}
}
.mtcul-cont {
    overflow: hidden;
    display: block;
    line-height: 1.5;
    color: #000;
    font-size: 20px;
    height: 60px;
	margin-bottom:20px;
	@media screen and (min-width:1px) and (max-width:1050px) {
		font-size:17px;
		height:50px;
		margin-bottom:10px;
	}
	@media screen and (min-width:1px) and (max-width:480px) {
		font-size: 15px;
		height:45px;
	}
}
.mtcul-text {	
    overflow: hidden;
    display: block;
    line-height: 1.5;
    color: #666;
    font-size: 15px;
    height: 45px;
	margin-bottom:15px;
	@media screen and (min-width:1px) and (max-width:480px) {
		font-size:14px;
		word-break:break-all;
	}
}
.mtcul-date {
	display: block;
    color: #666;
}

@media screen and (min-width:1px) and (max-width:768px) {
	.mtcul-cont {width:auto;}
	//.mtcul-date {display: none;}
}


/*서브탭*/
.sub-tab {
	overflow: hidden;
	&__ul {
		@include floatWrap;
		display: flex;
		flex-wrap: wrap;
		margin-bottom:30px;
		overflow: hidden;
		margin-left:-5px;
		&__li {

			flex:1;

			min-width: 16%;
			margin-bottom:5px;
			// .menu-num-7 &,
			// .menu-num-8 &,
			// .menu-num-9 &,
			// .menu-num-10 & {
			max-width: 50%;
			//}
			@media screen and (min-width:1px) and (max-width:1050px) {
				min-width: 33.33%;

			}
			@media screen and (min-width:1px) and (max-width:768px) {

				min-width: 50%;
				max-width: 50%;
			}
			@media screen and (min-width:1px) and (max-width:450px) {
				min-width: 150px;
				max-width: 50%;
			}
			a {
				color:#333;
				display:block;
				border:1px solid #ddd;
				text-align: center;
				margin-left:5px;
				@include fontSize(18);
				padding-top:12px;
				padding-bottom:12px;
				white-space: nowrap;
				&:hover {
					border: 1px solid #000;}
				&:focus {
					background-color: #fff;
					color: #000;
					border: 1px solid #000;
					text-decoration: underline;
					border-left: 1px solid #000;
				}
				@media screen and (min-width:1px) and (max-width:450px) {
					@include fontSize(15);
				}
			}
			&.on {
				a {
					background-color: #000;
					color:#fff;
					border:1px solid #000;
				}
			}
		}

	}
}


/*서브탭2*/
.sub-tab2 {
	overflow: hidden;

	&__ul {
		@include floatWrap;
		border-top:1px solid #ddd;
		border-left:1px solid #ddd;
		margin-bottom:30px;
		&__li {
			float:left;
			box-sizing: border-box;

			@media screen and (min-width:1px) and (max-width:900px) {
				width:25% !important;
			}
			@media screen and (min-width:1px) and (max-width:768px) {
				width:33.33% !important;
			}
			@media screen and (min-width:1px) and (max-width:540px) {
				width:50% !important;
			}
			a {
				color:#333;
				display:block;

				border:1px solid #ddd;
				margin-top:-1px;
				margin-left:-1px;
				text-align: center;
				@include fontSize(14);
				padding-top:10px;
				padding-bottom:10px;
				white-space: nowrap;
				box-sizing: border-box;

				&:hover {text-decoration: underline;}
				&:focus {text-decoration: underline;}
			}
			&.on {
				a {
					color:#2550a7;
					position: relative;
					z-index: 99999;
					font-weight: bold;
					padding-top:9px;
					padding-bottom:9px;
					border:2px solid #2550a7;
				}
			}
		}

	}

	&.menu-num-2 > ul > li {width:50%;}
	&.menu-num-3	> ul > li {width:33.33%;}
		*+ html &.menu-num-3	> ul > li {width:33%;}
	&.menu-num-4	> ul > li {width:25%;}
	&.menu-num-5	> ul > li {width:20%;}
	&.menu-num-6	> ul > li {width:16.66%;}
		*+ html &.menu-num-6	> ul > li {width:16.66%;}
	&.menu-num-7	> ul > li {width:16.66%;}
	&.menu-num-8	> ul > li {width:16.66%;}
	&.menu-num-9	> ul > li {width:16.66%;}
		*+ html .menu-num-9	> ul > li {width:16.66%;}
	&.menu-num-10 > ul > li {width:16.66%;}
	&.menu-num-11 > ul > li {width:16.66%;}
		*+ html &.menu-num-11 > ul > li {width:16.66%;}
	&.menu-num-12 > ul > li {width:16.66%;}
		*+ html &.menu-num-12 > ul > li {width:16.66%;}

	&.menu-num-13 > ul > li {width:16.66%;}

	&.menu-num-14 > ul > li {width:16.66%;}
	&.menu-num-15 > ul > li {width:16.66%;}
	&.menu-num-16 > ul > li {width:16.66%;}
	&.menu-num-17 > ul > li {width:16.66%;}
	&.menu-num-18 > ul > li {width:16.66%;}
	&.menu-num-19 > ul > li {width:16.66%;}
	&.menu-num-20 > ul > li {width:16.66%;}
	&.menu-num-21 > ul > li {width:16.66%;}
	&.menu-num-22 > ul > li {width:16.66%;}
	&.menu-num-23 > ul > li {width:16.66%;}
	&.menu-num-24 > ul > li {width:16.66%;}
	&.menu-num-25 > ul > li {width:16.66%;}

}