$loginSize: 500px;
$loginSizeMobile: 100%;

.login-wrap {
	padding-top:100px;
	text-align: center;
	&__desc {
		font-size: 15px;
		color:#666;
		margin-bottom: 30px;
	}
	&__form {
		margin-bottom:20px;
		&__input{
			display:block;
			margin:0 auto;
			margin-bottom:5px;
			width:$loginSize;
			border: 1px solid #000;
			height: 58px;
			line-height: 58px;
			font-size: 16px;
			padding: 0;
			padding-left: 10px;
			box-sizing: border-box;
		}
		&__submit{
			& {
				color:#fff;
				background-color:#000;
				height: 60px;
				line-height: 60px;
				display: inline-block;
				width:$loginSize;
				vertical-align: middle;
				font-size:15px;
				font-weight: 600;
			}
			&:focus {
				background-color: #333;
				color: gold;
			}

		}

	}
	&__form--bottom {
		width:$loginSize;
		margin:0 auto;
		display: flex;
		a {
			flex: 1;
			position:relative;
			&:before {
				position: absolute;
				top:5px;left:0;
				@include sprite($login-bul)
			}
			&:first-child:before {
				display:none !important;
			}
		}
	}
}

@media screen and (min-width:1px) and (max-width:600px) {
	.login-wrap {padding-top:50px;}
	.login-wrap__desc {font-size:14px;}
	.login-wrap__form__input {width:$loginSizeMobile;}
	.login-wrap__form__submit {width:$loginSizeMobile;}
	.login-wrap__form--bottom {width:$loginSizeMobile;}

}