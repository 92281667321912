//일정관리
.callender {
    position: relative;
    &__tit {@include hiddenWord}
    &__month {
        margin-bottom: 10px;
        @media screen and (min-width: 1px) and (max-width: 768px) {
            margin-bottom:50px;
        }
        strong {
            font-size:28px;
            display: inline-block;
            padding-left:30px;
            padding-right:30px;
            color:#666;
            vertical-align: middle;
        }
        &__btn {
            span {@include hiddenWord};
            display: inline-block;
            width:30px;
            height: 30px;
            vertical-align: middle;
            position: relative;
            border-radius: 4px;
            border:1px solid #fff;
            &:before {
                position: absolute;
                top:3px;
                left:8px;
            }
            &.prev-btn {
                &:before {
                    @include sprite($cal-left)
                }
            }
            &.next-btn {
                &:before {
                    @include sprite($cal-right)
                }
            }
            &:focus {
                border:1px solid gold;
            }
        }
    }
    &__ctg {
        position: absolute;
        top:10px;
        right:0;
        display: flex;
        span {
            margin-left:30px;
        }
        @media screen and (min-width: 1px) and (max-width: 768px) {
            top:54px;
        }
    }
}

.call-icon {
    &:focus {text-decoration: underline;}
    display: block;
    font-weight: normal;
    padding-left:25px;
    margin-bottom:3px;
    background-position: left center;
    background-repeat: no-repeat;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    &.type2 {
        background-image: url('../../resources/images/cont/call-type1.png');
    }
    &.type3 {
        background-image: url('../../resources/images/cont/call-type3.png');
    }
    &.type4 {
        background-image: url('../../resources/images/cont/call-type2.png');
    }
    .type2 & {background-image: url('../../resources/images/cont/call-type1.png');}
    .type3 & {background-image: url('../../resources/images/cont/call-type3.png');}
    .type4 & {background-image: url('../../resources/images/cont/call-type2.png');}
}
.call-item {
    position: relative;
}
.call-layer {
    display: none;
    &.on {
        display: block;
    }
    position: absolute;
    top:25px;
    left:0;
    z-index: 999;
    min-width: 320px;
    box-sizing: border-box;
    padding:20px;
    background-color: #fff;
    word-break: break-all;
    strong {
        margin-bottom:10px;
    }
    // &.type1 {
    //     border:2px solid #db4651;
    // }
    // &.type2 {
    //     border:2px solid #03928d;
    // }
    // &.type3 {
    //     border:2px solid #664795;
    // }
    .type2 & {border:2px solid #db4651;}
    .type3 & {border:2px solid #664795;}
    .type4 & {border:2px solid #03928d;}
    .layer-close {
        position: absolute;
        top:0;
        right:0;
        color:#fff;
        display: block;
        padding:2px 10px;
        &:focus {
            text-decoration: underline;
        }
    }
    
    // &.type1 .layer-close {
    //     background-color: #db4651;
    // }
    // &.type2 .layer-close {
    //     background-color: #03928d;
    // }
    // &.type3 .layer-close {
    //     background-color: #664795;
    // }
    
    .type2 & .layer-close {background-color: #db4651;}
    .type3 & .layer-close {background-color: #664795;}
    .type4 & .layer-close {background-color: #03928d;}
}



.cal_tb {
    margin-bottom:30px;
    width: 100%;
    
    @media screen and (min-width: 1px) and (max-width: 1400px) {
        overflow: auto;
    }
    table {
        width: 100%;
        min-width: 800px;
        thead {
            th {
                height:60px;
                line-height:60px;
                font-size:18px;
                font-weight: 400;
                border-top:2px solid #000;
                border-bottom:1px solid #000;
            }
        }
        tr th:first-child,
        tr td:first-child em {
            color:#db4651;
        }
        tr th:last-child,
        tr td:last-child em {
            color:#1658c5;
        }
        
        tbody {
            td {
                border:1px solid #ddd;
                .inner-box {
                    padding:8px;
                    position: relative;
                    min-height: 100px;
                    max-width: 175px;
                    em {display: block;}
                    @media screen and (min-width: 1px) and (max-width: 768px) {
                        max-width: 90px;
                    }
                }
            }
        }
    }
}


.cal_tb table tr td:last-child .call-layer{
    @media screen and (min-width: 1px) and (max-width: 1650px) {
        left: unset;
        right: 0;
    }
}

//일정관리 기존
.today_scz {
    position: relative;
}
body .schedule_btn {
    background-color:#fff !important;
    color:#000;
    display: block;
    font-weight: normal;
    padding-left:25px;
    margin-bottom:3px;
    font-size:15px;
    background-position: left center;
    background-repeat: no-repeat;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    &.type2 {
        background-image: url('../../resources/images/cont/call-type1.png');
    }
    &.type3 {
        background-image: url('../../resources/images/cont/call-type3.png');
    }
    &.type4 {
        background-image: url('../../resources/images/cont/call-type2.png');
    }
}


.schedule.tb_type1 {
    border-top:0px !important;
    border-right:0px;
    table thead th {
        padding-top:0px !important;
        padding-bottom: 0px !important;
    height: 60px;
    line-height: 60px;
    font-size: 18px;
    font-weight: 400;
    border-top: 2px solid #000;
    border-bottom: 1px solid #000;
    background-color: #fff;
    color:#000;
    }
    table td {
        border:1px solid #ddd;
    }
}

//상단 박스
.design-top {
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    min-height:280px;
    margin-bottom:40px;
    &__in {
        text-align: center;
        color:#fff;
        font-size:18px;
        padding-top:60px;
        line-height: 1.6;
        padding-bottom: 15px;
        .headline {
            font-size: 50px;
            font-weight: 300;
            margin-bottom:10px;
            &.small-ver {
                font-size:38px;
            }
            strong {
                font-weight: 700;
            }
        }
    }
    @media screen and (min-width: 1px) and (max-width: 768px) {
        min-height:10px;
        padding-bottom:40px;
        &__in {
            padding-top:30px;
            font-size: 16px;
            .headline,
            .headline.small-ver {
                font-size: 25px;
                word-break: break-all;
            }
            br {
                display: none;
            }
            padding-left:30px;
            padding-right:30px;
        }
    }
}

// 주요정보 박스
.info-design-box {
    display: flex;
    margin-left:-40px;
    margin-bottom:40px;
    flex-wrap: wrap;
    @media screen and (min-width: 1px) and (max-width: 1400px) {
        margin-left:-10px
    }
    &__item {
        flex:1;
        margin-left:40px;
        background-color: #f8f8f8;
        box-sizing: border-box;
        padding-top:40px;
        min-height:200px;
        padding-left:100px;
        padding-right:10px;
        font-size:18px;
        @media screen and (min-width: 1px) and (max-width: 1400px) {
            margin-left:10px;
            padding-left:60px;
        }
        @media screen and (min-width: 1px) and (max-width: 768px) {
            max-width: 50%;
            min-width: 45%;
            margin-bottom:10px;
            padding-top:20px;
            padding-left:20px;
            padding-right:10px;
            padding-bottom:10px;
            font-size:15px;
            min-height: 130px;
        }
        strong {
            font-size:25px;
            margin-bottom:10px;
            display: block;
            position: relative;
            @media screen and (min-width: 1px) and (max-width: 1400px) {
                font-size: 20px;
            }
            @media screen and (min-width: 1px) and (max-width: 768px) {
                padding-left:30px;
                font-size: 18px;
            }
            &:before {
                position: absolute;
                top:5px;
                left:-60px;
                @media screen and (min-width: 1px) and (max-width: 1400px) {
                    left:-40px;
                    top:-2px;
                }
                @media screen and (min-width: 1px) and (max-width: 768px) {
                    left:-10px;
                }
            }
        }
        &.type1 {
            strong:before {
                @include sprite($cont-icon-1)
            }
        }
        &.type2 {
            strong:before {
                @include sprite($cont-icon-2)
            }
        }
        &.type3 {
            strong:before {
                @include sprite($cont-icon-3)
            }
        }
        &.type4 {
            strong:before {
                @include sprite($cont-icon-4)
            }
        }
    }
}

// 좌측 타이틀 오른쪽 컨텐츠
.left-tit-box {
    &__item {
        display: flex;
        flex-wrap: wrap;
        padding-top:40px;
        padding-bottom:40px;
        border-top:1px solid #ddd;
        @media screen and (min-width: 1px) and (max-width: 768px) {
            display: block;
        }
        .gallview-type & { // 특별전시타입 갤러리 뷰페이지 일때
            border-top:0px;
            padding-top:0;
        }
        &__sbj {
            width: 25%;
            font-size:26px;
            word-break: break-all;
            @media screen and (min-width: 1px) and (max-width: 1400px) {
                width:100%;
                margin-bottom:10px;
            }
            .right-img-ver & { //소장품소개일때
                width: 19%;
                @media screen and (min-width: 1px) and (max-width: 1000px) {
                    width:100%;
                }
            }
            .vr-ver & { //온라인전시VR일때
                width: 21%;
                @media screen and (min-width: 1px) and (max-width: 1000px) {
                    width:100%;
                }
            }
            .gongmo-ver & { // 공모전일때
                width: 22%;
                padding-right:3%;
                @media screen and (min-width: 1px) and (max-width: 1000px) {
                    width:100%;
                    padding-right:0;
                }
            }
            .gallview-type & { // 특별전시타입 갤러리 뷰페이지 일때
                width: 22%;
                padding-right:3%;
                @media screen and (min-width: 1px) and (max-width: 1000px) {
                    width:100%;
                    padding-right:0;
                    text-align: center;
                    margin-bottom:20px;
                    display: block;
                    img {
                        max-width:370px;

                    }
                }
                @media screen and (min-width: 1px) and (max-width: 430px) {
                    img {
                        max-width: 100%;
                    }
                }
            }
            .exbview-type & { // 전시도록 타입 뷰페이지 일때
                width: 45%;
                padding-right:5%;
                @media screen and (min-width: 1px) and (max-width: 1000px) {
                    width:100%;
                    padding-right:0;
                    text-align: center;
                    margin-bottom:20px;
                    display: block;
                    img {
                        max-width:370px;
                        margin: 0 auto;

                    }
                }
                @media screen and (min-width: 1px) and (max-width: 430px) {
                    img {
                        max-width: 100%;
                    }
                }
            }
        }
        &__cont {
            width: 75%;
            padding-top:5px;
            @media screen and (min-width: 1px) and (max-width: 1400px) {
                padding-left:15px;
                width: 100%;
                box-sizing: border-box;
            }
            .right-img-ver & {
                flex:2;
                line-height: 2;
                padding-right:30px;
                @media screen and (min-width: 1px) and (max-width: 768px) {
                    flex:none;
                    margin-bottom:20px;
                }
            }
            .vr-ver & { //온라인전시VR일때
                width: 79%;
                @media screen and (min-width: 1px) and (max-width: 1000px) {
                    width:100%;
                }
                @media screen and (min-width: 1px) and (max-width: 768px) {
                    padding-left:0
                }
            }
            .gongmo-ver & { // 공모전일때
                position: relative;
                .info-text {
                    position: absolute;
                    bottom:0;
                    right:0;
                    color:#db4651;
                    @media screen and (min-width: 1px) and (max-width: 1400px) {
                        position: relative;
                    }
                }
                .gongmo-text {
                    color:#666;
                    padding-top:10px;
                    font-size: 19px;
                    line-height: 1.6;
                }
                @media screen and (min-width: 1px) and (max-width: 1400px) {
                    padding-left:0;
                }
            }
            .gallview-type & { // 특별전시타입 갤러리 뷰페이지 일때
                
                @media screen and (min-width: 1px) and (max-width: 1400px) {
                    width: 75%;
                }
                @media screen and (min-width: 1px) and (max-width: 1000px) {
                    width:100%;
                    padding-left:0;
                    .mgn_b30 {
                        margin-bottom:15px;
                    }

                }
            }
            .exbview-type & { // 전시도록 타입 뷰페이지 일때
                width:50%;
                @media screen and (min-width: 1px) and (max-width: 1000px) {
                    width:100%;
                    padding-left:0;
                    .mgn_b30 {
                        margin-bottom:15px;
                    }

                }
            }
        }
        &.right-img-ver {
            .imgs {
                width:360px;
                @media screen and (min-width: 1px) and (max-width: 768px) {
                    width: 100%;
                    text-align: center;
                }
            }
        }
        
    }
}

// 아이콘들
.icons {
    margin-right:10px;
    vertical-align: middle;
    margin-top:-3px;
    min-width: 30px !important;
}
.icon-1 {
    @include sprite($icon-1);
}
.icon-2 {
    @include sprite($icon-2);
}
.icon-3 {
    @include sprite($icon-3);
}
.icon-4 {
    @include sprite($icon-4);
}
.icon-5 {
    @include sprite($icon-5);
}
.icon-6 {
    @include sprite($icon-6);
}
.icon-7 {
    @include sprite($icon-7);
}
.icon-8 {
    @include sprite($icon-8);
}
.icon-9 {
    @include sprite($icon-9);
}
.icon-10 {
    @include sprite($icon-10);
}
.icon-11 {
    @include sprite($icon-11);
}

.red-circle {
    background-color: #db4651;
    font-size:18px;
    font-weight: bold;
    width:32px;
    height:32px;
    line-height: 32px;
    text-align: center;
    display: inline-block;
    border-radius: 100%;
    color:#fff;
}

.warning-box {
    background-color: #db4651;
    padding-top:25px;
    padding-bottom:25px;
    padding-left:100px;
    color:#fff;
    font-size:16px;
    line-height: 1.6;
    background-image: url('../../resources/images/icons/warning.png');
    background-position: 30px center;
    background-repeat: no-repeat;
    margin-bottom:30px;
}

//찾아오시는 길
.locate-box {
    @include floatWrap;
    &__sbj {
        width:480px;
        float:left;
        @media screen and (min-width: 1px) and (max-width: 1450px) {
            width: 100%;
            float:none;
        }

    }
    &__cont {
        width: 900px;
        float:right;
        @media screen and (min-width: 1px) and (max-width: 1450px) {
            width:100%;
            margin-bottom:30px;
            float:none;
            .root_daum_roughmap {width:100% !important;height:300px !important;}
            .root_daum_roughmap .wrap_map {width:100%;height:300px !important;}
        }
    }
}

// 추천 사이트
.basicList2.locate-type {
    display: flex;
    flex-wrap: wrap;
    li {
        flex:1;
        min-width: 22%;
        max-width: 22%;
        @media screen and (min-width: 1px) and (max-width: 1450px) {
            min-width: 45%;
            max-width: 45%;
        }
        @media screen and (min-width: 1px) and (max-width: 768px) {       
            min-width: 95%;
            max-width: 95%;
        }
    }
}
.location_box div {
    a {
        background-color: oldlace;
        display: inline-block;
        padding: 0 14px;
        border-radius: 10px;
        max-width: 300px;
        overflow: hidden;
        text-overflow: ellipsis;
        vertical-align: middle;
        @media screen and (min-width: 1px) and (max-width: 768px) { 
            word-break: break-all;
        }

    }
}

// 발자취
.years-type {
    .left-tit-box__item__sbj {
        font-size: 32px;
    }
    .yearsList {
        li {
            margin-bottom:10px;
            position: relative;
            padding-left: 40px;
            font-size: 19px;
            strong {
                color:#db4651;
                position: absolute;
                top: 0;
                left: 0;
            }
        }
    }
}

// 인사말
.hello {
    background-image: url('../../resources/images/cont/design-bg4.jpg');
    background-position: 0 0;
    background-repeat: no-repeat;
    
    @media screen and (min-width: 1px) and (max-width: 768px) {
        padding-top:200px;
        background-size: auto 170px;
        background-position: center top;
    }
    &__top {
        position: relative;
        height: 550px;
        @media screen and (min-width: 1px) and (max-width: 768px) {
            height:auto;
            margin-bottom:40px;
        }
        &__in {
            background-color: #db4651;
            padding:60px 50px;
            color: #fff;
            position: absolute;
            top:200px;
            font-size: 30px;
            strong {font-size: 44px;}
            &:before {
                content: ' ';
                display: block;
                width:45px;
                height:4px;
                background-color: #fff;
                position: absolute;
                top:45px;
                left:50px;
                @media screen and (min-width: 1px) and (max-width: 768px) {
                    display:none;
                }
            }
            
            @media screen and (min-width: 1px) and (max-width: 768px) {
                color:#000;
                background-color: transparent;
                top:0;
                position: relative;
                padding:0;
                font-size:23px;
                strong {
                    font-size:25px;
                }
            }
        }
    }
    p {
        margin-bottom: 30px;;
        font-size: 19px;
        line-height: 1.8;
    }
}

//전시관 소개
.vision {
    background-color: #fff5f6;
    position: relative;
    margin-bottom:145px;
    text-align: center;
    font-size: 36px;
    padding-bottom:40px;
    padding-top:70px;
    h3 {
        position: absolute;
        height:70px;
        line-height: 70px;
        color:#fff;
        width:200px;
        background-color: #db4651;
        top:-35px;
        left:50%;
        margin-left:-100px;
        font-size: 26px;
    }
    @media screen and (min-width: 1px) and (max-width: 1400px) {
        font-size:25px;
        padding-left:10px;
        padding-right:10px;
    }
}
.targets {
    position: relative;
    text-align: center;
    margin-bottom:100px;
    h3 {
        position: absolute;
        height:70px;
        line-height: 70px;
        color:#fff;
        width:200px;
        background-color: #444;
        top:-35px;
        left:50%;
        margin-left:-100px;
        font-size: 26px;
    }
    &__list {
        display: flex;
        flex-wrap: wrap;
        border:1px solid #ddd;
        li {
            flex: 1;
            border:1px solid #ddd;
            padding-top:60px;
            box-sizing: border-box;
            height:240px;
            font-family: $font2;
            font-size: 26px;
            color:#000;
            font-weight: bold;

            p {
                margin-bottom:10px;
            }
        }
        @media screen and (min-width: 1px) and (max-width: 1200px) {
            li {
                min-width: 300px;
                font-size:18px;
                height:220px;
            }
            
        }
    }
}

// 여성사 아카이브 인트로
.archive {
    margin-left:-25px;
    display: flex;
    flex-wrap: wrap;
    @media screen and (min-width: 1px) and (max-width: 1400px) {
        margin-left:-2%
    }
    @media screen and (min-width: 1px) and (max-width: 900px) {
        margin-left:0;
    }
    &__item {
        box-sizing: border-box;
        height:260px;
        margin-left:25px;
        background-color: #f7f7f7;
        background-position: right bottom;
        background-repeat: no-repeat;
        background-size: auto 100%;
        margin-bottom:25px;
        @media screen and (min-width: 1px) and (max-width: 900px) {
            height:auto;
        }
        a {
            display: block;
            height:100%;
            &:hover,
            &:focus {
                .go-btn {
                    background-color: #db4651;
                    color:#fff;
                    border: 1px solid #db4651;
                }
            }
        }
        &.type1 {
            width:545px;
            background-image: url('../../resources/images/cont/archive-bg1.jpg');
        }
        &.type2 {
            width:830px;
            background-image: url('../../resources/images/cont/archive-bg2.jpg');
        }
        &.type3 {
            width:545px;
            background-image: url('../../resources/images/cont/archive-bg3.jpg');
        }
        &.type4 {
            width:400px;
            background-image: url('../../resources/images/cont/archive-bg4.jpg');
        }
        &.type5 {
            width:405px;
            background-image: url('../../resources/images/cont/archive-bg5.jpg');
        }
        
        @media screen and (min-width: 1px) and (max-width: 1400px) {

            background-size: auto 70%;
            &.type1,
            &.type2,
            &.type3,
            &.type4,
            &.type5 {
                width:48%;
                margin-left:2%;
            }
        }
        
        @media screen and (min-width: 1px) and (max-width: 900px) {
            
            &.type1,
            &.type2,
            &.type3,
            &.type4,
            &.type5 {
                width: 100%;
                margin-left:0;
            }
            &.type2 {background-size: auto 50%;}
        }
        &__in {
            position: relative;
            box-sizing: border-box;
            height:100%;
            padding: 40px 40px 0 40px;
            .item1 {
                font-size: 28px;
                display: block;
                margin-bottom:15px;
            }
            .item2 {
                color:#666;
            }
            .item3 {}

            @media screen and (min-width: 1px) and (max-width: 900px) {
                padding:20px;
                padding-bottom:70px;
                .item1 {
                    font-size:22px;
                    margin-bottom:5px;
                }
                .item2 {
                    br {}
                }
            }
        }
        
        .go-btn {
            position: absolute;
            bottom:30px;
            left:40px;
            display: inline-block;
            vertical-align: middle;
            padding:5px 70px 5px 10px;
            border:1px solid #000;
            background-color: #f8f8f8;
            font-size:16px;
            &:after {
                position: absolute;
                top:13px;
                //transform: translateY(-50%);
                right:10px;
                @include sprite($ys-off)
            }
            @media screen and (min-width: 1px) and (max-width: 900px) {

                bottom:20px;
                left:20px;
            }
        }
        a:hover,
        a:focus {
            .go-btn:after {
                @include sprite($ys-on)
            }
        }

    }
}

// 개인정보처리방침
.privacy-box {
    margin-bottom:40px;
    color:#666;
    p {
        margin-bottom:20px;
    }
    a:hover,
    a:focus {
        text-decoration: underline;
    }
}

// 사이트맵. 빌드된 작업물을 board.css에 박아 넣음
/*
.sitemap {
    h4 {
        @include hiddenWord;
    }
    > ul {
        > li {
            display: flex;
            flex-wrap: wrap;
            padding-top:40px;
            padding-bottom:40px;
            border-top:1px solid #ddd;
            > a {
                width: 25%;
                font-size:26px;
                font-weight: bold;
                word-break: break-all;
                @media screen and (min-width: 1px) and (max-width: 1400px) {
                    width:100%;
                    margin-bottom:10px;
                }
            }
            > ul {
                @include basicList2;
                width: 75%;
                padding-top:5px;
                display: flex;
                flex-wrap: wrap;
                @media screen and (min-width: 1px) and (max-width: 1400px) {
                    padding-left:15px;
                    width: 100%;
                    box-sizing: border-box;
                }
                >li {
                    flex:1;
                    min-width:22%;
                    max-width:22%;
                    @media screen and (min-width: 1px) and (max-width: 768px) {
                        min-width:45%;
                        max-width:45%;
                    }
                    @media screen and (min-width: 1px) and (max-width: 400px) {
                        min-width:95%;
                        max-width:95%;
                    }
                    > a {}
                }
            }
        }
    }
}
*/

// 기증안내 부분 절차
.endowment {
    display: flex;
    flex-wrap: wrap;
    li {
        flex:1;
        text-align: center;
        position: relative;
        @media screen and (min-width: 1px) and (max-width: 900px) {
            min-width:50%;
            max-width: 50%;
            margin-bottom:20px;
        }
        @media screen and (min-width: 1px) and (max-width: 480px) {
            min-width:100%;
            max-width:100%;
        }
        &:after {
            position: absolute;
            top:50%;
            transform: translateY(-50%);
            right:-8px;
            @include sprite($red-right);
            @media screen and (min-width: 1px) and (max-width: 480px) {
                transform: rotate(90deg);
                right:50%;
                top:100%;
                margin-right:-7px;
            }
        }
        &:last-child:after {
            display: none;
        }
        &:before {
            position: absolute;
            top:40px;
            left:50%;
            transform: translateX(-50%);
        }
        &.type1:before {
            @include sprite($endowment-1)
        }
        &.type2:before {
            @include sprite($endowment-2)
        }
        &.type3:before {
            @include sprite($endowment-3)
        }
        &.type4:before {
            @include sprite($endowment-4)
        }
        div {
            border:1px solid #ddd;
            padding-top:110px;
            padding-bottom: 40px;
            margin-left:15px;
            margin-right:15px;
            height:100%;
            box-sizing: border-box;

        }
        strong {
            display: block;
            margin-bottom:10px;
            font-size:22px;
            @media screen and (min-width: 1px) and (max-width: 900px) {
                font-size: 18px;
            }
        }
    }
}

//공공누리 저작권 아이콘 박스
.ggnr-box {
    display: table;
    padding:10px;
    border:1px solid #ddd;
    border-radius: 5px;
    margin-bottom:20px;
    width: 100%;
    box-sizing: border-box;
    @media screen and (min-width: 1px) and (max-width: 768px) {
        display: block;
    }
    p {
        display: table-cell;
        vertical-align: middle;
        &:first-child {
            max-width: 100px;
            padding-right: 30px;
            @media screen and (min-width: 1px) and (max-width: 768px) {
                max-width: 100%;
                margin-bottom:5px;
                img {
                    height: 30px !important;
                }
            }
        }
        @media screen and (min-width: 1px) and (max-width: 768px) {
            display: block;
        }
    }
    img {vertical-align: middle;margin-right:30px;}
}

// 온라인 전시 vr
.vr-new {
    display: flex;
    flex-wrap: wrap;
    li {
        min-width: 33.33%;
        max-width: 33.33%;
        text-align: center;
        @media screen and (min-width: 1px) and (max-width: 768px) {
                
            min-width: 50%;
            max-width: 50%;
            margin-bottom:10px;
        }
        div {
            border:1px solid #ddd;
            padding:20px;
            margin-left:20px;
            margin-bottom:20px;
            @media screen and (min-width: 1px) and (max-width: 768px) {
                margin-left:10px;
                margin-bottom:0px;
                padding:10px;
                height: 100%;
                box-sizing: border-box;
            }
            a {
                display: block;
                margin-bottom:5px;
                &:hover,
                &:focus {
                    opacity: 0.7;
                }
            }
            
            p {
                font-size: 20px;
                font-weight: bold;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                display: block;
                padding-left:10px;
                padding-right:10px;
                @media screen and (min-width: 1px) and (max-width: 768px) {
                    font-size:15px;
                    white-space:normal;
                }
            }
        }
    }
}


//기본 갤러리 재정의
.new_gall_wrap {
    
}
.new_gall {
    ul {
        display: flex;
        flex-wrap: wrap;
        margin-left:-40px;
        @media screen and (min-width: 1px) and (max-width: 1050px) {
            margin-left:-10px;
        }
        li {
            flex: 1;
            min-width: 25%;
            max-width: 25%;
            margin-bottom:40px;
            @media screen and (min-width: 1px) and (max-width: 1050px) {
                margin-bottom:10px;
            }
            @media screen and (min-width: 1px) and (max-width: 768px) {
                min-width: 33.33%;
                max-width: 33.33%;
            }
            @media screen and (min-width: 1px) and (max-width: 560px) {
                min-width: 50%;
                max-width: 50%;
            }
            .gall_div {
                height:100%;
                a {
                    display: block;
                    margin-left:40px;
                    padding:25px;
                    border:1px solid #ddd;
                    box-sizing: border-box;
                    height:100%;
                    &:hover,
                    &:focus {
                        border:2px solid #db4651;
                        padding:24px;
                    }

                    .texts {
                        display: block;
                        max-height: 80px;
                        overflow: hidden;
                        font-size:18px;
                        word-break: break-all;
                        margin-bottom:12px;
                        .ctg {
                            color:#db4651;
                            display: block;
                            font-size:15px;
                        }
                    }
                    @media screen and (min-width: 1px) and (max-width: 1050px) {
                        margin-left:10px;
                        padding:15px;
                        &:hover,
                        &:focus {
                            border:2px solid #db4651;
                            padding:14px;
                        }
                    }
                    @media screen and (min-width: 1px) and (max-width: 560px) {
                        .texts {
                            font-size:16px;
                            max-height: 75px;
                        }
                    }
                }
            }
        }
    }

    .imgs {
        @include fixSize($fix43);
        img {
            object-fit: cover;
        }
        margin-bottom:10px;
    }
    .movie-sp .imgs { //구술영상기록의 경우        
        @include fixSize(52%);
    }
}


.new_gall2 {// 특별전시
    ul {
        display: flex;
        flex-wrap: wrap;
        margin-left:-40px;
        @media screen and (min-width: 1px) and (max-width: 1350px) {
            margin-left:-10px;
        }
        li {
            flex: 1;
            min-width: 25%;
            max-width: 25%;
            margin-bottom:40px;
            @media screen and (min-width: 1px) and (max-width: 1350px) {
                margin-bottom:10px;
            }
            @media screen and (min-width: 1px) and (max-width: 1050px) {
                min-width: 33.33%;
                max-width: 33.33%;
            }
            @media screen and (min-width: 1px) and (max-width: 768px) {
                min-width: 50%;
                max-width: 50%;
            }
            @media screen and (min-width: 1px) and (max-width: 460px) {
                min-width: 100%;
                max-width: 100%;
            }
            .gall_div {
                height:100%;
                a {
                    display: block;
                    margin-left:40px;
                    padding:25px;
                    border:1px solid #ddd;
                    box-sizing: border-box;
                    height:100%;
                    &:hover,
                    &:focus {
                        border:2px solid #db4651;
                        padding:24px;
                    }
                    .item01 {
                        margin-bottom:20px;
                        text-align: center;
                        img {
                            max-height: 400px;
                            @media screen and (min-width: 1px) and (max-width: 460px) {
                                max-height: 300px;
                            }
                        }
                    }
                    h3 {
                        font-size:20px;
                        height: 65px;
                        overflow: hidden;
                        font-weight: 500;
                        margin-bottom:20px;
                        word-break: break-all;
                    }
                    .item02 {
                        border-top: 1px solid #000;
                        font-size:15px;
                        padding-top:30px;
                        &__in {
                            position: relative;
                            padding-left:75px;
                            word-break: break-all;
                            min-height:25px;
                            strong {
                                position: absolute;
                                top:0;
                                left:0;
                            }
                        }
                    }
                    @media screen and (min-width: 1px) and (max-width: 1350px) {
                        margin-left:10px;
                        padding:15px;
                        &:hover,
                        &:focus {
                            border:2px solid #db4651;
                            padding:14px;
                        }
                    }
                    @media screen and (min-width: 1px) and (max-width: 460px) {
                        
                        .item01 {
                            margin-bottom:10px;
                        }
                        h3 {
                            margin-bottom:10px;
                            font-size:18px;
                            height:60px;
                        }
                        .item02 {
                            padding-top:10px;
                            &__in {
                                padding-left:0;
                                strong {
                                    position: relative;
                                    display: block;
                                }
                                margin-bottom: 10px;
                            }
                        }
                    }
                }
            }
        }
    }
}
.new_gall3 { //순회전시
    ul {
        display: flex;
        flex-wrap: wrap;
        margin-left:-40px;
        @media screen and (min-width: 1px) and (max-width: 1350px) {
            margin-left:-10px;
        }
        li {
            flex: 1;
            min-width: 25%;
            max-width: 25%;
            margin-bottom:40px;
            @media screen and (min-width: 1px) and (max-width: 1350px) {
                margin-bottom:10px;
            }
            @media screen and (min-width: 1px) and (max-width: 1050px) {
                min-width: 33.33%;
                max-width: 33.33%;
            }
            @media screen and (min-width: 1px) and (max-width: 768px) {
                min-width: 50%;
                max-width: 50%;
            }
            @media screen and (min-width: 1px) and (max-width: 460px) {
                min-width: 100%;
                max-width: 100%;
            }
            .gall_div {
                height:100%;
                a {
                    display: block;
                    margin-left:40px;
                    padding:25px;
                    border:1px solid #ddd;
                    box-sizing: border-box;
                    height:100%;
                    &:hover,
                    &:focus {
                        border:2px solid #db4651;
                        padding:24px;
                    }
                    .item01 {
                        margin-bottom:20px;
                        text-align: center;
                        //@include fixSize($fix43)
                        img {
                            max-height: 400px;
                            @media screen and (min-width: 1px) and (max-width: 460px) {
                                max-height: 300px;
                            }
                        }
                    }
                    h3 {
                        font-size:20px;
                        height: 65px;
                        overflow: hidden;
                        font-weight: 500;
                        margin-bottom:20px;
                        word-break: break-all;
                    }
                    .item02 {
                        border-top: 1px solid #000;
                        font-size:15px;
                        padding-top:20px;
                        &__in {
                            position: relative;
                            padding-left:75px;
                            word-break: break-all;
                            min-height:25px;
                            strong {
                                position: absolute;
                                top:0;
                                left:0;
                            }
                        }
                        p {
                            font-size:16px;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                            overflow: hidden;
                        }
                    }
                    @media screen and (min-width: 1px) and (max-width: 1350px) {
                        margin-left:10px;
                        padding:15px;
                        &:hover,
                        &:focus {
                            border:2px solid #db4651;
                            padding:14px;
                        }
                    }
                    @media screen and (min-width: 1px) and (max-width: 460px) {
                        
                        .item01 {
                            margin-bottom:10px;
                        }
                        h3 {
                            margin-bottom:10px;
                            font-size:18px;
                            height:60px;
                        }
                        .item02 {
                            padding-top:10px;
                            &__in {
                                padding-left:0;
                                strong {
                                    position: relative;
                                    display: block;
                                }
                                margin-bottom: 10px;
                            }
                        }
                    }
                }
            }
        }
    }
}
.new_gall4 { //전시도록
    ul {
        display: flex;
        flex-wrap: wrap;
        margin-left:-40px;
        @media screen and (min-width: 1px) and (max-width: 1350px) {
            margin-left:-10px;
        }
        li {
            flex: 1;
            min-width: 25%;
            max-width: 25%;
            margin-bottom:40px;
            @media screen and (min-width: 1px) and (max-width: 1350px) {
                margin-bottom:10px;
            }
            @media screen and (min-width: 1px) and (max-width: 1050px) {
                min-width: 33.33%;
                max-width: 33.33%;
            }
            @media screen and (min-width: 1px) and (max-width: 768px) {
                min-width: 50%;
                max-width: 50%;
            }
            @media screen and (min-width: 1px) and (max-width: 460px) {
                min-width: 100%;
                max-width: 100%;
            }
            .gall_div {
                height:100%;
                a {
                    display: block;
                    margin-left:40px;
                    padding:25px;
                    border:1px solid #ddd;
                    box-sizing: border-box;
                    height:100%;
                    &:hover,
                    &:focus {
                        border:2px solid #db4651;
                        padding:24px;
                    }
                    .item01 {
                        margin-bottom:20px;
                        text-align: center;
                        //@include fixSize($fix43)
                        img {
                            max-height: 400px;
                            @media screen and (min-width: 1px) and (max-width: 460px) {
                                max-height: 300px;
                            }
                        }
                    }
                    h3 {
                        font-size:20px;
                        height: 65px;
                        overflow: hidden;
                        font-weight: 500;
                        word-break: break-all;
                    }
                    .item02 {
                        border-top: 1px solid #000;
                        font-size:15px;
                        padding-top:30px;
                        &__in {
                            position: relative;
                            padding-left:75px;
                            word-break: break-all;
                            min-height:25px;
                            strong {
                                position: absolute;
                                top:0;
                                left:0;
                            }
                        }
                        p {
                            font-size:16px;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                            overflow: hidden;
                        }
                    }
                    @media screen and (min-width: 1px) and (max-width: 1350px) {
                        margin-left:10px;
                        padding:15px;
                        &:hover,
                        &:focus {
                            border:2px solid #db4651;
                            padding:14px;
                        }
                    }
                    @media screen and (min-width: 1px) and (max-width: 460px) {
                        
                        .item01 {
                            margin-bottom:10px;
                        }
                        h3 {
                            font-size:18px;
                            height:60px;
                        }
                        .item02 {
                            padding-top:10px;
                            &__in {
                                padding-left:0;
                                strong {
                                    position: relative;
                                    display: block;
                                }
                                margin-bottom: 10px;
                            }
                        }
                    }
                }
            }
        }
    }
}
.new_gall5 { //소장품갤러리
    ul {
        display: flex;
        flex-wrap: wrap;
        margin-left:-40px;
        @media screen and (min-width: 1px) and (max-width: 1350px) {
            margin-left:-10px;
        }
        li {
            flex: 1;
            min-width: 25%;
            max-width: 25%;
            margin-bottom:40px;
            @media screen and (min-width: 1px) and (max-width: 1350px) {
                margin-bottom:10px;
            }
            @media screen and (min-width: 1px) and (max-width: 1050px) {
                min-width: 33.33%;
                max-width: 33.33%;
            }
            @media screen and (min-width: 1px) and (max-width: 768px) {
                min-width: 50%;
                max-width: 50%;
            }
            @media screen and (min-width: 1px) and (max-width: 460px) {
                min-width: 100%;
                max-width: 100%;
            }
            .gall_div {
                height:100%;
                a {
                    display: block;
                    margin-left:40px;
                    padding:25px;
                    border:1px solid #ddd;
                    box-sizing: border-box;
                    height:100%;
                    &:hover,
                    &:focus {
                        border:2px solid #db4651;
                        padding:24px;
                    }
                    .item01 {
                        margin-bottom:20px;
                        text-align: center;
                        @include fixSize($fix11)
                        // img {
                        //     max-height: 400px;
                        //     @media screen and (min-width: 1px) and (max-width: 460px) {
                        //         max-height: 300px;
                        //     }
                        // }
                    }
                    h3 {
                        font-size:20px;
                        height: 31px;
                        text-align: center;
                        overflow: hidden;
                        font-weight: 500;
                        word-break: break-all;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                    }
                    @media screen and (min-width: 1px) and (max-width: 1350px) {
                        margin-left:10px;
                        padding:15px;
                        &:hover,
                        &:focus {
                            border:2px solid #db4651;
                            padding:14px;
                        }
                    }
                    @media screen and (min-width: 1px) and (max-width: 460px) {
                        
                        .item01 {
                            margin-bottom:10px;
                        }
                        h3 {
                            margin-bottom:0px;
                            font-size:18px;
                            height:29px;
                        }
                        .item02 {
                            padding-top:10px;
                            &__in {
                                padding-left:0;
                                strong {
                                    position: relative;
                                    display: block;
                                }
                                margin-bottom: 10px;
                            }
                        }
                    }
                }
            }
        }
    }
}
.new_gall6 { //소장품 검색, 여성독립운동가
    ul {
        display: flex;
        flex-wrap: wrap;
        margin-left:-40px;
        @media screen and (min-width: 1px) and (max-width: 1350px) {
            margin-left:-10px;
        }
        li {
            flex: 1;
            min-width: 25%;
            max-width: 25%;
            margin-bottom:40px;
            @media screen and (min-width: 1px) and (max-width: 1350px) {
                margin-bottom:10px;
            }
            @media screen and (min-width: 1px) and (max-width: 1050px) {
                min-width: 33.33%;
                max-width: 33.33%;
            }
            @media screen and (min-width: 1px) and (max-width: 768px) {
                min-width: 50%;
                max-width: 50%;
            }
            @media screen and (min-width: 1px) and (max-width: 460px) {
                min-width: 100%;
                max-width: 100%;
            }
            .gall_div {
                height:100%;
                a {
                    display: block;
                    margin-left:40px;
                    padding:25px;
                    border:1px solid #ddd;
                    box-sizing: border-box;
                    height:100%;
                    &:hover,
                    &:focus {
                        border:2px solid #db4651;
                        padding:24px;
                    }
                    .item01 {
                        margin-bottom:20px;
                        text-align: center;
                        @include fixSize($fix11);
                        // img {
                        //     max-height: 400px;
                        //     @media screen and (min-width: 1px) and (max-width: 460px) {
                        //         max-height: 300px;
                        //     }
                        // }
                        img {
                            object-fit: contain;
                        }
                    }
                    h3 {
                        font-size:20px;
                        min-height: 31px;
                        text-align: center;
                        overflow: hidden;
                        font-weight: 500;
                    }
                    .item02 {
                        border-top: 1px solid #000;
                        font-size:15px;
                        padding-top:30px;
                        &__in {
                            position: relative;
                            padding-left:75px;
                            word-break: break-all;
                            min-height:25px;
                            strong {
                                position: absolute;
                                top:0;
                                left:0;
                            }
                        }
                        p {
                            font-size:16px;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                            overflow: hidden;
                        }
                    }
                    @media screen and (min-width: 1px) and (max-width: 1350px) {
                        margin-left:10px;
                        padding:15px;
                        &:hover,
                        &:focus {
                            border:2px solid #db4651;
                            padding:14px;
                        }
                    }
                    @media screen and (min-width: 1px) and (max-width: 460px) {
                        
                        .item01 {
                            margin-bottom:10px;
                        }
                        h3 {
                            margin-bottom:0px;
                            font-size:18px;
                            height:29px;
                        }
                    }
                }
            }
        }
    }
}


.new_gall7 { // 이벤트 참여 게시판
    ul {
        display: flex;
        flex-wrap: wrap;
        margin-left:-40px;
        @media screen and (min-width: 1px) and (max-width: 1350px) {
            margin-left:-10px;
        }
        li {
            flex: 1;
            min-width: 25%;
            max-width: 25%;
            margin-bottom:40px;
            @media screen and (min-width: 1px) and (max-width: 1350px) {
                margin-bottom:10px;
            }
            @media screen and (min-width: 1px) and (max-width: 1050px) {
                min-width: 33.33%;
                max-width: 33.33%;
            }
            @media screen and (min-width: 1px) and (max-width: 768px) {
                min-width: 50%;
                max-width: 50%;
            }
            @media screen and (min-width: 1px) and (max-width: 460px) {
                min-width: 100%;
                max-width: 100%;
            }
            .gall_div {
                height:100%;
                a {
                    display: block;
                    margin-left:40px;
                    padding:25px;
                    border:1px solid #ddd;
                    box-sizing: border-box;
                    height:100%;
                    &:hover,
                    &:focus {
                        border:2px solid #db4651;
                        padding:24px;
                    }
                    .item01 {
                        margin-bottom:20px;
                        text-align: center;
                        @include fixSize($fix11);
                        img {
                            // max-height: 400px;
                            // @media screen and (min-width: 1px) and (max-width: 460px) {
                            //     max-height: 300px;
                            // }
                            object-fit: cover;
                        }
                        .item-ing {
                            position: absolute;
                            top:0;
                            right:0;
                            background-color: #db4651;
                            color:#fff;
                            border-radius: 0 0 0 10px;
                            display: block;
                            padding:5px 10px
                        }
                        .item-end {
                            position: absolute;
                            top:0;
                            right:0;
                            background-color: #eee;
                            color:#000;
                            border-radius: 0 0 0 10px;
                            display: block;
                            padding:5px 10px
                        }
                    }
                    h3 {
                        font-size:20px;
                        max-height: 65px;
                        overflow: hidden;
                        font-weight: 500;
                        word-break: break-all;
                    }
                    @media screen and (min-width: 1px) and (max-width: 1350px) {
                        margin-left:10px;
                        padding:15px;
                        &:hover,
                        &:focus {
                            border:2px solid #db4651;
                            padding:14px;
                        }
                    }
                    @media screen and (min-width: 1px) and (max-width: 460px) {
                        
                        .item01 {
                            margin-bottom:10px;
                        }
                        h3 {
                            font-size:18px;
                            height:60px;
                        }
                    }
                }
            }
        }
    }
}
// 이전글 다음글
.new-bn-box {
    display:flex;
    padding-top: 14px;
    padding-bottom: 14px;
    border-top:1px solid #000;
    border-bottom:1px solid #000;
    margin-bottom:30px;
    @media screen and (min-width: 1px) and (max-width: 768px) {
        display: block;
        padding-top:4px;
        padding-bottom:4px;
    }
    
    > p {
        flex:1;
        @media screen and (min-width: 1px) and (max-width: 768px) {
            flex:none;
            padding-top:5px;
            padding-bottom:5px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            display: block;
            &:last-child {
                border-top:1px dotted #ddd;
            }
        }

        a:hover,
        a:focus {
            text-decoration: underline;
        }
       
    }
    &__prev {
        padding-left:120px;
        position: relative;
        strong {
            position: absolute;
            top:0;
            left:0;
            padding-left:35px;
            &:after {
                position: absolute;
                top:5px;
                left:0;
                @include sprite($bbs-prev);
                @media screen and (min-width: 1px) and (max-width: 768px) {
                    display:none;
                }
            }
        }
        @media screen and (min-width: 1px) and (max-width: 768px) {
            padding-left:0;
            text-align: left;
            strong {
                position: relative;
                padding-right:20px;
                padding-left:10px;
            }
        }
    }
    &__next {
        text-align: right;
        padding-right:120px;
        position: relative;
        strong {
            position: absolute;
            top:0;
            right:0;
            padding-right:35px;
            &:after {
                position: absolute;
                top:5px;
                right:0;
                @include sprite($bbs-next);
                @media screen and (min-width: 1px) and (max-width: 768px) {
                    display:none;
                }
            }
        }
        @media screen and (min-width: 1px) and (max-width: 768px) {
            padding-right:0;
            text-align: left;
            strong {
                position: relative;
                padding-right:20px;
                padding-left:10px;
            }
        }
    }
}

// 게시판 뷰
.new-bbs-view {
    &__top {
        border-bottom:1px solid #000;
        .h3Type3 {
            border-bottom: 0px;
            padding-left:15px;
            padding-right:15px;
            padding-bottom: 0px;
            @media screen and (min-width: 1px) and (max-width: 768px) {
                padding-left:5px;
                padding-right:5px;
            }
        }
        &__list {
            display: flex;
            padding-left:15px;
            padding-right:15px;
            @media screen and (min-width: 1px) and (max-width: 768px) {
                display: block;
                padding-left:5px;
                padding-right:5px;
                font-size:16px;
            }
            li{
                padding-right: 40px;
                color:#000;
                strong {
                    font-weight: normal;
                    color:#777;
                }

            }
            padding-bottom: 20px;
            
        }
    }
}
.add-files {
    padding:15px 0 15px 40px;
    border-bottom:1px solid #ddd;
    position: relative;
    &::after {
        position: absolute;
        // top:50%;
        // transform: translateY(-50%);
        top: 19px;
        left:15px;
        @include sprite($file-icon);
    }
    a:focus,
    a:hover {
        text-decoration: underline;
    }
}
.new-bbs-cont {
    padding: 30px 15px;
    a:focus,
    a:hover {
        text-decoration: underline;
    }
    @media screen and (min-width: 1px) and (max-width: 768px) {
        padding:20px 5px;
    }
}

//구술영상기록 뷰
.new-bbs-view {
    margin-bottom:30px;
}
.new-bbs-cont {
    .left_img {
        width:62% !important;
        iframe {
            width: 100%;
            border:0px;
        }
        @media screen and (min-width: 1px) and (max-width: 768px) {
            width: 100% !important;
            iframe {
                height: auto;
                min-height:300px;
            }
        }
        @media screen and (min-width: 1px) and (max-width: 480px) {
            iframe {
                min-height:200px;
            }
        }
    }
    .right_table {
        width:35% !important;
        textarea {
            width:100% !important;
            box-sizing: border-box;
        }
        @media screen and (min-width: 1px) and (max-width: 768px) {
            width: 100% !important;
        }
    }
}


//뉴스레터 상단 텍스트

.go-news-scrb {
    display: inline-block;
    padding:10px 20px;
    font-size: 18px;
    border:1px solid #000;
    &:focus {
        text-decoration: underline;
    }
}
.news-letter-top {
    border: 2px solid #db4651;
    margin-bottom:30px;
    padding:30px 270px 30px 170px;
    background-image: url('../../resources/images/cont/newsletter-icon.png');
    background-position: 30px center;
    background-repeat: no-repeat;
    position: relative;
    min-height: 54px;
    .go-news-scrb {
        position: absolute;
        top:30px;
        right:40px;
    }
    @media screen and (min-width: 1px) and (max-width: 980px) {
        br {
            display: none;
        }
    }
    @media screen and (min-width: 1px) and (max-width: 768px) {
        padding-right: 30px;
        p {
            margin-bottom:10px;
        }
        .go-news-scrb {
            position: relative;
            right:0;
            top:0;
        }
    }
    @media screen and (min-width: 1px) and (max-width: 530px) {
        background-position: center 30px;
        padding:120px 30px 30px 30px;
        text-align: center;
    }
}

//뉴스레터 구독신청해지
.basic-box {
    border: 1px solid #eee;
    padding:30px;
    margin-bottom:30px;
}

//교육신청페이지
.openbox-btn {
    @include searchBtn;
}
.open-box {
    height: 0;
    overflow: hidden;
    transition: all 0.3s ease 0s;
    padding-top:0;
    &.on {
        height: auto;
        display: block;
        padding-top:10px;
    }
}
.privacy-box {
    border:1px solid #ddd;
    padding:20px;
    line-height: 1.8;
    color:#000;
    margin-bottom: 20px;
}
.cfrm-layer-box {
    position: relative;
    z-index: 99999999;
    &__bg {
        display: none;
        background-color: rgba(0,0,0,0.5);
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 999;
    }
    &__in {
        display: none;
        position: fixed;
        top:50%;
        left:50%;
        //transform: translate(-50%,-50%);
        margin-left:-185px;
        margin-top:-150px;
        z-index: 999;
        background-color: #fff;
        min-width:370px;
        box-sizing: border-box;
        
        a:hover,
        a:focus {
            border:1px solid #ddd;
            border-radius: 5px;
        }
        &__close {
            position: absolute;
            top:10px;
            right:10px;
            font-size: 30px;
            text-align: center;
            width: 50px;
            font-weight: normal;
            font-family: -apple-system,Arial,sans-serif;
            
        }
        h3 {
            background-color: #f8f8f8;
            font-size: 23px;
            font-family: $font1;
            font-weight: bold;
            padding:15px 20px;
        }
        &__cont {
            padding:20px;
            text-align: center;
            .cfrm-item1 {
                font-size:20px;
                font-weight: 600;
                padding-bottom: 20px;
            }
        }
    }
    &.on {
        .cfrm-layer-box__bg,
        .cfrm-layer-box__in {
            display: block;
        } 

    }
}

@media screen and (min-width: 1px) and (max-width: 768px) {
    .gongmojun .item01 {
        width:100% !important;
        margin-right:0 !important;
        float:none !important;
        margin-bottom:30px;
    }
    .gongmojun .item02 {
        width:100% !important;
        float:none !important;
    }
}