

/*	메인페이지
===========================*/


.main-section {
	position:relative;
	background-color:#fff;
	font-family: $font1;
}
.main-section__in {
	position:relative;
	min-height: 500px;
	@include floatWrap;
}
.main-laywrap-top{
	&__in {
		@include floatWrap;
		width:$siteSize;
		margin:0 auto;
		padding-bottom:80px;
		@media screen and (min-width:1px) and (max-width:$siteSize) {
			width:auto;
			padding-bottom: 40px;
			padding-left:10px;padding-right:10px;
		}
		@media screen and (min-width:1px) and (max-width:1050px) {
			padding-bottom:15px;
		}
	}
}
	.main-lay-1 {
		float: left;
		width: 100%;
		margin-bottom: 40px;
		@media screen and (min-width:1px) and (max-width:$siteSize) {
			margin-bottom: 20px;
		}
		@media screen and (min-width:1px) and (max-width:1050px) {
			margin-bottom:10px;
		}
	}

	.main-lay-2 {
		float: left;
		width: 1100px;
		@media screen and (min-width:1px) and (max-width:$siteSize) {
			width:60%;
		}
		@media screen and (min-width:1px) and (max-width:1050px) {
			width:100%;
			margin-bottom:10px;
		}
	}

	.main-lay-3 {
		float: right;
		width: 620px;
		@media screen and (min-width:1px) and (max-width:$siteSize) {
			width:40%;
		}
		@media screen and (min-width:1px) and (max-width:1050px) {
			width:100%;
		}
	}
	
.main-laywrap-middle{
	padding-top:80px;
	padding-bottom:80px;
	background-color: #fafafa;
	&__in {
		@include floatWrap;
		width:$siteSize;
		margin:0 auto;
		@media screen and (min-width:1px) and (max-width:$siteSize) {
			width:auto;
			padding-left:10px;padding-right:10px;
		}
	}
	@media screen and (min-width:1px) and (max-width:1050px) {
		padding-bottom:15px;
		padding-top: 15px;
	}
}
.main-lay-4 {
	float: left;
	width: 1280px;
	@media screen and (min-width:1px) and (max-width:$siteSize) {
		width:70%;
	}
	@media screen and (min-width:1px) and (max-width:1050px) {
		width:100%;
		margin-bottom:15px;
	}
}

.main-lay-5 {
	float: right;
	width: 400px;
	@media screen and (min-width:1px) and (max-width:$siteSize) {
		width:28%;
	}
	@media screen and (min-width:1px) and (max-width:1050px) {
		width:100%;
	}
}
.main-laywrap-bottom{
	padding-top:80px;
	padding-bottom:80px;
	@media screen and (min-width:1px) and (max-width:1050px) {
		padding-top:15px;padding-bottom:15px;
	}
	&__in {
		@include floatWrap;
		width:$siteSize;
		margin:0 auto;
		@media screen and (min-width:1px) and (max-width:$siteSize) {
			width:auto;
			padding-left:10px;padding-right:10px;
		}
	}
}
.main-lay-6 {
	float: left;
	width: 480px;
	margin-right: 80px;
	@media screen and (min-width:1px) and (max-width:$siteSize) {
		width:30%;
		margin-right:2%;
	}
	@media screen and (min-width:1px) and (max-width:1050px) {
		width:49%;
		margin-right:1%;
		margin-bottom:15px;
	}
	@media screen and (min-width:1px) and (max-width:768px) {
		width:100%;
		margin-right:0;
		float:none;
	}
}

.main-lay-7 {
	float: left;
	width: 480px;
	@media screen and (min-width:1px) and (max-width:$siteSize) {
		width:30%;
		margin-right:2%;
	}
	@media screen and (min-width:1px) and (max-width:1050px) {
		width:49%;
		margin-right:0;
		margin-left:1%;
		margin-bottom:15px;
	}
	@media screen and (min-width:1px) and (max-width:768px) {
		width:100%;
		margin-left:0;
		float:none;
	}
}

.main-lay-8 {
	float: right;
	width: 640px;
	@media screen and (min-width:1px) and (max-width:$siteSize) {
		width:36%;
	}
	@media screen and (min-width:1px) and (max-width:1050px) {
		width:100%;
		margin:0 auto;
	}
	@media screen and (min-width:1px) and (max-width:768px) {
		width:100%;
		margin-left:0;
		float:none;
	}
}

@media screen and (min-width:1px) and (max-width:$siteSize) {
	.main-section__in {width:auto;}
}

@media screen and (min-width:1px) and (max-width:768px) {
	.main-section {padding-top:51px;}
}

/*전시관 관람정보*/
.main-info-line {
	&__list {
		display: table;
		width: 100%;
		padding-top:50px;
		padding-bottom:50px;
		padding-left:10px;
		padding-right:10px;
		box-sizing: border-box;
		@media screen and (min-width:1px) and (max-width:1350px) {
			display: block;
			@include floatWrap;
			padding-top:20px;
			padding-bottom:0;
			padding-left:0;
			padding-right: 0;
		}
		@media screen and (min-width:1px) and (max-width:560px) {
			padding-top:15px;
			border-bottom: 1px solid #ddd;
		}
		li {
			display: table-cell;
			font-size:21px;
			color:#666;
			position: relative;
			box-sizing: border-box;
			@media screen and (min-width:1px) and (max-width:1350px) {
				width:50%;
				display: block;
				float: left;
				text-align: left !important;
				margin-bottom:20px;
				font-size: 18px;
			}
			@media screen and (min-width:1px) and (max-width:560px) {
				margin-bottom: 15px;
			}
			@media screen and (min-width:1px) and (max-width:480px) {
				width:100%;
			}
			
			strong {
				color:#000;
				display: inline-block;
				padding-right:20px;
				padding-left:38px;
				position: relative;
				&:before {
					position: absolute;
					top:50%;
					left:0;
					transform: translateY(-50%);
				}
			}
			&.type1 {
				strong:before {
					@include sprite($main-info-1);
					@media screen and (min-width:1px) and (max-width:480px) {
						@include sprite($main-info-1,1.3);
					}
				}
			}
			&.type2 {
				text-align: center;
				strong:before {
					@include sprite($main-info-2);
					@media screen and (min-width:1px) and (max-width:480px) {
						@include sprite($main-info-2,1.3);
					}
				}
			}
			&.type3 {
				text-align: right;
				strong:before {
					@include sprite($main-info-3);
					@media screen and (min-width:1px) and (max-width:480px) {
						@include sprite($main-info-3,1.3);
					}
				}
			}
			&.type4 {
				text-align: right;
				strong:before {
					@include sprite($main-info-4);
					@media screen and (min-width:1px) and (max-width:480px) {
						@include sprite($main-info-4,1.3);
					}
				}
			}
			@media screen and (min-width:1px) and (max-width:1050px) {
				font-size:15px;
				padding-left:110px;
				strong {
					position: absolute;
					top:0;
					left:0;
				}
			}
			@media screen and (min-width:1px) and (max-width:480px) {
				padding-left: 30px;
				padding-top: 25px;
				margin-bottom:10px;
				strong {
					padding-left:30px
				}
			}
			
		}
	}
}
//
@mixin mainTitle(){
	font-size:36px;
	font-weight: 500;
	margin-bottom:20px;
	@media screen and (min-width:1px) and (max-width:1750px) {
		font-size: 30px;
	}
	@media screen and (min-width:1px) and (max-width:1350px) {
		font-size:24px;
	}
	@media screen and (min-width:1px) and (max-width:768px) {
		font-size:18px;
	}
}
@mixin moreBtn(){
	font-size:16px;
	display: block;
	padding:5px;
	&:focus {
		border:1px solid #000;
	}
	padding-right:30px;
	&:after {
		position: absolute;
		top:7px;
		display: block;
		content: '+';
		right:0;
		width:15px;
		height:15px;
		line-height: 15px;
		font-size:25px;
	}
}
// 전시
.exb-box {
	position: relative;
}
.exb-title {
	@include mainTitle();
	@media screen and (min-width:1px) and (max-width:1350px) {
		margin-bottom:50px;
	}
	@media screen and (min-width:1px) and (max-width:1050px) {
		margin-bottom:20px;
	}
}
.exb-more {
	@include moreBtn();
	position: absolute;
	top:10px;
	right:50px;
	@media screen and (min-width:1px) and (max-width:1050px) {
		right:0;
		top:0px;
	}
}
.exb-list {
	height: 510px;
	@media screen and (min-width:1px) and (max-width:$siteSize) {
		height:auto;
	}
	.slick-arrow {
		position: absolute;
		top:0;
		width:360px;
		height:100%;
		z-index: 9998;
		text-indent: -9999px;
		@media screen and (min-width:1px) and (max-width:$siteSize) {
			width:33%;
		}
		@media screen and (min-width:1px) and (max-width:480px) {
			width:15%
		}
		&:after {
			content:' ';
			display:block;
			width:50px;
			height:50px;
			background-color: #fff;
			border-radius: 100%;
			background-position: center center;
			background-repeat: no-repeat;
			position: absolute;
			top:50%;
			margin-top:-25px;
			box-shadow: 1px 4px 20px -10px rgba(0, 0, 0, 1);
		}
		&.slick-prev {
			left:0;
			&:after {
				right:-4px;
				background-image: url('../../resources/images/icons/slick-arrow-left.png');
			}
		}
		&.slick-next {
			right:0;
			&:after {
				left:-4px;
				background-image: url('../../resources/images/icons/slick-arrow-right.png');
			}
		}
		
		&:focus {
			&:after {
				border:1px solid gold;
			}
		}
	}
	&__item {
		width:350px;
		a {
			display: block;
			text-align: center;
			position: relative;
			transition: all 0.3s ease 0s;
			margin-top:50px;
			img {
				margin: 0 auto;
				width:80%; transition: all 0.3s ease 0s;
				
			}

		}
		&__info {
			position: absolute;
			background-color: rgba(0,0,0,0.75);
			color:#fff;
			opacity: 0;
			top:0;
			left:10%;
			width:80%;
			height:100%;transition: all 0.3s ease 0s;
			box-sizing: border-box;
			padding-top:30%;
			.slick-center & {
				width:100%;
				left:0;
				padding-top:35%;
			}
			a:hover &,
			a:focus &{
				opacity: 1;
			}
			strong {
				font-size: 28px;
				display: block;
				padding-left:10px;
				padding-right:10px;
				margin-bottom:30px;
			}
			.item1 {
				font-size: 20px;
				margin-bottom:50px;
			}
			.item2 {
				border:1px solid #fff;
				font-size: 20px;
				padding:10px 20px;
				display: inline-block;
			}
			@media screen and (min-width:1px) and (max-width:$siteSize) {
				padding-top:25px;
				.slick-center & {
					padding-top:35px;
				}
				strong {font-size:22px;margin-bottom: 20px;}
				

			}
			@media screen and (min-width:1px) and (max-width:1350px) {
				padding-top:25px;
				.slick-center & {
					padding-top:25px;
				}
				strong {font-size:17px;margin-bottom: 10px;}
				
				.item1 {font-size:15px;margin-bottom:10px;}
				.item2 {font-size:15px;padding-left:10px;padding-right:10px;}

			}
		}
		&.slick-center {
			a {
				margin-top:0;
				margin-bottom: 15px;
				img {
					width:100%;
				}
				box-shadow: 4px 5px 19px -17px rgba(0, 0, 0, 0.75);
			}
		}
	}
}

// 교육
.edu-box {
	position: relative;
}
.edu-title {
	@include mainTitle();
}
.edu-more {
	@include moreBtn();
	position: absolute;
	top:10px;
	right:0;
	@media screen and (min-width:1px) and (max-width:1050px) {
		top:0px;
	}
}
.edu-list {
	@include floatWrap;
	margin-left:-20px;
	@media screen and (min-width:1px) and (max-width:768px) {
		margin-left:-5px;
	}
	&__item {
		width:50%;
		float:left;
		a {
			display: block;
			margin-left:20px;
			padding:9px;
			border:1px solid #ddd;
			&:hover,
			&:focus {
				background-color: #fafafa;
			}
			&:focus {
				text-decoration: underline;
			}
			@media screen and (min-width:1px) and (max-width:768px) {
				margin-left:5px;
			}
		}
		&__title {
			font-size: 22px;
			margin-bottom:20px;
			height:100px;
			line-height:1.6;
			overflow: hidden;
			@media screen and (min-width:1px) and (max-width:1350px) {
				font-size:17px;
				height:80px;
				margin-bottom:10px;
			}
		}
		&__info {
			font-size: 18px;
			color:#666;
			padding-bottom:10px;
			height:70px;
			overflow: hidden;
			box-sizing: border-box;
			p {
				margin-bottom:5px;
			}
			@media screen and (min-width:1px) and (max-width:1350px) {
				font-size:14px;
			}
		}
	}
}
.img-box {
	@include fixSize($fix11);
	margin-bottom:20px;
	@media screen and (min-width:1px) and (max-width:768px) {
		margin-bottom:10px;
	}
}

// 교육신청
.edu-enroll {
	padding: 30px 30px 0 30px;
	height: 243px;
	height: 183px;
	position: relative;
	background-image: url('../../resources/images/cont/enroll-bg.png');
	background-position: bottom right;
	background-repeat: no-repeat;
	@media screen and (min-width:1px) and (max-width:768px) {
		padding: 15px 15px 0 15px;
		height:200px;
	}
	@media screen and (min-width:1px) and (max-width:480px) {
		background-size: 40% auto;
	}
	&__title {
		font-size: 28px;
		font-weight: 500;
		margin-bottom:20px;
		margin-bottom:5px;
		@media screen and (min-width:1px) and (max-width:1050px) {
			font-size: 22px;
		}
		@media screen and (min-width:1px) and (max-width:768px) {
			font-size:18px;
		}
	}
	&__text {
		margin-bottom:10px;
		font-size: 18px;
		line-height: 1.6;
		@media screen and (min-width:1px) and (max-width:768px) {
			font-size:16px;
		}
	}
	&__more {
		position: absolute;
		bottom:30px;
		left:30px;
		display: inline-block;
		padding:10px 20px;
		border:1px solid #000;
		transition: all 0.3s ease 0s;
		&:hover {
			background-color: #9ed3cb;
		}
		&:focus {
			background-color: #9ed3cb;
			text-decoration: underline;
		}
		@media screen and (min-width:1px) and (max-width:768px) {
			left:15px;
		}
	}
}

// 이달의 소장품
.collection {
	border:1px solid #dddddd;
	padding:30px;
	position: relative;
	height:200px;
	@media screen and (min-width:1px) and (max-width:$siteSize) {
		padding:20px;
	}
	@media screen and (min-width:1px) and (max-width:768px) {
		padding:15px;
	}
	&__title {
		font-size: 28px;
		padding-bottom:15px;
		font-weight: 500;
		position: relative;
		margin-bottom: 20px;
		&:before {
			content: ' ';
			display: block;
			width:25px;
			height:3px;
			background-color: #db4651;
			position: absolute;
			bottom: 0;
			left:0;
		}
		@media screen and (min-width:1px) and (max-width:$siteSize) {
			font-size:22px;
		}
		@media screen and (min-width:1px) and (max-width:768px) {
			font-size:18px;
		}
	}
	&__date {
		font-size: 20px;
		max-width: 50%;
		strong {
			font-weight: 600;
			font-size: 43px;
		}
		@media screen and (min-width:1px) and (max-width:$siteSize) {
			font-size:18px;
			strong {font-size: 26px;}
		}
	}
	&__img-out {
		width:200px;
		position: absolute;
		top:30px;
		right:30px;
		display: block;
		@media screen and (min-width:1px) and (max-width:$siteSize) {
			width:40%;			
		}
		@media screen and (min-width:1px) and (max-width:768px) {
			max-width: 200px;
			top:20px;
		}
	}
	&__img {
		@include fixSize($fix11);
	}
	&__more {
		position: absolute;
		bottom:30px;
		left:30px;
		display: inline-block;
		padding:10px 20px;
		border:1px solid #000;
		transition: all 0.3s ease 0s;
		&:hover {
			background-color: #eee;
		}
		&:focus {
			background-color: #eee;
			text-decoration: underline;
		}
		@media screen and (min-width:1px) and (max-width:768px) {
			left:15px;
		}
	}
}

//VR 온라인전시
.vr-box {
		border:1px solid #dddddd;
		padding:30px;
		position: relative;
		height:200px;
		background-image: url('../../resources/images/cont/vr-bg.jpg');
		background-position: 0 0;
		background-repeat: no-repeat;
		background-size: cover;
		@media screen and (min-width:1px) and (max-width:$siteSize) {
			padding:20px;
		}
		@media screen and (min-width:1px) and (max-width:768px) {
			padding:15px;
			height:170px;
		}
	&__title {
		font-size: 28px;
		font-weight: 500;
		position: relative;
		margin-bottom: 20px;
		@media screen and (min-width:1px) and (max-width:$siteSize) {
			font-size:22px;
		}
		@media screen and (min-width:1px) and (max-width:768px) {
			font-size:18px;
			margin-bottom:10px;
		}
	}
	&__text {
		font-size: 18px;
		line-height: 1.6;
		@media screen and (min-width:1px) and (max-width:$siteSize) {
			word-break: break-all;
			br {display: none;}
		}
		@media screen and (min-width:1px) and (max-width:768px) {
			font-size:16px;
		}
	}
	&__more {
		position: absolute;
		bottom:30px;
		left:30px;
		display: inline-block;
		padding:10px 20px;
		border:1px solid #000;
		transition: all 0.3s ease 0s;
		&:hover {
			background-color: #ddd;
		}
		&:focus {
			background-color: #ddd;
			text-decoration: underline;
		}
		@media screen and (min-width:1px) and (max-width:768px) {
			left:15px;
		}
	}
}

/*팝업존*/
.popupzone {

    position: relative;
    margin-top: 0px;
    width: 100%;
	height: auto;
    max-width: 640px;
    margin: 0 auto;
	.slick-arrow {border:0px;top:220px;}
	.slick-prev {position: absolute;left:10px;z-index: 1;display:none !important;}
	.slick-next {position: absolute;right:10px;z-index: 1;display:none !important;}
	.slick-slide a:hover,
	.slick-slide a:focus {opacity: 0.8;}

	&__tit {@include hiddenWord}
	&__item > img {width:100%}
}

.popupzone-nav {
	@include slickDots;
}

@media screen and (min-width:1px) and (max-width:$siteSize) {
	//.popupzone .popupzone-list__item img {height:230px;object-fit:cover}
}
@media screen and (min-width:1px) and (max-width:1050px) {
	.popupzone .popupzone-list__item img {margin: 0 auto;}
}
@media screen and (min-width:1px) and (max-width:768px) {
	.popupzone {max-width:600px;margin:0 auto;}
	.popupzone .popupzone-list__item img {height:auto;}
}
// ie9
.lte-ie9 .popupzone .popupzone-list__item {height:340px;overflow: hidden;}
.lte-ie9 .popupzone .popupzone-list__item img {height:auto;}

@media screen and (min-width:1px) and (max-width:$siteSize) {
	.lte-ie9 .popupzone .popupzone-list__item {height:300px;overflow: hidden;}
	.lte-ie9 .popupzone .popupzone-list__item img {height:300px;object-fit:cover}
}
@media screen and (min-width:1px) and (max-width:768px) {
	.lte-ie9 .popupzone .popupzone-list__item {height:auto;overflow: hidden;}
	.lte-ie9 .popupzone .popupzone-list__item img {height:auto;}
}

/*팝업존2*/
.popupzone-nav2 {
	@include slickDots;
}

.popupzone-nav3 {
	@include slickDots;
}
