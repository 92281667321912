
/*메인슬라이드*/
.main-visual {
	//height: 620px;
}/*이미지의 상하사이즈로 설정*/

.main-visual {
	position: relative;
	margin-top: 0px;
	width: 100%;
	
	.slick-arrow {border:0px;top:220px;}
	.slick-prev {position: absolute;left:10px;z-index: 1;display:none !important;}
	.slick-next {position: absolute;right:10px;z-index: 1;display:none !important;}

	.slick-dots-wrap-outwrap{
		text-align:left;
		position: absolute;
		z-index: 3;
		bottom: 0px;
		left: 50%;
		margin-left: -$siteSize/2;
		@media screen and (min-width:1px) and (max-width:$siteSize) {
			//width:100%;
			left:0;
			margin-left: 0;
		}
	}
	.slick-dots-wrap {display:inline-block;text-align:center;margin-top:0;z-index:999;
		background-color: rgba(0,0,0,0.6);
		padding:0;border-radius: 0px;}
	.myslickcarousel {display:inline-block;
		padding:0 0 0 10px;
		@media screen and (min-width:1px) and (max-width:768px) {
			margin-top:7px;
		}
	}
	.slick-dots-on-off {display:inline-block;
		font-size: 0;
		li {
			display: inline-block;
			vertical-align: middle;
			a {color:gold;}
		}
		vertical-align: middle;

	}

	*+ html .myslickcarousel {display:inline;}
	*+ html .slick-dots-on-off {display:inline;}
	*+ html .slick-dots-on-off li {display:inline;}


	.slick-dots-list {
		display:inline-block !important;
		vertical-align: middle;
		li {
			display:inline-block;margin-right:3px;
			button { background-color:#444;display:inline-block;color:gold;
				vertical-align: middle;
				overflow: visible;border:3px solid #999;width:22px;height:22px;line-height:22px;border-radius:22px;font-size:0;
				@media screen and (min-width:1px) and (max-width:768px) {
					height:auto;
					line-height:1;
				}
			}

		}

		li.slick-active button {background-color:#fff;border:3px solid #fff;
			@media screen and (min-width:1px) and (max-width:768px) {
				border:0px;
				height:auto;
				line-height:1;
			}}
		li button:focus {background-color:#fff;}
		li.slick-active button:focus{background-color:gold;}
	}


	*+ html .slick-dots-list li {display:inline;}
	*+ html .slick-dots-list li button {display:inline;}

	.slick-dots-on-off img {vertical-align:middle;}

	.main-slide-prev {
		display:block;width:60px;height:60px;font-size:0;text-indent: -9999px;
		position: relative;
		border-left:1px solid #554b53;
		&:before {
			position: absolute;
			transform: translate(-50%, -50%);
			left:50%;
			top:50%;
			@include sprite($visual_prev);
		}
		&:focus {background-color:#000;border-radius: 3px;}
		
	}
	.main-slide-next {
		display:block;width:60px;height:60px;font-size:0;text-indent: -9999px;
		position: relative;
		border-left:1px solid #554b53;
		&:before {
			position: absolute;
			transform: translate(-50%, -50%);
			left:50%;
			top:50%;
			@include sprite($visual_next);
		}
		
		&:focus {background-color:#000;border-radius: 3px;}
	}

	.main-slide-pause {
		display:block;width:60px;height:60px;font-size:0;text-indent: -9999px;
		position: relative;
		border-left:1px solid #554b53;
		&:before {
			position: absolute;
			transform: translate(-50%, -50%);
			left:50%;
			top:50%;
			@include sprite($visual_pause);
		}
		
		&:focus {background-color:#000;border-radius: 3px;}
		@media screen and (min-width:1px) and (max-width:768px) {
			height:30px;width:30px;
			&:before {
				@include sprite($visual_pause,1.4);
			}
			border-left: 0px;
		}
	}
	.main-slide-play {
		display:block;width:60px;height:60px;font-size:0;text-indent: -9999px;
		position: relative;
		&:before {
			position: absolute;
			transform: translate(-50%, -50%);
			left:50%;
			top:50%;
			@include sprite($visual_play);
		}
		
		&:focus {background-color:gold;border-radius: 3px;}
	}

	.slick-dots-wrap-outwrap {display:block;}
	.slick-dots-wrap-outwrap.off {display:none;}

	&-prev {position: absolute;left:20px;top:45%;
		@include sprite($main-visual-prev);
		text-indent: -9999px;
		&:focus {border:1px solid gold;border-radius: 5px;}
		
		@media screen and (min-width:1px) and (max-width:1350px) {
			display:none !important;
		}
	}
	&-next {position: absolute;right:20px;top:45%;
		@include sprite($main-visual-next);
		text-indent: -9999px;
		&:focus {border:1px solid gold;border-radius: 5px;}
		@media screen and (min-width:1px) and (max-width:1350px) {
			display:none !important;
		}
	}

}

//total ver
.main-visual .popup-total {display:none;}

.main-visual.ver-total {
	.slick-dots-list {
		font-size: 0;
		margin-top: 7px;
		@media screen and (min-width:1px) and (max-width:768px) {
			margin-top:1px;
		}
		li {margin-right:0;}
		& button {font-size:17px;text-indent: 0;
			background-image:none;display:none;}
		& .slick-active button {
			background-image:none;display:block;width:auto;height:30px;text-align: center;
			color:#fff;
			font-size:22px;
			background-color:transparent;
			font-weight: bold;
			border-color:transparent;
			&:focus {border:2px solid #000;}
			@media screen and (min-width:1px) and (max-width:768px) {
				height:auto;
				font-size:17px;
			}
		}
	}
	.popup-total {
		color: #fff;
		display: inline-block;
		vertical-align: middle;
		font-size: 15px;
		margin-top: 8px;
		margin-left: 4px;
		@media screen and (min-width:1px) and (max-width:768px) {
			margin-top:0;
		}
	}
	*+ html .popup-total {display:inline;}
	.popup-total-num {padding-left:4px;
		padding-right:10px;}

}
//arrow ver
.nav-arrow {display:none !important;}
.main-visual.ver-arrow  {
		.nav-arrow {display:block !important;
		
			@media screen and (min-width:1px) and (max-width:768px) {
				display: none !important;
			}
		}
		.main-slide-play {display:none;}
}

@media screen and (min-width:1px) and (max-width:$siteSize) {
	.main-visual {width:100%;padding-top:0;height: auto;}
	.main-visual {margin-top:0;}
	.main-visual .slick-arrow {top:150px;}
}
@media screen and (min-width:1201px) and (max-width:$siteSize) {
	//.main-slide__item img {width:1600px}
}
@media screen and (min-width:901px) and (max-width:1200px) {
	.main-slide__item img {width:1250px}
}
@media screen and (min-width:769px) and (max-width:900px) {
	.main-slide__item img {width:1150px}
}
@media screen and (min-width:1px) and (max-width:768px) {
	.main-slide__item img {width:100%}
	.main-visual .slick-arrow {top:70px;}
}


/*video*/
.main-visual {overflow:hidden;background-color:#000;}
.video-box {position: relative;}
.video-box__over {
	position: absolute;
	top:0;left:0;width:100%;
	z-index: 9999;
	height:100%;
}
.video-box video {
	height: 620px;
}
.video-box__pause {
	position: absolute;
    bottom: 10px;
    right: 15px;
	z-index: 999999;
	background-color:#fff;
	font-weight: bold;
	display:block;
	padding:3px;
	border-radius:10px;
	border:2px solid #000;
}
.video-box__pause:focus {
	background-color:gold
}

@media screen and (min-width:1px) and (max-width:1900px) {
	.video-box video  {height:620px;}
}
@media screen and (min-width:1px) and (max-width:1350px) {
	.video-box video  {width:100% !important;height:auto !important;}
}
@media screen and (min-width:1px) and (max-width:768px) {
	.video-box video  {width:100%}
	.video-box__over {display:none;}
	.video-box__pause {
		display:none;
	}
	.slick-dots-wrap-outwrap {
		bottom:45px;
		left:11px;
		width:auto;
	}
}
@media screen and (min-width:1px) and (max-width:380px) {
	.video-box video  {
		max-height:120px;
	}
}
/* 전체화면 버튼 */ 
.main-slide {

	video::-webkit-media-controls-fullscreen-button {display: none !important;} 

	/* 일시정지, 재생 버튼 */ 
	video::-webkit-media-controls-play-button {display: none !important;} 
	
	/* 재생 슬라이드..? */ 
	video::-webkit-media-controls-timeline {display: none !important;} 
	
	/* 현재 진행 시간 */ 
	video::-webkit-media-controls-current-time-display{display: none !important;} 
	
	/* 전체 시간 */ 
	video::-webkit-media-controls-time-remaining-display {display: none !important;} 
	
	/* 음소거 버튼 */ 
	video::-webkit-media-controls-mute-button {display: none !important;} 
	
	/* 볼륨 조절 슬라이드 */ 
	video::-webkit-media-controls-volume-slider {display: none !important;} 
	
}