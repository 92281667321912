@charset "UTF-8";
/*게시판*/
/* 박스 타입 */
.icon-a-icon {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -669px -171px;
  background-image: url("../images/sprite.png");
  width: 30px;
  min-width: 30px;
  height: 30px;
}

.icon-all-menu-btn-close {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -669px -251px;
  background-image: url("../images/sprite.png");
  width: 30px;
  min-width: 30px;
  height: 30px;
}

.icon-all-menu-btn {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -830px -222px;
  background-image: url("../images/sprite.png");
  width: 20px;
  min-width: 20px;
  height: 17px;
}

.icon-all_menu_close {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -669px -331px;
  background-image: url("../images/sprite.png");
  width: 30px;
  min-width: 30px;
  height: 30px;
}

.icon-bbs-more {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -720px -689px;
  background-image: url("../images/sprite.png");
  width: 16px;
  min-width: 16px;
  height: 16px;
}

.icon-bbs-next {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -901px -72px;
  background-image: url("../images/sprite.png");
  width: 9px;
  min-width: 9px;
  height: 16px;
}

.icon-bbs-prev {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -963px -61px;
  background-image: url("../images/sprite.png");
  width: 8px;
  min-width: 8px;
  height: 16px;
}

.icon-cal-left {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -830px -289px;
  background-image: url("../images/sprite.png");
  width: 14px;
  min-width: 14px;
  height: 23px;
}

.icon-cal-right {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -830px -362px;
  background-image: url("../images/sprite.png");
  width: 14px;
  min-width: 14px;
  height: 23px;
}

.icon-check-type {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -963px -127px;
  background-image: url("../images/sprite.png");
  width: 12px;
  min-width: 12px;
  height: 9px;
}

.icon-check-type2-on {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -901px -138px;
  background-image: url("../images/sprite.png");
  width: 12px;
  min-width: 12px;
  height: 12px;
}

.icon-check-type2 {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -901px -200px;
  background-image: url("../images/sprite.png");
  width: 12px;
  min-width: 12px;
  height: 12px;
}

.icon-check-type3-on {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -963px 0px;
  background-image: url("../images/sprite.png");
  width: 13px;
  min-width: 13px;
  height: 11px;
}

.icon-confirm {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -101px -204px;
  background-image: url("../images/sprite.png");
  width: 49px;
  min-width: 49px;
  height: 71px;
}

.icon-cont-icon-1 {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -669px -411px;
  background-image: url("../images/sprite.png");
  width: 30px;
  min-width: 30px;
  height: 30px;
}

.icon-cont-icon-2 {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -669px -491px;
  background-image: url("../images/sprite.png");
  width: 30px;
  min-width: 30px;
  height: 30px;
}

.icon-cont-icon-3 {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -248px -122px;
  background-image: url("../images/sprite.png");
  width: 30px;
  min-width: 30px;
  height: 30px;
}

.icon-cont-icon-4 {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -306px -204px;
  background-image: url("../images/sprite.png");
  width: 30px;
  min-width: 30px;
  height: 30px;
}

.icon-copy_li_bg {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -963px -817px;
  background-image: url("../images/sprite.png");
  width: 1px;
  min-width: 1px;
  height: 13px;
}

.icon-copy_li_bg2 {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -963px -527px;
  background-image: url("../images/sprite.png");
  width: 5px;
  min-width: 5px;
  height: 10px;
}

.icon-down-icon1 {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -553px -607px;
  background-image: url("../images/sprite.png");
  width: 15px;
  min-width: 15px;
  height: 14px;
}

.icon-down-icon2 {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -618px -607px;
  background-image: url("../images/sprite.png");
  width: 15px;
  min-width: 15px;
  height: 14px;
}

.icon-endowment-1 {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -579px -84px;
  background-image: url("../images/sprite.png");
  width: 33px;
  min-width: 33px;
  height: 40px;
}

.icon-endowment-2 {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -579px 0px;
  background-image: url("../images/sprite.png");
  width: 40px;
  min-width: 40px;
  height: 34px;
}

.icon-endowment-3 {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -300px -327px;
  background-image: url("../images/sprite.png");
  width: 39px;
  min-width: 39px;
  height: 38px;
}

.icon-endowment-4 {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -669px 0px;
  background-image: url("../images/sprite.png");
  width: 33px;
  min-width: 33px;
  height: 38px;
}

.icon-file-icon {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -63px -769px;
  background-image: url("../images/sprite.png");
  width: 14px;
  min-width: 14px;
  height: 17px;
}

.icon-foot_img {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: 0px -104px;
  background-image: url("../images/sprite.png");
  width: 181px;
  min-width: 181px;
  height: 50px;
}

.icon-footer-select-bul {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -752px -629px;
  background-image: url("../images/sprite.png");
  width: 23px;
  min-width: 23px;
  height: 8px;
}

.icon-h3bul {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -683px -607px;
  background-image: url("../images/sprite.png");
  width: 13px;
  min-width: 13px;
  height: 13px;
}

.icon-h4-bul {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -328px -122px;
  background-image: url("../images/sprite.png");
  width: 3px;
  min-width: 3px;
  height: 3px;
}

.icon-header-links0 {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -313px -607px;
  background-image: url("../images/sprite.png");
  width: 44px;
  min-width: 44px;
  height: 15px;
}

.icon-header-search-btn {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -113px -327px;
  background-image: url("../images/sprite.png");
  width: 45px;
  min-width: 45px;
  height: 45px;
}

.icon-home {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -830px -654px;
  background-image: url("../images/sprite.png");
  width: 17px;
  min-width: 17px;
  height: 19px;
}

.icon-home02 {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -127px -769px;
  background-image: url("../images/sprite.png");
  width: 13px;
  min-width: 13px;
  height: 15px;
}

.icon-ico-roll-left {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -752px -159px;
  background-image: url("../images/sprite.png");
  width: 28px;
  min-width: 28px;
  height: 28px;
}

.icon-ico-roll-pause {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -752px -237px;
  background-image: url("../images/sprite.png");
  width: 28px;
  min-width: 28px;
  height: 28px;
}

.icon-ico-roll-plus {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -752px -315px;
  background-image: url("../images/sprite.png");
  width: 28px;
  min-width: 28px;
  height: 28px;
}

.icon-ico-roll-right {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -752px -393px;
  background-image: url("../images/sprite.png");
  width: 28px;
  min-width: 28px;
  height: 28px;
}

.icon-ico-roll-start {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -297px -689px;
  background-image: url("../images/sprite.png");
  width: 26px;
  min-width: 26px;
  height: 25px;
}

.icon-icon-1 {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -224px -689px;
  background-image: url("../images/sprite.png");
  width: 23px;
  min-width: 23px;
  height: 30px;
}

.icon-icon-10 {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -76px -689px;
  background-image: url("../images/sprite.png");
  width: 24px;
  min-width: 24px;
  height: 30px;
}

.icon-icon-11 {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -752px 0px;
  background-image: url("../images/sprite.png");
  width: 28px;
  min-width: 28px;
  height: 30px;
}

.icon-icon-2 {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -752px -80px;
  background-image: url("../images/sprite.png");
  width: 28px;
  min-width: 28px;
  height: 29px;
}

.icon-icon-3 {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -150px -689px;
  background-image: url("../images/sprite.png");
  width: 24px;
  min-width: 24px;
  height: 30px;
}

.icon-icon-4 {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -752px -549px;
  background-image: url("../images/sprite.png");
  width: 26px;
  min-width: 26px;
  height: 30px;
}

.icon-icon-5 {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -588px -521px;
  background-image: url("../images/sprite.png");
  width: 29px;
  min-width: 29px;
  height: 30px;
}

.icon-icon-6 {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: 0px -689px;
  background-image: url("../images/sprite.png");
  width: 26px;
  min-width: 26px;
  height: 30px;
}

.icon-icon-7 {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -191px -423px;
  background-image: url("../images/sprite.png");
  width: 30px;
  min-width: 30px;
  height: 30px;
}

.icon-icon-8 {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -233px -607px;
  background-image: url("../images/sprite.png");
  width: 30px;
  min-width: 30px;
  height: 28px;
}

.icon-icon-9 {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -154px -607px;
  background-image: url("../images/sprite.png");
  width: 29px;
  min-width: 29px;
  height: 29px;
}

.icon-icon-print {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -373px -689px;
  background-image: url("../images/sprite.png");
  width: 24px;
  min-width: 24px;
  height: 22px;
}

.icon-icon-url {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -830px 0px;
  background-image: url("../images/sprite.png");
  width: 21px;
  min-width: 21px;
  height: 24px;
}

.icon-info-icon {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: 0px -769px;
  background-image: url("../images/sprite.png");
  width: 13px;
  min-width: 13px;
  height: 21px;
}

.icon-join-icon-1 {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: 0px -204px;
  background-image: url("../images/sprite.png");
  width: 51px;
  min-width: 51px;
  height: 73px;
}

.icon-join-icon-2 {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -248px 0px;
  background-image: url("../images/sprite.png");
  width: 88px;
  min-width: 88px;
  height: 72px;
}

.icon-join-step-next {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -268px -769px;
  background-image: url("../images/sprite.png");
  width: 13px;
  min-width: 13px;
  height: 1px;
}

.icon-join-step-now {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: 0px -840px;
  background-image: url("../images/sprite.png");
  width: 13px;
  min-width: 13px;
  height: 13px;
}

.icon-lang {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -963px -363px;
  background-image: url("../images/sprite.png");
  width: 10px;
  min-width: 10px;
  height: 6px;
}

.icon-list-bul {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -901px -725px;
  background-image: url("../images/sprite.png");
  width: 5px;
  min-width: 5px;
  height: 2px;
}

.icon-list-bul2 {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -546px -840px;
  background-image: url("../images/sprite.png");
  width: 2px;
  min-width: 2px;
  height: 2px;
}

.icon-location-icon {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -901px -444px;
  background-image: url("../images/sprite.png");
  width: 4px;
  min-width: 4px;
  height: 7px;
}

.icon-location-icon2 {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -963px -186px;
  background-image: url("../images/sprite.png");
  width: 6px;
  min-width: 6px;
  height: 11px;
}

.icon-login-bul {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -901px -501px;
  background-image: url("../images/sprite.png");
  width: 1px;
  min-width: 1px;
  height: 10px;
}

.icon-main-info-1 {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -271px -423px;
  background-image: url("../images/sprite.png");
  width: 30px;
  min-width: 30px;
  height: 30px;
}

.icon-main-info-2 {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -351px -423px;
  background-image: url("../images/sprite.png");
  width: 30px;
  min-width: 30px;
  height: 30px;
}

.icon-main-info-3 {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -431px -423px;
  background-image: url("../images/sprite.png");
  width: 30px;
  min-width: 30px;
  height: 30px;
}

.icon-main-info-4 {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -428px -521px;
  background-image: url("../images/sprite.png");
  width: 30px;
  min-width: 30px;
  height: 30px;
}

.icon-main-visual-next {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -492px 0px;
  background-image: url("../images/sprite.png");
  width: 37px;
  min-width: 37px;
  height: 73px;
}

.icon-main-visual-prev {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -492px -123px;
  background-image: url("../images/sprite.png");
  width: 37px;
  min-width: 37px;
  height: 73px;
}

.icon-menu_open {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -200px -204px;
  background-image: url("../images/sprite.png");
  width: 56px;
  min-width: 56px;
  height: 56px;
}

.icon-menu_search {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -386px 0px;
  background-image: url("../images/sprite.png");
  width: 56px;
  min-width: 56px;
  height: 56px;
}

.icon-mobile-bul-minus {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -190px -769px;
  background-image: url("../images/sprite.png");
  width: 28px;
  min-width: 28px;
  height: 2px;
}

.icon-mobile-bul-plus {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -752px -471px;
  background-image: url("../images/sprite.png");
  width: 28px;
  min-width: 28px;
  height: 28px;
}

.icon-mobile-close {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -386px -106px;
  background-image: url("../images/sprite.png");
  width: 56px;
  min-width: 56px;
  height: 56px;
}

.icon-nav-3rd-bul {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -963px -419px;
  background-image: url("../images/sprite.png");
  width: 12px;
  min-width: 12px;
  height: 4px;
}

.icon-newsns-b-on {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -579px -174px;
  background-image: url("../images/sprite.png");
  width: 36px;
  min-width: 36px;
  height: 36px;
}

.icon-newsns-b {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -579px -260px;
  background-image: url("../images/sprite.png");
  width: 36px;
  min-width: 36px;
  height: 36px;
}

.icon-newsns-f-on {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -579px -346px;
  background-image: url("../images/sprite.png");
  width: 36px;
  min-width: 36px;
  height: 36px;
}

.icon-newsns-f {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -579px -432px;
  background-image: url("../images/sprite.png");
  width: 36px;
  min-width: 36px;
  height: 36px;
}

.icon-newsns-ks-on {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -492px -246px;
  background-image: url("../images/sprite.png");
  width: 36px;
  min-width: 36px;
  height: 36px;
}

.icon-newsns-ks {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -492px -332px;
  background-image: url("../images/sprite.png");
  width: 36px;
  min-width: 36px;
  height: 36px;
}

.icon-newsns-kt-on {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: 0px -521px;
  background-image: url("../images/sprite.png");
  width: 36px;
  min-width: 36px;
  height: 36px;
}

.icon-newsns-kt {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -86px -521px;
  background-image: url("../images/sprite.png");
  width: 36px;
  min-width: 36px;
  height: 36px;
}

.icon-newsns-tw-on {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -172px -521px;
  background-image: url("../images/sprite.png");
  width: 36px;
  min-width: 36px;
  height: 36px;
}

.icon-newsns-tw {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -258px -521px;
  background-image: url("../images/sprite.png");
  width: 36px;
  min-width: 36px;
  height: 36px;
}

.icon-open-close {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -963px -247px;
  background-image: url("../images/sprite.png");
  width: 8px;
  min-width: 8px;
  height: 8px;
}

.icon-open-done {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -901px -621px;
  background-image: url("../images/sprite.png");
  width: 8px;
  min-width: 8px;
  height: 2px;
}

.icon-open-icon {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -407px -607px;
  background-image: url("../images/sprite.png");
  width: 23px;
  min-width: 23px;
  height: 14px;
}

.icon-open-icon2 {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -480px -607px;
  background-image: url("../images/sprite.png");
  width: 23px;
  min-width: 23px;
  height: 14px;
}

.icon-q-icon {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -508px -521px;
  background-image: url("../images/sprite.png");
  width: 30px;
  min-width: 30px;
  height: 30px;
}

.icon-radio-off {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -901px -262px;
  background-image: url("../images/sprite.png");
  width: 12px;
  min-width: 12px;
  height: 12px;
}

.icon-radio-on {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -901px -324px;
  background-image: url("../images/sprite.png");
  width: 12px;
  min-width: 12px;
  height: 12px;
}

.icon-radio-type-off {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -63px -840px;
  background-image: url("../images/sprite.png");
  width: 13px;
  min-width: 13px;
  height: 13px;
}

.icon-radio-type-on {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -126px -840px;
  background-image: url("../images/sprite.png");
  width: 13px;
  min-width: 13px;
  height: 13px;
}

.icon-red-right {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -830px -435px;
  background-image: url("../images/sprite.png");
  width: 14px;
  min-width: 14px;
  height: 23px;
}

.icon-search-btn {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -344px -521px;
  background-image: url("../images/sprite.png");
  width: 34px;
  min-width: 34px;
  height: 34px;
}

.icon-search-close {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -669px -88px;
  background-image: url("../images/sprite.png");
  width: 33px;
  min-width: 33px;
  height: 33px;
}

.icon-second-menu {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -385px -840px;
  background-image: url("../images/sprite.png");
  width: 3px;
  min-width: 3px;
  height: 3px;
}

.icon-select-bul-n {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -963px -707px;
  background-image: url("../images/sprite.png");
  width: 9px;
  min-width: 9px;
  height: 5px;
}

.icon-select-bul {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -963px -762px;
  background-image: url("../images/sprite.png");
  width: 9px;
  min-width: 9px;
  height: 5px;
}

.icon-select_arrow {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -963px -473px;
  background-image: url("../images/sprite.png");
  width: 12px;
  min-width: 12px;
  height: 4px;
}

.icon-side-bul-on {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -963px -587px;
  background-image: url("../images/sprite.png");
  width: 5px;
  min-width: 5px;
  height: 10px;
}

.icon-side-bul {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -963px -647px;
  background-image: url("../images/sprite.png");
  width: 5px;
  min-width: 5px;
  height: 10px;
}

.icon-side-list-inbox {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -438px -840px;
  background-image: url("../images/sprite.png");
  width: 4px;
  min-width: 4px;
  height: 2px;
}

.icon-side-list-inbox_on {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -492px -840px;
  background-image: url("../images/sprite.png");
  width: 4px;
  min-width: 4px;
  height: 2px;
}

.icon-side-list-li-off {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -963px -305px;
  background-image: url("../images/sprite.png");
  width: 8px;
  min-width: 8px;
  height: 8px;
}

.icon-side-list-li-on {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -901px -673px;
  background-image: url("../images/sprite.png");
  width: 8px;
  min-width: 8px;
  height: 2px;
}

.icon-sidebarbul2 {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -901px -386px;
  background-image: url("../images/sprite.png");
  width: 4px;
  min-width: 4px;
  height: 8px;
}

.icon-slick-arrow-left {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -830px -508px;
  background-image: url("../images/sprite.png");
  width: 14px;
  min-width: 14px;
  height: 23px;
}

.icon-slick-arrow-right {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -830px -581px;
  background-image: url("../images/sprite.png");
  width: 14px;
  min-width: 14px;
  height: 23px;
}

.icon-sns-off {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: 0px -607px;
  background-image: url("../images/sprite.png");
  width: 27px;
  min-width: 27px;
  height: 32px;
}

.icon-sns-on {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -77px -607px;
  background-image: url("../images/sprite.png");
  width: 27px;
  min-width: 27px;
  height: 32px;
}

.icon-step-final {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: 0px -327px;
  background-image: url("../images/sprite.png");
  width: 63px;
  min-width: 63px;
  height: 46px;
}

.icon-step-next {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -389px -327px;
  background-image: url("../images/sprite.png");
  width: 43px;
  min-width: 43px;
  height: 33px;
}

.icon-step-prev {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -98px -423px;
  background-image: url("../images/sprite.png");
  width: 43px;
  min-width: 43px;
  height: 33px;
}

.icon-sub-menu-select {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -319px -840px;
  background-image: url("../images/sprite.png");
  width: 16px;
  min-width: 16px;
  height: 10px;
}

.icon-sub-nav_3rd_bul {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -598px -840px;
  background-image: url("../images/sprite.png");
  width: 2px;
  min-width: 2px;
  height: 2px;
}

.icon-thrd-menu {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -901px -777px;
  background-image: url("../images/sprite.png");
  width: 5px;
  min-width: 5px;
  height: 2px;
}

.icon-title {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: 0px 0px;
  background-image: url("../images/sprite.png");
  width: 198px;
  min-width: 198px;
  height: 54px;
}

.icon-top-banner-next {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -386px -212px;
  background-image: url("../images/sprite.png");
  width: 48px;
  min-width: 48px;
  height: 48px;
}

.icon-top-banner-prev {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: 0px -423px;
  background-image: url("../images/sprite.png");
  width: 48px;
  min-width: 48px;
  height: 48px;
}

.icon-total-count-bul {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -901px -561px;
  background-image: url("../images/sprite.png");
  width: 1px;
  min-width: 1px;
  height: 10px;
}

.icon-visual_next {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -830px -74px;
  background-image: url("../images/sprite.png");
  width: 15px;
  min-width: 15px;
  height: 24px;
}

.icon-visual_next2 {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -511px -423px;
  background-image: url("../images/sprite.png");
  width: 17px;
  min-width: 17px;
  height: 17px;
}

.icon-visual_pause {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -901px 0px;
  background-image: url("../images/sprite.png");
  width: 12px;
  min-width: 12px;
  height: 22px;
}

.icon-visual_pause2 {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -519px -689px;
  background-image: url("../images/sprite.png");
  width: 17px;
  min-width: 17px;
  height: 17px;
}

.icon-visual_play {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -447px -689px;
  background-image: url("../images/sprite.png");
  width: 22px;
  min-width: 22px;
  height: 22px;
}

.icon-visual_play2 {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -586px -689px;
  background-image: url("../images/sprite.png");
  width: 17px;
  min-width: 17px;
  height: 17px;
}

.icon-visual_prev {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -830px -148px;
  background-image: url("../images/sprite.png");
  width: 14px;
  min-width: 14px;
  height: 24px;
}

.icon-visual_prev2 {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -653px -689px;
  background-image: url("../images/sprite.png");
  width: 17px;
  min-width: 17px;
  height: 17px;
}

.icon-warning {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -208px -327px;
  background-image: url("../images/sprite.png");
  width: 42px;
  min-width: 42px;
  height: 43px;
}

.icon-ys-off {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -189px -840px;
  background-image: url("../images/sprite.png");
  width: 15px;
  min-width: 15px;
  height: 11px;
}

.icon-ys-on {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -254px -840px;
  background-image: url("../images/sprite.png");
  width: 15px;
  min-width: 15px;
  height: 11px;
}

main,
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
nav,
section {
  display: block;
}

audio,
canvas,
video {
  display: inline-block;
  *display: inline;
  *zoom: 1;
}

audio:not([controls]) {
  display: none;
}

[hidden] {
  display: none;
}

html {
  font-size: 100%;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}

body {
  margin: 0;
  word-break: keep-all;
}

button,
input,
select,
textarea {
  font-family: inherit;
}

a {
  color: inherit;
  text-decoration: none;
}

a:focus {
  outline: 0;
}

a:hover,
a:active {
  outline: 0;
}

b,
strong {
  font-weight: bold;
}

blockquote {
  margin: 1em 40px;
}

dfn {
  font-style: italic;
}

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0;
}

ins {
  text-decoration: underline;
}

del {
  text-decoration: line-through;
}

mark {
  background: #ff0;
  font-style: italic;
}

pre,
code,
kbd,
samp {
  font-family: monospace;
  _font-family: monospace;
  font-size: 1em;
}

pre {
  white-space: pre;
  white-space: pre-wrap;
  word-wrap: break-word;
}

p {
  margin: 0;
}

q {
  quotes: none;
}

q:before,
q:after {
  content: "";
  content: none;
}

small {
  font-size: 85%;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}

ul,
ol {
  list-style: none;
  list-style-image: none;
  margin: 0;
  padding: 0;
}

dl,
dt,
dd {
  margin: 0;
}

img {
  max-width: 100%;
  border: 0;
  -ms-interpolation-mode: bicubic;
  vertical-align: top;
}

svg:not(:root) {
  overflow: hidden;
}

figure {
  margin: 0;
}

form {
  margin: 0;
}

fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

legend {
  display: none;
  border: 0;
  *margin-left: -7px;
  padding: 0;
}

label {
  cursor: pointer;
}

button {
  padding: 0;
  outline: 0;
  border: none;
  background: transparent;
}

button,
input,
select,
textarea {
  font-size: 100%;
  font-family: inherit;
  margin: 0;
  /*vertical-align: baseline;*/
  vertical-align: middle;
  border-radius: 0;
  background-image: -webkit-gradient(linear, 0% 0%, 0% 100%, from(rgba(255, 255, 255, 0)), to(rgba(255, 255, 255, 0)));
  background-image: -webkit-linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0));
}

button,
input {
  line-height: normal;
  *overflow: visible;
}

table button,
table input {
  *overflow: auto;
}

button,
input[type="button"],
input[type="reset"],
input[type="submit"] {
  cursor: pointer;
  -webkit-appearance: button;
}

input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
}

input[type="search"] {
  -webkit-appearance: textfield;
  box-sizing: content-box;
  -webkit-appearance: none;
}

input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

textarea {
  overflow: auto;
  vertical-align: top;
  resize: vertical;
}

input:invalid,
textarea:invalid {
  border-color: #C82E2D;
}

button[disabled],
input[disabled] {
  cursor: default;
}

input::-ms-clear {
  display: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

td {
  vertical-align: top;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: inherit;
  font-weight: inherit;
  margin: 0;
}

address,
em,
i {
  font-style: normal;
}

caption {
  position: absolute;
  top: 0;
  left: -9999px;
  height: 0 !important;
  width: 0 !important;
  overflow: hidden;
  font-size: 0;
}

/*
-----------*/
.in_length30 {
  width: 30px;
}

.in_length40 {
  width: 40px;
}

.in_length50 {
  width: 50px;
}

.in_length60 {
  width: 60px;
}

.in_length70 {
  width: 70px;
}

.in_length80 {
  width: 80px;
}

.in_length90 {
  width: 90px;
}

.in_length100 {
  width: 100px;
}

.in_length110 {
  width: 110px;
}

.in_length120 {
  width: 120px;
}

.in_length130 {
  width: 130px;
}

.in_length140 {
  width: 140px;
}

.in_length150 {
  width: 150px;
}

.in_length160 {
  width: 160px;
}

.in_length170 {
  width: 170px;
}

.in_length180 {
  width: 180px;
}

.in_length190 {
  width: 190px;
}

.in_length200 {
  width: 200px;
}

.in_length250 {
  width: 250px;
}

.in_length300 {
  width: 300px;
}

.in_length350 {
  width: 350px;
}

.in_length400 {
  width: 400px;
}

.in_length450 {
  width: 450px;
}

.in_length500 {
  width: 500px;
}

.in_length600 {
  width: 600px;
}

.in_length98pro {
  width: 98%;
}

.float_wrap:after {
  content: ' ';
  clear: both;
  display: block;
  visibility: hidden;
}

* + html .float_wrap {
  display: inline-block;
}

* html .float_wrap {
  height: 1%;
}

.float-wrap:after {
  content: ' ';
  clear: both;
  visibility: hidden;
  display: block;
}

* + html .float-wrap {
  display: inline-block;
}

.align_right {
  text-align: right;
}

.align_left {
  text-align: left;
}

.align_center {
  text-align: center;
}

.hidden_word {
  position: absolute;
  top: 0;
  left: -9999px;
  text-indent: -9999px;
  display: block;
}

.mgn_b5 {
  margin-bottom: 5px;
}

.mgn_b8 {
  margin-bottom: 8px;
}

.mgn_b10 {
  margin-bottom: 10px;
}

.mgn_b15 {
  margin-bottom: 15px;
}

.mgn_b18 {
  margin-bottom: 18px;
}

.mgn_b20 {
  margin-bottom: 20px;
}

.mgn_b25 {
  margin-bottom: 25px;
}

.mgn_b30 {
  margin-bottom: 30px;
}

.mgn_b40 {
  margin-bottom: 40px;
}

.mgn_b50 {
  margin-bottom: 50px;
}

.mgn_t5 {
  margin-top: 5px;
}

.mgn_t8 {
  margin-top: 8px;
}

.mgn_t10 {
  margin-top: 10px;
}

.mgn_t15 {
  margin-top: 15px;
}

.mgn_t18 {
  margin-top: 18px;
}

.mgn_t20 {
  margin-top: 20px;
}

.mgn_t25 {
  margin-top: 25px;
}

.mgn_t30 {
  margin-top: 30px;
}

.mgn_t40 {
  margin-top: 40px;
}

.mgn_t50 {
  margin-top: 50px;
}

.mgn_t70 {
  margin-top: 70px;
}

.mgn_t80 {
  margin-top: 80px;
}

.mgn_t105 {
  margin-top: 105px;
}

.mgn_l10 {
  margin-left: 10px;
}

.mgn_l15 {
  margin-left: 15px;
}

.mgn_l20 {
  margin-left: 20px;
}

.mgn_l30 {
  margin-left: 30px;
}

.mgn_r10 {
  margin-right: 10px;
}

.mgn_r20 {
  margin-right: 20px;
}

.mgn_r30 {
  margin-right: 30px;
}

.mgn_r40 {
  margin-right: 40px;
}

.left_10 {
  padding-left: 10px;
}

.left_15 {
  padding-left: 15px;
}

.left_20 {
  padding-left: 20px;
}

.left_30 {
  padding-left: 30px;
}

.left_40 {
  padding-left: 40px;
}

.left_50 {
  padding-left: 50px;
}

.left_100 {
  padding-left: 100px;
}

.left_150 {
  padding-left: 150px;
}

.right_10 {
  padding-right: 10px;
}

.right_20 {
  padding-right: 20px;
}

.right_30 {
  padding-right: 30px;
}

.right_50 {
  padding-right: 50px;
}

.right_100 {
  padding-right: 100px;
}

.right_150 {
  padding-right: 150px;
}

.col_brown {
  color: #996630;
}

.line_14 {
  line-height: 1.4;
}

.line_16 {
  line-height: 1.6;
}

.line_18 {
  line-height: 1.8;
}

.col_red {
  color: #db4651;
}

.col_gold {
  color: #CD883D;
}

.col_black {
  color: #000000;
}

.col_org {
  color: #f05914;
}

.col_gray {
  color: #818283;
}

.col_black {
  color: #000;
}

.col_pul {
  color: #3c5e80;
}

.col_blue {
  color: #21539b;
}

.col_gold {
  color: #999900;
}

.col_yellow {
  color: #fff585;
}

.font_bold {
  font-weight: bold;
}

.font_11 {
  font-size: 11px;
  font-size: 1.1rem;
}

.font_12 {
  font-size: 12px;
  font-size: 1.2rem;
}

.font_13 {
  font-size: 13px;
  font-size: 1.3rem;
}

.font_14 {
  font-size: 14px;
  font-size: 1.4rem;
}

.font_15 {
  font-size: 15px;
  font-size: 1.5rem;
}

.font_16 {
  font-size: 16px;
  font-size: 1.6rem;
}

.font_17 {
  font-size: 17px;
  font-size: 1.7rem;
}

.font_18 {
  font-size: 18px;
  font-size: 1.8rem;
}

.font_20 {
  font-size: 20px;
  font-size: 2.0rem;
}

.font_22 {
  font-size: 22px;
  font-size: 2.2rem;
}

.chk_box {
  height: 13px;
  margin: 0;
}

.chk_box, label {
  vertical-align: middle;
}

@font-face {
  font-family: 'Nanum Gothic';
  font-style: normal;
  font-weight: 400;
  src: url(//themes.googleusercontent.com/static/fonts/earlyaccess/nanumgothic/v3/NanumGothic-Regular.eot);
  src: local("NanumGothic"), url(//themes.googleusercontent.com/static/fonts/earlyaccess/nanumgothic/v3/NanumGothic-Regular.eot?#iefix) format("embedded-opentype"), url(//themes.googleusercontent.com/static/fonts/earlyaccess/nanumgothic/v3/NanumGothic-Regular.woff2) format("x-woff2"), url(//themes.googleusercontent.com/static/fonts/earlyaccess/nanumgothic/v3/NanumGothic-Regular.woff) format("woff"), url(//themes.googleusercontent.com/static/fonts/earlyaccess/nanumgothic/v3/NanumGothic-Regular.ttf) format("truetype");
}

@font-face {
  font-family: 'Nanum Gothic';
  font-style: normal;
  font-weight: 700;
  src: url(//themes.googleusercontent.com/static/fonts/earlyaccess/nanumgothic/v3/NanumGothic-Bold.eot);
  src: local("NanumGothicBold"), url(//themes.googleusercontent.com/static/fonts/earlyaccess/nanumgothic/v3/NanumGothic-Bold.eot?#iefix) format("embedded-opentype"), url(//themes.googleusercontent.com/static/fonts/earlyaccess/nanumgothic/v3/NanumGothic-Bold.woff2) format("x-woff2"), url(//themes.googleusercontent.com/static/fonts/earlyaccess/nanumgothic/v3/NanumGothic-Bold.woff) format("woff"), url(//themes.googleusercontent.com/static/fonts/earlyaccess/nanumgothic/v3/NanumGothic-Bold.ttf) format("truetype");
}

@font-face {
  font-family: 'Nanum Gothic';
  font-style: normal;
  font-weight: 800;
  src: url(//themes.googleusercontent.com/static/fonts/earlyaccess/nanumgothic/v3/NanumGothic-ExtraBold.eot);
  src: local("NanumGothicExtraBold"), url(//themes.googleusercontent.com/static/fonts/earlyaccess/nanumgothic/v3/NanumGothic-ExtraBold.eot?#iefix) format("embedded-opentype"), url(//themes.googleusercontent.com/static/fonts/earlyaccess/nanumgothic/v3/NanumGothic-ExtraBold.woff2) format("x-woff2"), url(//themes.googleusercontent.com/static/fonts/earlyaccess/nanumgothic/v3/NanumGothic-ExtraBold.woff) format("woff"), url(//themes.googleusercontent.com/static/fonts/earlyaccess/nanumgothic/v3/NanumGothic-ExtraBold.ttf) format("truetype");
}

/* Noto Sans KR (korean) http://www.google.com/fonts/earlyaccess
@font-face { font-family: 'Noto Sans KR'; font-style: normal; font-weight: 100; src: url(https://fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Thin.woff2) format("woff2"), url(https://fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Thin.woff) format("woff"), url(https://fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Thin.otf) format("opentype"); }
@font-face { font-family: 'Noto Sans KR'; font-style: normal; font-weight: 300; src: url(https://fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Light.woff2) format("woff2"), url(https://fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Light.woff) format("woff"), url(https://fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Light.otf) format("opentype"); }
@font-face { font-family: 'Noto Sans KR'; font-style: normal; font-weight: 400; src: url(https://fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Regular.woff2) format("woff2"), url(https://fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Regular.woff) format("woff"), url(https://fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Regular.otf) format("opentype"); }
@font-face { font-family: 'Noto Sans KR'; font-style: normal; font-weight: 500; src: url(https://fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Medium.woff2) format("woff2"), url(https://fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Medium.woff) format("woff"), url(https://fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Medium.otf) format("opentype"); }
@font-face { font-family: 'Noto Sans KR'; font-style: normal; font-weight: 700; src: url(https://fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Bold.woff2) format("woff2"), url(https://fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Bold.woff) format("woff"), url(https://fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Bold.otf) format("opentype"); }
@font-face { font-family: 'Noto Sans KR'; font-style: normal; font-weight: 900; src: url(https://fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Black.woff2) format("woff2"), url(https://fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Black.woff) format("woff"), url(https://fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Black.otf) format("opentype"); }
*/
@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 100;
  src: url(../../resources/fonts/NotoSansKR-Thin.woff2) format("woff2"), url(../../resources/fonts/NotoSansKR-Thin.woff) format("woff"), url(../../resources/fonts/NotoSansKR-Thin.otf) format("opentype");
}

@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 300;
  src: url(../../resources/fonts/NotoSansKR-Light.woff2) format("woff2"), url(../../resources/fonts/NotoSansKR-Light.woff) format("woff"), url(../../resources/fonts/NotoSansKR-Light.otf) format("opentype");
}

@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 400;
  src: url(../../resources/fonts/NotoSansKR-Regular.woff2) format("woff2"), url(../../resources/fonts/NotoSansKR-Regular.woff) format("woff"), url(../../resources/fonts/NotoSansKR-Regular.otf) format("opentype");
}

@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 500;
  src: url(../../resources/fonts/NotoSansKR-Medium.woff2) format("woff2"), url(../../resources/fonts/NotoSansKR-Medium.woff) format("woff"), url(../../resources/fonts/NotoSansKR-Medium.otf) format("opentype");
}

@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 700;
  src: url(../../resources/fonts/NotoSansKR-Bold.woff2) format("woff2"), url(../../resources/fonts/NotoSansKR-Bold.woff) format("woff"), url(../../resources/fonts/NotoSansKR-Bold.otf) format("opentype");
}

@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 900;
  src: url(../../resources/fonts/NotoSansKR-Black.woff2) format("woff2"), url(../../resources/fonts/NotoSansKR-Black.woff) format("woff"), url(../../resources/fonts/NotoSansKR-Black.otf) format("opentype");
}

@font-face {
  font-family: 'yes24';
  font-style: normal;
  font-weight: 500;
  src: url(../../resources/fonts/yes24.woff) format("woff");
}

@font-face {
  font-family: 'scdream';
  font-style: normal;
  font-weight: 400;
  src: url(../../resources/fonts/SCDream4.woff) format("woff");
}

@font-face {
  font-family: 'scdream';
  font-style: normal;
  font-weight: 500;
  src: url(../../resources/fonts/SCDream5.woff) format("woff");
}

@font-face {
  font-family: 'scdream';
  font-style: normal;
  font-weight: 600;
  src: url(../../resources/fonts/SCDream6.woff) format("woff");
}

@font-face {
  font-family: 'NanumSquare';
  font-style: normal;
  font-weight: 500;
  src: url(../../resources/fonts/NanumSquare_acL.woff) format("woff");
}

@font-face {
  font-family: 'NanumSquare';
  font-style: normal;
  font-weight: 400;
  src: url(../../resources/fonts/NanumSquare_acR.woff) format("woff");
}

@font-face {
  font-family: 'NanumSquare';
  font-style: normal;
  font-weight: 500;
  src: url(../../resources/fonts/NanumSquare_acB.woff) format("woff");
}

@font-face {
  font-family: 'NanumSquare';
  font-style: normal;
  font-weight: 600;
  src: url(../../resources/fonts/NanumSquare_acEB.woff) format("woff");
}

@font-face {
  font-family: 'NanumBarunGothic';
  font-style: normal;
  font-weight: 400;
  src: url("//cdn.jsdelivr.net/font-nanumlight/1.0/NanumBarunGothicWeb.eot");
  src: url("//cdn.jsdelivr.net/font-nanumlight/1.0/NanumBarunGothicWeb.eot?#iefix") format("embedded-opentype"), url("//cdn.jsdelivr.net/font-nanumlight/1.0/NanumBarunGothicWeb.woff") format("woff"), url("//cdn.jsdelivr.net/font-nanumlight/1.0/NanumBarunGothicWeb.ttf") format("truetype");
}

@font-face {
  font-family: 'NanumBarunGothic';
  font-style: normal;
  font-weight: 700;
  src: url("//cdn.jsdelivr.net/font-nanumlight/1.0/NanumBarunGothicWebBold.eot");
  src: url("//cdn.jsdelivr.net/font-nanumlight/1.0/NanumBarunGothicWebBold.eot?#iefix") format("embedded-opentype"), url("//cdn.jsdelivr.net/font-nanumlight/1.0/NanumBarunGothicWebBold.woff") format("woff"), url("//cdn.jsdelivr.net/font-nanumlight/1.0/NanumBarunGothicWebBold.ttf") format("truetype");
}

@font-face {
  font-family: 'NanumBarunGothic';
  font-style: normal;
  font-weight: 300;
  src: url("//cdn.jsdelivr.net/font-nanumlight/1.0/NanumBarunGothicWebLight.eot");
  src: url("//cdn.jsdelivr.net/font-nanumlight/1.0/NanumBarunGothicWebLight.eot?#iefix") format("embedded-opentype"), url("//cdn.jsdelivr.net/font-nanumlight/1.0/NanumBarunGothicWebLight.woff") format("woff"), url("//cdn.jsdelivr.net/font-nanumlight/1.0/NanumBarunGothicWebLight.ttf") format("truetype");
}

@font-face {
  font-family: 'gmarket';
  font-style: normal;
  font-weight: 500;
  src: url(../../resources/fonts/GmarketSansTTFLight.woff) format("woff");
}

@font-face {
  font-family: 'gmarket';
  font-style: normal;
  font-weight: 700;
  src: url(../../resources/fonts/GmarketSansTTFBold.woff) format("woff");
}

@font-face {
  font-family: 'jalnan';
  font-style: normal;
  font-weight: 500;
  src: url(../../resources/fonts/Jalnan.woff) format("woff");
}

.nanumbarungothic * {
  font-family: 'NanumBarunGothic', sans-serif;
}

@font-face {
  font-family: 'Sam3KRFont';
  font-style: normal;
  font-weight: 500;
  src: url(../../resources/fonts/Sam3KRFont.woff) format("woff");
}

html {
  font-size: 62.5%;
}

html.websize-1 {
  font-size: 62.5%;
}

html.websize-2 {
  font-size: 68.5%;
}

html.websize-3 {
  font-size: 72.5%;
}

html.websize-4 {
  font-size: 78.5%;
}

html.websize-5 {
  font-size: 82.5%;
}

html.websize-6 {
  font-size: 88.5%;
}

html.websize-7 {
  font-size: 93.5%;
}

.float-wrap:after {
  content: ' ';
  clear: both;
  visibility: hidden;
  display: block;
}

* + html .float-wrap {
  display: inline-block;
}

.float_wrap:after {
  content: ' ';
  clear: both;
  visibility: hidden;
  display: block;
}

* + html .float_wrap {
  display: inline-block;
}

/**/
.size-realtime {
  display: none;
  font-size: 12px;
  padding-left: 0 !important;
}

html.websize-1 .size-realtime.s1 {
  display: inline-block;
}

html.websize-2 .size-realtime.s2 {
  display: inline-block;
}

html.websize-3 .size-realtime.s3 {
  display: inline-block;
}

html.websize-4 .size-realtime.s4 {
  display: inline-block;
}

html.websize-5 .size-realtime.s5 {
  display: inline-block;
}

html.websize-6 .size-realtime.s6 {
  display: inline-block;
}

html.websize-7 .size-realtime.s7 {
  display: inline-block;
}

@font-face {
  font-family: 'korail';
  font-style: normal;
  font-weight: 100;
  src: url(../../resources/fonts/korail-kyae9613.woff) format("woff");
}

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.slick-track:before,
.slick-track:after {
  display: table;
  content: '';
}

.slick-track:after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
}

[dir='rtl'] .slick-slide {
  float: right;
}

.slick-slide img {
  display: block;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

/**/
html body {
  font-size: 14px;
  font-size: 1.4rem;
}

/*사이즈 확인*/
.resol {
  position: fixed;
  bottom: 0;
  right: 0;
  border: 3px solid red;
  background-color: #fff;
  padding: 5px;
}

/*숫자클래스 삽입*/
.menu-num-2 > ul > li {
  width: 50%;
}

.menu-num-3 > ul > li {
  width: 33.33%;
}

* + html .menu-num-3 > ul > li {
  width: 33%;
}

.menu-num-4 > ul > li {
  width: 25%;
}

.menu-num-5 > ul > li {
  width: 20%;
}

.menu-num-6 > ul > li {
  width: 16.66%;
}

* + html .menu-num-6 > ul > li {
  width: 16%;
}

.menu-num-7 > ul > li {
  width: 14%;
}

.menu-num-8 > ul > li {
  width: 12.5%;
}

.menu-num-9 > ul > li {
  width: 11.20%;
}

* + html .menu-num-9 > ul > li {
  width: 11%;
}

.menu-num-10 > ul > li {
  width: 10%;
}

.menu-num-11 > ul > li {
  width: 9.09%;
}

* + html .menu-num-11 > ul > li {
  width: 9%;
}

.menu-num-12 > ul > li {
  width: 8.3%;
}

* + html .menu-num-12 > ul > li {
  width: 8%;
}

.menu-num-13 > ul > li {
  width: 7.5%;
}

* + html .menu-num-13 > ul > li {
  width: 7%;
}

/*유동적 가로세로 비율 박스*/
#movie-player-wrapper {
  position: relative;
  width: 100%;
  height: 0;
  overflow: hidden;
  padding-bottom: 56.25%;
  /*16:9*/
  background-color: #00235d;
  color: #fff;
}

#movie-player-wrapper .mov-wrap {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

#movie-player-wrapper iframe {
  width: 100%;
  height: 100%;
}

/*표 반응형*/
.test-table {
  margin-bottom: 30px;
}

.test-table table {
  border-collapse: collapse;
  width: 100%;
}

.test-table th {
  border: 1px solid #777;
  padding: 5px;
}

.test-table td {
  border: 1px solid #777;
  padding: 5px;
}

@media (max-width: 800px) {
  .test-table th {
    display: none;
  }
  .test-table td {
    display: block;
    margin-bottom: 10px;
  }
  .test-table td:before {
    color: blue;
    font-weight: bold;
    content: attr(data-th) " : ";
  }
}

.show-list__top {
  min-height: 80px;
  position: relative;
  margin-bottom: 20px;
  background-color: #fff;
}

.show-list__top__info {
  position: absolute;
  bottom: 0px;
  left: 30px;
}

.show-list__top__info strong {
  color: #da4453;
}

.show-list__top__search {
  position: absolute;
  bottom: 0;
  right: 30px;
}

.show-list__top__search__selectbox {
  margin-right: 10px;
}

.show-list__top__search__selectbox {
  overflow: hidden;
  display: inline-block;
  vertical-align: middle;
  position: relative;
  background-color: #fff;
  text-align: left;
  border: 1px solid #cccccc;
}

.show-list__top__search__selectbox:after {
  position: absolute;
  top: 50%;
  right: 0;
  margin-right: 10px;
  transform: translateY(-50%);
  content: ' ';
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -963px -762px;
  background-image: url("../images/sprite.png");
  width: 9px;
  min-width: 9px;
  height: 5px;
}

.lte-ie9 .show-list__top__search__selectbox:after {
  display: none;
}

.show-list__top__search__selectbox label {
  text-indent: -9999px;
  position: absolute;
  top: 0;
  left: -9999px;
  height: 0;
  overflow: hidden;
}

.show-list__top__search__selectbox select {
  width: 100%;
  box-sizing: border-box;
  background-color: transparent;
  appearance: none;
  min-width: 100px;
  vertical-align: middle;
  color: #000;
  font-size: 16px;
  height: 48px;
  margin-top: 0;
  padding: 2px 2px 2px 10px;
  padding-right: 40px;
  position: relative;
  border: 0px;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  /*ie10~11 에서 셀렉트 기본디자인 무력화*/
}

.show-list__top__search__selectbox select::-ms-expand {
  display: none;
}

.lte-ie9 .show-list__top__search__selectbox select {
  width: 100%;
  vertical-align: top;
  padding-right: 0px;
}

.show-list__top__search__selectbox select option {
  font-family: "scdream";
}

.show-list__top__search__selectbox label {
  text-indent: -9999px;
  position: absolute;
  top: 0;
  left: -9999px;
  height: 0;
  overflow: hidden;
}

.show-list__top__search__inputbox {
  display: inline-block;
  vertical-align: middle;
  position: relative;
}

.show-list__top__search__inputbox input {
  width: 250px;
  border: 1px solid #000;
  height: 58px;
  line-height: 58px;
  font-size: 16px;
  padding: 0;
  padding-left: 10px;
}

.show-list__top__search__inputbox a {
  color: #fff;
  background-color: #000;
  height: 50px;
  line-height: 50px;
  display: inline-block;
  padding-left: 30px;
  padding-right: 30px;
  vertical-align: middle;
  box-sizing: border-box;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  border: 1px solid #000;
}

.show-list__top__search__inputbox a:hover, .show-list__top__search__inputbox a:focus {
  background-color: #444;
}

.show-list__top.ver2 {
  background-color: #fff;
}

.ver2 .show-list__top__info {
  left: 0;
}

.ver2 .show-list__top__search {
  right: 0;
}

.ver2 .show-list__top__search .show-list__top__search__selectbox {
  border: 1px solid #000;
}

.ver2 .show-list__top__search input {
  border: 1px solid #000;
}

/* show list top */
@media screen and (min-width: 1px) and (max-width: 1760px) {
  .show-list__top__search__selectbox {
    overflow: hidden;
    display: inline-block;
    vertical-align: middle;
    position: relative;
    background-color: #fff;
    text-align: left;
    border: 1px solid #cccccc;
  }
  .show-list__top__search__selectbox:after {
    position: absolute;
    top: 50%;
    right: 0;
    margin-right: 10px;
    transform: translateY(-50%);
    content: ' ';
    content: '';
    vertical-align: middle;
    display: inline-block;
    background-repeat: no-repeat;
    background-position: -963px -762px;
    background-image: url("../images/sprite.png");
    width: 9px;
    min-width: 9px;
    height: 5px;
  }
  .lte-ie9 .show-list__top__search__selectbox:after {
    display: none;
  }
  .show-list__top__search__selectbox label {
    text-indent: -9999px;
    position: absolute;
    top: 0;
    left: -9999px;
    height: 0;
    overflow: hidden;
  }
  .show-list__top__search__selectbox select {
    width: 100%;
    box-sizing: border-box;
    background-color: transparent;
    appearance: none;
    min-width: 100px;
    vertical-align: middle;
    color: #000;
    font-size: 16px;
    height: 48px;
    margin-top: 0;
    padding: 2px 2px 2px 10px;
    padding-right: 40px;
    position: relative;
    border: 0px;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    -o-appearance: none;
    /*ie10~11 에서 셀렉트 기본디자인 무력화*/
  }
  .show-list__top__search__selectbox select::-ms-expand {
    display: none;
  }
  .lte-ie9 .show-list__top__search__selectbox select {
    width: 100%;
    vertical-align: top;
    padding-right: 0px;
  }
  .show-list__top__search__selectbox select option {
    font-family: "scdream";
  }
  .show-list__top__search__inputbox {
    display: inline-block;
    vertical-align: middle;
    position: relative;
  }
  .show-list__top__search__inputbox input {
    width: 200px;
    border: 1px solid #000;
    height: 58px;
    line-height: 58px;
    font-size: 16px;
    padding: 0;
    padding-left: 10px;
  }
  .show-list__top__search__inputbox a {
    color: #fff;
    background-color: #000;
    height: 50px;
    line-height: 50px;
    display: inline-block;
    padding-left: 30px;
    padding-right: 30px;
    vertical-align: middle;
    box-sizing: border-box;
    font-size: 16px;
    font-weight: 600;
    text-align: center;
    border: 1px solid #000;
  }
  .show-list__top__search__inputbox a:hover, .show-list__top__search__inputbox a:focus {
    background-color: #444;
  }
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .show-list__top {
    padding: 0px;
  }
  .show-list__top__info {
    transform: translateY(0);
    top: 0;
    left: 0;
    position: relative;
    margin-bottom: 10px;
  }
  .show-list__top__search {
    transform: translateY(0);
    top: 0;
    right: 0;
    position: relative;
  }
  .show-list__top__search__selectbox {
    margin-bottom: 10px;
  }
  .show-list__top__search__selectbox {
    overflow: hidden;
    display: inline-block;
    vertical-align: middle;
    position: relative;
    background-color: #fff;
    text-align: left;
    border: 1px solid #cccccc;
  }
  .show-list__top__search__selectbox:after {
    position: absolute;
    top: 50%;
    right: 0;
    margin-right: 10px;
    transform: translateY(-50%);
    content: ' ';
    content: '';
    vertical-align: middle;
    display: inline-block;
    background-repeat: no-repeat;
    background-position: -963px -762px;
    background-image: url("../images/sprite.png");
    width: 9px;
    min-width: 9px;
    height: 5px;
  }
  .lte-ie9 .show-list__top__search__selectbox:after {
    display: none;
  }
  .show-list__top__search__selectbox label {
    text-indent: -9999px;
    position: absolute;
    top: 0;
    left: -9999px;
    height: 0;
    overflow: hidden;
  }
  .show-list__top__search__selectbox select {
    width: 100%;
    box-sizing: border-box;
    background-color: transparent;
    appearance: none;
    min-width: 100px;
    vertical-align: middle;
    color: #000;
    font-size: 16px;
    height: 48px;
    margin-top: 0;
    padding: 2px 2px 2px 10px;
    padding-right: 40px;
    position: relative;
    border: 0px;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    -o-appearance: none;
    /*ie10~11 에서 셀렉트 기본디자인 무력화*/
  }
  .show-list__top__search__selectbox select::-ms-expand {
    display: none;
  }
  .lte-ie9 .show-list__top__search__selectbox select {
    width: 100%;
    vertical-align: top;
    padding-right: 0px;
  }
  .show-list__top__search__selectbox select option {
    font-family: "scdream";
  }
  .show-list__top__search__inputbox {
    margin-bottom: 10px;
  }
  .show-list__top__search__inputbox {
    display: inline-block;
    vertical-align: middle;
    position: relative;
  }
  .show-list__top__search__inputbox input {
    width: 280px;
    border: 1px solid #000;
    height: 58px;
    line-height: 58px;
    font-size: 16px;
    padding: 0;
    padding-left: 10px;
  }
  .show-list__top__search__inputbox a {
    color: #fff;
    background-color: #000;
    height: 50px;
    line-height: 50px;
    display: inline-block;
    padding-left: 30px;
    padding-right: 30px;
    vertical-align: middle;
    box-sizing: border-box;
    font-size: 16px;
    font-weight: 600;
    text-align: center;
    border: 1px solid #000;
  }
  .show-list__top__search__inputbox a:hover, .show-list__top__search__inputbox a:focus {
    background-color: #444;
  }
  .show-list__top.ver2 {
    margin-bottom: 0px;
  }
}

.common_layer_box {
  display: none;
}

.test {
  font-size: 19px;
  font-size: 1.9rem;
}

/*skip navigation*/
.skiptoContent {
  position: fixed;
  left: 50%;
  top: -30px;
  height: 30px;
  line-height: 30px;
  background-color: #00235d;
  width: 540px;
  margin-left: -270px;
  text-align: center;
  transition: all 0.3s ease 0s;
  z-index: 9999;
}

.skiptoContent.on {
  top: 0;
}

.skiptoContent strong {
  font-size: 0;
}

.skiptoContent a {
  color: #fff;
  font-size: 18px;
  margin-left: 10px;
  margin-right: 10px;
}

.skiptoContent a:focus {
  color: gold;
  text-decoration: underline;
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .skiptoContent {
    display: none;
  }
}

/*ul 사이즈 설정필수*/
/*컬러설정*/
/*header*/
.header {
  height: 121px;
  position: relative;
  z-index: 999999;
  background-image: url("../../resources/images/basic/bg.png");
  background-repeat: repeat-x;
  background-position: center top;
  /*헤더 위 링크들*/
  /*header search*/
  /*
	&__search {position:absolute;top:0px;left:50%;margin-left:-70px;}
	&__search__in {border:1px solid #333;background-color:#fff;}
	&__search-in_box {border:0px;padding:0;height:32px;width:150px;line-height: 32px; vertical-align: top;}
    */
}

.header__links {
  position: relative;
  top: 0;
  left: 0;
  width: 1760px;
  margin: 0 auto;
  background-color: #eee;
  height: 0px;
  z-index: 99;
  /*font-size*/
}

@media screen and (min-width: 769px) and (max-width: 1760px) {
  .header__links {
    width: 100%;
  }
}

.header__links__0 {
  position: absolute;
  top: 14px;
  right: 0;
  color: #777;
  padding-left: 50px;
}

.header__links__0:before {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -313px -607px;
  background-image: url("../images/sprite.png");
  width: 44px;
  min-width: 44px;
  height: 15px;
}

@media screen and (min-width: 1px) and (max-width: 1400px) {
  .header__links__0 {
    top: 60px;
    right: 70px;
  }
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .header__links__0 {
    display: none;
  }
}

.header__links__1 {
  position: absolute;
  margin: 0 auto;
  top: 50px;
  text-align: right;
  white-space: nowrap;
  font-size: 0;
  box-sizing: border-box;
  padding-right: 205px;
}

header.add-search-btn .header__links__1 {
  padding-right: 0px;
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .header__links__1 {
    padding-right: 0px;
  }
}

.header__links__1 > a {
  padding-left: 0px;
  font-size: 14px;
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .header__links__1 > a {
    color: #000;
  }
}

.header__links__1 > a:focus {
  text-decoration: underline;
}

.add-search-btn .header__links__1 {
  right: 58px;
}

.header__links__font-size-box {
  font-size: 13px;
}

.header__links__font-size-box h3 {
  display: inline;
  margin-left: 10px;
  font-size: 13px;
}

@media screen and (min-width: 1px) and (max-width: 1760px) {
  .header__links__1 {
    top: 24px;
  }
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .header__links__font-size-box {
    display: none;
  }
}

.header__links .header-icons {
  margin-right: 18px;
  display: inline-block;
  font-weight: bold;
}

.header__links .header-icons img {
  vertical-align: middle;
  border-radius: 4px;
  padding: 4px;
}

.header__links .header-icons span {
  display: none;
}

.header__links .header-icons:focus {
  border: 1px solid #00235d;
  border-radius: 4px;
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .header__links .header-icons {
    padding-left: 7px !important;
    margin-right: 7px !important;
  }
  .header__links .header-icons img {
    display: none;
  }
  .header__links .header-icons span {
    display: inline;
  }
}

.header__wrap {
  position: relative;
}

.header__wrap__h1 {
  width: 1760px;
  margin: 0 auto;
  position: relative;
  z-index: 10000;
}

.header__wrap__h1 h1 {
  position: absolute;
  left: 10px;
  top: 35px;
}

.header__wrap__h1 h1 a {
  display: block;
  text-align: center;
  font-size: 0;
}

.header__wrap__h1 h1 a:focus {
  border: 1px solid gold;
}

@media screen and (min-width: 1px) and (max-width: 1760px) {
  .header__wrap__h1 {
    width: auto;
  }
}

@media screen and (min-width: 1px) and (max-width: 1400px) {
  .header__links {
    width: 100%;
  }
  .header__wrap__h1 {
    width: auto;
  }
  .header__wrap__h1 h1 {
    left: 5px;
  }
  .header__wrap__h1 h1 a img {
    max-height: 55px;
  }
}

.header__search {
  position: relative;
  width: 100%;
  max-width: 1760px;
  margin: 0 auto;
  z-index: 9998;
}

.header__search__in {
  position: absolute;
  top: 5px;
  right: 400px;
  border: 1px solid #eeeeee;
  background-color: #fff;
}

@media screen and (min-width: 1px) and (max-width: 1400px) {
  .header__search__in {
    right: 10px;
    top: 10px;
  }
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .header__search__in {
    right: 0px;
  }
}

.header__search-in_box {
  border: 0px;
  padding: 0;
  font-size: 13px;
  padding-left: 10px;
  height: -2px;
  width: 240px;
  line-height: -2px;
  vertical-align: top;
  height: 36px;
  vertical-align: middle;
}

.header__search .header-search-btn-big {
  width: 36px;
  height: 36px;
  position: relative;
}

.header__search .header-search-btn-big:before {
  position: absolute;
  top: 0;
  left: 0;
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -227.05882px 0px;
  background-image: url("../images/sprite.png");
  background-size: 574.11765px auto;
  width: 32.94118px;
  min-width: 32.94118px;
  height: 32.94118px;
}

.header__search .header-search-btn-big:focus {
  border: 1px solid gold;
}

.header__search input[type="image"]:focus {
  border: 1px solid gold;
}

.header-search-btn {
  display: none;
}

.add-search-btn .header__search__in {
  right: 50%;
  margin-right: -70px;
}

.add-search-btn .header__search {
  display: none;
}

.add-search-btn .header__search.on {
  display: block;
}

.add-search-btn .header-search-btn {
  display: inline-block;
}

@media screen and (min-width: 769px) and (max-width: 1760px) {
  .header__search {
    width: auto;
  }
  .header__search input[type="image"] {
    margin-top: 0px;
  }
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .header__search {
    display: none;
  }
  .header__search-in_box {
    height: 32px;
    line-height: 32px;
  }
  .header__search__in {
    top: 50px;
  }
  .add-search-btn .header__search__in {
    right: 0;
    margin-right: 0;
  }
  .header__search.on {
    display: block;
    width: auto;
  }
  .header-search-btn {
    display: none !important;
  }
}

.fav-word {
  padding-top: 20px;
  font-size: 16px;
}

.fav-word h3 {
  display: inline-block;
  vertical-align: middle;
  font-size: 18px;
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .fav-word h3 {
    font-size: 16px;
    display: block;
  }
}

.fav-word__list {
  display: inline-block;
  vertical-align: middle;
}

.fav-word a {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  padding-left: 20px;
  padding-right: 20px;
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .fav-word a {
    padding-left: 10px;
    padding-right: 0px;
    font-size: 14px;
  }
}

.fav-word a:focus {
  text-decoration: underline;
}

.fav-word a:before {
  position: absolute;
  top: 50%;
  left: 0;
  content: ' ';
  display: block;
  width: 1px;
  height: 13px;
  transform: translateY(-50%);
  background-color: #999;
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .fav-word a:before {
    display: none;
  }
}

.fav-word a:first-child:before {
  display: none;
}

.main-section.on:before {
  content: ' ';
  display: block;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  z-index: 9999;
  background: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(5px);
}

.sub-page-container.on:before {
  content: ' ';
  display: block;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  z-index: 9999;
  background: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(5px);
}

/*gnb*/
.gnb {
  width: 100%;
  position: absolute;
  left: 0;
  top: 41px;
  background-color: #fff;
  background-repeat: repeat-x;
  background-position: center top;
  box-shadow: 0px 7px 11px -13px rgba(0, 0, 0, 0.75);
  /*한개씩 떨어짐*/
}

.gnb .gnb-ul {
  display: table;
  width: 1760px;
  padding-left: 0px;
  margin: 0 auto;
}

.gnb .gnb-menu {
  display: table-cell;
  position: relative;
}

.gnb .gnb-menu > a {
  display: block;
  text-align: center;
  color: #000;
  height: 80px;
  line-height: 80px;
  text-decoration: none;
  font-size: 22px;
  font-family: "scdream";
  font-weight: 500;
}

.gnb .gnb-menu.on > a,
.gnb .gnb-menu.over > a,
.gnb .gnb-menu.focus > a,
.gnb .gnb-menu > a:hover,
.gnb .gnb-menu > a:focus {
  color: #db4651;
}

.gnb .gnb-menu.on > a,
.gnb .gnb-menu.focus > a,
.gnb .gnb-menu > a:focus {
  text-decoration: underline;
}

.gnb .sub-nav {
  visibility: hidden;
  height: 0;
  position: absolute;
  top: 80px;
  left: 0;
  width: 100%;
  z-index: 98;
  background-color: #fff;
  padding-top: 0;
  opacity: 0;
  transition: all 0.3s ease 0s;
}

.gnb .gnb-menu.focus .sub-nav,
.gnb .gnb-menu.over .sub-nav,
.gnb.selected .gnb-menu.focus .sub-nav {
  visibility: visible;
  height: auto;
  opacity: 1;
}

.gnb .sub-nav__box {
  border: 2px solid #00235d;
  border-top: 0px;
  padding-left: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  transition: all 0.3s ease 0s;
}

.gnb .sub-nav__box__list > a {
  display: block;
  padding: 8px 0 8px 10px;
  color: #777;
  font-size: 19px;
}

.gnb .sub-nav__box__list > a:hover,
.gnb .sub-nav__box__list > a:focus {
  color: #db4651;
  background-color: #eee;
  border-radius: 5px;
}

.gnb .sub-nav__box__list > a:focus {
  text-decoration: underline;
}

.gnb .gnb-menu.over .sub-nav__box,
.gnb.selected .gnb-menu.focus .sub-nav__box {
  padding-bottom: 15px;
  padding-top: 15px;
}

* + html .gnb {
  position: absolute;
  top: 0;
  padding-top: 0;
}

/* all 2nd menu down
===============================*/
.down-all .gnb {
  background-image: url("../../resources/images/basic/header-bg-ver1.png");
  background-position: center -41px;
}

.down-all .gnb .sub-nav {
  display: block;
  text-align: center;
  height: 0;
  overflow: hidden;
  visibility: hidden;
  position: relative;
  top: 0;
  left: 0;
  opacity: 1;
  background: transparent;
}

.down-all .gnb .sub-nav__box {
  border: 0px;
  padding-left: 0;
  padding-top: 10px;
  padding-bottom: 10px;
}

.down-all .gnb .sub-nav__box__list a {
  display: block;
  width: 100%;
  padding: 8px 0;
  font-family: "scdream";
  font-weight: 500;
  /*
                &:hover,
				&:focus {}
				*/
}

.down-all .gnb.selected .gnb-ul .sub-nav {
  height: auto;
  visibility: visible;
  padding-top: 10px;
  padding-bottom: 10px;
  transition: all 0s ease 0s;
}

.down-all .gnb .gnb-menu.over .sub-nav {
  background-color: #fff;
}

.down-all .gnb .gnb-menu.over .sub-nav__box__list > a {
  color: #777;
}

.down-all .gnb .gnb-menu.over .sub-nav__box__list > a:hover,
.down-all .gnb .gnb-menu.over .sub-nav__box__list > a:focus {
  color: #db4651;
}

.down-all .gnb .gnb-menu > a {
  background-repeat: repeat-x;
  background-position: left bottom;
}

@media screen and (min-width: 769px) and (max-width: 1400px) {
  .down-all .gnb .gnb-menu > a {
    border-bottom: 1px solid #ddd;
  }
}

.down-all .gnb .gnb-menu.over > a,
.down-all .gnb .gnb-menu.focus > a {
  background-image: url("../../resources/images/basic/header-gnb-bg-on.png");
  background-position: left bottom;
  background-repeat: repeat-x;
  background-color: #fff;
}

.down-all .gnb .gnb-menu:first-child > a > span {
  background-image: none;
}

/* 2차메뉴 균일 박스 디자인 적용시 */
.down-all .gnb .gnb-ul {
  display: table;
}

.down-all .gnb .gnb-menu {
  display: table-cell;
  float: none;
}

.down-all .gnb .gnb-menu.over {
  background-color: #fff;
}

/* 2차메뉴 보더적용시 주석 해제 */
/*
    .down-all .gnb .gnb-menu > a {background-image:url('../../resources/images/basic/header-gnb-bg.png');background-position: left bottom;background-repeat: repeat-x;}
	.down-all .gnb .gnb-menu:first-child {border-left:1px solid #ededed}
	.down-all .gnb .gnb-menu:last-child {border-right:1px solid #ededed}
	.down-all .gnb .gnb-menu {padding-right:1px;}
	.down-all .gnb.selected .gnb-menu {border-right:1px solid #ededed;padding-right:0;}
	.down-all .gnb .gnb-menu:first-child {}
	.down-all .gnb .gnb-menu > a {
		display: block;
		margin-left: -1px;
		position: relative;
		text-align: center;
		width: 102%;
		}
		*/
/*ie7*/
* + html .gnb {
  top: 90px;
}

* + html .gnb .gnb-menu {
  float: left;
  width: 25%;
}

* + html .gnb .gnb-menu > a {
  white-space: nowrap;
}

* + html .down-all .gnb .gnb {
  position: absolute;
  top: 34px;
}

* + html .down-all .gnb .gnb-ul {
  display: block;
}

* + html .down-all .gnb .gnb-menu {
  display: block;
  float: left;
  width: 25%;
}

* + html .down-all .gnb .gnb-menu.over {
  background-color: #fff;
}

* + html .down-all .gnb .gnb-menu:first-child {
  border-left: 0px solid #acc1bf;
}

* + html .down-all .gnb .gnb-menu {
  border-right: 0px solid #fff;
  border-left: 0px;
}

* + html .down-all .gnb .gnb-menu > a {
  width: 100%;
  margin-left: 0;
}

* + html .down-all .gnb .gnb-menu.over .sub-nav .sub-nav__box__list a {
  color: #777;
}

* + html .down-all .gnb .gnb-menu.over .sub-nav .sub-nav__box__list a:hover,
* + html .down-all .gnb .gnb-menu.over .sub-nav .sub-nav__box__list a:focus {
  color: #db4651;
}

* + html .down-all .gnb .selected .gnb-menu {
  border-right: 0;
}

/*모드별 디자인
	ver2
	down-all
	add-all-menu
	add-search-btn
--------------*/
/*ver2*/
.ver2 .header__wrap__h1 h1 {
  top: 36px;
  z-index: 9999;
}

.ver2 .gnb {
  top: 0px;
}

.ver2 .gnb .gnb-ul {
  width: 900px;
  padding-left: 860px;
}

.ver2 .gnb .gnb-menu > a {
  height: 100px;
  line-height: 110px;
  white-space: nowrap;
}

.ver2 .gnb .sub-nav {
  top: 121px;
}

.ver2.down-all .gnb {
  background-image: url("../../resources/images/basic/header-bg-ver2.png");
  background-position: center 0px;
}

.ver2.down-all .gnb .sub-nav {
  top: 0 !important;
}

@media screen and (min-width: 769px) and (max-width: 1760px) {
  .ver2 .gnb .sub-nav {
    top: 60px !important;
  }
}

/* add-all-menu */
.add-all-menu .all_menu_wrap__outwrap {
  display: block;
}

.add-all-menu .gnb .gnb-ul {
  padding-right: 150px;
  width: 1610px;
}

/*ver2 + add-all-menu*/
.ver2.add-all-menu .gnb .gnb-ul {
  padding-left: 310px;
  width: 1300px;
  padding-right: 150px;
  padding-top: 21px;
}

@media screen and (min-width: 1601px) and (max-width: 1900px) {
  .ver2.add-all-menu .gnb .gnb-ul {
    width: 100%;
    box-sizing: border-box;
  }
}

@media screen and (min-width: 1401px) and (max-width: 1600px) {
  .ver2.add-all-menu .gnb .gnb-ul {
    padding-left: 300px !important;
    width: 100%;
    box-sizing: border-box;
    padding-right: 160px;
  }
}

@media screen and (min-width: 769px) and (max-width: 1400px) {
  .header {
    height: 140px;
  }
  .header__wrap__h1 h1 {
    top: 10px !important;
  }
  .gnb {
    top: 60px !important;
    background-image: none !important;
  }
  .gnb .gnb-ul {
    width: 100% !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .gnb .gnb-menu > a {
    height: 60px !important;
    line-height: 60px !important;
  }
  .gnb .sub-nav {
    top: 60px;
  }
}

/*유동적 메뉴사이즈*/
.none-width.header .gnb .gnb-ul {
  display: block;
  width: 1760px;
  margin: 0 auto;
}

.none-width.header .gnb .gnb-ul .gnb-menu {
  display: block;
  float: left;
}

.none-width.header.down-all .gnb .gnb-ul .gnb-menu {
  display: table-cell;
  float: none;
}

@media screen and (min-width: 769px) and (max-width: 1760px) {
  .none-width.header .gnb .gnb-ul {
    display: table;
    width: 100%;
    margin: 0 auto;
  }
  .none-width.header .gnb .gnb-ul .gnb-menu {
    display: table-cell;
    float: none;
  }
  .none-width.header .gnb .gnb-ul .gnb-menu > a {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
}

.none-width.ver2.header .gnb .gnb-ul {
  width: 900px;
}

.none-width.header.add-all-menu .gnb .gnb-ul {
  width: 1610px;
}

.none-width.header.add-all-menu.ver2 .gnb .gnb-ul {
  width: 1300px;
}

/* 펼쳤을때 좌측 타이틀*/
.gnb-tit {
  display: none;
}

.ver-left .gnb-tit {
  display: none !important;
}

.ver-left .down-all.ver2.left-tit .gnb {
  height: 121px;
  overflow: hidden;
}

.down-all.ver2.left-tit .gnb .gnb-ul {
  position: relative;
}

.down-all.ver2.left-tit .gnb.selected .gnb-ul {
  background-image: url("../../resources/images/basic/gnb-tit-bg.png");
  background-position: left bottom;
  background-repeat: no-repeat;
}

@media screen and (min-width: 769px) and (max-width: 1760px) {
  .down-all.ver2.left-tit .gnb.selected .gnb-ul {
    background-image: none;
  }
}

.down-all.ver2.left-tit .gnb .gnb-menu .gnb-tit {
  position: fixed;
  left: 50%;
  margin-left: -880px;
  z-index: 999;
  width: 310px;
  text-align: center;
  color: #000;
  letter-spacing: -2px;
  font-size: 27px;
  font-weight: bold;
}

.mainpage .down-all.ver2.left-tit .gnb .gnb-menu .gnb-tit {
  top: 301px;
}

.pop-closed .down-all.ver2.left-tit .gnb .gnb-menu .gnb-tit {
  top: 161px;
}

.top-banner-none .down-all.ver2.left-tit .gnb .gnb-menu .gnb-tit {
  top: 161px;
}

.subpage .down-all.ver2.left-tit .gnb .gnb-menu .gnb-tit {
  top: 161px;
}

@media screen and (min-width: 1px) and (max-width: 1760px) {
  .down-all.ver2.left-tit .gnb .gnb-menu .gnb-tit {
    display: none;
  }
}

.down-all.ver2.left-tit .gnb .gnb-menu.over .gnb-tit, .down-all.ver2.left-tit .gnb .gnb-menu.focus .gnb-tit {
  display: block;
}

@media screen and (min-width: 1px) and (max-width: 1760px) {
  .down-all.ver2.left-tit .gnb .gnb-menu.over .gnb-tit, .down-all.ver2.left-tit .gnb .gnb-menu.focus .gnb-tit {
    display: none;
  }
}

.gnb .sub-nav__3rd {
  display: none;
}

.lang-wrap {
  position: relative;
  width: 1760px;
  margin: 0 auto;
  z-index: 999;
}

@media screen and (min-width: 1px) and (max-width: 1760px) {
  .lang-wrap {
    width: auto;
  }
}

.header__lang {
  position: absolute;
  top: 64px;
  right: 75px;
  z-index: 9;
  font-size: 16px;
}

@media screen and (min-width: 1px) and (max-width: 1400px) {
  .header__lang {
    top: 20px;
    right: 330px;
  }
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .header__lang {
    top: 10px;
    left: -300px;
    right: unset;
    z-index: 99999;
    transition: all 0.3s ease 0s;
  }
  .mobile_menu_open .header__lang {
    left: 20px;
  }
}

.header__lang > a {
  font-weight: bold;
  border-bottom: 1px solid #000;
  padding-right: 25px;
}

.header__lang > a:focus {
  background-color: #eee;
}

.header__lang > a:after {
  position: absolute;
  top: 8px;
  right: 0;
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -963px -363px;
  background-image: url("../images/sprite.png");
  width: 10px;
  min-width: 10px;
  height: 6px;
}

.header__lang__in {
  display: none;
  position: absolute;
  top: 30px;
  left: 0;
  border: 1px solid #000;
  background-color: #fff;
  border-radius: 10px;
}

.header__lang__in.on {
  display: block;
}

.header__lang__in a {
  display: block;
  padding: 5px;
  box-sizing: border-box;
  width: 60px;
  text-align: center;
}

.header__lang__in a:focus {
  text-decoration: underline;
}

/*상단배너*/
.top-banner {
  max-height: 140px;
}

.top-banner__in {
  min-height: 30px;
  position: relative;
  text-align: center;
}

.top-banner__in > div:nth-child(1) {
  min-height: 30px;
}

.top-banner__in__link {
  display: block;
  text-align: center;
  margin: 0 auto;
  color: #fff;
  font-size: 17px;
}

.top-banner__in__link img {
  margin: 0 auto;
}

.top-banner__in__link__tit {
  color: #f2cb8e;
  font-weight: normal;
  padding-right: 10px;
}

.top-banner__in img {
  max-width: 100%;
  margin: 0 auto;
}

.top-banner__in p {
  position: absolute;
  top: 0;
  left: -9999px;
  font-size: 0;
  overflow: hidden;
  width: 0;
  height: 0;
}

.top-banner__in .top-banner-close {
  background-color: #2b3353;
  border-radius: 5px;
  padding: 2px 5px;
  font-size: 0;
  position: absolute;
  top: 0;
  right: 10px;
  text-align: right;
}

.top-banner__in .top-banner-close a,
.top-banner__in .top-banner-close label {
  vertical-align: middle;
  color: #fff;
  font-size: 14px;
  margin-lefT: 5px;
}

.top-banner__in__slick-pause {
  position: absolute;
  top: 0;
  right: 200px;
  display: block;
  width: 22px;
  height: 22px;
  font-size: 0;
  text-indent: -9999px;
  border-radius: 20px;
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -901px 0px;
  background-image: url("../images/sprite.png");
  width: 12px;
  min-width: 12px;
  height: 22px;
  vertical-align: top;
}

.top-banner__in__slick-pause:focus {
  background-color: gold;
}

.top-banner .slick-arrow {
  display: none !important;
}

.top-banner .top-banner-nav {
  position: absolute;
  top: 10px;
  left: 0;
  width: 100%;
}

.top-banner .top-banner-nav__in {
  width: 1760px;
  margin: 0 auto;
  position: relative;
}

@media screen and (min-width: 1px) and (max-width: 1760px) {
  .top-banner .top-banner-nav__in {
    width: auto;
  }
}

.top-banner .top-banner-nav__in .slick-dots {
  position: absolute;
  top: 0;
  right: 225px;
}

.top-banner .top-banner-nav__in .slick-dots:after {
  content: ' ';
  clear: both;
  display: block;
  visibility: hidden;
}

* + html .top-banner .top-banner-nav__in .slick-dots {
  display: inline-block;
}

.top-banner .top-banner-nav__in .slick-dots li {
  float: left;
  margin-right: 5px;
}

.top-banner .top-banner-nav__in .slick-dots li button {
  background-color: transparent;
  display: inline-block;
  color: gold;
  vertical-align: middle;
  overflow: visible;
  border: 3px solid #fff;
  width: 22px;
  height: 22px;
  line-height: 22px;
  border-radius: 22px;
  font-size: 0;
}

.top-banner .top-banner-nav__in .slick-dots li.slick-active button {
  background-color: #fff;
  border: 3px solid #fff;
}

.top-banner .top-banner-nav__in .slick-dots li button:focus {
  background-color: gold;
}

.top-banner .top-banner-nav__in__prev {
  position: absolute;
  left: 0;
  top: 40px;
  text-indent: -9999px;
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: 0px -423px;
  background-image: url("../images/sprite.png");
  width: 48px;
  min-width: 48px;
  height: 48px;
}

.top-banner .top-banner-nav__in__prev:focus {
  opacity: 0.7;
}

.top-banner .top-banner-nav__in__next {
  position: absolute;
  right: 0;
  top: 40px;
  text-indent: -9999px;
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -386px -212px;
  background-image: url("../images/sprite.png");
  width: 48px;
  min-width: 48px;
  height: 48px;
}

.top-banner .top-banner-nav__in__next:focus {
  opacity: 0.7;
}

body .top-banner {
  overflow: hidden;
  height: 0px;
  max-height: 140px;
  position: relative;
  z-index: 9;
}

body.pop-closed .top-banner {
  height: 0 !important;
  min-height: 0;
  overflow: hidden;
}

@media screen and (min-width: 1px) and (max-width: 1760px) {
  body .top-banner {
    height: auto;
  }
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  body .top-banner {
    display: none;
  }
}

/*모바일메뉴*/
.mobile_top_nav {
  display: none;
}

#gnb_nav_mobile {
  height: auto;
  display: none;
}

/*모바일 메뉴 닫기*/
.mobile-close {
  position: fixed;
  left: -30px;
  top: 10px;
  opacity: 0;
  transition: all 0.3s ease 0s;
}

.mobile_on_off a {
  display: none;
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .mobile_on_off a {
    display: block;
  }
}

.mobile_top_nav.selected .mobile-close {
  opacity: 1;
  left: 285px;
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .header {
    overflow: visible;
    position: fixed;
    border-bottom: 1px solid #333;
    width: 100%;
    padding-bottom: 0px;
    height: 50px;
    background-color: #fff;
    background-image: none;
  }
  .header.selected {
    height: 100%;
  }
  .header__wrap__h1 h1 {
    margin-left: 0;
    left: 0;
    position: relative;
    top: 9px;
    width: 100%;
    text-align: center;
    padding-top: 0px;
  }
  .header__wrap__h1 h1 a {
    height: 35px;
    line-height: 35px;
    display: inline-block;
    margin: 0 auto;
  }
  .header__wrap__h1 h1 a img {
    max-height: 35px;
  }
  .ver2 .header__wrap__h1 h1 {
    top: 9px;
  }
  .header__wrap .gnb {
    display: none;
  }
  [class*="header__wrap"] {
    height: 50px;
  }
  /*모바일 메뉴*/
  .mobile_top_nav {
    display: block;
    z-index: 10000;
    max-height: 100%;
    position: fixed;
    top: 0;
    left: 0px;
    width: 100%;
    background-image: url("../../resources/images/basic/opa.png");
    background-repeat: repeat;
    background-position: 0 0;
  }
  .mobile_top_nav .m_s_btn {
    position: absolute;
    top: 8px;
    right: 10px;
    display: block;
    width: 28px;
    height: 28px;
    font-size: 0;
    text-indent: -9999px;
    content: '';
    vertical-align: middle;
    display: inline-block;
    background-repeat: no-repeat;
    background-position: -257.33333px 0px;
    background-image: url("../images/sprite.png");
    background-size: 650.66667px auto;
    width: 37.33333px;
    min-width: 37.33333px;
    height: 37.33333px;
  }
  .mobile_top_nav .m_open_btn {
    position: absolute;
    top: 12px;
    left: 15px;
    display: block;
    width: 28px;
    height: 28px;
    font-size: 0;
    text-indent: -9999px;
    content: '';
    vertical-align: middle;
    display: inline-block;
    background-repeat: no-repeat;
    background-position: -100px -102px;
    background-image: url("../images/sprite.png");
    background-size: 488px auto;
    width: 28px;
    min-width: 28px;
    height: 28px;
  }
  .mobile_top_nav__in {
    left: -320px;
    width: 320px;
    position: fixed;
    top: 0;
    height: 100%;
    background-color: #fff;
    transition: all 0.3s ease 0s;
    overflow: auto;
  }
  .mobile_top_nav.selected {
    height: 100%;
    overflow: auto;
    width: 100%;
    left: 0px;
  }
  .mobile_top_nav.selected .m_s_btn {
    display: none;
  }
  .mobile_top_nav.selected .mobile_top_nav__in {
    left: 0;
    box-shadow: 3px 4px 18px -7px rgba(0, 0, 0, 0.75);
  }
  #gnb_nav_mobile {
    opacity: 0;
    position: relative;
    padding-top: 44px;
    z-index: 9999;
    background-color: #fff;
    border-top: 1px solid #d1d1d6;
    background-color: #fff;
  }
  #gnb_nav_mobile li.gnb-menu {
    background-color: #fff;
  }
  #gnb_nav_mobile li.gnb-menu.over {
    background-color: #fff;
  }
  #gnb_nav_mobile li.gnb-menu:first-child {
    border-left: 0px;
  }
  #gnb_nav_mobile li.gnb-menu > a {
    width: auto;
    display: block;
    text-shadow: none;
    padding: 0px 10px 0px 20px;
    height: 38px;
    line-height: 38px;
    font-size: 17px;
    font-weight: 500;
    color: #222;
    text-align: left;
    border-top: 1px solid #d1d1d6;
  }
  #gnb_nav_mobile li.gnb-menu > a > span {
    background-image: none;
  }
  #gnb_nav_mobile .sub-nav__box__ul {
    background-color: #eee;
  }
  #gnb_nav_mobile .sub-nav__box__list a {
    border-top: 1px solid #d1d1d6;
    padding: 0px 10px 0px 25px;
    width: auto;
    height: 38px;
    line-height: 38px;
    font-weight: bold;
    display: block;
    color: #000;
    text-shadow: 0px 0px 0px;
  }
  #gnb_nav_mobile .sub-nav__box__list a:hover,
  #gnb_nav_mobile .sub-nav__box__list a:focus {
    background-color: #fff;
  }
  /*불켜짐*/
  #gnb_nav_mobile .gnb-menu {
    position: relative;
  }
  #gnb_nav_mobile .gnb-menu:after {
    content: '';
    display: inline-block;
    content: '';
    vertical-align: middle;
    display: inline-block;
    background-repeat: no-repeat;
    background-position: -376px -235.5px;
    background-image: url("../images/sprite.png");
    background-size: 488px auto;
    width: 14px;
    min-width: 14px;
    height: 14px;
    position: absolute;
    top: 12px;
    right: 10px;
  }
  #gnb_nav_mobile .gnb-menu.selected:after,
  #gnb_nav_mobile .gnb-menu.on:after {
    content: '';
    display: inline-block;
    content: '';
    vertical-align: middle;
    display: inline-block;
    background-repeat: no-repeat;
    background-position: -95px -384.5px;
    background-image: url("../images/sprite.png");
    background-size: 488px auto;
    width: 14px;
    min-width: 14px;
    height: 1px;
    position: absolute;
    top: 20px;
    right: 10px;
  }
  #gnb_nav_mobile .gnb-menu.on > a {
    color: #000;
  }
  #gnb_nav_mobile .gnb-menu.no-has-menu:after {
    display: none;
  }
  #gnb_nav_mobile .sub-nav__box__list.on > a {
    color: #000;
    text-decoration: underline;
  }
  #gnb_nav_mobile .sub-nav__box__list.on > a span {
    color: #000;
    text-decoration: underline;
  }
  /*3rd*/
  #gnb_nav_mobile .sub-nav__3rd {
    position: relative;
  }
  #gnb_nav_mobile .sub-nav__3rd a {
    color: #666;
    border-bottom: 0px;
    font-size: 13px;
    font-weight: normal;
    width: auto;
    display: block;
    text-decoration: none;
    text-align: left;
    padding-left: 35px;
    position: relative;
  }
  #gnb_nav_mobile .sub-nav__3rd a:after {
    content: '';
    display: inline-block;
    content: '';
    vertical-align: middle;
    display: inline-block;
    background-repeat: no-repeat;
    background-position: -598px -840px;
    background-image: url("../images/sprite.png");
    width: 2px;
    min-width: 2px;
    height: 2px;
    position: absolute;
    top: 20px;
    left: 25px;
  }
  #gnb_nav_mobile .sub-nav__3rd li:first-child {
    border-top: 0px;
  }
  /*#gnb_nav_mobile .gnb-menu.nav-item.on .sub-nav{height:auto;		visibility: visible;}*/
  #gnb_nav_mobile .gnb-menu.nav-item.on .sub-nav {
    height: auto;
    visibility: visible;
    display: block;
  }
  /*sldieup*/
  #gnb_nav_mobile .gnb-menu.nav-item .sub-nav {
    display: none;
    height: auto;
    visibility: visible;
  }
  /*sldieup*/
  #gnb_nav_mobile .gnb-menu.nav-item.on > a {
    color: #087f97;
  }
  #gnb_nav_mobile .sub-nav__box__list.on > a span {
    color: #087f97;
  }
  #gnb_nav_mobile .sub-nav__3rd .on a {
    color: #000;
    font-weight: bold;
  }
  #gnb_nav_mobile .sub-nav__3rd a.on {
    color: #000;
    font-weight: bold;
  }
  /*모바일 메뉴 닫기*/
  .mobile-close {
    position: fixed;
    left: -30px;
    top: 9px;
    opacity: 0;
    transition: all 0.3s ease 0s;
  }
  .mobile_menu_open .mobile-close {
    left: 275px;
    z-index: 10000;
    opacity: 1;
  }
  .mobile-close a {
    font-size: 0;
    width: 25px;
    height: 25px;
    text-indent: -9999px;
    display: block;
    content: '';
    vertical-align: middle;
    display: inline-block;
    background-repeat: no-repeat;
    background-position: -193px -53px;
    background-image: url("../images/sprite.png");
    background-size: 488px auto;
    width: 28px;
    min-width: 28px;
    height: 28px;
  }
  .mobile_top_nav.selected .mobile-close {
    opacity: 1;
    left: 285px;
  }
  /*gnb 방식변경*/
  .gnb-ul {
    width: 100%;
    display: block;
    padding-right: 0;
  }
  .gnb-menu {
    width: 100%;
    display: block;
    border-right: 0px;
  }
  .gnb-menu > a {
    text-align: left;
    line-height: 30px;
    height: 30px;
    padding-left: 15px;
  }
  .sub-nav {
    width: auto;
    border: 0;
    background-color: #f7f7f7;
    text-align: left;
  }
  .sub-nav__box__list a span {
    color: #666666;
    background-image: none !important;
    font-size: 17px;
  }
  #gnb_nav_mobile li.gnb-menu.selected .sub-nav {
    height: auto;
    visibility: visible;
  }
  #gnb_nav_mobile.selected {
    display: block;
    opacity: 1;
  }
  .gnb.selected {
    display: block;
    opacity: 1;
  }
  .header__links {
    z-index: 0;
    width: auto;
    height: 0;
  }
  .header__links.selected {
    z-index: 99999;
  }
  .add-search-btn .header__links__1,
  .header__links__1 {
    width: 250px;
    right: unset;
    left: -250px;
    top: 14px;
    transition: all 0.3s ease 0s;
  }
  .add-search-btn .header__links__1 a,
  .header__links__1 a {
    padding-left: 15px;
  }
  .header__links.selected .header__links__1 {
    left: 0;
  }
}

/*전체메뉴보기*/
.all_menu_wrap__outwrap {
  position: relative;
  width: 100%;
  margin: 0 auto;
  display: none;
  font-family: "scdream";
}

.all_menu_wrap__outwrap.selected {
  z-index: 10001;
}

.btn_all-wrap {
  width: 1760px;
  margin: 0 auto;
  position: relative;
}

.btn_all {
  position: absolute;
  right: 0;
  top: 50px;
  display: block;
  width: 49px;
  height: 49px;
}

.btn_all span {
  display: block;
  font-size: 0;
}

.btn_all span.type1 {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -830px -222px;
  background-image: url("../images/sprite.png");
  width: 20px;
  min-width: 20px;
  height: 17px;
  margin-left: 12px !important;
  margin-top: 15px;
}

.btn_all span.type2 {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -669px -251px;
  background-image: url("../images/sprite.png");
  width: 30px;
  min-width: 30px;
  height: 30px;
  display: none !important;
  margin-left: 10px !important;
  margin-top: 5px;
}

.selected .btn_all span.type1 {
  display: none !important;
}

.selected .btn_all span.type2 {
  display: inline-block !important;
  margin-top: 10px;
}

.btn_all:focus {
  background-color: #e0e0e0;
}

.btn_all:hover,
.btn_all:focus {
  opacity: 0.8;
}

.all_menu_wrap {
  width: 100%;
  background-color: #fff;
  margin: 0 auto;
  z-index: 9999;
  position: absolute;
  top: 121px;
  border-top: 1px solid #ddd;
  left: 0px;
  box-shadow: 4px 3px 6px -3px rgba(0, 0, 0, 0.75);
  /*
	height:0;
	overflow:hidden;
	visibility: hidden;*/
  opacity: 0;
  transition: all 0.7s ease 0s;
  display: none;
}

.all_menu_wrap__h2 {
  height: 110px;
  background-color: #db4651;
  display: none;
}

.selected .all_menu_wrap {
  display: block;
  height: auto;
  opacity: 1;
  visibility: visible;
  display: block;
}

.all_menu_in {
  position: relative;
  padding: 20px;
  padding-top: 40px;
  width: 1760px;
  margin: 0 auto;
  background-color: #fff;
}

.selected .all_menu_in .gnb-menu {
  border-right: 0;
}

.all_menu_in .gnb-ul {
  padding-right: 0;
  margin-left: -10px;
}

.all_menu_in .gnb-menu {
  float: left;
}

.all_menu_in .gnb-menu > a {
  display: block;
  color: #db4651;
  font-size: 22px;
  height: 48px;
  line-height: 48px;
  border-bottom: 2px solid #db4651;
  margin-left: 20px;
  text-align: left;
  padding-left: 15px;
  font-weight: bold;
  background-position: right bottom;
  background-repeat: no-repeat;
}

.all_menu_in .gnb-menu > a:focus {
  text-decoration: underline;
}

.all_menu_in .all_menu_close {
  color: #333;
  display: block;
  font-size: 0;
  position: absolute;
  right: 20px;
  top: -85px;
  z-index: 9999;
  width: 30px;
  height: 30px;
}

.all_menu_in .sub-nav {
  height: auto;
  visibility: visible;
}

.all_menu_in .sub-nav__box__ul {
  margin-left: 20px;
  padding-top: 20px;
}

.all_menu_in .sub-nav__box__list > a {
  display: block;
  min-height: 30px;
  line-height: 30px;
  padding: 0 0 6px 20px;
  color: #000;
  font-size: 19px;
  position: relative;
}

.all_menu_in .sub-nav__box__list > a:before {
  position: absolute;
  top: 14px;
  left: 8px;
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -385px -840px;
  background-image: url("../images/sprite.png");
  width: 3px;
  min-width: 3px;
  height: 3px;
}

.all_menu_in .sub-nav__box__list > a:hover,
.all_menu_in .sub-nav__box__list > a:focus {
  opacity: 0.8;
  text-decoration: underline;
}

.all_menu_in .gnb-ul {
  display: table;
  width: 100%;
}

.all_menu_in .gnb-menu {
  min-width: 12%;
  margin-bottom: 20px;
}

.all_menu_in .gnb-menu:first-child {
  border-left: 0px;
}

.all_menu_in .sub-nav__3rd {
  text-align: left;
  padding-left: 20px;
  padding-top: 0px;
  padding-bottom: 10px;
}

.all_menu_in .sub-nav__3rd__ul__li a,
.all_menu_in .gnb-thrd-menu__item {
  display: block;
  margin-bottom: 12px;
  padding-left: 13px;
  color: #666;
  position: relative;
}

.all_menu_in .sub-nav__3rd__ul__li a:hover, .all_menu_in .sub-nav__3rd__ul__li a:focus,
.all_menu_in .gnb-thrd-menu__item:hover,
.all_menu_in .gnb-thrd-menu__item:focus {
  text-decoration: underline;
}

.all_menu_in .sub-nav__3rd__ul__li a:before,
.all_menu_in .gnb-thrd-menu__item:before {
  position: absolute;
  top: 8px;
  left: 0;
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -901px -777px;
  background-image: url("../images/sprite.png");
  width: 5px;
  min-width: 5px;
  height: 2px;
}

@media screen and (min-width: 1401px) and (max-width: 1760px) {
  .all_menu_wrap__outwrap {
    width: 100%;
    top: 0;
  }
  .all_menu_in {
    width: auto;
  }
  .all_menu_in .gnb-menu > a {
    background-image: none !important;
  }
  .btn_all-wrap {
    width: 100%;
  }
}

@media screen and (min-width: 769px) and (max-width: 1400px) {
  .all_menu_wrap__outwrap {
    width: 100%;
    top: 0;
  }
  .all_menu_in {
    width: auto;
  }
  .all_menu_in .gnb-menu > a {
    background-image: none !important;
  }
  .btn_all-wrap {
    width: 100%;
  }
  .btn_all {
    top: 45px;
  }
}

@media screen and (min-width: 769px) and (max-width: 1400px) {
  .all_menu_wrap {
    top: 141px;
  }
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .all_menu_wrap__outwrap {
    display: none !important;
  }
  .selected .all_menu_wrap {
    display: none;
  }
}

.guide-box__table {
  margin-bottom: 30px;
  border-top: 2px solid #000;
  border-bottom: 1px solid #000;
}

.guide-box__table table {
  border-collapse: collapse;
  width: 100%;
  min-width: 540px;
}

.guide-box__table thead th {
  border-bottom: 0px;
}

.guide-box__table th {
  border: 1px solid #dcdcdc;
  padding: 13px 0;
  background-color: #f4f4f4;
}

.guide-box__table td {
  border: 1px solid #dcdcdc;
  padding: 13px 5px;
  text-align: center;
  vertical-align: middle;
}

.guide-box__table.left-td td {
  padding-left: 20px;
  padding-right: 10px;
  text-align: left;
}

.guide-box__table td.left-td {
  padding-left: 10px;
  padding-right: 10px;
  text-align: left;
}

.guide-box__table td.align_left {
  padding-left: 20px;
  padding-right: 10px;
  text-align: left;
}

@media (max-width: 768px) {
  .guide-box__table {
    overflow: auto;
  }
  .guide-box__table.res {
    padding-top: 10px;
  }
  .guide-box__table.res table {
    min-width: auto;
  }
  .guide-box__table.res colgroup {
    display: none;
  }
  .guide-box__table.res thead {
    display: none;
  }
  .guide-box__table.res tr {
    display: block;
    margin-bottom: 20px;
    border-top: 1px solid #dcdcdc;
  }
  .guide-box__table.res th {
    display: none;
  }
  .guide-box__table.res td {
    display: block;
    margin-bottom: 0px;
    text-align: left;
    padding-left: 10px;
    border-top: 0px;
    border-right: 1px solid #dcdcdc;
  }
  .guide-box__table.res td:before {
    color: #000;
    font-weight: bold;
    content: attr(data-th) " - ";
  }
}

.guide-box__table table colgroup .col-1 {
  width: 40%;
}

.guide-box__table table colgroup .col-2 {
  width: 30%;
}

.guide-box__table table colgroup .col-3 {
  width: 30%;
}

.guide-box__table table td {
  text-align: left;
}

.guide-box__box {
  border: 1px solid #ddd;
  border-top: 1px solid #000;
  border-bottom: 1px solid #000;
  padding: 20px;
  margin-bottom: 20px;
}

.guide-box__box__item {
  border: 1px solid #ddd;
  margin-bottom: 20px;
  padding: 20px;
}

.guide-box__box__item:last-child {
  margin-bottom: 0px;
}

.h3Type1 {
  color: #000;
  font-weight: bold;
  font-size: 26px;
  margin-bottom: 15px;
  padding-left: 0px;
  font-family: "scdream";
  position: relative;
}

@media (max-width: 768px) {
  .h3Type1 {
    font-size: 21px;
  }
}

.h3Type2 {
  color: #000;
  font-weight: bold;
  font-size: 21px;
  padding-left: 20px;
  margin-bottom: 10px;
  position: relative;
}

.h3Type2:before {
  padding-top: 10px;
  position: absolute;
  top: 11px;
  left: 0;
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -683px -607px;
  background-image: url("../images/sprite.png");
  width: 13px;
  min-width: 13px;
  height: 13px;
}

@media (max-width: 768px) {
  .h3Type2 {
    font-size: 18px;
  }
  .h3Type2:before {
    top: 7px;
    margin-bottom: 5px;
  }
}

.h3Type3 {
  font-weight: bold;
  font-size: 26px;
  padding-left: 3px;
  padding-top: 15px;
  padding-bottom: 15px;
  border-top: 2px solid #000;
  border-bottom: 1px solid #000;
  margin-bottom: 15px;
}

@media (max-width: 768px) {
  .h3Type3 {
    font-size: 22px;
  }
}

.h3Type4 {
  color: #db4651;
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 0px;
  padding-left: 0px;
  position: relative;
}

@media (max-width: 768px) {
  .h3Type4 {
    font-size: 15px;
  }
}

.h4Type2 {
  color: #000;
  font-weight: bold;
  font-size: 15px;
  margin-bottom: 10px;
  position: relative;
  padding-left: 10px;
}

@media (max-width: 768px) {
  .h4Type2 {
    font-size: 14px;
  }
}

.h4Type2:before {
  content: ' ';
  position: absolute;
  top: 9px;
  left: 0;
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -328px -122px;
  background-image: url("../images/sprite.png");
  width: 3px;
  min-width: 3px;
  height: 3px;
}

.searchBtn {
  color: #fff;
  background-color: #000;
  height: 50px;
  line-height: 50px;
  display: inline-block;
  padding-left: 30px;
  padding-right: 30px;
  vertical-align: middle;
  box-sizing: border-box;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  border: 1px solid #000;
}

.searchBtn:hover, .searchBtn:focus {
  background-color: #444;
}

.btn1 {
  color: #fff;
  background-color: #000;
  height: 60px;
  line-height: 60px;
  display: inline-block;
  padding-left: 30px;
  padding-right: 30px;
  vertical-align: middle;
  box-sizing: border-box;
  font-size: 15px;
  font-weight: 600;
  text-align: center;
  border: 1px solid #000;
}

.btn1:hover, .btn1:focus {
  background-color: #eee;
  color: #000;
}

.btn2 {
  color: #000;
  background-color: #fff;
  border: 1px solid #000;
  height: 60px;
  line-height: 60px;
  display: inline-block;
  padding-left: 30px;
  padding-right: 30px;
  box-sizing: border-box;
  vertical-align: middle;
  font-size: 15px;
  font-weight: 600;
  text-align: center;
}

.btn2:hover, .btn2:focus {
  background-color: #eee;
  text-decoration: underline;
}

.btn3 {
  display: inline-block;
  color: #fff;
  background-color: #da4453;
  font-size: 19px;
  padding: 20px 60px;
  font-weight: 600;
  transition: all 0.3s ease 0s;
  min-width: 100px;
  text-align: center;
}

.btn3:hover, .btn3:focus {
  background-color: #da5b67;
  color: gold;
}

.btn4 {
  display: inline-block;
  color: #000;
  background-color: #fff;
  font-size: 19px;
  padding: 19px 59px;
  border: 1px solid #000;
  font-weight: 600;
  transition: all 0.3s ease 0s;
  min-width: 100px;
  text-align: center;
}

.btn4:hover, .btn4:focus {
  background-color: #eee;
  color: #000;
}

.btn5 {
  display: inline-block;
  color: #fff;
  width: 200px;
  background-color: #f43142;
  font-size: 19px;
  height: 50px;
  text-align: center;
  line-height: 50px;
  font-weight: 600;
  transition: all 0.3s ease 0s;
  min-width: 100px;
  text-align: center;
}

.btn5:hover, .btn5:focus {
  background-color: #ff0016;
  color: gold;
}

.btn6 {
  display: inline-block;
  color: #fff;
  width: 200px;
  background-color: #3a8dcb;
  font-size: 14px;
  height: 33px;
  text-align: center;
  line-height: 33px;
  font-weight: 600;
  transition: all 0.3s ease 0s;
  min-width: 100px;
  text-align: center;
}

.btn6:hover, .btn6:focus {
  background-color: #51a5e3;
  color: gold;
}

.btn7 {
  display: inline-block;
  color: #fff;
  width: 200px;
  background-color: #000;
  font-size: 18px;
  height: 100px;
  text-align: center;
  line-height: 100px;
  font-weight: 600;
  transition: all 0.3s ease 0s;
  min-width: 100px;
  text-align: center;
}

.btn7:hover, .btn7:focus {
  background-color: #666;
  color: gold;
}

.downBtn1 {
  display: inline-block;
  color: #666;
  background-color: #fff;
  font-size: 15px;
  padding: 10px 40px 10px 30px;
  font-weight: 600;
  transition: all 0.3s ease 0s;
  min-width: 80px;
  text-align: center;
  border: 1px solid #666;
  position: relative;
}

.downBtn1:after {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 15px;
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -553px -607px;
  background-image: url("../images/sprite.png");
  width: 15px;
  min-width: 15px;
  height: 14px;
}

.downBtn1:hover, .downBtn1:focus {
  color: #000;
  background-color: #fff;
}

.downBtn1:hover:after, .downBtn1:focus:after {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 15px;
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -618px -607px;
  background-image: url("../images/sprite.png");
  width: 15px;
  min-width: 15px;
  height: 14px;
}

.downBtn2 {
  display: inline-block;
  color: #000;
  background-color: #fff;
  font-size: 15px;
  padding: 10px 40px 10px 30px;
  font-weight: 600;
  transition: all 0.3s ease 0s;
  min-width: 80px;
  text-align: center;
  border: 1px solid #000;
  position: relative;
}

.downBtn2:after {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 15px;
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -618px -607px;
  background-image: url("../images/sprite.png");
  width: 15px;
  min-width: 15px;
  height: 14px;
}

.downBtn2:hover, .downBtn2:focus {
  background-color: #eee;
}

.btnSmall-1 {
  display: inline-block;
  color: #fff;
  background-color: #000;
  border: 1px solid #000;
  box-sizing: border-box;
  vertical-align: middle;
  font-size: 15px;
  padding: 0 10px;
  height: 40px;
  line-height: 40px;
  font-weight: 600;
  transition: all 0.3s ease 0s;
  min-width: 90px;
  text-align: center;
}

.btnSmall-1:hover, .btnSmall-1:focus {
  background-color: #333;
  border: 1px solid #333;
  text-decoration: underline;
}

.btnSmall-2 {
  display: inline-block;
  color: #000;
  background-color: #dddddd;
  border: 1px solid #dddddd;
  box-sizing: border-box;
  vertical-align: middle;
  font-size: 15px;
  padding: 0 10px;
  height: 40px;
  line-height: 40px;
  font-weight: 600;
  transition: all 0.3s ease 0s;
  min-width: 90px;
  text-align: center;
}

.btnSmall-2:hover, .btnSmall-2:focus {
  text-decoration: underline;
}

.btnMore {
  display: inline-block;
  color: #0e5390;
  background-color: #fff;
  border: 1px solid #0e5390;
  box-sizing: border-box;
  vertical-align: middle;
  font-size: 15px;
  padding: 0 20px 0 20px;
  height: 40px;
  line-height: 40px;
  font-weight: 600;
  transition: all 0.3s ease 0s;
  min-width: 50px;
  text-align: center;
}

.btnMore:hover, .btnMore:focus {
  background-color: #eee;
}

.basicList > li {
  text-align: left;
  position: relative;
  padding-left: 15px;
  line-height: 1.6;
}

.basicList > li:before {
  position: absolute;
  top: 11px;
  left: 5px;
  content: ' ';
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -901px -725px;
  background-image: url("../images/sprite.png");
  width: 5px;
  min-width: 5px;
  height: 2px;
}

.basicList2 > li {
  text-align: left;
  position: relative;
  padding-left: 15px;
  line-height: 1.6;
  font-size: 17px;
  margin-bottom: 8px;
}

.basicList2 > li:before {
  position: absolute;
  top: 10px;
  left: 0px;
  content: ' ';
  display: block;
  width: 6px;
  height: 6px;
  background-color: #db4651;
}

.basicList3 > li {
  text-align: left;
  position: relative;
  padding-left: 15px;
  line-height: 1.6;
}

.basicList3 > li:before {
  position: absolute;
  top: 11px;
  left: 3px;
  content: ' ';
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -901px -725px;
  background-image: url("../images/sprite.png");
  width: 5px;
  min-width: 5px;
  height: 2px;
}

.basicTable1 {
  margin-bottom: 30px;
  border-top: 2px solid #000;
  border-bottom: 1px solid #000;
}

.basicTable1 table {
  border-collapse: collapse;
  width: 100%;
  min-width: 540px;
}

.basicTable1 thead th {
  border-bottom: 0px;
}

.basicTable1 th {
  border: 1px solid #dcdcdc;
  padding: 13px 0;
  background-color: #f4f4f4;
}

.basicTable1 td {
  border: 1px solid #dcdcdc;
  padding: 13px 5px;
  text-align: center;
  vertical-align: middle;
}

.basicTable1.left-td td {
  padding-left: 20px;
  padding-right: 10px;
  text-align: left;
}

.basicTable1 td.left-td {
  padding-left: 10px;
  padding-right: 10px;
  text-align: left;
}

.basicTable1 td.align_left {
  padding-left: 20px;
  padding-right: 10px;
  text-align: left;
}

@media (max-width: 768px) {
  .basicTable1 {
    overflow: auto;
  }
  .basicTable1.res {
    padding-top: 10px;
  }
  .basicTable1.res table {
    min-width: auto;
  }
  .basicTable1.res colgroup {
    display: none;
  }
  .basicTable1.res thead {
    display: none;
  }
  .basicTable1.res tr {
    display: block;
    margin-bottom: 20px;
    border-top: 1px solid #dcdcdc;
  }
  .basicTable1.res th {
    display: none;
  }
  .basicTable1.res td {
    display: block;
    margin-bottom: 0px;
    text-align: left;
    padding-left: 10px;
    border-top: 0px;
    border-right: 1px solid #dcdcdc;
  }
  .basicTable1.res td:before {
    color: #000;
    font-weight: bold;
    content: attr(data-th) " - ";
  }
}

.basicTable2 {
  margin-bottom: 30px;
  border-top: 2px solid #000;
}

.basicTable2 table {
  border-collapse: collapse;
  width: 100%;
  min-width: 540px;
}

.basicTable2 th {
  border: 1px solid #dcdcdc;
  border-left: 0;
  padding: 18px 10px;
  background-color: #f8f8f8;
  font-weight: normal;
}

.basicTable2 td {
  border: 1px solid #dcdcdc;
  border-right: 0;
  padding: 18px 5px;
  text-align: left;
  padding-left: 30px;
  vertical-align: middle;
}

@media (max-width: 768px) {
  .basicTable2 {
    overflow: auto;
  }
  .basicTable2.res {
    padding-top: 10px;
  }
  .basicTable2.res table {
    min-width: auto;
  }
  .basicTable2.res colgroup {
    display: none;
  }
  .basicTable2.res thead {
    display: none;
  }
  .basicTable2.res tr {
    display: block;
    margin-bottom: 20px;
    border-top: 1px solid #dcdcdc;
  }
  .basicTable2.res th {
    display: none;
  }
  .basicTable2.res td {
    display: block;
    margin-bottom: 0px;
    text-align: left;
    padding-left: 10px;
    border-top: 0px;
    border-right: 1px solid #dcdcdc;
  }
  .basicTable2.res td:before {
    color: #000;
    font-weight: bold;
    content: attr(data-th) " - ";
  }
}

.basicTable3 {
  text-align: center;
  margin-bottom: 30px;
  border-top: 2px solid #22316a;
  border-bottom: 1px solid #22316a;
}

.basicTable3 table {
  border-collapse: collapse;
  width: 100%;
  min-width: 540px;
}

.basicTable3 thead th {
  background-color: #f4f4f7;
  color: #22316a;
  border-right: 0px;
  border-left: 1px solid #dcdcdc;
  border-bottom: 0;
  font-weight: bold;
}

.basicTable3 thead th:first-child {
  border-left: 0px;
}

.basicTable3 th {
  border: 1px solid #dcdcdc;
  border-left: 0;
  padding: 10px 0;
  font-weight: normal;
}

.basicTable3 td {
  border: 1px solid #dcdcdc;
  border-right: 0;
  padding: 10px 5px;
  padding-left: 30px;
  text-align: left;
}

@media (max-width: 768px) {
  .basicTable3 {
    overflow: auto;
  }
  .basicTable3.res {
    padding-top: 10px;
    border-top: 0px;
    border-bottom: 0px;
  }
  .basicTable3.res table {
    min-width: auto;
  }
  .basicTable3.res colgroup {
    display: none;
  }
  .basicTable3.res thead {
    display: none;
  }
  .basicTable3.res tr {
    display: block;
    margin-bottom: 20px;
    border-top: 1px solid #dcdcdc;
  }
  .basicTable3.res th {
    display: none;
  }
  .basicTable3.res td {
    display: block;
    margin-bottom: 0px;
    text-align: left;
    padding-left: 10px;
    border-top: 0px;
    border-right: 1px solid #dcdcdc;
  }
  .basicTable3.res td:before {
    color: #000;
    font-weight: bold;
    content: attr(data-th) " - ";
  }
}

.basicTable4 {
  margin-bottom: 30px;
  border-top: 2px solid #000;
  border-bottom: 1px solid #000;
  overflow: hidden;
}

.basicTable4 table {
  border-collapse: collapse;
  width: 100%;
  min-width: 540px;
  margin-left: -1px;
}

.basicTable4 th {
  border-left: 1px solid #dcdcdc;
  padding: 13px 0;
  background-color: #f4f4f4;
  text-align: center;
  color: #000;
}

.basicTable4 tbody th {
  border: 1px solid #dcdcdc;
  padding: 13px 5px;
  text-align: center;
  border-right: 0px;
  border-bottom: 0px;
  background-color: #fbfbfb;
}

.basicTable4 td {
  border: 1px solid #dcdcdc;
  padding: 13px 5px;
  text-align: center;
  border-right: 0px;
  border-bottom: 0px;
}

.basicTable4 td.sbj-line {
  padding-left: 15px;
  text-align: left;
}

.basicTable4 td.sbj-line > a:hover, .basicTable4 td.sbj-line > a:focus {
  text-decoration: underline;
}

.basicTable4 td > a:hover, .basicTable4 td > a:focus {
  text-decoration: underline;
}

.basicTable4 td.align-left {
  text-align: left;
  padding-left: 15px;
}

@media (max-width: 768px) {
  .basicTable4 {
    overflow: auto;
  }
  .basicTable4.res {
    padding-top: 0px;
    border-top: 0px;
    border-bottom: 0px;
  }
  .basicTable4.res table {
    min-width: auto;
    margin-left: 0px;
  }
  .basicTable4.res colgroup {
    display: none;
  }
  .basicTable4.res thead {
    display: none;
  }
  .basicTable4.res tr {
    display: block;
    margin-bottom: 20px;
    border-bottom: 1px solid #dcdcdc;
  }
  .basicTable4.res th {
    display: none;
  }
  .basicTable4.res td {
    display: block;
    margin-bottom: 0px;
    text-align: left;
    padding-left: 10px;
    border-right: 1px solid #dcdcdc;
    border-top: 1px solid #dcdcdc;
    border-bottom: 0px;
  }
  .basicTable4.res td:first-child {
    border-left: 1px solid #dcdcdc;
  }
  .basicTable4.res td:before {
    color: #000;
    font-weight: bold;
    content: attr(data-th) " : ";
  }
  .basicTable4.res td.align-left {
    padding-left: 10px;
  }
}

.basicTable5 {
  text-align: center;
  margin-bottom: 30px;
  max-width: 100%;
}

.basicTable5 table {
  border-collapse: collapse;
  width: 100%;
  min-width: 540px;
}

.basicTable5 thead th {
  background-color: #f3f7f9;
  color: #22316a;
  border: 1px solid #ddd;
  padding: 10px 0;
  border-bottom: 0px;
}

.basicTable5 thead tr:first-child th {
  border-top: 4px solid #0f4c81;
}

.basicTable5 thead tr:first-child th:nth-child(2n-1) {
  border-top: 4px solid #1e82b0;
}

.basicTable5 tbody th {
  border: 1px solid #dcdcdc;
  padding: 8px 0;
  background-color: #fff;
  padding-left: 3px;
  padding-right: 3px;
  text-align: center;
  color: #000;
  font-weight: normal;
}

.basicTable5 tbody td {
  border: 1px solid #dcdcdc;
  padding: 8px 2px;
  text-align: center;
}

.basicTable5.left-td td {
  padding-left: 10px;
  padding-right: 10px;
  text-align: left;
}

.basicTable5 td.left-td {
  padding-left: 10px;
  padding-right: 10px;
  text-align: left;
}

@media (max-width: 768px) {
  .basicTable5 {
    overflow: auto;
  }
  .basicTable5.res {
    padding-top: 10px;
    border-top: 0px;
    border-bottom: 0px;
  }
  .basicTable5.res table {
    min-width: auto;
  }
  .basicTable5.res colgroup {
    display: none;
  }
  .basicTable5.res thead {
    display: none;
  }
  .basicTable5.res tr {
    display: block;
    margin-bottom: 20px;
    border-top: 1px solid #dcdcdc;
  }
  .basicTable5.res th {
    display: none;
  }
  .basicTable5.res td {
    display: block;
    margin-bottom: 0px;
    text-align: left;
    padding-left: 10px;
    border-top: 0px;
    border-right: 1px solid #dcdcdc;
  }
  .basicTable5.res td:before {
    color: #000;
    font-weight: bold;
    content: attr(data-th) " - ";
  }
}

.selectboxN1 {
  overflow: hidden;
  display: inline-block;
  vertical-align: middle;
  position: relative;
  background-color: #fff;
  text-align: left;
  border: 1px solid #cccccc;
}

.selectboxN1:after {
  position: absolute;
  top: 50%;
  right: 0;
  margin-right: 10px;
  transform: translateY(-50%);
  content: ' ';
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -963px -762px;
  background-image: url("../images/sprite.png");
  width: 9px;
  min-width: 9px;
  height: 5px;
}

.lte-ie9 .selectboxN1:after {
  display: none;
}

.selectboxN1 label {
  text-indent: -9999px;
  position: absolute;
  top: 0;
  left: -9999px;
  height: 0;
  overflow: hidden;
}

.selectboxN1 select {
  width: 100%;
  box-sizing: border-box;
  background-color: transparent;
  appearance: none;
  min-width: 100px;
  vertical-align: middle;
  color: #000;
  font-size: 16px;
  height: 48px;
  margin-top: 0;
  padding: 2px 2px 2px 10px;
  padding-right: 40px;
  position: relative;
  border: 0px;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  /*ie10~11 에서 셀렉트 기본디자인 무력화*/
}

.selectboxN1 select::-ms-expand {
  display: none;
}

.lte-ie9 .selectboxN1 select {
  width: 100%;
  vertical-align: top;
  padding-right: 0px;
}

.selectboxN1 select option {
  font-family: "scdream";
}

.selectboxN2 {
  overflow: hidden;
  display: inline-block;
  vertical-align: middle;
  position: relative;
  background-color: #fff;
  text-align: left;
  border: 1px solid #000;
}

.selectboxN2:after {
  position: absolute;
  top: 50%;
  right: 0;
  margin-right: 10px;
  transform: translateY(-50%);
  content: ' ';
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -963px -762px;
  background-image: url("../images/sprite.png");
  width: 9px;
  min-width: 9px;
  height: 5px;
}

.lte-ie9 .selectboxN2:after {
  display: none;
}

.selectboxN2 select {
  width: 100%;
  box-sizing: border-box;
  background-color: transparent;
  appearance: none;
  min-width: 100px;
  vertical-align: middle;
  color: #000;
  font-size: 16px;
  height: 34px;
  margin-top: 0;
  padding: 2px 2px 2px 10px;
  padding-right: 40px;
  position: relative;
  border: 0px;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  /*ie10~11 에서 셀렉트 기본디자인 무력화*/
}

.selectboxN2 select::-ms-expand {
  display: none;
}

.lte-ie9 .selectboxN2 select {
  width: 100%;
  vertical-align: top;
  padding-right: 0px;
}

.selectboxN2 select option {
  font-family: "scdream";
}

.selectbox {
  overflow: hidden;
  display: inline-block;
  vertical-align: middle;
  position: relative;
  background-color: #fff;
  text-align: left;
  border: 1px solid #000;
}

.selectbox:after {
  position: absolute;
  top: 50%;
  right: 0;
  margin-right: 10px;
  transform: translateY(-50%);
  content: ' ';
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -963px -762px;
  background-image: url("../images/sprite.png");
  width: 9px;
  min-width: 9px;
  height: 5px;
}

.lte-ie9 .selectbox:after {
  display: none;
}

.selectbox select {
  width: 100%;
  background-color: transparent;
  appearance: none;
  min-width: 230px;
  vertical-align: middle;
  color: #000;
  font-size: 16px;
  height: 58px;
  margin-top: 0;
  padding: 2px 2px 2px 10px;
  position: relative;
  border: 0px;
  /*
			@media screen and (min-width:0\0){
				& {width:125%}
			}
			*/
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  /*ie10~11 에서 셀렉트 기본디자인 무력화*/
}

.selectbox select::-ms-expand {
  display: none;
}

.lte-ie9 .selectbox select {
  width: 100%;
  vertical-align: top;
  padding-right: 0px;
}

.selectbox select option {
  font-family: "scdream";
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .selectbox select {
    min-width: 200px;
  }
}

.selectbox2 {
  overflow: hidden;
  display: inline-block;
  vertical-align: middle;
  position: relative;
  background-color: transparent;
  text-align: left;
  border-bottom: 2px solid #000;
}

.selectbox2:after {
  position: absolute;
  top: 50%;
  right: 0;
  margin-right: 10px;
  transform: translateY(-50%);
  content: ' ';
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -963px -762px;
  background-image: url("../images/sprite.png");
  width: 9px;
  min-width: 9px;
  height: 5px;
}

.lte-ie9 .selectbox2:after {
  display: none;
}

.selectbox2 select {
  width: 100%;
  background-color: transparent;
  appearance: none;
  min-width: 230px;
  vertical-align: middle;
  color: #000;
  font-size: 14px;
  height: 38px;
  margin-top: 0;
  padding: 2px 2px 2px 10px;
  position: relative;
  border: 0px;
  /*
			@media screen and (min-width:0\0){
				& {width:125%}
			}
			*/
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  /*ie10~11 에서 셀렉트 기본디자인 무력화*/
}

.selectbox2 select::-ms-expand {
  display: none;
}

.lte-ie9 .selectbox2 select {
  width: 100%;
  vertical-align: top;
  padding-right: 0px;
}

.selectbox2 select option {
  font-family: "scdream";
}

.selectbox4 {
  overflow: hidden;
  display: inline-block;
  vertical-align: middle;
  position: relative;
  background-color: #fff;
  text-align: left;
  border: 1px solid #000;
}

.selectbox4:after {
  position: absolute;
  top: 50%;
  right: 0;
  margin-right: 10px;
  transform: translateY(-50%);
  content: ' ';
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -963px -762px;
  background-image: url("../images/sprite.png");
  width: 9px;
  min-width: 9px;
  height: 5px;
}

.lte-ie9 .selectbox4:after {
  display: none;
}

.selectbox4 select {
  width: 100%;
  background-color: transparent;
  appearance: none;
  min-width: 230px;
  vertical-align: middle;
  color: #000;
  font-size: 16px;
  height: 34px;
  margin-top: 0;
  padding: 2px 2px 2px 10px;
  position: relative;
  border: 0px;
  /*
			@media screen and (min-width:0\0){
				& {width:125%}
			}
			*/
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  /*ie10~11 에서 셀렉트 기본디자인 무력화*/
}

.selectbox4 select::-ms-expand {
  display: none;
}

.lte-ie9 .selectbox4 select {
  width: 100%;
  vertical-align: top;
  padding-right: 0px;
}

.selectbox4 select option {
  font-family: "scdream";
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .selectbox4 select {
    min-width: 200px;
  }
}

.inText {
  border: 1px solid #000;
  height: 34px;
  width: 200px;
  line-height: 34px;
  font-size: 16px;
  padding: 0;
  padding-left: 10px;
  box-sizing: border-box;
}

.inText2 {
  border: 1px solid #ccc;
  height: 50px;
  line-height: 50px;
  font-size: 16px;
  min-width: 30%;
  padding: 0;
  padding-left: 10px;
  box-sizing: border-box;
}

.inText3 {
  border: 1px solid #e0e0e0;
  height: 37px;
  line-height: 37px;
  font-size: 16px;
  min-width: 30%;
  padding: 0;
  padding-left: 10px;
  box-sizing: border-box;
}

.inText4 {
  border: 1px solid #e0e0e0;
  height: 45px;
  line-height: 45px;
  font-size: 16px;
  min-width: 30%;
  padding: 0;
  padding-left: 10px;
  box-sizing: border-box;
}

.inText5 {
  border: 1px solid #e0e0e0;
  height: 30px;
  line-height: 30px;
  font-size: 16px;
  min-width: 30%;
  padding: 0;
  padding-left: 10px;
  box-sizing: border-box;
}

.inTextareaN1 {
  border: 1px solid #f6f6f6;
  height: 120px;
  width: 100%;
  line-height: 1.6;
  font-size: 16px;
  padding: 10px;
  box-sizing: border-box;
  background-color: #f6f6f6;
}

.fix-size {
  position: relative;
  width: 100%;
  height: 0;
  overflow: hidden;
  padding-bottom: 56.25%;
  /*16:9*/
  background-color: #eee;
  color: #fff;
}

.fix-size__in {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.fix-size iframe {
  width: 100%;
  height: 100%;
}

.fix-size img {
  width: 100%;
  height: 100% !important;
  /* 비율 안깨지고 상하 가운데 정렬시
		 object-fit: cover;
		 position: absolute;
		 top: 50%;
		 left: 0;
		 transform: translateY(-50%);
		*/
}

/*	메인페이지
===========================*/
.main-section {
  position: relative;
  background-color: #fff;
  font-family: "scdream";
}

.main-section__in {
  position: relative;
  min-height: 500px;
}

.main-section__in:after {
  content: ' ';
  clear: both;
  display: block;
  visibility: hidden;
}

* + html .main-section__in {
  display: inline-block;
}

.main-laywrap-top__in {
  width: 1760px;
  margin: 0 auto;
  padding-bottom: 80px;
}

.main-laywrap-top__in:after {
  content: ' ';
  clear: both;
  display: block;
  visibility: hidden;
}

* + html .main-laywrap-top__in {
  display: inline-block;
}

@media screen and (min-width: 1px) and (max-width: 1760px) {
  .main-laywrap-top__in {
    width: auto;
    padding-bottom: 40px;
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media screen and (min-width: 1px) and (max-width: 1050px) {
  .main-laywrap-top__in {
    padding-bottom: 15px;
  }
}

.main-lay-1 {
  float: left;
  width: 100%;
  margin-bottom: 40px;
}

@media screen and (min-width: 1px) and (max-width: 1760px) {
  .main-lay-1 {
    margin-bottom: 20px;
  }
}

@media screen and (min-width: 1px) and (max-width: 1050px) {
  .main-lay-1 {
    margin-bottom: 10px;
  }
}

.main-lay-2 {
  float: left;
  width: 1100px;
}

@media screen and (min-width: 1px) and (max-width: 1760px) {
  .main-lay-2 {
    width: 60%;
  }
}

@media screen and (min-width: 1px) and (max-width: 1050px) {
  .main-lay-2 {
    width: 100%;
    margin-bottom: 10px;
  }
}

.main-lay-3 {
  float: right;
  width: 620px;
}

@media screen and (min-width: 1px) and (max-width: 1760px) {
  .main-lay-3 {
    width: 40%;
  }
}

@media screen and (min-width: 1px) and (max-width: 1050px) {
  .main-lay-3 {
    width: 100%;
  }
}

.main-laywrap-middle {
  padding-top: 80px;
  padding-bottom: 80px;
  background-color: #fafafa;
}

.main-laywrap-middle__in {
  width: 1760px;
  margin: 0 auto;
}

.main-laywrap-middle__in:after {
  content: ' ';
  clear: both;
  display: block;
  visibility: hidden;
}

* + html .main-laywrap-middle__in {
  display: inline-block;
}

@media screen and (min-width: 1px) and (max-width: 1760px) {
  .main-laywrap-middle__in {
    width: auto;
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media screen and (min-width: 1px) and (max-width: 1050px) {
  .main-laywrap-middle {
    padding-bottom: 15px;
    padding-top: 15px;
  }
}

.main-lay-4 {
  float: left;
  width: 1280px;
}

@media screen and (min-width: 1px) and (max-width: 1760px) {
  .main-lay-4 {
    width: 70%;
  }
}

@media screen and (min-width: 1px) and (max-width: 1050px) {
  .main-lay-4 {
    width: 100%;
    margin-bottom: 15px;
  }
}

.main-lay-5 {
  float: right;
  width: 400px;
}

@media screen and (min-width: 1px) and (max-width: 1760px) {
  .main-lay-5 {
    width: 28%;
  }
}

@media screen and (min-width: 1px) and (max-width: 1050px) {
  .main-lay-5 {
    width: 100%;
  }
}

.main-laywrap-bottom {
  padding-top: 80px;
  padding-bottom: 80px;
}

@media screen and (min-width: 1px) and (max-width: 1050px) {
  .main-laywrap-bottom {
    padding-top: 15px;
    padding-bottom: 15px;
  }
}

.main-laywrap-bottom__in {
  width: 1760px;
  margin: 0 auto;
}

.main-laywrap-bottom__in:after {
  content: ' ';
  clear: both;
  display: block;
  visibility: hidden;
}

* + html .main-laywrap-bottom__in {
  display: inline-block;
}

@media screen and (min-width: 1px) and (max-width: 1760px) {
  .main-laywrap-bottom__in {
    width: auto;
    padding-left: 10px;
    padding-right: 10px;
  }
}

.main-lay-6 {
  float: left;
  width: 480px;
  margin-right: 80px;
}

@media screen and (min-width: 1px) and (max-width: 1760px) {
  .main-lay-6 {
    width: 30%;
    margin-right: 2%;
  }
}

@media screen and (min-width: 1px) and (max-width: 1050px) {
  .main-lay-6 {
    width: 49%;
    margin-right: 1%;
    margin-bottom: 15px;
  }
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .main-lay-6 {
    width: 100%;
    margin-right: 0;
    float: none;
  }
}

.main-lay-7 {
  float: left;
  width: 480px;
}

@media screen and (min-width: 1px) and (max-width: 1760px) {
  .main-lay-7 {
    width: 30%;
    margin-right: 2%;
  }
}

@media screen and (min-width: 1px) and (max-width: 1050px) {
  .main-lay-7 {
    width: 49%;
    margin-right: 0;
    margin-left: 1%;
    margin-bottom: 15px;
  }
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .main-lay-7 {
    width: 100%;
    margin-left: 0;
    float: none;
  }
}

.main-lay-8 {
  float: right;
  width: 640px;
}

@media screen and (min-width: 1px) and (max-width: 1760px) {
  .main-lay-8 {
    width: 36%;
  }
}

@media screen and (min-width: 1px) and (max-width: 1050px) {
  .main-lay-8 {
    width: 100%;
    margin: 0 auto;
  }
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .main-lay-8 {
    width: 100%;
    margin-left: 0;
    float: none;
  }
}

@media screen and (min-width: 1px) and (max-width: 1760px) {
  .main-section__in {
    width: auto;
  }
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .main-section {
    padding-top: 51px;
  }
}

/*전시관 관람정보*/
.main-info-line__list {
  display: table;
  width: 100%;
  padding-top: 50px;
  padding-bottom: 50px;
  padding-left: 10px;
  padding-right: 10px;
  box-sizing: border-box;
}

@media screen and (min-width: 1px) and (max-width: 1350px) {
  .main-info-line__list {
    display: block;
    padding-top: 20px;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
  }
  .main-info-line__list:after {
    content: ' ';
    clear: both;
    display: block;
    visibility: hidden;
  }
  * + html .main-info-line__list {
    display: inline-block;
  }
}

@media screen and (min-width: 1px) and (max-width: 560px) {
  .main-info-line__list {
    padding-top: 15px;
    border-bottom: 1px solid #ddd;
  }
}

.main-info-line__list li {
  display: table-cell;
  font-size: 21px;
  color: #666;
  position: relative;
  box-sizing: border-box;
}

@media screen and (min-width: 1px) and (max-width: 1350px) {
  .main-info-line__list li {
    width: 50%;
    display: block;
    float: left;
    text-align: left !important;
    margin-bottom: 20px;
    font-size: 18px;
  }
}

@media screen and (min-width: 1px) and (max-width: 560px) {
  .main-info-line__list li {
    margin-bottom: 15px;
  }
}

@media screen and (min-width: 1px) and (max-width: 480px) {
  .main-info-line__list li {
    width: 100%;
  }
}

.main-info-line__list li strong {
  color: #000;
  display: inline-block;
  padding-right: 20px;
  padding-left: 38px;
  position: relative;
}

.main-info-line__list li strong:before {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.main-info-line__list li.type1 strong:before {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -271px -423px;
  background-image: url("../images/sprite.png");
  width: 30px;
  min-width: 30px;
  height: 30px;
}

@media screen and (min-width: 1px) and (max-width: 480px) {
  .main-info-line__list li.type1 strong:before {
    content: '';
    vertical-align: middle;
    display: inline-block;
    background-repeat: no-repeat;
    background-position: -208.46154px -325.38462px;
    background-image: url("../images/sprite.png");
    background-size: 750.76923px auto;
    width: 23.07692px;
    min-width: 23.07692px;
    height: 23.07692px;
  }
}

.main-info-line__list li.type2 {
  text-align: center;
}

.main-info-line__list li.type2 strong:before {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -351px -423px;
  background-image: url("../images/sprite.png");
  width: 30px;
  min-width: 30px;
  height: 30px;
}

@media screen and (min-width: 1px) and (max-width: 480px) {
  .main-info-line__list li.type2 strong:before {
    content: '';
    vertical-align: middle;
    display: inline-block;
    background-repeat: no-repeat;
    background-position: -270px -325.38462px;
    background-image: url("../images/sprite.png");
    background-size: 750.76923px auto;
    width: 23.07692px;
    min-width: 23.07692px;
    height: 23.07692px;
  }
}

.main-info-line__list li.type3 {
  text-align: right;
}

.main-info-line__list li.type3 strong:before {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -431px -423px;
  background-image: url("../images/sprite.png");
  width: 30px;
  min-width: 30px;
  height: 30px;
}

@media screen and (min-width: 1px) and (max-width: 480px) {
  .main-info-line__list li.type3 strong:before {
    content: '';
    vertical-align: middle;
    display: inline-block;
    background-repeat: no-repeat;
    background-position: -331.53846px -325.38462px;
    background-image: url("../images/sprite.png");
    background-size: 750.76923px auto;
    width: 23.07692px;
    min-width: 23.07692px;
    height: 23.07692px;
  }
}

.main-info-line__list li.type4 {
  text-align: right;
}

.main-info-line__list li.type4 strong:before {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -428px -521px;
  background-image: url("../images/sprite.png");
  width: 30px;
  min-width: 30px;
  height: 30px;
}

@media screen and (min-width: 1px) and (max-width: 480px) {
  .main-info-line__list li.type4 strong:before {
    content: '';
    vertical-align: middle;
    display: inline-block;
    background-repeat: no-repeat;
    background-position: -329.23077px -400.76923px;
    background-image: url("../images/sprite.png");
    background-size: 750.76923px auto;
    width: 23.07692px;
    min-width: 23.07692px;
    height: 23.07692px;
  }
}

@media screen and (min-width: 1px) and (max-width: 1050px) {
  .main-info-line__list li {
    font-size: 15px;
    padding-left: 110px;
  }
  .main-info-line__list li strong {
    position: absolute;
    top: 0;
    left: 0;
  }
}

@media screen and (min-width: 1px) and (max-width: 480px) {
  .main-info-line__list li {
    padding-left: 30px;
    padding-top: 25px;
    margin-bottom: 10px;
  }
  .main-info-line__list li strong {
    padding-left: 30px;
  }
}

.exb-box {
  position: relative;
}

.exb-title {
  font-size: 36px;
  font-weight: 500;
  margin-bottom: 20px;
}

@media screen and (min-width: 1px) and (max-width: 1750px) {
  .exb-title {
    font-size: 30px;
  }
}

@media screen and (min-width: 1px) and (max-width: 1350px) {
  .exb-title {
    font-size: 24px;
  }
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .exb-title {
    font-size: 18px;
  }
}

@media screen and (min-width: 1px) and (max-width: 1350px) {
  .exb-title {
    margin-bottom: 50px;
  }
}

@media screen and (min-width: 1px) and (max-width: 1050px) {
  .exb-title {
    margin-bottom: 20px;
  }
}

.exb-more {
  font-size: 16px;
  display: block;
  padding: 5px;
  padding-right: 30px;
  position: absolute;
  top: 10px;
  right: 50px;
}

.exb-more:focus {
  border: 1px solid #000;
}

.exb-more:after {
  position: absolute;
  top: 7px;
  display: block;
  content: '+';
  right: 0;
  width: 15px;
  height: 15px;
  line-height: 15px;
  font-size: 25px;
}

@media screen and (min-width: 1px) and (max-width: 1050px) {
  .exb-more {
    right: 0;
    top: 0px;
  }
}

.exb-list {
  height: 510px;
}

@media screen and (min-width: 1px) and (max-width: 1760px) {
  .exb-list {
    height: auto;
  }
}

.exb-list .slick-arrow {
  position: absolute;
  top: 0;
  width: 360px;
  height: 100%;
  z-index: 9998;
  text-indent: -9999px;
}

@media screen and (min-width: 1px) and (max-width: 1760px) {
  .exb-list .slick-arrow {
    width: 33%;
  }
}

@media screen and (min-width: 1px) and (max-width: 480px) {
  .exb-list .slick-arrow {
    width: 15%;
  }
}

.exb-list .slick-arrow:after {
  content: ' ';
  display: block;
  width: 50px;
  height: 50px;
  background-color: #fff;
  border-radius: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  position: absolute;
  top: 50%;
  margin-top: -25px;
  box-shadow: 1px 4px 20px -10px black;
}

.exb-list .slick-arrow.slick-prev {
  left: 0;
}

.exb-list .slick-arrow.slick-prev:after {
  right: -4px;
  background-image: url("../../resources/images/icons/slick-arrow-left.png");
}

.exb-list .slick-arrow.slick-next {
  right: 0;
}

.exb-list .slick-arrow.slick-next:after {
  left: -4px;
  background-image: url("../../resources/images/icons/slick-arrow-right.png");
}

.exb-list .slick-arrow:focus:after {
  border: 1px solid gold;
}

.exb-list__item {
  width: 350px;
}

.exb-list__item a {
  display: block;
  text-align: center;
  position: relative;
  transition: all 0.3s ease 0s;
  margin-top: 50px;
}

.exb-list__item a img {
  margin: 0 auto;
  width: 80%;
  transition: all 0.3s ease 0s;
}

.exb-list__item__info {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.75);
  color: #fff;
  opacity: 0;
  top: 0;
  left: 10%;
  width: 80%;
  height: 100%;
  transition: all 0.3s ease 0s;
  box-sizing: border-box;
  padding-top: 30%;
}

.slick-center .exb-list__item__info {
  width: 100%;
  left: 0;
  padding-top: 35%;
}

a:hover .exb-list__item__info,
a:focus .exb-list__item__info {
  opacity: 1;
}

.exb-list__item__info strong {
  font-size: 28px;
  display: block;
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 30px;
}

.exb-list__item__info .item1 {
  font-size: 20px;
  margin-bottom: 50px;
}

.exb-list__item__info .item2 {
  border: 1px solid #fff;
  font-size: 20px;
  padding: 10px 20px;
  display: inline-block;
}

@media screen and (min-width: 1px) and (max-width: 1760px) {
  .exb-list__item__info {
    padding-top: 25px;
  }
  .slick-center .exb-list__item__info {
    padding-top: 35px;
  }
  .exb-list__item__info strong {
    font-size: 22px;
    margin-bottom: 20px;
  }
}

@media screen and (min-width: 1px) and (max-width: 1350px) {
  .exb-list__item__info {
    padding-top: 25px;
  }
  .slick-center .exb-list__item__info {
    padding-top: 25px;
  }
  .exb-list__item__info strong {
    font-size: 17px;
    margin-bottom: 10px;
  }
  .exb-list__item__info .item1 {
    font-size: 15px;
    margin-bottom: 10px;
  }
  .exb-list__item__info .item2 {
    font-size: 15px;
    padding-left: 10px;
    padding-right: 10px;
  }
}

.exb-list__item.slick-center a {
  margin-top: 0;
  margin-bottom: 15px;
  box-shadow: 4px 5px 19px -17px rgba(0, 0, 0, 0.75);
}

.exb-list__item.slick-center a img {
  width: 100%;
}

.edu-box {
  position: relative;
}

.edu-title {
  font-size: 36px;
  font-weight: 500;
  margin-bottom: 20px;
}

@media screen and (min-width: 1px) and (max-width: 1750px) {
  .edu-title {
    font-size: 30px;
  }
}

@media screen and (min-width: 1px) and (max-width: 1350px) {
  .edu-title {
    font-size: 24px;
  }
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .edu-title {
    font-size: 18px;
  }
}

.edu-more {
  font-size: 16px;
  display: block;
  padding: 5px;
  padding-right: 30px;
  position: absolute;
  top: 10px;
  right: 0;
}

.edu-more:focus {
  border: 1px solid #000;
}

.edu-more:after {
  position: absolute;
  top: 7px;
  display: block;
  content: '+';
  right: 0;
  width: 15px;
  height: 15px;
  line-height: 15px;
  font-size: 25px;
}

@media screen and (min-width: 1px) and (max-width: 1050px) {
  .edu-more {
    top: 0px;
  }
}

.edu-list {
  margin-left: -20px;
}

.edu-list:after {
  content: ' ';
  clear: both;
  display: block;
  visibility: hidden;
}

* + html .edu-list {
  display: inline-block;
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .edu-list {
    margin-left: -5px;
  }
}

.edu-list__item {
  width: 50%;
  float: left;
}

.edu-list__item a {
  display: block;
  margin-left: 20px;
  padding: 9px;
  border: 1px solid #ddd;
}

.edu-list__item a:hover, .edu-list__item a:focus {
  background-color: #fafafa;
}

.edu-list__item a:focus {
  text-decoration: underline;
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .edu-list__item a {
    margin-left: 5px;
  }
}

.edu-list__item__title {
  font-size: 22px;
  margin-bottom: 20px;
  height: 100px;
  line-height: 1.6;
  overflow: hidden;
}

@media screen and (min-width: 1px) and (max-width: 1350px) {
  .edu-list__item__title {
    font-size: 17px;
    height: 80px;
    margin-bottom: 10px;
  }
}

.edu-list__item__info {
  font-size: 18px;
  color: #666;
  padding-bottom: 10px;
  height: 70px;
  overflow: hidden;
  box-sizing: border-box;
}

.edu-list__item__info p {
  margin-bottom: 5px;
}

@media screen and (min-width: 1px) and (max-width: 1350px) {
  .edu-list__item__info {
    font-size: 14px;
  }
}

.img-box {
  margin-bottom: 20px;
}

.img-box {
  position: relative;
  width: 100%;
  height: 0;
  overflow: hidden;
  padding-bottom: 100%;
  /*16:9*/
  background-color: #eee;
  color: #fff;
}

.img-box__in {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.img-box iframe {
  width: 100%;
  height: 100%;
}

.img-box img {
  width: 100%;
  height: 100% !important;
  /* 비율 안깨지고 상하 가운데 정렬시
		 object-fit: cover;
		 position: absolute;
		 top: 50%;
		 left: 0;
		 transform: translateY(-50%);
		*/
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .img-box {
    margin-bottom: 10px;
  }
}

.edu-enroll {
  padding: 30px 30px 0 30px;
  height: 243px;
  height: 183px;
  position: relative;
  background-image: url("../../resources/images/cont/enroll-bg.png");
  background-position: bottom right;
  background-repeat: no-repeat;
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .edu-enroll {
    padding: 15px 15px 0 15px;
    height: 200px;
  }
}

@media screen and (min-width: 1px) and (max-width: 480px) {
  .edu-enroll {
    background-size: 40% auto;
  }
}

.edu-enroll__title {
  font-size: 28px;
  font-weight: 500;
  margin-bottom: 20px;
  margin-bottom: 5px;
}

@media screen and (min-width: 1px) and (max-width: 1050px) {
  .edu-enroll__title {
    font-size: 22px;
  }
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .edu-enroll__title {
    font-size: 18px;
  }
}

.edu-enroll__text {
  margin-bottom: 10px;
  font-size: 18px;
  line-height: 1.6;
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .edu-enroll__text {
    font-size: 16px;
  }
}

.edu-enroll__more {
  position: absolute;
  bottom: 30px;
  left: 30px;
  display: inline-block;
  padding: 10px 20px;
  border: 1px solid #000;
  transition: all 0.3s ease 0s;
}

.edu-enroll__more:hover {
  background-color: #9ed3cb;
}

.edu-enroll__more:focus {
  background-color: #9ed3cb;
  text-decoration: underline;
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .edu-enroll__more {
    left: 15px;
  }
}

.collection {
  border: 1px solid #dddddd;
  padding: 30px;
  position: relative;
  height: 200px;
}

@media screen and (min-width: 1px) and (max-width: 1760px) {
  .collection {
    padding: 20px;
  }
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .collection {
    padding: 15px;
  }
}

.collection__title {
  font-size: 28px;
  padding-bottom: 15px;
  font-weight: 500;
  position: relative;
  margin-bottom: 20px;
}

.collection__title:before {
  content: ' ';
  display: block;
  width: 25px;
  height: 3px;
  background-color: #db4651;
  position: absolute;
  bottom: 0;
  left: 0;
}

@media screen and (min-width: 1px) and (max-width: 1760px) {
  .collection__title {
    font-size: 22px;
  }
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .collection__title {
    font-size: 18px;
  }
}

.collection__date {
  font-size: 20px;
  max-width: 50%;
}

.collection__date strong {
  font-weight: 600;
  font-size: 43px;
}

@media screen and (min-width: 1px) and (max-width: 1760px) {
  .collection__date {
    font-size: 18px;
  }
  .collection__date strong {
    font-size: 26px;
  }
}

.collection__img-out {
  width: 200px;
  position: absolute;
  top: 30px;
  right: 30px;
  display: block;
}

@media screen and (min-width: 1px) and (max-width: 1760px) {
  .collection__img-out {
    width: 40%;
  }
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .collection__img-out {
    max-width: 200px;
    top: 20px;
  }
}

.collection__img {
  position: relative;
  width: 100%;
  height: 0;
  overflow: hidden;
  padding-bottom: 100%;
  /*16:9*/
  background-color: #eee;
  color: #fff;
}

.collection__img__in {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.collection__img iframe {
  width: 100%;
  height: 100%;
}

.collection__img img {
  width: 100%;
  height: 100% !important;
  /* 비율 안깨지고 상하 가운데 정렬시
		 object-fit: cover;
		 position: absolute;
		 top: 50%;
		 left: 0;
		 transform: translateY(-50%);
		*/
}

.collection__more {
  position: absolute;
  bottom: 30px;
  left: 30px;
  display: inline-block;
  padding: 10px 20px;
  border: 1px solid #000;
  transition: all 0.3s ease 0s;
}

.collection__more:hover {
  background-color: #eee;
}

.collection__more:focus {
  background-color: #eee;
  text-decoration: underline;
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .collection__more {
    left: 15px;
  }
}

.vr-box {
  border: 1px solid #dddddd;
  padding: 30px;
  position: relative;
  height: 200px;
  background-image: url("../../resources/images/cont/vr-bg.jpg");
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: cover;
}

@media screen and (min-width: 1px) and (max-width: 1760px) {
  .vr-box {
    padding: 20px;
  }
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .vr-box {
    padding: 15px;
    height: 170px;
  }
}

.vr-box__title {
  font-size: 28px;
  font-weight: 500;
  position: relative;
  margin-bottom: 20px;
}

@media screen and (min-width: 1px) and (max-width: 1760px) {
  .vr-box__title {
    font-size: 22px;
  }
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .vr-box__title {
    font-size: 18px;
    margin-bottom: 10px;
  }
}

.vr-box__text {
  font-size: 18px;
  line-height: 1.6;
}

@media screen and (min-width: 1px) and (max-width: 1760px) {
  .vr-box__text {
    word-break: break-all;
  }
  .vr-box__text br {
    display: none;
  }
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .vr-box__text {
    font-size: 16px;
  }
}

.vr-box__more {
  position: absolute;
  bottom: 30px;
  left: 30px;
  display: inline-block;
  padding: 10px 20px;
  border: 1px solid #000;
  transition: all 0.3s ease 0s;
}

.vr-box__more:hover {
  background-color: #ddd;
}

.vr-box__more:focus {
  background-color: #ddd;
  text-decoration: underline;
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .vr-box__more {
    left: 15px;
  }
}

/*팝업존*/
.popupzone {
  position: relative;
  margin-top: 0px;
  width: 100%;
  height: auto;
  max-width: 640px;
  margin: 0 auto;
}

.popupzone .slick-arrow {
  border: 0px;
  top: 220px;
}

.popupzone .slick-prev {
  position: absolute;
  left: 10px;
  z-index: 1;
  display: none !important;
}

.popupzone .slick-next {
  position: absolute;
  right: 10px;
  z-index: 1;
  display: none !important;
}

.popupzone .slick-slide a:hover,
.popupzone .slick-slide a:focus {
  opacity: 0.8;
}

.popupzone__tit {
  text-indent: -9999px;
  position: absolute;
  top: 0;
  left: -9999px;
  height: 0;
  overflow: hidden;
}

.popupzone__item > img {
  width: 100%;
}

.popupzone-nav {
  text-align: right;
  position: absolute;
  top: 0;
  right: 0px;
  box-sizing: border-box;
  z-index: 3;
  width: 100%;
  display: block;
}

.popupzone-nav.off {
  display: none;
}

.popupzone-nav > div {
  display: inline-block;
  text-align: center;
  margin-top: 0;
  z-index: 999;
  padding: 0px;
  border-radius: 0px;
}

.popupzone-nav > div > div {
  display: inline-block;
  vertical-align: top;
  margin-top: 13px;
  margin-right: 3px;
}

* + html .popupzone-nav > div > div {
  display: inline;
}

.popupzone-nav > div > ul {
  display: inline-block;
  font-size: 0;
  vertical-align: top;
}

* + html .popupzone-nav > div > ul {
  display: inline;
}

.popupzone-nav > div > ul li {
  display: inline-block;
  vertical-align: top;
}

* + html .popupzone-nav > div > ul li {
  display: inline;
}

.popupzone-nav > div > ul li a {
  color: gold;
}

.popupzone-nav > div > ul li .slide-pause-item {
  display: block;
  width: 40px;
  height: 40px;
  font-size: 0;
  text-indent: -9999px;
  position: relative;
  border-left: 1px solid #554b53;
  background-color: rgba(0, 0, 0, 0.75);
}

.popupzone-nav > div > ul li .slide-pause-item:before {
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -901px 0px;
  background-image: url("../images/sprite.png");
  width: 12px;
  min-width: 12px;
  height: 22px;
}

.popupzone-nav > div > ul li .slide-pause-item:focus {
  background-color: #000;
  border-radius: 3px;
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .popupzone-nav > div > ul li .slide-pause-item {
    height: 30px;
    width: 30px;
    border-left: 0px;
  }
  .popupzone-nav > div > ul li .slide-pause-item:before {
    content: '';
    vertical-align: middle;
    display: inline-block;
    background-repeat: no-repeat;
    background-position: -643.57143px 0px;
    background-image: url("../images/sprite.png");
    background-size: 697.14286px auto;
    width: 8.57143px;
    min-width: 8.57143px;
    height: 15.71429px;
  }
}

.popupzone-nav > div > ul li .slide-play-item {
  display: block;
  width: 17px;
  height: 17px;
  font-size: 0;
  text-indent: -9999px;
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -586px -689px;
  background-image: url("../images/sprite.png");
  width: 17px;
  min-width: 17px;
  height: 17px;
  margin-left: 5px;
  margin-top: 0px;
}

.popupzone-nav > div > ul li .slide-play-item:focus {
  background-color: gold;
  border-radius: 100%;
}

.popupzone-nav > div > ul li .slide-more-item {
  display: block;
  width: 17px;
  height: 17px;
  font-size: 0;
  text-indent: -9999px;
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -720px -689px;
  background-image: url("../images/sprite.png");
  width: 16px;
  min-width: 16px;
  height: 16px;
  margin-left: 5px;
  margin-top: 0px;
}

.popupzone-nav > div > ul li .slide-more-item:focus {
  background-color: gold;
  border-radius: 100%;
}

.popupzone-nav > div > ul li .slide-next-item {
  display: block;
  width: 40px;
  height: 40px;
  font-size: 0;
  text-indent: -9999px;
  position: relative;
  border-left: 1px solid #554b53;
  background-color: rgba(0, 0, 0, 0.75);
}

.popupzone-nav > div > ul li .slide-next-item:before {
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -830px -74px;
  background-image: url("../images/sprite.png");
  width: 15px;
  min-width: 15px;
  height: 24px;
}

.popupzone-nav > div > ul li .slide-next-item:focus {
  background-color: #000;
  border-radius: 3px;
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .popupzone-nav > div > ul li .slide-next-item {
    height: 30px;
    width: 30px;
    border-left: 0px;
  }
  .popupzone-nav > div > ul li .slide-next-item:before {
    content: '';
    vertical-align: middle;
    display: inline-block;
    background-repeat: no-repeat;
    background-position: -592.85714px -52.85714px;
    background-image: url("../images/sprite.png");
    background-size: 697.14286px auto;
    width: 10.71429px;
    min-width: 10.71429px;
    height: 17.14286px;
  }
}

.popupzone-nav > div > ul li .slide-prev-item {
  display: block;
  width: 40px;
  height: 40px;
  font-size: 0;
  text-indent: -9999px;
  position: relative;
  border-left: 1px solid #554b53;
  background-color: rgba(0, 0, 0, 0.75);
}

.popupzone-nav > div > ul li .slide-prev-item:before {
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -830px -148px;
  background-image: url("../images/sprite.png");
  width: 14px;
  min-width: 14px;
  height: 24px;
}

.popupzone-nav > div > ul li .slide-prev-item:focus {
  background-color: #000;
  border-radius: 3px;
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .popupzone-nav > div > ul li .slide-prev-item {
    height: 30px;
    width: 30px;
    border-left: 0px;
  }
  .popupzone-nav > div > ul li .slide-prev-item:before {
    content: '';
    vertical-align: middle;
    display: inline-block;
    background-repeat: no-repeat;
    background-position: -592.85714px -105.71429px;
    background-image: url("../images/sprite.png");
    background-size: 697.14286px auto;
    width: 10px;
    min-width: 10px;
    height: 17.14286px;
  }
}

.popupzone-nav .slick-dots-list {
  display: inline-block !important;
  vertical-align: top;
}

* + html .popupzone-nav .slick-dots-list {
  display: inline !important;
}

.popupzone-nav .slick-dots-list li {
  display: inline-block;
  margin-right: 3px;
}

.popupzone-nav .slick-dots-list li button {
  background-color: transparent;
  display: inline-block;
  color: #e19026;
  vertical-align: top;
  background-color: #cccccc;
  overflow: visible;
  border: 2px solid #cccccc;
  width: 15px;
  height: 15px;
  line-height: 15px;
  border-radius: 15px;
  font-size: 0;
  margin-top: 2px;
}

.ver-total .popupzone-nav .slick-dots-list li button {
  margin-top: 0;
}

.popupzone-nav .slick-dots-list li.slick-active button {
  background-color: #000;
  border: 2px solid #000;
}

.popupzone-nav .slick-dots-list li button:focus {
  background-color: #e19026;
}

.popupzone-nav * + html .slick-dots-list li {
  display: inline;
}

.popupzone-nav * + html .slick-dots-list li button {
  display: inline;
}

.popupzone-nav .popup-total {
  display: none;
}

.ver-total .popupzone-nav .slick-dots-list {
  font-size: 0;
  margin-top: 0px;
}

.lte-ie9 .ver-total .popupzone-nav .slick-dots-list {
  margin-top: -1px;
}

.ver-total .popupzone-nav .slick-dots-list li {
  margin-right: 0;
}

.ver-total .popupzone-nav .slick-dots-list button {
  font-size: 16px;
  text-indent: 0;
  height: auto;
  background-image: none;
  display: none;
}

.ver-total .popupzone-nav .slick-dots-list .slick-active button {
  background-image: none;
  display: block;
  width: auto;
  text-align: center;
  color: #000;
  background-color: transparent;
  font-weight: bold;
  border-color: transparent;
}

.ver-total .popupzone-nav .slick-dots-list .slick-active button:focus {
  border: 2px solid #000;
}

.ver-total .popupzone-nav .popup-total {
  color: #000;
  display: inline-block;
  vertical-align: top;
  font-size: 15px;
  margin-top: 0px;
  line-height: 1.2;
  margin-top: 13px;
  margin-right: 5px;
}

.ver-total .popupzone-nav * + html .popup-total {
  display: inline;
}

.ver-total .popupzone-nav .popup-total-num {
  padding-left: 4px;
}

.popupzone-nav .nav-arrow {
  display: none !important;
}

.ver-arrow .popupzone-nav .nav-arrow {
  display: block !important;
}

.ver-arrow .popupzone-nav .slide-play-item {
  display: none;
}

* + html .popupzone-nav.ver-total .popup-total {
  display: none !important;
}

* + html .popupzone-nav.ver-total .slick-dots-list {
  display: none !important;
}

@media screen and (min-width: 1px) and (max-width: 1050px) {
  .popupzone .popupzone-list__item img {
    margin: 0 auto;
  }
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .popupzone {
    max-width: 600px;
    margin: 0 auto;
  }
  .popupzone .popupzone-list__item img {
    height: auto;
  }
}

.lte-ie9 .popupzone .popupzone-list__item {
  height: 340px;
  overflow: hidden;
}

.lte-ie9 .popupzone .popupzone-list__item img {
  height: auto;
}

@media screen and (min-width: 1px) and (max-width: 1760px) {
  .lte-ie9 .popupzone .popupzone-list__item {
    height: 300px;
    overflow: hidden;
  }
  .lte-ie9 .popupzone .popupzone-list__item img {
    height: 300px;
    object-fit: cover;
  }
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .lte-ie9 .popupzone .popupzone-list__item {
    height: auto;
    overflow: hidden;
  }
  .lte-ie9 .popupzone .popupzone-list__item img {
    height: auto;
  }
}

/*팝업존2*/
.popupzone-nav2 {
  text-align: right;
  position: absolute;
  top: 0;
  right: 0px;
  box-sizing: border-box;
  z-index: 3;
  width: 100%;
  display: block;
}

.popupzone-nav2.off {
  display: none;
}

.popupzone-nav2 > div {
  display: inline-block;
  text-align: center;
  margin-top: 0;
  z-index: 999;
  padding: 0px;
  border-radius: 0px;
}

.popupzone-nav2 > div > div {
  display: inline-block;
  vertical-align: top;
  margin-top: 13px;
  margin-right: 3px;
}

* + html .popupzone-nav2 > div > div {
  display: inline;
}

.popupzone-nav2 > div > ul {
  display: inline-block;
  font-size: 0;
  vertical-align: top;
}

* + html .popupzone-nav2 > div > ul {
  display: inline;
}

.popupzone-nav2 > div > ul li {
  display: inline-block;
  vertical-align: top;
}

* + html .popupzone-nav2 > div > ul li {
  display: inline;
}

.popupzone-nav2 > div > ul li a {
  color: gold;
}

.popupzone-nav2 > div > ul li .slide-pause-item {
  display: block;
  width: 40px;
  height: 40px;
  font-size: 0;
  text-indent: -9999px;
  position: relative;
  border-left: 1px solid #554b53;
  background-color: rgba(0, 0, 0, 0.75);
}

.popupzone-nav2 > div > ul li .slide-pause-item:before {
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -901px 0px;
  background-image: url("../images/sprite.png");
  width: 12px;
  min-width: 12px;
  height: 22px;
}

.popupzone-nav2 > div > ul li .slide-pause-item:focus {
  background-color: #000;
  border-radius: 3px;
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .popupzone-nav2 > div > ul li .slide-pause-item {
    height: 30px;
    width: 30px;
    border-left: 0px;
  }
  .popupzone-nav2 > div > ul li .slide-pause-item:before {
    content: '';
    vertical-align: middle;
    display: inline-block;
    background-repeat: no-repeat;
    background-position: -643.57143px 0px;
    background-image: url("../images/sprite.png");
    background-size: 697.14286px auto;
    width: 8.57143px;
    min-width: 8.57143px;
    height: 15.71429px;
  }
}

.popupzone-nav2 > div > ul li .slide-play-item {
  display: block;
  width: 17px;
  height: 17px;
  font-size: 0;
  text-indent: -9999px;
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -586px -689px;
  background-image: url("../images/sprite.png");
  width: 17px;
  min-width: 17px;
  height: 17px;
  margin-left: 5px;
  margin-top: 0px;
}

.popupzone-nav2 > div > ul li .slide-play-item:focus {
  background-color: gold;
  border-radius: 100%;
}

.popupzone-nav2 > div > ul li .slide-more-item {
  display: block;
  width: 17px;
  height: 17px;
  font-size: 0;
  text-indent: -9999px;
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -720px -689px;
  background-image: url("../images/sprite.png");
  width: 16px;
  min-width: 16px;
  height: 16px;
  margin-left: 5px;
  margin-top: 0px;
}

.popupzone-nav2 > div > ul li .slide-more-item:focus {
  background-color: gold;
  border-radius: 100%;
}

.popupzone-nav2 > div > ul li .slide-next-item {
  display: block;
  width: 40px;
  height: 40px;
  font-size: 0;
  text-indent: -9999px;
  position: relative;
  border-left: 1px solid #554b53;
  background-color: rgba(0, 0, 0, 0.75);
}

.popupzone-nav2 > div > ul li .slide-next-item:before {
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -830px -74px;
  background-image: url("../images/sprite.png");
  width: 15px;
  min-width: 15px;
  height: 24px;
}

.popupzone-nav2 > div > ul li .slide-next-item:focus {
  background-color: #000;
  border-radius: 3px;
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .popupzone-nav2 > div > ul li .slide-next-item {
    height: 30px;
    width: 30px;
    border-left: 0px;
  }
  .popupzone-nav2 > div > ul li .slide-next-item:before {
    content: '';
    vertical-align: middle;
    display: inline-block;
    background-repeat: no-repeat;
    background-position: -592.85714px -52.85714px;
    background-image: url("../images/sprite.png");
    background-size: 697.14286px auto;
    width: 10.71429px;
    min-width: 10.71429px;
    height: 17.14286px;
  }
}

.popupzone-nav2 > div > ul li .slide-prev-item {
  display: block;
  width: 40px;
  height: 40px;
  font-size: 0;
  text-indent: -9999px;
  position: relative;
  border-left: 1px solid #554b53;
  background-color: rgba(0, 0, 0, 0.75);
}

.popupzone-nav2 > div > ul li .slide-prev-item:before {
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -830px -148px;
  background-image: url("../images/sprite.png");
  width: 14px;
  min-width: 14px;
  height: 24px;
}

.popupzone-nav2 > div > ul li .slide-prev-item:focus {
  background-color: #000;
  border-radius: 3px;
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .popupzone-nav2 > div > ul li .slide-prev-item {
    height: 30px;
    width: 30px;
    border-left: 0px;
  }
  .popupzone-nav2 > div > ul li .slide-prev-item:before {
    content: '';
    vertical-align: middle;
    display: inline-block;
    background-repeat: no-repeat;
    background-position: -592.85714px -105.71429px;
    background-image: url("../images/sprite.png");
    background-size: 697.14286px auto;
    width: 10px;
    min-width: 10px;
    height: 17.14286px;
  }
}

.popupzone-nav2 .slick-dots-list {
  display: inline-block !important;
  vertical-align: top;
}

* + html .popupzone-nav2 .slick-dots-list {
  display: inline !important;
}

.popupzone-nav2 .slick-dots-list li {
  display: inline-block;
  margin-right: 3px;
}

.popupzone-nav2 .slick-dots-list li button {
  background-color: transparent;
  display: inline-block;
  color: #e19026;
  vertical-align: top;
  background-color: #cccccc;
  overflow: visible;
  border: 2px solid #cccccc;
  width: 15px;
  height: 15px;
  line-height: 15px;
  border-radius: 15px;
  font-size: 0;
  margin-top: 2px;
}

.ver-total .popupzone-nav2 .slick-dots-list li button {
  margin-top: 0;
}

.popupzone-nav2 .slick-dots-list li.slick-active button {
  background-color: #000;
  border: 2px solid #000;
}

.popupzone-nav2 .slick-dots-list li button:focus {
  background-color: #e19026;
}

.popupzone-nav2 * + html .slick-dots-list li {
  display: inline;
}

.popupzone-nav2 * + html .slick-dots-list li button {
  display: inline;
}

.popupzone-nav2 .popup-total {
  display: none;
}

.ver-total .popupzone-nav2 .slick-dots-list {
  font-size: 0;
  margin-top: 0px;
}

.lte-ie9 .ver-total .popupzone-nav2 .slick-dots-list {
  margin-top: -1px;
}

.ver-total .popupzone-nav2 .slick-dots-list li {
  margin-right: 0;
}

.ver-total .popupzone-nav2 .slick-dots-list button {
  font-size: 16px;
  text-indent: 0;
  height: auto;
  background-image: none;
  display: none;
}

.ver-total .popupzone-nav2 .slick-dots-list .slick-active button {
  background-image: none;
  display: block;
  width: auto;
  text-align: center;
  color: #000;
  background-color: transparent;
  font-weight: bold;
  border-color: transparent;
}

.ver-total .popupzone-nav2 .slick-dots-list .slick-active button:focus {
  border: 2px solid #000;
}

.ver-total .popupzone-nav2 .popup-total {
  color: #000;
  display: inline-block;
  vertical-align: top;
  font-size: 15px;
  margin-top: 0px;
  line-height: 1.2;
  margin-top: 13px;
  margin-right: 5px;
}

.ver-total .popupzone-nav2 * + html .popup-total {
  display: inline;
}

.ver-total .popupzone-nav2 .popup-total-num {
  padding-left: 4px;
}

.popupzone-nav2 .nav-arrow {
  display: none !important;
}

.ver-arrow .popupzone-nav2 .nav-arrow {
  display: block !important;
}

.ver-arrow .popupzone-nav2 .slide-play-item {
  display: none;
}

* + html .popupzone-nav2.ver-total .popup-total {
  display: none !important;
}

* + html .popupzone-nav2.ver-total .slick-dots-list {
  display: none !important;
}

.popupzone-nav3 {
  text-align: right;
  position: absolute;
  top: 0;
  right: 0px;
  box-sizing: border-box;
  z-index: 3;
  width: 100%;
  display: block;
}

.popupzone-nav3.off {
  display: none;
}

.popupzone-nav3 > div {
  display: inline-block;
  text-align: center;
  margin-top: 0;
  z-index: 999;
  padding: 0px;
  border-radius: 0px;
}

.popupzone-nav3 > div > div {
  display: inline-block;
  vertical-align: top;
  margin-top: 13px;
  margin-right: 3px;
}

* + html .popupzone-nav3 > div > div {
  display: inline;
}

.popupzone-nav3 > div > ul {
  display: inline-block;
  font-size: 0;
  vertical-align: top;
}

* + html .popupzone-nav3 > div > ul {
  display: inline;
}

.popupzone-nav3 > div > ul li {
  display: inline-block;
  vertical-align: top;
}

* + html .popupzone-nav3 > div > ul li {
  display: inline;
}

.popupzone-nav3 > div > ul li a {
  color: gold;
}

.popupzone-nav3 > div > ul li .slide-pause-item {
  display: block;
  width: 40px;
  height: 40px;
  font-size: 0;
  text-indent: -9999px;
  position: relative;
  border-left: 1px solid #554b53;
  background-color: rgba(0, 0, 0, 0.75);
}

.popupzone-nav3 > div > ul li .slide-pause-item:before {
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -901px 0px;
  background-image: url("../images/sprite.png");
  width: 12px;
  min-width: 12px;
  height: 22px;
}

.popupzone-nav3 > div > ul li .slide-pause-item:focus {
  background-color: #000;
  border-radius: 3px;
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .popupzone-nav3 > div > ul li .slide-pause-item {
    height: 30px;
    width: 30px;
    border-left: 0px;
  }
  .popupzone-nav3 > div > ul li .slide-pause-item:before {
    content: '';
    vertical-align: middle;
    display: inline-block;
    background-repeat: no-repeat;
    background-position: -643.57143px 0px;
    background-image: url("../images/sprite.png");
    background-size: 697.14286px auto;
    width: 8.57143px;
    min-width: 8.57143px;
    height: 15.71429px;
  }
}

.popupzone-nav3 > div > ul li .slide-play-item {
  display: block;
  width: 17px;
  height: 17px;
  font-size: 0;
  text-indent: -9999px;
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -586px -689px;
  background-image: url("../images/sprite.png");
  width: 17px;
  min-width: 17px;
  height: 17px;
  margin-left: 5px;
  margin-top: 0px;
}

.popupzone-nav3 > div > ul li .slide-play-item:focus {
  background-color: gold;
  border-radius: 100%;
}

.popupzone-nav3 > div > ul li .slide-more-item {
  display: block;
  width: 17px;
  height: 17px;
  font-size: 0;
  text-indent: -9999px;
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -720px -689px;
  background-image: url("../images/sprite.png");
  width: 16px;
  min-width: 16px;
  height: 16px;
  margin-left: 5px;
  margin-top: 0px;
}

.popupzone-nav3 > div > ul li .slide-more-item:focus {
  background-color: gold;
  border-radius: 100%;
}

.popupzone-nav3 > div > ul li .slide-next-item {
  display: block;
  width: 40px;
  height: 40px;
  font-size: 0;
  text-indent: -9999px;
  position: relative;
  border-left: 1px solid #554b53;
  background-color: rgba(0, 0, 0, 0.75);
}

.popupzone-nav3 > div > ul li .slide-next-item:before {
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -830px -74px;
  background-image: url("../images/sprite.png");
  width: 15px;
  min-width: 15px;
  height: 24px;
}

.popupzone-nav3 > div > ul li .slide-next-item:focus {
  background-color: #000;
  border-radius: 3px;
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .popupzone-nav3 > div > ul li .slide-next-item {
    height: 30px;
    width: 30px;
    border-left: 0px;
  }
  .popupzone-nav3 > div > ul li .slide-next-item:before {
    content: '';
    vertical-align: middle;
    display: inline-block;
    background-repeat: no-repeat;
    background-position: -592.85714px -52.85714px;
    background-image: url("../images/sprite.png");
    background-size: 697.14286px auto;
    width: 10.71429px;
    min-width: 10.71429px;
    height: 17.14286px;
  }
}

.popupzone-nav3 > div > ul li .slide-prev-item {
  display: block;
  width: 40px;
  height: 40px;
  font-size: 0;
  text-indent: -9999px;
  position: relative;
  border-left: 1px solid #554b53;
  background-color: rgba(0, 0, 0, 0.75);
}

.popupzone-nav3 > div > ul li .slide-prev-item:before {
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -830px -148px;
  background-image: url("../images/sprite.png");
  width: 14px;
  min-width: 14px;
  height: 24px;
}

.popupzone-nav3 > div > ul li .slide-prev-item:focus {
  background-color: #000;
  border-radius: 3px;
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .popupzone-nav3 > div > ul li .slide-prev-item {
    height: 30px;
    width: 30px;
    border-left: 0px;
  }
  .popupzone-nav3 > div > ul li .slide-prev-item:before {
    content: '';
    vertical-align: middle;
    display: inline-block;
    background-repeat: no-repeat;
    background-position: -592.85714px -105.71429px;
    background-image: url("../images/sprite.png");
    background-size: 697.14286px auto;
    width: 10px;
    min-width: 10px;
    height: 17.14286px;
  }
}

.popupzone-nav3 .slick-dots-list {
  display: inline-block !important;
  vertical-align: top;
}

* + html .popupzone-nav3 .slick-dots-list {
  display: inline !important;
}

.popupzone-nav3 .slick-dots-list li {
  display: inline-block;
  margin-right: 3px;
}

.popupzone-nav3 .slick-dots-list li button {
  background-color: transparent;
  display: inline-block;
  color: #e19026;
  vertical-align: top;
  background-color: #cccccc;
  overflow: visible;
  border: 2px solid #cccccc;
  width: 15px;
  height: 15px;
  line-height: 15px;
  border-radius: 15px;
  font-size: 0;
  margin-top: 2px;
}

.ver-total .popupzone-nav3 .slick-dots-list li button {
  margin-top: 0;
}

.popupzone-nav3 .slick-dots-list li.slick-active button {
  background-color: #000;
  border: 2px solid #000;
}

.popupzone-nav3 .slick-dots-list li button:focus {
  background-color: #e19026;
}

.popupzone-nav3 * + html .slick-dots-list li {
  display: inline;
}

.popupzone-nav3 * + html .slick-dots-list li button {
  display: inline;
}

.popupzone-nav3 .popup-total {
  display: none;
}

.ver-total .popupzone-nav3 .slick-dots-list {
  font-size: 0;
  margin-top: 0px;
}

.lte-ie9 .ver-total .popupzone-nav3 .slick-dots-list {
  margin-top: -1px;
}

.ver-total .popupzone-nav3 .slick-dots-list li {
  margin-right: 0;
}

.ver-total .popupzone-nav3 .slick-dots-list button {
  font-size: 16px;
  text-indent: 0;
  height: auto;
  background-image: none;
  display: none;
}

.ver-total .popupzone-nav3 .slick-dots-list .slick-active button {
  background-image: none;
  display: block;
  width: auto;
  text-align: center;
  color: #000;
  background-color: transparent;
  font-weight: bold;
  border-color: transparent;
}

.ver-total .popupzone-nav3 .slick-dots-list .slick-active button:focus {
  border: 2px solid #000;
}

.ver-total .popupzone-nav3 .popup-total {
  color: #000;
  display: inline-block;
  vertical-align: top;
  font-size: 15px;
  margin-top: 0px;
  line-height: 1.2;
  margin-top: 13px;
  margin-right: 5px;
}

.ver-total .popupzone-nav3 * + html .popup-total {
  display: inline;
}

.ver-total .popupzone-nav3 .popup-total-num {
  padding-left: 4px;
}

.popupzone-nav3 .nav-arrow {
  display: none !important;
}

.ver-arrow .popupzone-nav3 .nav-arrow {
  display: block !important;
}

.ver-arrow .popupzone-nav3 .slide-play-item {
  display: none;
}

* + html .popupzone-nav3.ver-total .popup-total {
  display: none !important;
}

* + html .popupzone-nav3.ver-total .slick-dots-list {
  display: none !important;
}

/*메인슬라이드*/
/*이미지의 상하사이즈로 설정*/
.main-visual {
  position: relative;
  margin-top: 0px;
  width: 100%;
}

.main-visual .slick-arrow {
  border: 0px;
  top: 220px;
}

.main-visual .slick-prev {
  position: absolute;
  left: 10px;
  z-index: 1;
  display: none !important;
}

.main-visual .slick-next {
  position: absolute;
  right: 10px;
  z-index: 1;
  display: none !important;
}

.main-visual .slick-dots-wrap-outwrap {
  text-align: left;
  position: absolute;
  z-index: 3;
  bottom: 0px;
  left: 50%;
  margin-left: -880px;
}

@media screen and (min-width: 1px) and (max-width: 1760px) {
  .main-visual .slick-dots-wrap-outwrap {
    left: 0;
    margin-left: 0;
  }
}

.main-visual .slick-dots-wrap {
  display: inline-block;
  text-align: center;
  margin-top: 0;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.6);
  padding: 0;
  border-radius: 0px;
}

.main-visual .myslickcarousel {
  display: inline-block;
  padding: 0 0 0 10px;
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .main-visual .myslickcarousel {
    margin-top: 7px;
  }
}

.main-visual .slick-dots-on-off {
  display: inline-block;
  font-size: 0;
  vertical-align: middle;
}

.main-visual .slick-dots-on-off li {
  display: inline-block;
  vertical-align: middle;
}

.main-visual .slick-dots-on-off li a {
  color: gold;
}

.main-visual * + html .myslickcarousel {
  display: inline;
}

.main-visual * + html .slick-dots-on-off {
  display: inline;
}

.main-visual * + html .slick-dots-on-off li {
  display: inline;
}

.main-visual .slick-dots-list {
  display: inline-block !important;
  vertical-align: middle;
}

.main-visual .slick-dots-list li {
  display: inline-block;
  margin-right: 3px;
}

.main-visual .slick-dots-list li button {
  background-color: #444;
  display: inline-block;
  color: gold;
  vertical-align: middle;
  overflow: visible;
  border: 3px solid #999;
  width: 22px;
  height: 22px;
  line-height: 22px;
  border-radius: 22px;
  font-size: 0;
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .main-visual .slick-dots-list li button {
    height: auto;
    line-height: 1;
  }
}

.main-visual .slick-dots-list li.slick-active button {
  background-color: #fff;
  border: 3px solid #fff;
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .main-visual .slick-dots-list li.slick-active button {
    border: 0px;
    height: auto;
    line-height: 1;
  }
}

.main-visual .slick-dots-list li button:focus {
  background-color: #fff;
}

.main-visual .slick-dots-list li.slick-active button:focus {
  background-color: gold;
}

.main-visual * + html .slick-dots-list li {
  display: inline;
}

.main-visual * + html .slick-dots-list li button {
  display: inline;
}

.main-visual .slick-dots-on-off img {
  vertical-align: middle;
}

.main-visual .main-slide-prev {
  display: block;
  width: 60px;
  height: 60px;
  font-size: 0;
  text-indent: -9999px;
  position: relative;
  border-left: 1px solid #554b53;
}

.main-visual .main-slide-prev:before {
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -830px -148px;
  background-image: url("../images/sprite.png");
  width: 14px;
  min-width: 14px;
  height: 24px;
}

.main-visual .main-slide-prev:focus {
  background-color: #000;
  border-radius: 3px;
}

.main-visual .main-slide-next {
  display: block;
  width: 60px;
  height: 60px;
  font-size: 0;
  text-indent: -9999px;
  position: relative;
  border-left: 1px solid #554b53;
}

.main-visual .main-slide-next:before {
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -830px -74px;
  background-image: url("../images/sprite.png");
  width: 15px;
  min-width: 15px;
  height: 24px;
}

.main-visual .main-slide-next:focus {
  background-color: #000;
  border-radius: 3px;
}

.main-visual .main-slide-pause {
  display: block;
  width: 60px;
  height: 60px;
  font-size: 0;
  text-indent: -9999px;
  position: relative;
  border-left: 1px solid #554b53;
}

.main-visual .main-slide-pause:before {
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -901px 0px;
  background-image: url("../images/sprite.png");
  width: 12px;
  min-width: 12px;
  height: 22px;
}

.main-visual .main-slide-pause:focus {
  background-color: #000;
  border-radius: 3px;
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .main-visual .main-slide-pause {
    height: 30px;
    width: 30px;
    border-left: 0px;
  }
  .main-visual .main-slide-pause:before {
    content: '';
    vertical-align: middle;
    display: inline-block;
    background-repeat: no-repeat;
    background-position: -643.57143px 0px;
    background-image: url("../images/sprite.png");
    background-size: 697.14286px auto;
    width: 8.57143px;
    min-width: 8.57143px;
    height: 15.71429px;
  }
}

.main-visual .main-slide-play {
  display: block;
  width: 60px;
  height: 60px;
  font-size: 0;
  text-indent: -9999px;
  position: relative;
}

.main-visual .main-slide-play:before {
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -447px -689px;
  background-image: url("../images/sprite.png");
  width: 22px;
  min-width: 22px;
  height: 22px;
}

.main-visual .main-slide-play:focus {
  background-color: gold;
  border-radius: 3px;
}

.main-visual .slick-dots-wrap-outwrap {
  display: block;
}

.main-visual .slick-dots-wrap-outwrap.off {
  display: none;
}

.main-visual-prev {
  position: absolute;
  left: 20px;
  top: 45%;
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -492px -123px;
  background-image: url("../images/sprite.png");
  width: 37px;
  min-width: 37px;
  height: 73px;
  text-indent: -9999px;
}

.main-visual-prev:focus {
  border: 1px solid gold;
  border-radius: 5px;
}

@media screen and (min-width: 1px) and (max-width: 1350px) {
  .main-visual-prev {
    display: none !important;
  }
}

.main-visual-next {
  position: absolute;
  right: 20px;
  top: 45%;
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -492px 0px;
  background-image: url("../images/sprite.png");
  width: 37px;
  min-width: 37px;
  height: 73px;
  text-indent: -9999px;
}

.main-visual-next:focus {
  border: 1px solid gold;
  border-radius: 5px;
}

@media screen and (min-width: 1px) and (max-width: 1350px) {
  .main-visual-next {
    display: none !important;
  }
}

.main-visual .popup-total {
  display: none;
}

.main-visual.ver-total .slick-dots-list {
  font-size: 0;
  margin-top: 7px;
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .main-visual.ver-total .slick-dots-list {
    margin-top: 1px;
  }
}

.main-visual.ver-total .slick-dots-list li {
  margin-right: 0;
}

.main-visual.ver-total .slick-dots-list button {
  font-size: 17px;
  text-indent: 0;
  background-image: none;
  display: none;
}

.main-visual.ver-total .slick-dots-list .slick-active button {
  background-image: none;
  display: block;
  width: auto;
  height: 30px;
  text-align: center;
  color: #fff;
  font-size: 22px;
  background-color: transparent;
  font-weight: bold;
  border-color: transparent;
}

.main-visual.ver-total .slick-dots-list .slick-active button:focus {
  border: 2px solid #000;
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .main-visual.ver-total .slick-dots-list .slick-active button {
    height: auto;
    font-size: 17px;
  }
}

.main-visual.ver-total .popup-total {
  color: #fff;
  display: inline-block;
  vertical-align: middle;
  font-size: 15px;
  margin-top: 8px;
  margin-left: 4px;
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .main-visual.ver-total .popup-total {
    margin-top: 0;
  }
}

.main-visual.ver-total * + html .popup-total {
  display: inline;
}

.main-visual.ver-total .popup-total-num {
  padding-left: 4px;
  padding-right: 10px;
}

.nav-arrow {
  display: none !important;
}

.main-visual.ver-arrow .nav-arrow {
  display: block !important;
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .main-visual.ver-arrow .nav-arrow {
    display: none !important;
  }
}

.main-visual.ver-arrow .main-slide-play {
  display: none;
}

@media screen and (min-width: 1px) and (max-width: 1760px) {
  .main-visual {
    width: 100%;
    padding-top: 0;
    height: auto;
  }
  .main-visual {
    margin-top: 0;
  }
  .main-visual .slick-arrow {
    top: 150px;
  }
}

@media screen and (min-width: 901px) and (max-width: 1200px) {
  .main-slide__item img {
    width: 1250px;
  }
}

@media screen and (min-width: 769px) and (max-width: 900px) {
  .main-slide__item img {
    width: 1150px;
  }
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .main-slide__item img {
    width: 100%;
  }
  .main-visual .slick-arrow {
    top: 70px;
  }
}

/*video*/
.main-visual {
  overflow: hidden;
  background-color: #000;
}

.video-box {
  position: relative;
}

.video-box__over {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9999;
  height: 100%;
}

.video-box video {
  height: 620px;
}

.video-box__pause {
  position: absolute;
  bottom: 10px;
  right: 15px;
  z-index: 999999;
  background-color: #fff;
  font-weight: bold;
  display: block;
  padding: 3px;
  border-radius: 10px;
  border: 2px solid #000;
}

.video-box__pause:focus {
  background-color: gold;
}

@media screen and (min-width: 1px) and (max-width: 1900px) {
  .video-box video {
    height: 620px;
  }
}

@media screen and (min-width: 1px) and (max-width: 1350px) {
  .video-box video {
    width: 100% !important;
    height: auto !important;
  }
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .video-box video {
    width: 100%;
  }
  .video-box__over {
    display: none;
  }
  .video-box__pause {
    display: none;
  }
  .slick-dots-wrap-outwrap {
    bottom: 45px;
    left: 11px;
    width: auto;
  }
}

@media screen and (min-width: 1px) and (max-width: 380px) {
  .video-box video {
    max-height: 120px;
  }
}

/* 전체화면 버튼 */
.main-slide {
  /* 일시정지, 재생 버튼 */
  /* 재생 슬라이드..? */
  /* 현재 진행 시간 */
  /* 전체 시간 */
  /* 음소거 버튼 */
  /* 볼륨 조절 슬라이드 */
}

.main-slide video::-webkit-media-controls-fullscreen-button {
  display: none !important;
}

.main-slide video::-webkit-media-controls-play-button {
  display: none !important;
}

.main-slide video::-webkit-media-controls-timeline {
  display: none !important;
}

.main-slide video::-webkit-media-controls-current-time-display {
  display: none !important;
}

.main-slide video::-webkit-media-controls-time-remaining-display {
  display: none !important;
}

.main-slide video::-webkit-media-controls-mute-button {
  display: none !important;
}

.main-slide video::-webkit-media-controls-volume-slider {
  display: none !important;
}

/*배너존*/
.banner_zone_outwrap {
  font-family: 'Noto Sans KR';
  margin: 0 auto;
  padding-bottom: 10px;
  padding-top: 10px;
  border-top: 1px solid #e0e0e0;
}

.banner_zone_wrap {
  position: relative;
  overflow: hidden;
  width: 1760px;
  margin: 0 auto;
  height: 72px;
}

.banner_zone_wrap h3 {
  margin-left: 0px;
  margin-bottom: 0px;
  font-size: 18px;
  color: #333333;
  position: absolute;
  top: 22px;
  left: 2px;
}

.banner_zone {
  width: 1350px;
  overflow: hidden;
  z-index: 0;
  position: relative;
  text-align: center;
  margin-left: 210px;
}

.banner_zone .slick-arrow {
  display: none !important;
}

.banner_zone a {
  padding: 0 0;
}

.listwrap {
  position: relative;
  left: 0;
  overflow: hidden;
}

.listwrap li {
  float: left;
  width: 210px;
  height: 72px;
}

.listwrap li a {
  display: block;
}

.listwrap li a:focus img {
  border: 1px solid gold;
}

.listwrap li a img {
  width: 205px;
  height: 70px;
}

.foot_roll_nav2 {
  position: relative;
  z-index: 1;
}

.foot_roll_nav2 a:focus {
  border: 1px solid gold;
}

.foot_roll_nav2 .r_prev {
  left: 83px;
  top: 22px;
}

.foot_roll_nav2 .r_pause {
  left: 110px;
  top: 22px;
}

.foot_roll_nav2 .r_next {
  left: 137px;
  top: 22px;
}

.foot_roll_nav2 .r_plus {
  left: 164px;
  top: 22px;
}

.foot_roll_nav2 .r_start {
  left: 34px;
  top: 29px;
}

.foot_roll_nav2 span {
  float: left;
  position: absolute;
}

.r_plus a {
  width: 28px;
  height: 28px;
  font-size: 0;
  display: block;
  background-color: #fff;
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -752px -315px;
  background-image: url("../images/sprite.png");
  width: 28px;
  min-width: 28px;
  height: 28px;
}

.r_pause a {
  width: 28px;
  height: 28px;
  font-size: 0;
  display: block;
  background-color: #fff;
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -752px -237px;
  background-image: url("../images/sprite.png");
  width: 28px;
  min-width: 28px;
  height: 28px;
}

.r_start a {
  width: 28px;
  height: 28px;
  font-size: 0;
  display: block;
  background-color: #fff;
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -297px -689px;
  background-image: url("../images/sprite.png");
  width: 26px;
  min-width: 26px;
  height: 25px;
}

.r_prev a {
  width: 28px;
  height: 28px;
  font-size: 0;
  display: block;
  background-color: #fff;
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -752px -159px;
  background-image: url("../images/sprite.png");
  width: 28px;
  min-width: 28px;
  height: 28px;
}

.r_next a {
  width: 28px;
  height: 28px;
  font-size: 0;
  display: block;
  background-color: #fff;
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -752px -393px;
  background-image: url("../images/sprite.png");
  width: 28px;
  min-width: 28px;
  height: 28px;
  margin-right: -1px;
}

@media screen and (min-width: 1px) and (max-width: 1760px) {
  .banner_zone_outwrap {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    padding-top: 10px;
    padding-bottom: 20px;
  }
  .banner_zone_wrap {
    width: auto;
  }
  .banner_zone {
    width: 1250px;
  }
}

@media screen and (min-width: 851px) and (max-width: 1000px) {
  /*배너존*/
  .banner_zone {
    width: 595px;
  }
}

@media screen and (min-width: 769px) and (max-width: 850px) {
  /*배너존*/
  .banner_zone {
    width: 562px;
  }
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .banner_zone_wrap {
    height: auto;
    padding-left: 10px;
  }
  .banner_zone_wrap h3 {
    top: 0px;
    left: 10px;
  }
  .foot_roll_nav2 .r_prev {
    left: 83px;
    top: 0px;
  }
  .foot_roll_nav2 .r_pause {
    left: 110px;
    top: 0px;
  }
  .foot_roll_nav2 .r_next {
    left: 137px;
    top: 0px;
  }
  .foot_roll_nav2 .r_plus {
    left: 164px;
    top: 0px;
  }
  .foot_roll_nav2 .r_start {
    left: 34px;
    top: 0px;
  }
  .banner_zone {
    width: 392px;
    margin: 0 auto;
    padding-top: 40px;
  }
}

@media screen and (min-width: 1px) and (max-width: 470px) {
  .banner_zone {
    width: 200px;
  }
}

.sub-visual {
  height: 210px;
  transition: all 0.3s ease 0s;
  background-image: url("../../resources/images/basic/visual.jpg");
  background-repeat: repeat;
  background-position: center top;
  background-size: auto 100%;
  font-family: "scdream";
}

.sub-visual__tit {
  color: #fff;
  font-size: 35px;
  font-weight: bold;
  padding-top: 100px;
  text-align: center;
}

.sub-visual__desc {
  color: #fff;
  font-size: 24px;
  padding-top: 10px;
  text-align: center;
}

@media screen and (min-width: 769px) and (max-width: 1200px) {
  .sub-visual__tit {
    font-size: 40px;
  }
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .sub-visual {
    display: none;
  }
}

/*sub-page-container*/
.sub-page-container {
  font-family: "scdream";
}

.sub-page-container__in {
  margin: 0 auto;
  position: relative;
  background-color: #fff;
}

.sub-page-container__in:after {
  content: ' ';
  clear: both;
  display: block;
  visibility: hidden;
}

* + html .sub-page-container__in {
  display: inline-block;
}

.sub-contents {
  float: right;
  width: 900px;
}

.cont-in {
  padding-bottom: 40px;
  min-height: 500px;
  font-size: 17px;
  line-height: 1.6;
  color: #333;
  font-family: "scdream";
}

.cont-in img {
  max-width: 100%;
}

@media screen and (min-width: 1px) and (max-width: 1760px) {
  .sub-page-container__in {
    width: auto;
  }
  .sub-contents {
    width: auto;
    float: none;
    padding: 0 10px;
  }
  .cont-in img {
    height: auto !important;
  }
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .sub-page-container {
    padding-top: 51px;
  }
}

.sub-contents__top {
  position: relative;
  border-bottom: 1px solid #e0e0e0;
  font-family: 'Noto Sans KR';
  /*background-image:url("../../commons/images/global/sub-visual-bg1.jpg");*/
  background-position: right top;
  background-repeat: no-repeat;
  margin-bottom: 40px;
}

.sub-contents__top h2.sub-tit {
  font-size: 40px;
  color: #222;
  padding-top: 30px;
  padding-bottom: 20px;
  border-bottom: 2px solid #db4651;
  margin-bottom: 90px;
  height: 70px;
  font-weight: 500;
  text-align: center;
  font-weight: 600;
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .sub-contents__top h2.sub-tit {
    border-bottom: 0;
    padding-bottom: 0;
    text-align: left;
  }
}

/* location */
.location-box {
  position: relative;
}

.location-box__cont {
  position: absolute;
  right: 0;
  text-align: center;
  top: 90px;
}

.location-box__cont span {
  display: inline-block;
  font-size: 14px;
  font-size: 1.4rem;
  height: 28px;
  line-height: 28px;
  color: #666;
}

.location-box__cont span:before {
  content: '';
  display: inline-block;
  font-size: 0;
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -901px -444px;
  background-image: url("../images/sprite.png");
  width: 4px;
  min-width: 4px;
  height: 7px;
  margin: 0 17px;
}

@media screen and (min-width: 1px) and (max-width: 480px) {
  .location-box__cont span:before {
    margin: 0 10px;
  }
}

.location-box__cont span:first-child::before {
  display: none;
}

.location-box__cont .loc-home {
  display: block;
  padding-left: 30px;
  font-size: 14px;
  font-size: 1.4rem;
  height: 28px;
  line-height: 27px;
  background-image: url("../../resources/images/icons/home.png");
  background-position: 0 center;
  background-repeat: no-repeat;
  color: #666;
}

.location-box__cont .loc-home:hover, .location-box__cont .loc-home:focus {
  text-decoration: underline;
}

/* 프린트, url 버튼 등*/
.special-box {
  position: absolute;
  top: -46px;
  right: 0;
  z-index: 9;
}

.special-box a {
  border: 1px solid #fff;
  margin-left: 10px;
}

.special-box a:focus {
  border: 2px solid gold;
}

.special-box__print {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -373px -689px;
  background-image: url("../images/sprite.png");
  width: 24px;
  min-width: 24px;
  height: 22px;
  margin-right: 4px;
  text-indent: -9999px;
}

.special-box__url {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -830px 0px;
  background-image: url("../images/sprite.png");
  width: 21px;
  min-width: 21px;
  height: 24px;
  text-indent: -9999px;
}

.special-box__sns {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -830px 0px;
  background-image: url("../images/sprite.png");
  width: 21px;
  min-width: 21px;
  height: 24px;
  text-indent: -9999px;
}

.special-box .special-openbox__cont {
  display: none;
  border: 1px solid #ddd;
  white-space: nowrap;
  padding: 10px;
  background-color: #fff;
}

.special-box .special-openbox__cont a {
  vertical-align: middle;
  display: inline-block;
}

.special-box .special-openbox__cont a span {
  text-indent: -9999px;
  position: absolute;
  top: 0;
  left: -9999px;
  height: 0;
  overflow: hidden;
}

.special-box .special-openbox__cont a.type1 {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -579px -432px;
  background-image: url("../images/sprite.png");
  width: 36px;
  min-width: 36px;
  height: 36px;
}

.special-box .special-openbox__cont a.type2 {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -258px -521px;
  background-image: url("../images/sprite.png");
  width: 36px;
  min-width: 36px;
  height: 36px;
}

.special-box .special-openbox__cont a.type3 {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -492px -332px;
  background-image: url("../images/sprite.png");
  width: 36px;
  min-width: 36px;
  height: 36px;
}

.special-box .special-openbox__cont a.type4 {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -86px -521px;
  background-image: url("../images/sprite.png");
  width: 36px;
  min-width: 36px;
  height: 36px;
}

.special-box .special-openbox__cont a.type5 {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -579px -260px;
  background-image: url("../images/sprite.png");
  width: 36px;
  min-width: 36px;
  height: 36px;
}

.special-box .special-openbox__cont a:hover.type1, .special-box .special-openbox__cont a:focus.type1 {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -579px -346px;
  background-image: url("../images/sprite.png");
  width: 36px;
  min-width: 36px;
  height: 36px;
}

.special-box .special-openbox__cont a:hover.type2, .special-box .special-openbox__cont a:focus.type2 {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -172px -521px;
  background-image: url("../images/sprite.png");
  width: 36px;
  min-width: 36px;
  height: 36px;
}

.special-box .special-openbox__cont a:hover.type3, .special-box .special-openbox__cont a:focus.type3 {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -492px -246px;
  background-image: url("../images/sprite.png");
  width: 36px;
  min-width: 36px;
  height: 36px;
}

.special-box .special-openbox__cont a:hover.type4, .special-box .special-openbox__cont a:focus.type4 {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: 0px -521px;
  background-image: url("../images/sprite.png");
  width: 36px;
  min-width: 36px;
  height: 36px;
}

.special-box .special-openbox__cont a:hover.type5, .special-box .special-openbox__cont a:focus.type5 {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -579px -174px;
  background-image: url("../images/sprite.png");
  width: 36px;
  min-width: 36px;
  height: 36px;
}

.special-box .special-openbox.on .special-openbox__btn {
  border: 1px solid #000;
}

.special-box .special-openbox.on .special-openbox__btn:before {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -77px -607px;
  background-image: url("../images/sprite.png");
  width: 27px;
  min-width: 27px;
  height: 32px;
}

.special-box .special-openbox.on .special-openbox__cont {
  display: block;
  position: absolute;
  top: 65px;
  right: 0;
}

@media screen and (min-width: 769px) and (max-width: 1400px) {
  .location-box__cont {
    position: relative;
    top: 3px;
    width: auto;
    text-align: right;
  }
  .special-box {
    top: 10px;
  }
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .sub-contents__top {
    background-image: none !important;
    margin-bottom: 10px;
    display: block;
  }
  .sub-contents__top h2.sub-tit {
    font-size: 21px;
    margin-bottom: 8px;
    padding-top: 10px;
    height: auto;
  }
  .location-box {
    position: relative;
    top: 0;
    right: 0;
    padding-bottom: 0px;
  }
  .location-box__cont {
    position: relative;
    white-space: normal;
    width: auto;
    text-align: left;
    overflow: visible;
    top: 0;
    left: 0;
    padding-top: 0px;
  }
  .special-box {
    display: none;
  }
}

/* checkbox */
input[class="check-type"] + label {
  display: inline-block;
  height: 25px;
  line-height: 25px;
  cursor: pointer;
  position: relative;
  padding-left: 35px;
}

input[class="check-type"] + label:after {
  display: inline-block;
  content: ' ';
  width: 25px;
  height: 25px;
  box-sizing: border-box;
  position: absolute;
  top: 0px;
  left: 0px;
  border: 2px solid #acacac;
  background-color: #acacac;
  border-radius: 25px;
}

input[class="check-type"] + label:before {
  content: ' ';
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -963px -127px;
  background-image: url("../images/sprite.png");
  width: 12px;
  min-width: 12px;
  height: 9px;
  position: absolute;
  top: 8px;
  left: 7px;
  z-index: 9;
}

input[class="check-type"]:checked + label:after {
  background-color: #fc4451;
}

input[class="check-type"]:focus + label:after {
  border: 2px solid gold;
}

input[class="check-type"] {
  position: absolute;
  left: -9999px;
}

/*

input[class="check-type"] + label {
	display: inline-block;
	width: 30px;
	height: 30px;
	border: 2px solid #cccccc;
	background-color:#dddddd;
	cursor: pointer;
	border-radius: 30px;
	position: relative;
	font-size: 0;
	text-indent: -9999px;
	&:before {
		content:' ';
		@include sprite($check-type);
		position: absolute;
		top:10px;left:10px;
	}
}

input[class="check-type"]:checked + label {
	background-color: #666666;
}

input[class="check-type"]:focus + label {
	border: 2px solid gold;
}
input[class="check-type"] {
	position: absolute;
	left: -9999px;
}
*/
/* 2 */
input[class="check-type2"] + label {
  display: inline-block;
  height: 18px;
  line-height: 18px;
  position: relative;
  padding-left: 27px;
}

input[class="check-type2"] + label:after {
  display: inline-block;
  content: ' ';
  width: 18px;
  height: 18px;
  cursor: pointer;
  border: 1px solid #ddd;
  position: absolute;
  top: 0px;
  left: 0px;
}

input[class="check-type2"]:checked + label:before {
  content: ' ';
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -901px -138px;
  background-image: url("../images/sprite.png");
  width: 12px;
  min-width: 12px;
  height: 12px;
  position: absolute;
  top: 4px;
  left: 4px;
}

input[class="check-type2"]:focus + label:after {
  border: 1px solid #bbbbbb;
}

input[class="check-type2"] {
  position: absolute;
  left: -9999px;
}

/* 3 */
input[class="check-type3"] + label {
  display: inline-block;
  height: 18px;
  line-height: 18px;
  cursor: pointer;
  position: relative;
  padding-left: 27px;
}

input[class="check-type3"] + label:after {
  display: inline-block;
  content: ' ';
  width: 18px;
  height: 18px;
  cursor: pointer;
  border: 1px solid #ddd;
  position: absolute;
  top: 0px;
  left: 0px;
}

input[class="check-type3"]:checked + label:before {
  content: ' ';
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -963px 0px;
  background-image: url("../images/sprite.png");
  width: 13px;
  min-width: 13px;
  height: 11px;
  position: absolute;
  top: 4px;
  left: 4px;
}

input[class="check-type3"]:focus + label:after {
  border: 1px solid #ec6159;
}

input[class="check-type3"] {
  position: absolute;
  left: -9999px;
}

/*라디오버튼*/
input[class="radio-type"] + label {
  display: inline-block;
  height: 17px;
  line-height: 17px;
  border: 0;
  cursor: pointer;
  position: relative;
  padding-left: 27px;
}

input[class="radio-type"] + label:before {
  content: ' ';
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -63px -840px;
  background-image: url("../images/sprite.png");
  width: 13px;
  min-width: 13px;
  height: 13px;
  position: absolute;
  top: 6px;
  left: 5px;
}

input[class="radio-type"]:checked + label:before {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -126px -840px;
  background-image: url("../images/sprite.png");
  width: 13px;
  min-width: 13px;
  height: 13px;
}

input[class="radio-type"] {
  position: absolute;
  left: -9999px;
}

.choose-label-text {
  margin-left: 0;
  color: #7d7d7d;
  font-weight: bold;
  font-size: 16px;
  padding: 5px;
}

.choose-textarea {
  display: block;
  box-sizing: border-box;
  border: 1px solid #d5d9dd;
  background-color: #e9ecf0;
  padding: 20px;
  height: 100px;
  font-size: 16px;
  margin-top: 10px;
  width: 100%;
  color: #768393;
}

.choose-textarea.long {
  height: 150px;
}

.editor_view.on .editor_view__cont {
  position: relative;
  width: 100%;
  height: 0;
  overflow: hidden;
  padding-bottom: 56.25%;
  /*16:9*/
  background-color: #00235d;
  color: #fff;
}

.editor_view.on .editor_view__cont > p {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.editor_view.on .editor_view__cont > p iframe {
  width: 100%;
  height: 100%;
}

.sub-slide {
  position: relative;
  margin-bottom: 10px;
}

.sub-slide__box {
  max-width: 100%;
  margin: 0 auto;
}

.sub-slide__box img {
  width: 100%;
}

.sub-slide__nav {
  position: absolute;
  width: 50px;
  display: block;
  top: 50%;
  margin-top: -35px;
  height: 70px;
  background-color: #000;
  opacity: 0.8;
  text-indent: -9999px;
}

.sub-slide__nav:after {
  width: 20px;
  height: 34px;
  display: block;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 17px;
  color: #fff;
  text-indent: 0;
  font-size: 22px;
  font-family: serif;
}

.sub-slide__nav:hover:after, .sub-slide__nav:focus:after {
  color: gold;
}

.sub-slide__prev {
  left: 0;
}

.sub-slide__prev:after {
  content: '<';
}

.sub-slide__next {
  right: 0;
}

.sub-slide__next:after {
  content: '>';
}

.sub-slide-cro {
  margin-bottom: 40px;
  margin-left: -10px;
  overflow: hidden;
}

.sub-slide-cro img {
  height: 100px;
  width: 100%;
  padding-left: 10px;
  box-sizing: border-box;
  opacity: 0.4;
  transition: all 0.3s ease 0s;
}

@media screen and (min-width: 1px) and (max-width: 1760px) {
  .sub-slide-cro img {
    height: 100px !important;
  }
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .sub-slide-cro img {
    height: 55px !important;
  }
}

.sub-slide-cro .slick-current img {
  opacity: 1;
}

.sub-slide-cro a:focus img {
  opacity: 1;
}

.layer-box {
  height: 0;
  overflow: hidden;
  visibility: hidden;
  opacity: 0;
}

.layer-box.on {
  height: 100%;
  visibility: visible;
  opacity: 1;
}

.layer-box {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 9999;
  height: 100%;
  background-image: url("../../resources/images/basic/opa2.png");
  background-repeat: repeat;
  color: #fff;
}

.photo-slide {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  width: 100%;
}

.photo-slide .slick-prev {
  color: #fff;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 10%;
  z-index: 999;
  font-size: 0;
}

.photo-slide .slick-prev.slick-disabled {
  display: none !important;
}

.photo-slide .slick-prev:before {
  font-size: 40px;
  content: '<';
  display: block;
  padding: 20px;
}

.photo-slide .slick-prev:hover:before, .photo-slide .slick-prev:focus:before {
  color: gold;
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .photo-slide .slick-prev {
    left: 2%;
  }
}

.photo-slide .slick-next {
  color: #fff;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 10%;
  z-index: 999;
  font-size: 0;
}

.photo-slide .slick-next.slick-disabled {
  display: none !important;
}

.photo-slide .slick-next:before {
  font-size: 40px;
  content: '>';
  display: block;
  padding: 20px;
}

.photo-slide .slick-next:hover:before, .photo-slide .slick-next:focus:before {
  color: gold;
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .photo-slide .slick-next {
    right: 2%;
  }
}

.photo-slide__item {
  width: 100%;
  text-align: center;
}

.photo-slide__item img {
  margin: 0 auto;
}

.layer-box__close {
  font-size: 30px;
  font-weight: bold;
  display: block;
  width: 40px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  background-color: #ddd;
  border-radius: 40px;
  color: #000;
  position: absolute;
  top: 30px;
  right: 30px;
}

/* 다음맵 접근성 추가 .warp_map으로 감쌈 */
.wrap_map a:focus,
.wrap_map button:focus,
.roughmap_maker_label a:focus .roughmap_lebel_text,
.root_daum_roughmap .wrap_btn_zoom button:focus {
  border: 2px solid gold;
}

.wrap_controllers.hide {
  display: none;
}

.slider-for {
  width: 1200px !important;
  margin: 0 auto;
}

.slider-for a {
  display: inline-block;
}

.slider-for a:focus {
  border: 1px solid black;
}

.slider-for__item {
  background-color: cadetblue;
  min-height: 200px;
}

.slider-nav-outwrap {
  width: 1278px;
  overflow: hidden;
  margin: 0 auto;
}

.slider-nav {
  width: 1200px !important;
  margin: 0 auto;
}

.slider-nav a {
  display: inline-block;
  text-align: center;
}

.slider-nav a:focus {
  background-color: #444;
}

.slider-nav .slick-current {
  background-color: black;
  color: #fff;
}

.sub-page-container__in {
  width: auto;
}

.sub-contents {
  width: 1400px;
  max-width: 1400px;
  margin: 0 auto;
  float: none;
}

/* 로케이션 형태 사이드메뉴 */
.new-lnb {
  width: 100%;
  background-color: #f8f8f8;
  border-bottom: 1px solid #eeeeee;
  position: relative;
  z-index: 1;
}

.new-lnb__in {
  width: 1400px;
  max-width: 1400px;
  margin: 0 auto;
}

.new-lnb__box {
  float: left;
  position: relative;
  border-right: 1px solid #eeeeee;
}

.new-lnb__box:first-child {
  padding-left: 0;
  width: 60px;
  height: 100%;
}

.loc-dep-home {
  text-indent: -9999px;
  width: 60px;
  height: 60px;
  position: relative;
  display: block;
}

.loc-dep-home:after {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 19px;
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -830px -654px;
  background-image: url("../images/sprite.png");
  width: 17px;
  min-width: 17px;
  height: 19px;
}

.loc-dep-home:focus {
  background-color: #f0f0f0;
}

/*1*/
.loc-dep-1 {
  display: block;
  height: 60px;
  line-height: 60px;
  padding-left: 20px;
  min-width: 100px;
  color: #000;
  font-size: 18px;
  padding-right: 90px;
}

.loc-dep-1:after {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 12px;
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -319px -840px;
  background-image: url("../images/sprite.png");
  width: 16px;
  min-width: 16px;
  height: 10px;
}

.loc-dep-1:focus {
  background-color: #f0f0f0;
}

.loc-dep-1-list {
  position: absolute;
  top: 60px;
  left: 0;
  min-width: 177px;
  width: 100%;
}

.loc-dep-1-list a {
  display: block;
  padding: 10px 29px 10px 20px;
  background-color: #fff;
  font-size: 15px;
  color: #333;
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .loc-dep-1-list a {
    background-color: #445eaf;
  }
}

.loc-dep-1-list a:hover,
.loc-dep-1-list a:focus {
  background-color: #d2127e;
  color: #fff;
  position: relative;
}

.loc-dep-1-list a:hover:after,
.loc-dep-1-list a:focus:after {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -901px -386px;
  background-image: url("../images/sprite.png");
  width: 4px;
  min-width: 4px;
  height: 8px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 10px;
}

/* location */
/*2*/
.sidebar__h2 {
  display: none;
}

.loc-dep-2 {
  height: 60px;
  line-height: 60px;
  padding-left: 20px;
  padding-right: 39px;
  display: block;
  min-width: 150px;
  color: #000;
  font-size: 18px;
}

.loc-dep-2:after {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 12px;
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -319px -840px;
  background-image: url("../images/sprite.png");
  width: 16px;
  min-width: 16px;
  height: 10px;
}

.loc-dep-2:focus {
  background-color: #f0f0f0;
}

.loc-dep-2-list {
  position: absolute;
  top: 60px;
  left: 0;
  min-width: 187px;
}

.side-list__li > span > a {
  display: block;
  padding: 10px 29px 10px 20px;
  background-color: #fff;
  font-size: 15px;
  color: #333;
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .side-list__li > span > a {
    background-color: #445eaf;
  }
}

.side-list__li > span > a:hover,
.side-list__li > span > a:focus {
  background-color: #d2127e;
  color: #fff;
  position: relative;
}

.side-list__li > span > a:hover:after,
.side-list__li > span > a:focus:after {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -901px -386px;
  background-image: url("../images/sprite.png");
  width: 4px;
  min-width: 4px;
  height: 8px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 10px;
}

.loc-dep-1-list {
  display: none;
}

.loc-dep-2-list {
  width: 100%;
}

.loc-dep-2-list .side-list__li {
  display: none;
}

.loc-dep-2-list .side-list__li.on {
  display: block;
}

.loc-dep-2-list .side-list__li.on span {
  display: none;
}

.loc-dep-2-list.on .side-list__li {
  display: block;
}

.loc-dep-2-list.on .side-list__li.on span {
  display: block;
}

.loc-dep-1-list.on {
  display: block;
  box-sizing: border-box;
  border: 1px solid #eeeeee;
  border-top: 0;
}

.loc-dep-2-list.on {
  display: block;
  box-sizing: border-box;
  border: 1px solid #eeeeee;
  border-top: 0;
}

.sub_left_menu2 {
  display: none;
  min-width: 209px;
  background-color: #fff;
}

.sub_left_menu2.on {
  display: block;
  box-sizing: border-box;
  border: 1px solid #eeeeee;
  border-top: 0;
}

@media screen and (min-width: 769px) and (max-width: 1760px) {
  .new-lnb {
    width: auto;
  }
  .new-lnb__in {
    width: auto;
  }
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .new-lnb {
    display: none;
  }
  .new-lnb__in {
    width: auto;
  }
  .new-lnb__box--0 {
    display: none;
  }
  .new-lnb__box--1 {
    display: none;
  }
  .new-lnb__box {
    background-image: none;
    padding-left: 0;
    float: none;
  }
  .loc-dep-2 {
    display: none !important;
  }
  .loc-dep-2-list {
    left: 0;
    position: relative;
    top: 0;
  }
}

/*사이드 메뉴 수정 추가 반드시*/
.mobile-submenu-btn {
  display: none;
  background-color: #203c92;
  height: 25px;
  line-height: 25px;
  text-align: center;
  color: #fff;
  padding-left: 5px;
  padding-right: 5px;
  position: absolute;
  top: 5px;
  right: 7px;
  z-index: 99;
  display: none;
}

.mobile-submenu-btn .type1 {
  display: block;
}

.mobile-submenu-btn .type2 {
  display: none;
}

.selected.mobile-submenu-btn .type1 {
  display: none;
}

.selected.mobile-submenu-btn .type2 {
  display: block;
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .sidebar {
    display: block;
    position: relative;
    z-index: 1;
    margin-top: 0px;
    float: none;
    width: 100%;
  }
  .side_in_wrap {
    display: none;
    border-bottom: 2px solid #4d5256;
    opacity: 0;
  }
  .sidebar h2 {
    display: block;
    text-align: left;
    background-image: none;
    background-color: #203c92;
    padding-left: 10px;
    font-size: 1.4rem;
    height: 32px;
    font-weight: bold;
    padding-top: 5px;
    line-height: 1.6;
    color: #fff;
    min-height: 10px;
    height: 30px;
    border-radius: 0px;
    text-align: left;
    padding-left: 20px;
  }
  .sidebar h2 a {
    display: none;
  }
  .sidebar__h2 {
    display: block;
    padding-bottom: 0px;
  }
  .side-list__li > span > a {
    height: 30px;
    line-height: 30px;
    padding-top: 3px;
    padding-bottom: 3px;
  }
  .side-list__li.open-type > span > a {
    background-position: 96% 12px;
  }
  .side-list__li.open-type.on > span > a {
    background-position: 96% 15px;
  }
  .sp-open-type {
    background-position: 97% 12px;
  }
  .sp-open-type.on {
    background-position: 97% 15px;
  }
  /**/
  .sidebar__wrap {
    opacity: 1.0 !important;
    margin-left: 0px !important;
  }
  .side_in_wrap li.selected span a {
    background-color: #fff !important;
    color: #f6804e !important;
  }
  .side_in_wrap {
    display: block;
    border-bottom: 2px solid #000;
    opacity: 1;
  }
  .mobile-submenu-btn {
    display: block;
  }
  .on > .side-list__li__inbox {
    padding: 0 10px;
  }
  .sidebar .side-list > li {
    display: none;
  }
  /*.sidebar .side-list > li.on {display:block;}*/
  .sidebar.selected .depth01 > li {
    display: block;
  }
  .sidebar .side-list > li > a {
    padding: 10px 0 10px 20px;
  }
  .side-list__li > span > a {
    border-left: 0;
    border-right: 0;
  }
  .sidebar .side-list > li.on {
    display: block;
  }
  .sidebar.selected .side-list > li {
    display: block;
  }
  .sidebar.selected .side-list > li.open-type {
    display: block;
  }
  .sidebar.selected .side-list > li.on > span > a {
    text-decoration: underline;
  }
}

.sub-contents__top {
  border-bottom: 0px;
  margin-bottom: 0;
}

.location-box {
  display: none;
}

.location-box__cont {
  top: -170px;
  width: 100%;
  text-align: center;
}

.location-box__cont span {
  color: #fff;
}

.location-box__cont .loc-home {
  color: #fff;
}

.location-box__cont span:before {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -963px -186px;
  background-image: url("../images/sprite.png");
  width: 6px;
  min-width: 6px;
  height: 11px;
}

@media screen and (min-width: 1px) and (max-width: 1760px) {
  .sub-contents {
    width: auto;
  }
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .location-box__cont {
    top: 0;
    text-align: left;
  }
  .location-box__cont span {
    color: #000;
  }
  .location-box__cont .loc-home {
    color: #000;
  }
  .sub-contents__top h2.sub-tit {
    display: block;
  }
  .cont-in {
    padding-top: 0;
  }
  .location-box {
    display: block;
  }
}

.side-list__li.on .side-list__li__inbox {
  position: absolute;
  top: 0;
  right: 0;
  top: -60px;
  left: 100%;
}

.spp__in a {
  display: block;
  padding: 10px 29px 10px 20px;
  background-color: transparent;
  font-size: 15px;
  color: #333;
  min-width: 160px;
}

.spp__in a:hover, .spp__in a:focus {
  background-color: #d2127e;
  color: #fff;
  position: relative;
}

.spp__in a:hover:after, .spp__in a:focus:after {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -901px -386px;
  background-image: url("../images/sprite.png");
  width: 4px;
  min-width: 4px;
  height: 8px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 10px;
}

.loc-dep-3 {
  height: 60px;
  line-height: 60px;
  padding-left: 20px;
  padding-right: 39px;
  min-width: 150px;
  display: inline-block;
  color: #000;
  font-size: 18px;
  position: relative;
  white-space: nowrap;
}

.loc-dep-3:after {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 12px;
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -319px -840px;
  background-image: url("../images/sprite.png");
  width: 16px;
  min-width: 16px;
  height: 10px;
}

.loc-dep-3:focus {
  background-color: #f0f0f0;
}

/*탭*/
.js-box {
  display: none;
  position: relative;
  min-height: 20px;
}

.js-box.on {
  display: block;
}

.js-box2 {
  display: none;
  position: relative;
  min-height: 100px;
}

.js-box2.on {
  display: block;
}

.sub-tab .js-box {
  margin-left: 1px;
}

.sub-tab .js-box2 {
  margin-left: 1px;
}

/*메인탭*/
.main-tab {
  position: relative;
}

.main-tab__ul {
  margin-bottom: 20px;
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .main-tab__ul {
    margin-bottom: 10px;
  }
}

.main-tab__ul__li {
  float: left;
}

.main-tab__ul__li a {
  height: 40px;
  line-height: 40px;
  display: block;
  font-size: 20px;
  text-align: center;
  padding-left: 20px;
  padding-right: 20px;
  color: #333;
  opacity: 0.7;
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .main-tab__ul__li a {
    font-size: 16px;
    padding: 0 7px;
    height: 25px;
    line-height: 25px;
  }
}

.main-tab__ul__li.on a {
  color: #000;
  font-weight: 500;
  opacity: 1;
  border-bottom: 2px solid #db4651;
}

.main-tab__ul__li a:hover,
.main-tab__ul__li a:focus {
  border-bottom: 2px solid #e86770;
}

.main-tab__cont {
  position: relative;
}

.main-tab__cont__ul {
  display: flex;
  margin-left: -40px;
}

@media screen and (min-width: 1px) and (max-width: 1050px) {
  .main-tab__cont__ul {
    margin-left: -15px;
  }
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .main-tab__cont__ul {
    margin-left: -5px;
  }
}

.main-tab__cont__ul__li {
  width: 33.33%;
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .main-tab__cont__ul__li {
    width: 50%;
    display: none;
  }
  .main-tab__cont__ul__li:nth-child(1), .main-tab__cont__ul__li:nth-child(2) {
    display: block;
  }
}

.main-tab__cont__ul__li a {
  margin-left: 40px;
  display: block;
  position: relative;
  padding: 25px 30px;
  border: 1px solid #ddd;
  background-color: #fff;
  box-sizing: border-box;
}

@media screen and (min-width: 1px) and (max-width: 1050px) {
  .main-tab__cont__ul__li a {
    margin-left: 15px;
    padding: 15px;
  }
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .main-tab__cont__ul__li a {
    margin-left: 5px;
  }
}

@media screen and (min-width: 1px) and (max-width: 480px) {
  .main-tab__cont__ul__li a {
    padding: 10px;
  }
}

.main-tab__cont__ul__li a:hover,
.main-tab__cont__ul__li a:focus {
  border: 1px solid #000;
}

.main-tab__cont__more {
  font-size: 16px;
  display: block;
  padding: 5px;
  padding-right: 30px;
  position: absolute;
  top: -50px;
  right: 0;
}

.main-tab__cont__more:focus {
  border: 1px solid #000;
}

.main-tab__cont__more:after {
  position: absolute;
  top: 7px;
  display: block;
  content: '+';
  right: 0;
  width: 15px;
  height: 15px;
  line-height: 15px;
  font-size: 25px;
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .main-tab__cont__more {
    top: -40px;
    font-size: 0;
    color: #fafafa;
  }
  .main-tab__cont__more:after {
    color: #000;
  }
}

.mtcul-cont {
  overflow: hidden;
  display: block;
  line-height: 1.5;
  color: #000;
  font-size: 20px;
  height: 60px;
  margin-bottom: 20px;
}

@media screen and (min-width: 1px) and (max-width: 1050px) {
  .mtcul-cont {
    font-size: 17px;
    height: 50px;
    margin-bottom: 10px;
  }
}

@media screen and (min-width: 1px) and (max-width: 480px) {
  .mtcul-cont {
    font-size: 15px;
    height: 45px;
  }
}

.mtcul-text {
  overflow: hidden;
  display: block;
  line-height: 1.5;
  color: #666;
  font-size: 15px;
  height: 45px;
  margin-bottom: 15px;
}

@media screen and (min-width: 1px) and (max-width: 480px) {
  .mtcul-text {
    font-size: 14px;
    word-break: break-all;
  }
}

.mtcul-date {
  display: block;
  color: #666;
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .mtcul-cont {
    width: auto;
  }
}

/*서브탭*/
.sub-tab {
  overflow: hidden;
}

.sub-tab__ul {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 30px;
  overflow: hidden;
  margin-left: -5px;
}

.sub-tab__ul:after {
  content: ' ';
  clear: both;
  display: block;
  visibility: hidden;
}

* + html .sub-tab__ul {
  display: inline-block;
}

.sub-tab__ul__li {
  flex: 1;
  min-width: 16%;
  margin-bottom: 5px;
  max-width: 50%;
}

@media screen and (min-width: 1px) and (max-width: 1050px) {
  .sub-tab__ul__li {
    min-width: 33.33%;
  }
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .sub-tab__ul__li {
    min-width: 50%;
    max-width: 50%;
  }
}

@media screen and (min-width: 1px) and (max-width: 450px) {
  .sub-tab__ul__li {
    min-width: 150px;
    max-width: 50%;
  }
}

.sub-tab__ul__li a {
  color: #333;
  display: block;
  border: 1px solid #ddd;
  text-align: center;
  margin-left: 5px;
  font-size: 18px;
  font-size: 1.8rem;
  padding-top: 12px;
  padding-bottom: 12px;
  white-space: nowrap;
}

.sub-tab__ul__li a:hover {
  border: 1px solid #000;
}

.sub-tab__ul__li a:focus {
  background-color: #fff;
  color: #000;
  border: 1px solid #000;
  text-decoration: underline;
  border-left: 1px solid #000;
}

@media screen and (min-width: 1px) and (max-width: 450px) {
  .sub-tab__ul__li a {
    font-size: 15px;
    font-size: 1.5rem;
  }
}

.sub-tab__ul__li.on a {
  background-color: #000;
  color: #fff;
  border: 1px solid #000;
}

/*서브탭2*/
.sub-tab2 {
  overflow: hidden;
}

.sub-tab2__ul {
  border-top: 1px solid #ddd;
  border-left: 1px solid #ddd;
  margin-bottom: 30px;
}

.sub-tab2__ul:after {
  content: ' ';
  clear: both;
  display: block;
  visibility: hidden;
}

* + html .sub-tab2__ul {
  display: inline-block;
}

.sub-tab2__ul__li {
  float: left;
  box-sizing: border-box;
}

@media screen and (min-width: 1px) and (max-width: 900px) {
  .sub-tab2__ul__li {
    width: 25% !important;
  }
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .sub-tab2__ul__li {
    width: 33.33% !important;
  }
}

@media screen and (min-width: 1px) and (max-width: 540px) {
  .sub-tab2__ul__li {
    width: 50% !important;
  }
}

.sub-tab2__ul__li a {
  color: #333;
  display: block;
  border: 1px solid #ddd;
  margin-top: -1px;
  margin-left: -1px;
  text-align: center;
  font-size: 14px;
  font-size: 1.4rem;
  padding-top: 10px;
  padding-bottom: 10px;
  white-space: nowrap;
  box-sizing: border-box;
}

.sub-tab2__ul__li a:hover {
  text-decoration: underline;
}

.sub-tab2__ul__li a:focus {
  text-decoration: underline;
}

.sub-tab2__ul__li.on a {
  color: #2550a7;
  position: relative;
  z-index: 99999;
  font-weight: bold;
  padding-top: 9px;
  padding-bottom: 9px;
  border: 2px solid #2550a7;
}

.sub-tab2.menu-num-2 > ul > li {
  width: 50%;
}

.sub-tab2.menu-num-3 > ul > li {
  width: 33.33%;
}

* + html .sub-tab2.menu-num-3 > ul > li {
  width: 33%;
}

.sub-tab2.menu-num-4 > ul > li {
  width: 25%;
}

.sub-tab2.menu-num-5 > ul > li {
  width: 20%;
}

.sub-tab2.menu-num-6 > ul > li {
  width: 16.66%;
}

* + html .sub-tab2.menu-num-6 > ul > li {
  width: 16.66%;
}

.sub-tab2.menu-num-7 > ul > li {
  width: 16.66%;
}

.sub-tab2.menu-num-8 > ul > li {
  width: 16.66%;
}

.sub-tab2.menu-num-9 > ul > li {
  width: 16.66%;
}

.sub-tab2 * + html .menu-num-9 > ul > li {
  width: 16.66%;
}

.sub-tab2.menu-num-10 > ul > li {
  width: 16.66%;
}

.sub-tab2.menu-num-11 > ul > li {
  width: 16.66%;
}

* + html .sub-tab2.menu-num-11 > ul > li {
  width: 16.66%;
}

.sub-tab2.menu-num-12 > ul > li {
  width: 16.66%;
}

* + html .sub-tab2.menu-num-12 > ul > li {
  width: 16.66%;
}

.sub-tab2.menu-num-13 > ul > li {
  width: 16.66%;
}

.sub-tab2.menu-num-14 > ul > li {
  width: 16.66%;
}

.sub-tab2.menu-num-15 > ul > li {
  width: 16.66%;
}

.sub-tab2.menu-num-16 > ul > li {
  width: 16.66%;
}

.sub-tab2.menu-num-17 > ul > li {
  width: 16.66%;
}

.sub-tab2.menu-num-18 > ul > li {
  width: 16.66%;
}

.sub-tab2.menu-num-19 > ul > li {
  width: 16.66%;
}

.sub-tab2.menu-num-20 > ul > li {
  width: 16.66%;
}

.sub-tab2.menu-num-21 > ul > li {
  width: 16.66%;
}

.sub-tab2.menu-num-22 > ul > li {
  width: 16.66%;
}

.sub-tab2.menu-num-23 > ul > li {
  width: 16.66%;
}

.sub-tab2.menu-num-24 > ul > li {
  width: 16.66%;
}

.sub-tab2.menu-num-25 > ul > li {
  width: 16.66%;
}

/* 회원가입 인트로 */
.join-wrap {
  margin-left: -20px;
}

.join-wrap:after {
  content: ' ';
  clear: both;
  display: block;
  visibility: hidden;
}

* + html .join-wrap {
  display: inline-block;
}

.join-wrap__box {
  float: left;
}

.join-wrap__box__in {
  margin-left: 20px;
  padding-top: 60px;
  padding-bottom: 10px;
  min-height: 300px;
  border: 1px solid #000;
  border-radius: 5px;
  text-align: center;
}

.join-wrap__box__in__tit {
  display: block;
  font-size: 28px;
  margin-bottom: 35px;
  position: relative;
  padding-top: 80px;
}

.join-wrap__box__in__desc {
  margin-bottom: 30px;
}

.join-wrap__box__in__go {
  display: inline-block;
  color: #fff;
  background-color: #da4453;
  font-size: 19px;
  padding: 20px 60px;
  font-weight: 600;
  transition: all 0.3s ease 0s;
  min-width: 100px;
  text-align: center;
}

.join-wrap__box__in__go:hover, .join-wrap__box__in__go:focus {
  background-color: #da5b67;
  color: gold;
}

.join-wrap__box--1 {
  width: 50%;
}

.join-wrap__box--1 strong:before {
  display: block;
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: 0px -204px;
  background-image: url("../images/sprite.png");
  width: 51px;
  min-width: 51px;
  height: 73px;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

.join-wrap__box--2 {
  width: 50%;
}

.join-wrap__box--2 strong:before {
  display: block;
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -248px 0px;
  background-image: url("../images/sprite.png");
  width: 88px;
  min-width: 88px;
  height: 72px;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .join-wrap {
    margin-left: 0;
  }
  .join-wrap__box {
    float: none;
    margin: 0 20px;
    margin-bottom: 10px;
    width: auto;
  }
  .join-wrap__box__in {
    margin-left: 0;
  }
}

/* 회원가입 스탭
===========================*/
/* step top */
.join-step__top {
  margin-bottom: 50px;
}

.join-step__top__ul {
  margin-left: -7px;
}

.join-step__top__ul:after {
  content: ' ';
  clear: both;
  display: block;
  visibility: hidden;
}

* + html .join-step__top__ul {
  display: inline-block;
}

.join-step__top__ul__li {
  float: left;
  width: 33%;
}

.join-step__top__ul__li:first-child {
  width: 34%;
}

.join-step__top__ul__li p {
  white-space: nowrap;
  background-color: #f7f8fc;
  border: 1px solid #d6dde7;
  padding: 10px 20px;
  margin-left: 7px;
  position: relative;
}

.join-step__top__ul__li p:after {
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -268px -769px;
  background-image: url("../images/sprite.png");
  width: 13px;
  min-width: 13px;
  height: 1px;
}

.join-step__top__ul__li.on p {
  color: #fff;
  background-color: #444444;
  border: 1px solid #444444;
}

.join-step__top__ul__li.on p:after {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: 0px -840px;
  background-image: url("../images/sprite.png");
  width: 13px;
  min-width: 13px;
  height: 13px;
}

@media screen and (min-width: 769px) and (max-width: 900px) {
  .join-step__top__ul__li p {
    font-size: 13px;
  }
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .join-step__top__ul__li {
    display: none;
  }
  .join-step__top__ul__li.on {
    display: block;
    width: 100%;
  }
}

/* step1 */
.join-step--1__tit {
  margin-bottom: 20px;
}

.join-step--1__tit .label-text {
  font-size: 16px;
  margin-left: 5px;
}

.join-step--1__tit.allcheck {
  padding-bottom: 20px;
  border-bottom: 1px solid #eee;
  margin-bottom: 60px;
}

.join-step--1__textbox {
  height: 140px;
  overflow: auto;
  margin-bottom: 30px;
  border: 1px solid #eee;
  padding-left: 20px;
  padding-right: 20px;
  border-top: 1px solid #000;
  color: #666;
}

.join-step__confirm {
  background-color: #eeeeee;
  border-top: 1px solid #000;
  padding: 50px 30px;
  text-align: center;
  margin-bottom: 40px;
  width: 48%;
  margin-left: 2%;
  float: left;
  box-sizing: border-box;
}

.join-step__confirm__tit {
  display: block;
  font-size: 40px;
  margin-bottom: 20px;
}

.join-step__confirm__desc {
  padding-bottom: 20px;
  font-size: 16px;
  font-weight: 600;
  min-height: 52px;
}

.join-step__confirm__btn {
  margin-left: 24% !important;
  margin-right: 24% !important;
  margin-top: 100px !important;
  position: relative;
}

.join-step__confirm__btn {
  color: #fff;
  background-color: #000;
  height: 60px;
  line-height: 60px;
  display: inline-block;
  padding-left: 30px;
  padding-right: 30px;
  vertical-align: middle;
  box-sizing: border-box;
  font-size: 15px;
  font-weight: 600;
  text-align: center;
  border: 1px solid #000;
}

.join-step__confirm__btn:hover, .join-step__confirm__btn:focus {
  background-color: #eee;
  color: #000;
}

.join-step__confirm__btn:before {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -101px -204px;
  background-image: url("../images/sprite.png");
  width: 49px;
  min-width: 49px;
  height: 71px;
  position: absolute;
  top: -83px;
  left: 50%;
  transform: translateX(-50%);
}

.join-step__info {
  margin-bottom: 20px;
}

.join-step__info__tit {
  position: relative;
  padding-left: 22px;
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: 600;
}

.join-step__info__tit:before {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: 0px -769px;
  background-image: url("../images/sprite.png");
  width: 13px;
  min-width: 13px;
  height: 21px;
  position: absolute;
  left: 0;
  top: -2px;
}

.join-step__info__ul:after {
  content: ' ';
  clear: both;
  display: block;
  visibility: hidden;
}

* + html .join-step__info__ul {
  display: inline-block;
}

.join-step__info__ul > li {
  text-align: left;
  position: relative;
  padding-left: 15px;
  line-height: 1.6;
}

.join-step__info__ul > li:before {
  position: absolute;
  top: 11px;
  left: 3px;
  content: ' ';
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -901px -725px;
  background-image: url("../images/sprite.png");
  width: 5px;
  min-width: 5px;
  height: 2px;
}

.join-step__confirm-wrap {
  margin-left: -2%;
}

.join-step__confirm-wrap:after {
  content: ' ';
  clear: both;
  display: block;
  visibility: hidden;
}

* + html .join-step__confirm-wrap {
  display: inline-block;
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .join-step__confirm__tit {
    font-size: 28px;
  }
  .join-step__confirm-wrap {
    margin-left: 0;
  }
  .join-step__confirm {
    float: none;
    margin-left: 0;
    width: auto;
    margin-bottom: 10px;
  }
}

/* step2 */
.serius {
  color: #da4453;
}

.join-form {
  margin: 0 5%;
  margin-bottom: 30px;
}

.join-form__line {
  border-bottom: 1px solid #d1d1d1;
  padding: 20px 0;
  position: relative;
}

.join-form__line__tit {
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
  font-size: 16px;
  transform: translateY(-50%);
}

.join-form__line__in {
  padding-left: 220px;
}

.join-form__line__in__input {
  border: 1px solid #000;
  height: 34px;
  width: 50%;
  line-height: 34px;
  font-size: 16px;
  padding: 0;
  padding-left: 10px;
  box-sizing: border-box;
}

.join-form__line__in__input2 {
  border: 1px solid #000;
  height: 34px;
  width: 15%;
  line-height: 34px;
  font-size: 16px;
  padding: 0;
  padding-left: 10px;
  box-sizing: border-box;
}

.join-form__line__in__input3 {
  border: 1px solid #000;
  height: 34px;
  width: 25%;
  line-height: 34px;
  font-size: 16px;
  padding: 0;
  padding-left: 10px;
  box-sizing: border-box;
}

.join-form__line__in__input4 {
  border: 1px solid #000;
  height: 34px;
  width: 35%;
  line-height: 34px;
  font-size: 16px;
  padding: 0;
  padding-left: 10px;
  box-sizing: border-box;
}

.join-form__line__in__selectbox {
  overflow: hidden;
  display: inline-block;
  vertical-align: middle;
  position: relative;
  background-color: #fff;
  text-align: left;
  border: 1px solid #000;
}

.join-form__line__in__selectbox:after {
  position: absolute;
  top: 50%;
  right: 0;
  margin-right: 10px;
  transform: translateY(-50%);
  content: ' ';
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -963px -762px;
  background-image: url("../images/sprite.png");
  width: 9px;
  min-width: 9px;
  height: 5px;
}

.lte-ie9 .join-form__line__in__selectbox:after {
  display: none;
}

.join-form__line__in__selectbox select {
  width: 100%;
  background-color: transparent;
  appearance: none;
  min-width: 130px;
  vertical-align: middle;
  color: #000;
  font-size: 16px;
  height: 58px;
  margin-top: 0;
  padding: 2px 2px 2px 10px;
  position: relative;
  border: 0px;
  /*
			@media screen and (min-width:0\0){
				& {width:125%}
			}
			*/
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  /*ie10~11 에서 셀렉트 기본디자인 무력화*/
}

.join-form__line__in__selectbox select::-ms-expand {
  display: none;
}

.lte-ie9 .join-form__line__in__selectbox select {
  width: 100%;
  vertical-align: top;
  padding-right: 0px;
}

.join-form__line__in__selectbox select option {
  font-family: "scdream";
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .join-form__line__in__selectbox select {
    min-width: 100px;
  }
}

.join-form__line__in__btn {
  color: #000;
  background-color: #fff;
  border: 1px solid #000;
  height: 60px;
  line-height: 60px;
  display: inline-block;
  padding-left: 30px;
  padding-right: 30px;
  box-sizing: border-box;
  vertical-align: middle;
  font-size: 15px;
  font-weight: 600;
  text-align: center;
}

.join-form__line__in__btn:hover, .join-form__line__in__btn:focus {
  background-color: #eee;
  text-decoration: underline;
}

.join-subs {
  margin-bottom: 30px;
}

.join-subs__box {
  background-color: #f6f6f6;
  margin-bottom: 7px;
  font-size: 15px;
  height: 58px;
  line-height: 58px;
  position: relative;
  padding-left: 20px;
}

.join-subs__box__radiobox {
  position: absolute;
  top: 50%;
  right: 10%;
  transform: translateY(-50%);
}

.join-subs__box__radiobox .in_check {
  margin-left: 20px;
}

.ok-btn {
  display: inline-block;
  color: #fff;
  background-color: #da4453;
  font-size: 19px;
  padding: 20px 60px;
  font-weight: 600;
  transition: all 0.3s ease 0s;
  min-width: 100px;
  text-align: center;
}

.ok-btn:hover, .ok-btn:focus {
  background-color: #da5b67;
  color: gold;
}

.cancel-btn {
  display: inline-block;
  color: #000;
  background-color: #fff;
  font-size: 19px;
  padding: 19px 59px;
  border: 1px solid #000;
  font-weight: 600;
  transition: all 0.3s ease 0s;
  min-width: 100px;
  text-align: center;
}

.cancel-btn:hover, .cancel-btn:focus {
  background-color: #eee;
  color: #000;
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .join-form__line__tit {
    position: relative;
    top: 0;
    left: 0;
    font-size: 16px;
    transform: translateY(0);
    margin-bottom: 5px;
  }
  .join-form__line__in {
    padding-left: 0;
  }
  .join-form__line__in__input {
    width: 100%;
    box-sizing: border-box;
  }
  .join-form__line__in__input3 {
    width: 50%;
    box-sizing: border-box;
  }
  .join-form__line__in__input2 {
    width: 30%;
    box-sizing: border-box;
  }
  .join-form__line__in__input4 {
    width: 100%;
    box-sizing: border-box;
    margin-bottom: 5px;
  }
  .join-form__line__in__btn {
    margin-top: 10px;
    display: block;
    width: auto;
    text-align: center;
  }
  .join-subs__box {
    height: auto;
    line-height: 1.6;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .join-subs__box__radiobox {
    position: relative;
    top: 0;
    right: 0;
    transform: translateY(0);
  }
}

/* step 3 */
.join-step__final {
  text-align: center;
  padding-top: 30px;
}

.join-step__final__text1 {
  position: relative;
  padding-top: 60px;
  font-size: 22px;
  margin-bottom: 20px;
}

.join-step__final__text1 span {
  color: #da4453;
}

.join-step__final__text1:before {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: 0px -327px;
  background-image: url("../images/sprite.png");
  width: 63px;
  min-width: 63px;
  height: 46px;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

.join-step__final__text2 {
  font-size: 15px;
  color: #666;
  margin-bottom: 80px;
}

.join-step__final__text2 strong {
  color: #000;
}

.idpass-wrap {
  border: 1px solid #333;
  width: 760px;
  box-sizing: border-box;
  margin: 0 auto;
}

.idpass-wrap__top {
  background-color: #f7f7f7;
  border-bottom: 1px solid #333;
  text-align: center;
  padding: 10px;
}

.idpass-wrap__top__list {
  margin-right: 10px;
  padding-left: 20px;
  position: relative;
  display: inline-block;
}

.idpass-wrap__top__list:before {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -901px -262px;
  background-image: url("../images/sprite.png");
  width: 12px;
  min-width: 12px;
  height: 12px;
}

.idpass-wrap__top__list.on:before {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -901px -324px;
  background-image: url("../images/sprite.png");
  width: 12px;
  min-width: 12px;
  height: 12px;
}

.idpass-wrap__form {
  padding: 60px;
  text-align: center;
}

.idpass-wrap__form .line-box {
  padding-bottom: 15px;
  margin-bottom: 15px;
  border-bottom: 1px solid #eee;
}

.idpass-wrap__form__label {
  width: 100px;
  display: inline-block;
  font-size: 15px;
  text-align: left;
  font-weight: bold;
}

.idpass-wrap__form__input {
  margin: 0 auto;
  border: 1px solid #000;
  height: 60px;
  line-height: 60px;
  font-size: 16px;
  padding: 0;
  padding-left: 10px;
  box-sizing: border-box;
}

.idpass-wrap__form__input--1 {
  width: 476px;
}

.idpass-wrap__form__input--2 {
  width: 150px;
}

.idpass-wrap__form__select {
  overflow: hidden;
  display: inline-block;
  vertical-align: middle;
  position: relative;
  background-color: #fff;
  text-align: left;
  border: 1px solid #000;
}

.idpass-wrap__form__select:after {
  position: absolute;
  top: 50%;
  right: 0;
  margin-right: 10px;
  transform: translateY(-50%);
  content: ' ';
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -963px -762px;
  background-image: url("../images/sprite.png");
  width: 9px;
  min-width: 9px;
  height: 5px;
}

.lte-ie9 .idpass-wrap__form__select:after {
  display: none;
}

.idpass-wrap__form__select select {
  width: 100%;
  background-color: transparent;
  appearance: none;
  min-width: 150px;
  vertical-align: middle;
  color: #000;
  font-size: 16px;
  height: 58px;
  margin-top: 0;
  padding: 2px 2px 2px 10px;
  position: relative;
  border: 0px;
  /*
			@media screen and (min-width:0\0){
				& {width:125%}
			}
			*/
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  /*ie10~11 에서 셀렉트 기본디자인 무력화*/
}

.idpass-wrap__form__select select::-ms-expand {
  display: none;
}

.lte-ie9 .idpass-wrap__form__select select {
  width: 100%;
  vertical-align: top;
  padding-right: 0px;
}

.idpass-wrap__form__select select option {
  font-family: "scdream";
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .idpass-wrap__form__select select {
    min-width: 120px;
  }
}

.idpass-wrap__form__submit {
  margin-top: 30px;
}

.idpass-wrap__form__submit {
  color: #000;
  background-color: #fff;
  border: 1px solid #000;
  height: 60px;
  line-height: 60px;
  display: inline-block;
  padding-left: 30px;
  padding-right: 30px;
  box-sizing: border-box;
  vertical-align: middle;
  font-size: 15px;
  font-weight: 600;
  text-align: center;
}

.idpass-wrap__form__submit:hover, .idpass-wrap__form__submit:focus {
  background-color: #eee;
  text-decoration: underline;
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .idpass-wrap {
    width: 100%;
    text-align: left;
  }
  .idpass-wrap__form {
    padding: 30px 14px;
    text-align: left;
  }
  .idpass-wrap__form__label {
    width: 100%;
  }
  .idpass-wrap__form__input--1 {
    width: 100%;
  }
  .idpass-wrap__form__input--2 {
    width: 50%;
    margin-bottom: 5px;
  }
  .idpass-wrap__form__select {
    margin-bottom: 5px;
  }
}

.login-wrap {
  padding-top: 100px;
  text-align: center;
}

.login-wrap__desc {
  font-size: 15px;
  color: #666;
  margin-bottom: 30px;
}

.login-wrap__form {
  margin-bottom: 20px;
}

.login-wrap__form__input {
  display: block;
  margin: 0 auto;
  margin-bottom: 5px;
  width: 500px;
  border: 1px solid #000;
  height: 58px;
  line-height: 58px;
  font-size: 16px;
  padding: 0;
  padding-left: 10px;
  box-sizing: border-box;
}

.login-wrap__form__submit {
  color: #fff;
  background-color: #000;
  height: 60px;
  line-height: 60px;
  display: inline-block;
  width: 500px;
  vertical-align: middle;
  font-size: 15px;
  font-weight: 600;
}

.login-wrap__form__submit:focus {
  background-color: #333;
  color: gold;
}

.login-wrap__form--bottom {
  width: 500px;
  margin: 0 auto;
  display: flex;
}

.login-wrap__form--bottom a {
  flex: 1;
  position: relative;
}

.login-wrap__form--bottom a:before {
  position: absolute;
  top: 5px;
  left: 0;
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -901px -501px;
  background-image: url("../images/sprite.png");
  width: 1px;
  min-width: 1px;
  height: 10px;
}

.login-wrap__form--bottom a:first-child:before {
  display: none !important;
}

@media screen and (min-width: 1px) and (max-width: 600px) {
  .login-wrap {
    padding-top: 50px;
  }
  .login-wrap__desc {
    font-size: 14px;
  }
  .login-wrap__form__input {
    width: 100%;
  }
  .login-wrap__form__submit {
    width: 100%;
  }
  .login-wrap__form--bottom {
    width: 100%;
  }
}

.faq-box {
  margin-bottom: 50px;
  border-top: 2px solid #000;
}

.faq-box__list {
  background-color: #fff;
}

.faq-box__list__tit {
  padding: 15px;
  padding-left: 30px;
  padding-top: 17px;
  box-sizing: border-box;
  min-height: 60px;
  position: relative;
  font-size: 18px;
  padding-right: 100px;
  border-bottom: 1px solid #333;
  padding-left: 70px;
  position: relative;
  display: block;
}

.faq-box__list__tit strong {
  font-weight: normal;
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .faq-box__list__tit {
    padding-right: 30px;
    padding-left: 60px;
  }
}

.faq-box__list__tit:focus {
  text-decoration: underline;
  text-underline-position: under;
}

.faq-box__list__tit:before {
  position: absolute;
  top: 15px;
  left: 15px;
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -508px -521px;
  background-image: url("../images/sprite.png");
  width: 30px;
  min-width: 30px;
  height: 30px;
}

.faq-box__list__tit:after {
  position: absolute;
  top: 25px;
  right: 15px;
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -480px -607px;
  background-image: url("../images/sprite.png");
  width: 23px;
  min-width: 23px;
  height: 14px;
}

.faq-box__list__answer {
  display: none;
  border-bottom: 1px solid #333;
  padding: 30px;
  padding-top: 25px;
  padding-left: 70px;
  background-color: #f8f8f8;
  position: relative;
  font-size: 17px;
  line-height: 1.6;
}

.faq-box__list__answer:before {
  position: absolute;
  top: 25px;
  left: 15px;
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -669px -171px;
  background-image: url("../images/sprite.png");
  width: 30px;
  min-width: 30px;
  height: 30px;
}

.faq-box__list.on .faq-box__list__tit:after {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -407px -607px;
  background-image: url("../images/sprite.png");
  width: 23px;
  min-width: 23px;
  height: 14px;
}

.callender {
  position: relative;
}

.callender__tit {
  text-indent: -9999px;
  position: absolute;
  top: 0;
  left: -9999px;
  height: 0;
  overflow: hidden;
}

.callender__month {
  margin-bottom: 10px;
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .callender__month {
    margin-bottom: 50px;
  }
}

.callender__month strong {
  font-size: 28px;
  display: inline-block;
  padding-left: 30px;
  padding-right: 30px;
  color: #666;
  vertical-align: middle;
}

.callender__month__btn {
  display: inline-block;
  width: 30px;
  height: 30px;
  vertical-align: middle;
  position: relative;
  border-radius: 4px;
  border: 1px solid #fff;
}

.callender__month__btn span {
  text-indent: -9999px;
  position: absolute;
  top: 0;
  left: -9999px;
  height: 0;
  overflow: hidden;
}

.callender__month__btn:before {
  position: absolute;
  top: 3px;
  left: 8px;
}

.callender__month__btn.prev-btn:before {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -830px -289px;
  background-image: url("../images/sprite.png");
  width: 14px;
  min-width: 14px;
  height: 23px;
}

.callender__month__btn.next-btn:before {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -830px -362px;
  background-image: url("../images/sprite.png");
  width: 14px;
  min-width: 14px;
  height: 23px;
}

.callender__month__btn:focus {
  border: 1px solid gold;
}

.callender__ctg {
  position: absolute;
  top: 10px;
  right: 0;
  display: flex;
}

.callender__ctg span {
  margin-left: 30px;
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .callender__ctg {
    top: 54px;
  }
}

.call-icon {
  display: block;
  font-weight: normal;
  padding-left: 25px;
  margin-bottom: 3px;
  background-position: left center;
  background-repeat: no-repeat;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.call-icon:focus {
  text-decoration: underline;
}

.call-icon.type2 {
  background-image: url("../../resources/images/cont/call-type1.png");
}

.call-icon.type3 {
  background-image: url("../../resources/images/cont/call-type3.png");
}

.call-icon.type4 {
  background-image: url("../../resources/images/cont/call-type2.png");
}

.type2 .call-icon {
  background-image: url("../../resources/images/cont/call-type1.png");
}

.type3 .call-icon {
  background-image: url("../../resources/images/cont/call-type3.png");
}

.type4 .call-icon {
  background-image: url("../../resources/images/cont/call-type2.png");
}

.call-item {
  position: relative;
}

.call-layer {
  display: none;
  position: absolute;
  top: 25px;
  left: 0;
  z-index: 999;
  min-width: 320px;
  box-sizing: border-box;
  padding: 20px;
  background-color: #fff;
  word-break: break-all;
}

.call-layer.on {
  display: block;
}

.call-layer strong {
  margin-bottom: 10px;
}

.type2 .call-layer {
  border: 2px solid #db4651;
}

.type3 .call-layer {
  border: 2px solid #664795;
}

.type4 .call-layer {
  border: 2px solid #03928d;
}

.call-layer .layer-close {
  position: absolute;
  top: 0;
  right: 0;
  color: #fff;
  display: block;
  padding: 2px 10px;
}

.call-layer .layer-close:focus {
  text-decoration: underline;
}

.type2 .call-layer .layer-close {
  background-color: #db4651;
}

.type3 .call-layer .layer-close {
  background-color: #664795;
}

.type4 .call-layer .layer-close {
  background-color: #03928d;
}

.cal_tb {
  margin-bottom: 30px;
  width: 100%;
}

@media screen and (min-width: 1px) and (max-width: 1400px) {
  .cal_tb {
    overflow: auto;
  }
}

.cal_tb table {
  width: 100%;
  min-width: 800px;
}

.cal_tb table thead th {
  height: 60px;
  line-height: 60px;
  font-size: 18px;
  font-weight: 400;
  border-top: 2px solid #000;
  border-bottom: 1px solid #000;
}

.cal_tb table tr th:first-child,
.cal_tb table tr td:first-child em {
  color: #db4651;
}

.cal_tb table tr th:last-child,
.cal_tb table tr td:last-child em {
  color: #1658c5;
}

.cal_tb table tbody td {
  border: 1px solid #ddd;
}

.cal_tb table tbody td .inner-box {
  padding: 8px;
  position: relative;
  min-height: 100px;
  max-width: 175px;
}

.cal_tb table tbody td .inner-box em {
  display: block;
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .cal_tb table tbody td .inner-box {
    max-width: 90px;
  }
}

@media screen and (min-width: 1px) and (max-width: 1650px) {
  .cal_tb table tr td:last-child .call-layer {
    left: unset;
    right: 0;
  }
}

.today_scz {
  position: relative;
}

body .schedule_btn {
  background-color: #fff !important;
  color: #000;
  display: block;
  font-weight: normal;
  padding-left: 25px;
  margin-bottom: 3px;
  font-size: 15px;
  background-position: left center;
  background-repeat: no-repeat;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

body .schedule_btn.type2 {
  background-image: url("../../resources/images/cont/call-type1.png");
}

body .schedule_btn.type3 {
  background-image: url("../../resources/images/cont/call-type3.png");
}

body .schedule_btn.type4 {
  background-image: url("../../resources/images/cont/call-type2.png");
}

.schedule.tb_type1 {
  border-top: 0px !important;
  border-right: 0px;
}

.schedule.tb_type1 table thead th {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  height: 60px;
  line-height: 60px;
  font-size: 18px;
  font-weight: 400;
  border-top: 2px solid #000;
  border-bottom: 1px solid #000;
  background-color: #fff;
  color: #000;
}

.schedule.tb_type1 table td {
  border: 1px solid #ddd;
}

.design-top {
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 280px;
  margin-bottom: 40px;
}

.design-top__in {
  text-align: center;
  color: #fff;
  font-size: 18px;
  padding-top: 60px;
  line-height: 1.6;
  padding-bottom: 15px;
}

.design-top__in .headline {
  font-size: 50px;
  font-weight: 300;
  margin-bottom: 10px;
}

.design-top__in .headline.small-ver {
  font-size: 38px;
}

.design-top__in .headline strong {
  font-weight: 700;
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .design-top {
    min-height: 10px;
    padding-bottom: 40px;
  }
  .design-top__in {
    padding-top: 30px;
    font-size: 16px;
    padding-left: 30px;
    padding-right: 30px;
  }
  .design-top__in .headline,
  .design-top__in .headline.small-ver {
    font-size: 25px;
    word-break: break-all;
  }
  .design-top__in br {
    display: none;
  }
}

.info-design-box {
  display: flex;
  margin-left: -40px;
  margin-bottom: 40px;
  flex-wrap: wrap;
}

@media screen and (min-width: 1px) and (max-width: 1400px) {
  .info-design-box {
    margin-left: -10px;
  }
}

.info-design-box__item {
  flex: 1;
  margin-left: 40px;
  background-color: #f8f8f8;
  box-sizing: border-box;
  padding-top: 40px;
  min-height: 200px;
  padding-left: 100px;
  padding-right: 10px;
  font-size: 18px;
}

@media screen and (min-width: 1px) and (max-width: 1400px) {
  .info-design-box__item {
    margin-left: 10px;
    padding-left: 60px;
  }
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .info-design-box__item {
    max-width: 50%;
    min-width: 45%;
    margin-bottom: 10px;
    padding-top: 20px;
    padding-left: 20px;
    padding-right: 10px;
    padding-bottom: 10px;
    font-size: 15px;
    min-height: 130px;
  }
}

.info-design-box__item strong {
  font-size: 25px;
  margin-bottom: 10px;
  display: block;
  position: relative;
}

@media screen and (min-width: 1px) and (max-width: 1400px) {
  .info-design-box__item strong {
    font-size: 20px;
  }
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .info-design-box__item strong {
    padding-left: 30px;
    font-size: 18px;
  }
}

.info-design-box__item strong:before {
  position: absolute;
  top: 5px;
  left: -60px;
}

@media screen and (min-width: 1px) and (max-width: 1400px) {
  .info-design-box__item strong:before {
    left: -40px;
    top: -2px;
  }
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .info-design-box__item strong:before {
    left: -10px;
  }
}

.info-design-box__item.type1 strong:before {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -669px -411px;
  background-image: url("../images/sprite.png");
  width: 30px;
  min-width: 30px;
  height: 30px;
}

.info-design-box__item.type2 strong:before {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -669px -491px;
  background-image: url("../images/sprite.png");
  width: 30px;
  min-width: 30px;
  height: 30px;
}

.info-design-box__item.type3 strong:before {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -248px -122px;
  background-image: url("../images/sprite.png");
  width: 30px;
  min-width: 30px;
  height: 30px;
}

.info-design-box__item.type4 strong:before {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -306px -204px;
  background-image: url("../images/sprite.png");
  width: 30px;
  min-width: 30px;
  height: 30px;
}

.left-tit-box__item {
  display: flex;
  flex-wrap: wrap;
  padding-top: 40px;
  padding-bottom: 40px;
  border-top: 1px solid #ddd;
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .left-tit-box__item {
    display: block;
  }
}

.gallview-type .left-tit-box__item {
  border-top: 0px;
  padding-top: 0;
}

.left-tit-box__item__sbj {
  width: 25%;
  font-size: 26px;
  word-break: break-all;
}

@media screen and (min-width: 1px) and (max-width: 1400px) {
  .left-tit-box__item__sbj {
    width: 100%;
    margin-bottom: 10px;
  }
}

.right-img-ver .left-tit-box__item__sbj {
  width: 19%;
}

@media screen and (min-width: 1px) and (max-width: 1000px) {
  .right-img-ver .left-tit-box__item__sbj {
    width: 100%;
  }
}

.vr-ver .left-tit-box__item__sbj {
  width: 21%;
}

@media screen and (min-width: 1px) and (max-width: 1000px) {
  .vr-ver .left-tit-box__item__sbj {
    width: 100%;
  }
}

.gongmo-ver .left-tit-box__item__sbj {
  width: 22%;
  padding-right: 3%;
}

@media screen and (min-width: 1px) and (max-width: 1000px) {
  .gongmo-ver .left-tit-box__item__sbj {
    width: 100%;
    padding-right: 0;
  }
}

.gallview-type .left-tit-box__item__sbj {
  width: 22%;
  padding-right: 3%;
}

@media screen and (min-width: 1px) and (max-width: 1000px) {
  .gallview-type .left-tit-box__item__sbj {
    width: 100%;
    padding-right: 0;
    text-align: center;
    margin-bottom: 20px;
    display: block;
  }
  .gallview-type .left-tit-box__item__sbj img {
    max-width: 370px;
  }
}

@media screen and (min-width: 1px) and (max-width: 430px) {
  .gallview-type .left-tit-box__item__sbj img {
    max-width: 100%;
  }
}

.exbview-type .left-tit-box__item__sbj {
  width: 45%;
  padding-right: 5%;
}

@media screen and (min-width: 1px) and (max-width: 1000px) {
  .exbview-type .left-tit-box__item__sbj {
    width: 100%;
    padding-right: 0;
    text-align: center;
    margin-bottom: 20px;
    display: block;
  }
  .exbview-type .left-tit-box__item__sbj img {
    max-width: 370px;
    margin: 0 auto;
  }
}

@media screen and (min-width: 1px) and (max-width: 430px) {
  .exbview-type .left-tit-box__item__sbj img {
    max-width: 100%;
  }
}

.left-tit-box__item__cont {
  width: 75%;
  padding-top: 5px;
}

@media screen and (min-width: 1px) and (max-width: 1400px) {
  .left-tit-box__item__cont {
    padding-left: 15px;
    width: 100%;
    box-sizing: border-box;
  }
}

.right-img-ver .left-tit-box__item__cont {
  flex: 2;
  line-height: 2;
  padding-right: 30px;
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .right-img-ver .left-tit-box__item__cont {
    flex: none;
    margin-bottom: 20px;
  }
}

.vr-ver .left-tit-box__item__cont {
  width: 79%;
}

@media screen and (min-width: 1px) and (max-width: 1000px) {
  .vr-ver .left-tit-box__item__cont {
    width: 100%;
  }
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .vr-ver .left-tit-box__item__cont {
    padding-left: 0;
  }
}

.gongmo-ver .left-tit-box__item__cont {
  position: relative;
}

.gongmo-ver .left-tit-box__item__cont .info-text {
  position: absolute;
  bottom: 0;
  right: 0;
  color: #db4651;
}

@media screen and (min-width: 1px) and (max-width: 1400px) {
  .gongmo-ver .left-tit-box__item__cont .info-text {
    position: relative;
  }
}

.gongmo-ver .left-tit-box__item__cont .gongmo-text {
  color: #666;
  padding-top: 10px;
  font-size: 19px;
  line-height: 1.6;
}

@media screen and (min-width: 1px) and (max-width: 1400px) {
  .gongmo-ver .left-tit-box__item__cont {
    padding-left: 0;
  }
}

@media screen and (min-width: 1px) and (max-width: 1400px) {
  .gallview-type .left-tit-box__item__cont {
    width: 75%;
  }
}

@media screen and (min-width: 1px) and (max-width: 1000px) {
  .gallview-type .left-tit-box__item__cont {
    width: 100%;
    padding-left: 0;
  }
  .gallview-type .left-tit-box__item__cont .mgn_b30 {
    margin-bottom: 15px;
  }
}

.exbview-type .left-tit-box__item__cont {
  width: 50%;
}

@media screen and (min-width: 1px) and (max-width: 1000px) {
  .exbview-type .left-tit-box__item__cont {
    width: 100%;
    padding-left: 0;
  }
  .exbview-type .left-tit-box__item__cont .mgn_b30 {
    margin-bottom: 15px;
  }
}

.left-tit-box__item.right-img-ver .imgs {
  width: 360px;
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .left-tit-box__item.right-img-ver .imgs {
    width: 100%;
    text-align: center;
  }
}

.icons {
  margin-right: 10px;
  vertical-align: middle;
  margin-top: -3px;
  min-width: 30px !important;
}

.icon-1 {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -224px -689px;
  background-image: url("../images/sprite.png");
  width: 23px;
  min-width: 23px;
  height: 30px;
}

.icon-2 {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -752px -80px;
  background-image: url("../images/sprite.png");
  width: 28px;
  min-width: 28px;
  height: 29px;
}

.icon-3 {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -150px -689px;
  background-image: url("../images/sprite.png");
  width: 24px;
  min-width: 24px;
  height: 30px;
}

.icon-4 {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -752px -549px;
  background-image: url("../images/sprite.png");
  width: 26px;
  min-width: 26px;
  height: 30px;
}

.icon-5 {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -588px -521px;
  background-image: url("../images/sprite.png");
  width: 29px;
  min-width: 29px;
  height: 30px;
}

.icon-6 {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: 0px -689px;
  background-image: url("../images/sprite.png");
  width: 26px;
  min-width: 26px;
  height: 30px;
}

.icon-7 {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -191px -423px;
  background-image: url("../images/sprite.png");
  width: 30px;
  min-width: 30px;
  height: 30px;
}

.icon-8 {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -233px -607px;
  background-image: url("../images/sprite.png");
  width: 30px;
  min-width: 30px;
  height: 28px;
}

.icon-9 {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -154px -607px;
  background-image: url("../images/sprite.png");
  width: 29px;
  min-width: 29px;
  height: 29px;
}

.icon-10 {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -76px -689px;
  background-image: url("../images/sprite.png");
  width: 24px;
  min-width: 24px;
  height: 30px;
}

.icon-11 {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -752px 0px;
  background-image: url("../images/sprite.png");
  width: 28px;
  min-width: 28px;
  height: 30px;
}

.red-circle {
  background-color: #db4651;
  font-size: 18px;
  font-weight: bold;
  width: 32px;
  height: 32px;
  line-height: 32px;
  text-align: center;
  display: inline-block;
  border-radius: 100%;
  color: #fff;
}

.warning-box {
  background-color: #db4651;
  padding-top: 25px;
  padding-bottom: 25px;
  padding-left: 100px;
  color: #fff;
  font-size: 16px;
  line-height: 1.6;
  background-image: url("../../resources/images/icons/warning.png");
  background-position: 30px center;
  background-repeat: no-repeat;
  margin-bottom: 30px;
}

.locate-box:after {
  content: ' ';
  clear: both;
  display: block;
  visibility: hidden;
}

* + html .locate-box {
  display: inline-block;
}

.locate-box__sbj {
  width: 480px;
  float: left;
}

@media screen and (min-width: 1px) and (max-width: 1450px) {
  .locate-box__sbj {
    width: 100%;
    float: none;
  }
}

.locate-box__cont {
  width: 900px;
  float: right;
}

@media screen and (min-width: 1px) and (max-width: 1450px) {
  .locate-box__cont {
    width: 100%;
    margin-bottom: 30px;
    float: none;
  }
  .locate-box__cont .root_daum_roughmap {
    width: 100% !important;
    height: 300px !important;
  }
  .locate-box__cont .root_daum_roughmap .wrap_map {
    width: 100%;
    height: 300px !important;
  }
}

.basicList2.locate-type {
  display: flex;
  flex-wrap: wrap;
}

.basicList2.locate-type li {
  flex: 1;
  min-width: 22%;
  max-width: 22%;
}

@media screen and (min-width: 1px) and (max-width: 1450px) {
  .basicList2.locate-type li {
    min-width: 45%;
    max-width: 45%;
  }
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .basicList2.locate-type li {
    min-width: 95%;
    max-width: 95%;
  }
}

.location_box div a {
  background-color: oldlace;
  display: inline-block;
  padding: 0 14px;
  border-radius: 10px;
  max-width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: middle;
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .location_box div a {
    word-break: break-all;
  }
}

.years-type .left-tit-box__item__sbj {
  font-size: 32px;
}

.years-type .yearsList li {
  margin-bottom: 10px;
  position: relative;
  padding-left: 40px;
  font-size: 19px;
}

.years-type .yearsList li strong {
  color: #db4651;
  position: absolute;
  top: 0;
  left: 0;
}

.hello {
  background-image: url("../../resources/images/cont/design-bg4.jpg");
  background-position: 0 0;
  background-repeat: no-repeat;
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .hello {
    padding-top: 200px;
    background-size: auto 170px;
    background-position: center top;
  }
}

.hello__top {
  position: relative;
  height: 550px;
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .hello__top {
    height: auto;
    margin-bottom: 40px;
  }
}

.hello__top__in {
  background-color: #db4651;
  padding: 60px 50px;
  color: #fff;
  position: absolute;
  top: 200px;
  font-size: 30px;
}

.hello__top__in strong {
  font-size: 44px;
}

.hello__top__in:before {
  content: ' ';
  display: block;
  width: 45px;
  height: 4px;
  background-color: #fff;
  position: absolute;
  top: 45px;
  left: 50px;
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .hello__top__in:before {
    display: none;
  }
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .hello__top__in {
    color: #000;
    background-color: transparent;
    top: 0;
    position: relative;
    padding: 0;
    font-size: 23px;
  }
  .hello__top__in strong {
    font-size: 25px;
  }
}

.hello p {
  margin-bottom: 30px;
  font-size: 19px;
  line-height: 1.8;
}

.vision {
  background-color: #fff5f6;
  position: relative;
  margin-bottom: 145px;
  text-align: center;
  font-size: 36px;
  padding-bottom: 40px;
  padding-top: 70px;
}

.vision h3 {
  position: absolute;
  height: 70px;
  line-height: 70px;
  color: #fff;
  width: 200px;
  background-color: #db4651;
  top: -35px;
  left: 50%;
  margin-left: -100px;
  font-size: 26px;
}

@media screen and (min-width: 1px) and (max-width: 1400px) {
  .vision {
    font-size: 25px;
    padding-left: 10px;
    padding-right: 10px;
  }
}

.targets {
  position: relative;
  text-align: center;
  margin-bottom: 100px;
}

.targets h3 {
  position: absolute;
  height: 70px;
  line-height: 70px;
  color: #fff;
  width: 200px;
  background-color: #444;
  top: -35px;
  left: 50%;
  margin-left: -100px;
  font-size: 26px;
}

.targets__list {
  display: flex;
  flex-wrap: wrap;
  border: 1px solid #ddd;
}

.targets__list li {
  flex: 1;
  border: 1px solid #ddd;
  padding-top: 60px;
  box-sizing: border-box;
  height: 240px;
  font-family: "NanumBarunGothic", "Nanum Gothic", "NanumSquare", "Noto Sans KR", sans-serif;
  font-size: 26px;
  color: #000;
  font-weight: bold;
}

.targets__list li p {
  margin-bottom: 10px;
}

@media screen and (min-width: 1px) and (max-width: 1200px) {
  .targets__list li {
    min-width: 300px;
    font-size: 18px;
    height: 220px;
  }
}

.archive {
  margin-left: -25px;
  display: flex;
  flex-wrap: wrap;
}

@media screen and (min-width: 1px) and (max-width: 1400px) {
  .archive {
    margin-left: -2%;
  }
}

@media screen and (min-width: 1px) and (max-width: 900px) {
  .archive {
    margin-left: 0;
  }
}

.archive__item {
  box-sizing: border-box;
  height: 260px;
  margin-left: 25px;
  background-color: #f7f7f7;
  background-position: right bottom;
  background-repeat: no-repeat;
  background-size: auto 100%;
  margin-bottom: 25px;
}

@media screen and (min-width: 1px) and (max-width: 900px) {
  .archive__item {
    height: auto;
  }
}

.archive__item a {
  display: block;
  height: 100%;
}

.archive__item a:hover .go-btn, .archive__item a:focus .go-btn {
  background-color: #db4651;
  color: #fff;
  border: 1px solid #db4651;
}

.archive__item.type1 {
  width: 545px;
  background-image: url("../../resources/images/cont/archive-bg1.jpg");
}

.archive__item.type2 {
  width: 830px;
  background-image: url("../../resources/images/cont/archive-bg2.jpg");
}

.archive__item.type3 {
  width: 545px;
  background-image: url("../../resources/images/cont/archive-bg3.jpg");
}

.archive__item.type4 {
  width: 400px;
  background-image: url("../../resources/images/cont/archive-bg4.jpg");
}

.archive__item.type5 {
  width: 405px;
  background-image: url("../../resources/images/cont/archive-bg5.jpg");
}

@media screen and (min-width: 1px) and (max-width: 1400px) {
  .archive__item {
    background-size: auto 70%;
  }
  .archive__item.type1, .archive__item.type2, .archive__item.type3, .archive__item.type4, .archive__item.type5 {
    width: 48%;
    margin-left: 2%;
  }
}

@media screen and (min-width: 1px) and (max-width: 900px) {
  .archive__item.type1, .archive__item.type2, .archive__item.type3, .archive__item.type4, .archive__item.type5 {
    width: 100%;
    margin-left: 0;
  }
  .archive__item.type2 {
    background-size: auto 50%;
  }
}

.archive__item__in {
  position: relative;
  box-sizing: border-box;
  height: 100%;
  padding: 40px 40px 0 40px;
}

.archive__item__in .item1 {
  font-size: 28px;
  display: block;
  margin-bottom: 15px;
}

.archive__item__in .item2 {
  color: #666;
}

@media screen and (min-width: 1px) and (max-width: 900px) {
  .archive__item__in {
    padding: 20px;
    padding-bottom: 70px;
  }
  .archive__item__in .item1 {
    font-size: 22px;
    margin-bottom: 5px;
  }
}

.archive__item .go-btn {
  position: absolute;
  bottom: 30px;
  left: 40px;
  display: inline-block;
  vertical-align: middle;
  padding: 5px 70px 5px 10px;
  border: 1px solid #000;
  background-color: #f8f8f8;
  font-size: 16px;
}

.archive__item .go-btn:after {
  position: absolute;
  top: 13px;
  right: 10px;
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -189px -840px;
  background-image: url("../images/sprite.png");
  width: 15px;
  min-width: 15px;
  height: 11px;
}

@media screen and (min-width: 1px) and (max-width: 900px) {
  .archive__item .go-btn {
    bottom: 20px;
    left: 20px;
  }
}

.archive__item a:hover .go-btn:after,
.archive__item a:focus .go-btn:after {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -254px -840px;
  background-image: url("../images/sprite.png");
  width: 15px;
  min-width: 15px;
  height: 11px;
}

.privacy-box {
  margin-bottom: 40px;
  color: #666;
}

.privacy-box p {
  margin-bottom: 20px;
}

.privacy-box a:hover,
.privacy-box a:focus {
  text-decoration: underline;
}

/*
.sitemap {
    h4 {
        @include hiddenWord;
    }
    > ul {
        > li {
            display: flex;
            flex-wrap: wrap;
            padding-top:40px;
            padding-bottom:40px;
            border-top:1px solid #ddd;
            > a {
                width: 25%;
                font-size:26px;
                font-weight: bold;
                word-break: break-all;
                @media screen and (min-width: 1px) and (max-width: 1400px) {
                    width:100%;
                    margin-bottom:10px;
                }
            }
            > ul {
                @include basicList2;
                width: 75%;
                padding-top:5px;
                display: flex;
                flex-wrap: wrap;
                @media screen and (min-width: 1px) and (max-width: 1400px) {
                    padding-left:15px;
                    width: 100%;
                    box-sizing: border-box;
                }
                >li {
                    flex:1;
                    min-width:22%;
                    max-width:22%;
                    @media screen and (min-width: 1px) and (max-width: 768px) {
                        min-width:45%;
                        max-width:45%;
                    }
                    @media screen and (min-width: 1px) and (max-width: 400px) {
                        min-width:95%;
                        max-width:95%;
                    }
                    > a {}
                }
            }
        }
    }
}
*/
.endowment {
  display: flex;
  flex-wrap: wrap;
}

.endowment li {
  flex: 1;
  text-align: center;
  position: relative;
}

@media screen and (min-width: 1px) and (max-width: 900px) {
  .endowment li {
    min-width: 50%;
    max-width: 50%;
    margin-bottom: 20px;
  }
}

@media screen and (min-width: 1px) and (max-width: 480px) {
  .endowment li {
    min-width: 100%;
    max-width: 100%;
  }
}

.endowment li:after {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: -8px;
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -830px -435px;
  background-image: url("../images/sprite.png");
  width: 14px;
  min-width: 14px;
  height: 23px;
}

@media screen and (min-width: 1px) and (max-width: 480px) {
  .endowment li:after {
    transform: rotate(90deg);
    right: 50%;
    top: 100%;
    margin-right: -7px;
  }
}

.endowment li:last-child:after {
  display: none;
}

.endowment li:before {
  position: absolute;
  top: 40px;
  left: 50%;
  transform: translateX(-50%);
}

.endowment li.type1:before {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -579px -84px;
  background-image: url("../images/sprite.png");
  width: 33px;
  min-width: 33px;
  height: 40px;
}

.endowment li.type2:before {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -579px 0px;
  background-image: url("../images/sprite.png");
  width: 40px;
  min-width: 40px;
  height: 34px;
}

.endowment li.type3:before {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -300px -327px;
  background-image: url("../images/sprite.png");
  width: 39px;
  min-width: 39px;
  height: 38px;
}

.endowment li.type4:before {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -669px 0px;
  background-image: url("../images/sprite.png");
  width: 33px;
  min-width: 33px;
  height: 38px;
}

.endowment li div {
  border: 1px solid #ddd;
  padding-top: 110px;
  padding-bottom: 40px;
  margin-left: 15px;
  margin-right: 15px;
  height: 100%;
  box-sizing: border-box;
}

.endowment li strong {
  display: block;
  margin-bottom: 10px;
  font-size: 22px;
}

@media screen and (min-width: 1px) and (max-width: 900px) {
  .endowment li strong {
    font-size: 18px;
  }
}

.ggnr-box {
  display: table;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  margin-bottom: 20px;
  width: 100%;
  box-sizing: border-box;
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .ggnr-box {
    display: block;
  }
}

.ggnr-box p {
  display: table-cell;
  vertical-align: middle;
}

.ggnr-box p:first-child {
  max-width: 100px;
  padding-right: 30px;
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .ggnr-box p:first-child {
    max-width: 100%;
    margin-bottom: 5px;
  }
  .ggnr-box p:first-child img {
    height: 30px !important;
  }
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .ggnr-box p {
    display: block;
  }
}

.ggnr-box img {
  vertical-align: middle;
  margin-right: 30px;
}

.vr-new {
  display: flex;
  flex-wrap: wrap;
}

.vr-new li {
  min-width: 33.33%;
  max-width: 33.33%;
  text-align: center;
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .vr-new li {
    min-width: 50%;
    max-width: 50%;
    margin-bottom: 10px;
  }
}

.vr-new li div {
  border: 1px solid #ddd;
  padding: 20px;
  margin-left: 20px;
  margin-bottom: 20px;
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .vr-new li div {
    margin-left: 10px;
    margin-bottom: 0px;
    padding: 10px;
    height: 100%;
    box-sizing: border-box;
  }
}

.vr-new li div a {
  display: block;
  margin-bottom: 5px;
}

.vr-new li div a:hover, .vr-new li div a:focus {
  opacity: 0.7;
}

.vr-new li div p {
  font-size: 20px;
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  padding-left: 10px;
  padding-right: 10px;
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .vr-new li div p {
    font-size: 15px;
    white-space: normal;
  }
}

.new_gall ul {
  display: flex;
  flex-wrap: wrap;
  margin-left: -40px;
}

@media screen and (min-width: 1px) and (max-width: 1050px) {
  .new_gall ul {
    margin-left: -10px;
  }
}

.new_gall ul li {
  flex: 1;
  min-width: 25%;
  max-width: 25%;
  margin-bottom: 40px;
}

@media screen and (min-width: 1px) and (max-width: 1050px) {
  .new_gall ul li {
    margin-bottom: 10px;
  }
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .new_gall ul li {
    min-width: 33.33%;
    max-width: 33.33%;
  }
}

@media screen and (min-width: 1px) and (max-width: 560px) {
  .new_gall ul li {
    min-width: 50%;
    max-width: 50%;
  }
}

.new_gall ul li .gall_div {
  height: 100%;
}

.new_gall ul li .gall_div a {
  display: block;
  margin-left: 40px;
  padding: 25px;
  border: 1px solid #ddd;
  box-sizing: border-box;
  height: 100%;
}

.new_gall ul li .gall_div a:hover, .new_gall ul li .gall_div a:focus {
  border: 2px solid #db4651;
  padding: 24px;
}

.new_gall ul li .gall_div a .texts {
  display: block;
  max-height: 80px;
  overflow: hidden;
  font-size: 18px;
  word-break: break-all;
  margin-bottom: 12px;
}

.new_gall ul li .gall_div a .texts .ctg {
  color: #db4651;
  display: block;
  font-size: 15px;
}

@media screen and (min-width: 1px) and (max-width: 1050px) {
  .new_gall ul li .gall_div a {
    margin-left: 10px;
    padding: 15px;
  }
  .new_gall ul li .gall_div a:hover, .new_gall ul li .gall_div a:focus {
    border: 2px solid #db4651;
    padding: 14px;
  }
}

@media screen and (min-width: 1px) and (max-width: 560px) {
  .new_gall ul li .gall_div a .texts {
    font-size: 16px;
    max-height: 75px;
  }
}

.new_gall .imgs {
  margin-bottom: 10px;
}

.new_gall .imgs {
  position: relative;
  width: 100%;
  height: 0;
  overflow: hidden;
  padding-bottom: 75%;
  /*16:9*/
  background-color: #eee;
  color: #fff;
}

.new_gall .imgs__in {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.new_gall .imgs iframe {
  width: 100%;
  height: 100%;
}

.new_gall .imgs img {
  width: 100%;
  height: 100% !important;
  /* 비율 안깨지고 상하 가운데 정렬시
		 object-fit: cover;
		 position: absolute;
		 top: 50%;
		 left: 0;
		 transform: translateY(-50%);
		*/
}

.new_gall .imgs img {
  object-fit: cover;
}

.new_gall .movie-sp .imgs {
  position: relative;
  width: 100%;
  height: 0;
  overflow: hidden;
  padding-bottom: 52%;
  /*16:9*/
  background-color: #eee;
  color: #fff;
}

.new_gall .movie-sp .imgs__in {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.new_gall .movie-sp .imgs iframe {
  width: 100%;
  height: 100%;
}

.new_gall .movie-sp .imgs img {
  width: 100%;
  height: 100% !important;
  /* 비율 안깨지고 상하 가운데 정렬시
		 object-fit: cover;
		 position: absolute;
		 top: 50%;
		 left: 0;
		 transform: translateY(-50%);
		*/
}

.new_gall2 ul {
  display: flex;
  flex-wrap: wrap;
  margin-left: -40px;
}

@media screen and (min-width: 1px) and (max-width: 1350px) {
  .new_gall2 ul {
    margin-left: -10px;
  }
}

.new_gall2 ul li {
  flex: 1;
  min-width: 25%;
  max-width: 25%;
  margin-bottom: 40px;
}

@media screen and (min-width: 1px) and (max-width: 1350px) {
  .new_gall2 ul li {
    margin-bottom: 10px;
  }
}

@media screen and (min-width: 1px) and (max-width: 1050px) {
  .new_gall2 ul li {
    min-width: 33.33%;
    max-width: 33.33%;
  }
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .new_gall2 ul li {
    min-width: 50%;
    max-width: 50%;
  }
}

@media screen and (min-width: 1px) and (max-width: 460px) {
  .new_gall2 ul li {
    min-width: 100%;
    max-width: 100%;
  }
}

.new_gall2 ul li .gall_div {
  height: 100%;
}

.new_gall2 ul li .gall_div a {
  display: block;
  margin-left: 40px;
  padding: 25px;
  border: 1px solid #ddd;
  box-sizing: border-box;
  height: 100%;
}

.new_gall2 ul li .gall_div a:hover, .new_gall2 ul li .gall_div a:focus {
  border: 2px solid #db4651;
  padding: 24px;
}

.new_gall2 ul li .gall_div a .item01 {
  margin-bottom: 20px;
  text-align: center;
}

.new_gall2 ul li .gall_div a .item01 img {
  max-height: 400px;
}

@media screen and (min-width: 1px) and (max-width: 460px) {
  .new_gall2 ul li .gall_div a .item01 img {
    max-height: 300px;
  }
}

.new_gall2 ul li .gall_div a h3 {
  font-size: 20px;
  height: 65px;
  overflow: hidden;
  font-weight: 500;
  margin-bottom: 20px;
  word-break: break-all;
}

.new_gall2 ul li .gall_div a .item02 {
  border-top: 1px solid #000;
  font-size: 15px;
  padding-top: 30px;
}

.new_gall2 ul li .gall_div a .item02__in {
  position: relative;
  padding-left: 75px;
  word-break: break-all;
  min-height: 25px;
}

.new_gall2 ul li .gall_div a .item02__in strong {
  position: absolute;
  top: 0;
  left: 0;
}

@media screen and (min-width: 1px) and (max-width: 1350px) {
  .new_gall2 ul li .gall_div a {
    margin-left: 10px;
    padding: 15px;
  }
  .new_gall2 ul li .gall_div a:hover, .new_gall2 ul li .gall_div a:focus {
    border: 2px solid #db4651;
    padding: 14px;
  }
}

@media screen and (min-width: 1px) and (max-width: 460px) {
  .new_gall2 ul li .gall_div a .item01 {
    margin-bottom: 10px;
  }
  .new_gall2 ul li .gall_div a h3 {
    margin-bottom: 10px;
    font-size: 18px;
    height: 60px;
  }
  .new_gall2 ul li .gall_div a .item02 {
    padding-top: 10px;
  }
  .new_gall2 ul li .gall_div a .item02__in {
    padding-left: 0;
    margin-bottom: 10px;
  }
  .new_gall2 ul li .gall_div a .item02__in strong {
    position: relative;
    display: block;
  }
}

.new_gall3 ul {
  display: flex;
  flex-wrap: wrap;
  margin-left: -40px;
}

@media screen and (min-width: 1px) and (max-width: 1350px) {
  .new_gall3 ul {
    margin-left: -10px;
  }
}

.new_gall3 ul li {
  flex: 1;
  min-width: 25%;
  max-width: 25%;
  margin-bottom: 40px;
}

@media screen and (min-width: 1px) and (max-width: 1350px) {
  .new_gall3 ul li {
    margin-bottom: 10px;
  }
}

@media screen and (min-width: 1px) and (max-width: 1050px) {
  .new_gall3 ul li {
    min-width: 33.33%;
    max-width: 33.33%;
  }
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .new_gall3 ul li {
    min-width: 50%;
    max-width: 50%;
  }
}

@media screen and (min-width: 1px) and (max-width: 460px) {
  .new_gall3 ul li {
    min-width: 100%;
    max-width: 100%;
  }
}

.new_gall3 ul li .gall_div {
  height: 100%;
}

.new_gall3 ul li .gall_div a {
  display: block;
  margin-left: 40px;
  padding: 25px;
  border: 1px solid #ddd;
  box-sizing: border-box;
  height: 100%;
}

.new_gall3 ul li .gall_div a:hover, .new_gall3 ul li .gall_div a:focus {
  border: 2px solid #db4651;
  padding: 24px;
}

.new_gall3 ul li .gall_div a .item01 {
  margin-bottom: 20px;
  text-align: center;
}

.new_gall3 ul li .gall_div a .item01 img {
  max-height: 400px;
}

@media screen and (min-width: 1px) and (max-width: 460px) {
  .new_gall3 ul li .gall_div a .item01 img {
    max-height: 300px;
  }
}

.new_gall3 ul li .gall_div a h3 {
  font-size: 20px;
  height: 65px;
  overflow: hidden;
  font-weight: 500;
  margin-bottom: 20px;
  word-break: break-all;
}

.new_gall3 ul li .gall_div a .item02 {
  border-top: 1px solid #000;
  font-size: 15px;
  padding-top: 20px;
}

.new_gall3 ul li .gall_div a .item02__in {
  position: relative;
  padding-left: 75px;
  word-break: break-all;
  min-height: 25px;
}

.new_gall3 ul li .gall_div a .item02__in strong {
  position: absolute;
  top: 0;
  left: 0;
}

.new_gall3 ul li .gall_div a .item02 p {
  font-size: 16px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

@media screen and (min-width: 1px) and (max-width: 1350px) {
  .new_gall3 ul li .gall_div a {
    margin-left: 10px;
    padding: 15px;
  }
  .new_gall3 ul li .gall_div a:hover, .new_gall3 ul li .gall_div a:focus {
    border: 2px solid #db4651;
    padding: 14px;
  }
}

@media screen and (min-width: 1px) and (max-width: 460px) {
  .new_gall3 ul li .gall_div a .item01 {
    margin-bottom: 10px;
  }
  .new_gall3 ul li .gall_div a h3 {
    margin-bottom: 10px;
    font-size: 18px;
    height: 60px;
  }
  .new_gall3 ul li .gall_div a .item02 {
    padding-top: 10px;
  }
  .new_gall3 ul li .gall_div a .item02__in {
    padding-left: 0;
    margin-bottom: 10px;
  }
  .new_gall3 ul li .gall_div a .item02__in strong {
    position: relative;
    display: block;
  }
}

.new_gall4 ul {
  display: flex;
  flex-wrap: wrap;
  margin-left: -40px;
}

@media screen and (min-width: 1px) and (max-width: 1350px) {
  .new_gall4 ul {
    margin-left: -10px;
  }
}

.new_gall4 ul li {
  flex: 1;
  min-width: 25%;
  max-width: 25%;
  margin-bottom: 40px;
}

@media screen and (min-width: 1px) and (max-width: 1350px) {
  .new_gall4 ul li {
    margin-bottom: 10px;
  }
}

@media screen and (min-width: 1px) and (max-width: 1050px) {
  .new_gall4 ul li {
    min-width: 33.33%;
    max-width: 33.33%;
  }
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .new_gall4 ul li {
    min-width: 50%;
    max-width: 50%;
  }
}

@media screen and (min-width: 1px) and (max-width: 460px) {
  .new_gall4 ul li {
    min-width: 100%;
    max-width: 100%;
  }
}

.new_gall4 ul li .gall_div {
  height: 100%;
}

.new_gall4 ul li .gall_div a {
  display: block;
  margin-left: 40px;
  padding: 25px;
  border: 1px solid #ddd;
  box-sizing: border-box;
  height: 100%;
}

.new_gall4 ul li .gall_div a:hover, .new_gall4 ul li .gall_div a:focus {
  border: 2px solid #db4651;
  padding: 24px;
}

.new_gall4 ul li .gall_div a .item01 {
  margin-bottom: 20px;
  text-align: center;
}

.new_gall4 ul li .gall_div a .item01 img {
  max-height: 400px;
}

@media screen and (min-width: 1px) and (max-width: 460px) {
  .new_gall4 ul li .gall_div a .item01 img {
    max-height: 300px;
  }
}

.new_gall4 ul li .gall_div a h3 {
  font-size: 20px;
  height: 65px;
  overflow: hidden;
  font-weight: 500;
  word-break: break-all;
}

.new_gall4 ul li .gall_div a .item02 {
  border-top: 1px solid #000;
  font-size: 15px;
  padding-top: 30px;
}

.new_gall4 ul li .gall_div a .item02__in {
  position: relative;
  padding-left: 75px;
  word-break: break-all;
  min-height: 25px;
}

.new_gall4 ul li .gall_div a .item02__in strong {
  position: absolute;
  top: 0;
  left: 0;
}

.new_gall4 ul li .gall_div a .item02 p {
  font-size: 16px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

@media screen and (min-width: 1px) and (max-width: 1350px) {
  .new_gall4 ul li .gall_div a {
    margin-left: 10px;
    padding: 15px;
  }
  .new_gall4 ul li .gall_div a:hover, .new_gall4 ul li .gall_div a:focus {
    border: 2px solid #db4651;
    padding: 14px;
  }
}

@media screen and (min-width: 1px) and (max-width: 460px) {
  .new_gall4 ul li .gall_div a .item01 {
    margin-bottom: 10px;
  }
  .new_gall4 ul li .gall_div a h3 {
    font-size: 18px;
    height: 60px;
  }
  .new_gall4 ul li .gall_div a .item02 {
    padding-top: 10px;
  }
  .new_gall4 ul li .gall_div a .item02__in {
    padding-left: 0;
    margin-bottom: 10px;
  }
  .new_gall4 ul li .gall_div a .item02__in strong {
    position: relative;
    display: block;
  }
}

.new_gall5 ul {
  display: flex;
  flex-wrap: wrap;
  margin-left: -40px;
}

@media screen and (min-width: 1px) and (max-width: 1350px) {
  .new_gall5 ul {
    margin-left: -10px;
  }
}

.new_gall5 ul li {
  flex: 1;
  min-width: 25%;
  max-width: 25%;
  margin-bottom: 40px;
}

@media screen and (min-width: 1px) and (max-width: 1350px) {
  .new_gall5 ul li {
    margin-bottom: 10px;
  }
}

@media screen and (min-width: 1px) and (max-width: 1050px) {
  .new_gall5 ul li {
    min-width: 33.33%;
    max-width: 33.33%;
  }
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .new_gall5 ul li {
    min-width: 50%;
    max-width: 50%;
  }
}

@media screen and (min-width: 1px) and (max-width: 460px) {
  .new_gall5 ul li {
    min-width: 100%;
    max-width: 100%;
  }
}

.new_gall5 ul li .gall_div {
  height: 100%;
}

.new_gall5 ul li .gall_div a {
  display: block;
  margin-left: 40px;
  padding: 25px;
  border: 1px solid #ddd;
  box-sizing: border-box;
  height: 100%;
}

.new_gall5 ul li .gall_div a:hover, .new_gall5 ul li .gall_div a:focus {
  border: 2px solid #db4651;
  padding: 24px;
}

.new_gall5 ul li .gall_div a .item01 {
  margin-bottom: 20px;
  text-align: center;
}

.new_gall5 ul li .gall_div a .item01 {
  position: relative;
  width: 100%;
  height: 0;
  overflow: hidden;
  padding-bottom: 100%;
  /*16:9*/
  background-color: #eee;
  color: #fff;
}

.new_gall5 ul li .gall_div a .item01__in {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.new_gall5 ul li .gall_div a .item01 iframe {
  width: 100%;
  height: 100%;
}

.new_gall5 ul li .gall_div a .item01 img {
  width: 100%;
  height: 100% !important;
  /* 비율 안깨지고 상하 가운데 정렬시
		 object-fit: cover;
		 position: absolute;
		 top: 50%;
		 left: 0;
		 transform: translateY(-50%);
		*/
}

.new_gall5 ul li .gall_div a h3 {
  font-size: 20px;
  height: 31px;
  text-align: center;
  overflow: hidden;
  font-weight: 500;
  word-break: break-all;
  white-space: nowrap;
  text-overflow: ellipsis;
}

@media screen and (min-width: 1px) and (max-width: 1350px) {
  .new_gall5 ul li .gall_div a {
    margin-left: 10px;
    padding: 15px;
  }
  .new_gall5 ul li .gall_div a:hover, .new_gall5 ul li .gall_div a:focus {
    border: 2px solid #db4651;
    padding: 14px;
  }
}

@media screen and (min-width: 1px) and (max-width: 460px) {
  .new_gall5 ul li .gall_div a .item01 {
    margin-bottom: 10px;
  }
  .new_gall5 ul li .gall_div a h3 {
    margin-bottom: 0px;
    font-size: 18px;
    height: 29px;
  }
  .new_gall5 ul li .gall_div a .item02 {
    padding-top: 10px;
  }
  .new_gall5 ul li .gall_div a .item02__in {
    padding-left: 0;
    margin-bottom: 10px;
  }
  .new_gall5 ul li .gall_div a .item02__in strong {
    position: relative;
    display: block;
  }
}

.new_gall6 ul {
  display: flex;
  flex-wrap: wrap;
  margin-left: -40px;
}

@media screen and (min-width: 1px) and (max-width: 1350px) {
  .new_gall6 ul {
    margin-left: -10px;
  }
}

.new_gall6 ul li {
  flex: 1;
  min-width: 25%;
  max-width: 25%;
  margin-bottom: 40px;
}

@media screen and (min-width: 1px) and (max-width: 1350px) {
  .new_gall6 ul li {
    margin-bottom: 10px;
  }
}

@media screen and (min-width: 1px) and (max-width: 1050px) {
  .new_gall6 ul li {
    min-width: 33.33%;
    max-width: 33.33%;
  }
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .new_gall6 ul li {
    min-width: 50%;
    max-width: 50%;
  }
}

@media screen and (min-width: 1px) and (max-width: 460px) {
  .new_gall6 ul li {
    min-width: 100%;
    max-width: 100%;
  }
}

.new_gall6 ul li .gall_div {
  height: 100%;
}

.new_gall6 ul li .gall_div a {
  display: block;
  margin-left: 40px;
  padding: 25px;
  border: 1px solid #ddd;
  box-sizing: border-box;
  height: 100%;
}

.new_gall6 ul li .gall_div a:hover, .new_gall6 ul li .gall_div a:focus {
  border: 2px solid #db4651;
  padding: 24px;
}

.new_gall6 ul li .gall_div a .item01 {
  margin-bottom: 20px;
  text-align: center;
}

.new_gall6 ul li .gall_div a .item01 {
  position: relative;
  width: 100%;
  height: 0;
  overflow: hidden;
  padding-bottom: 100%;
  /*16:9*/
  background-color: #eee;
  color: #fff;
}

.new_gall6 ul li .gall_div a .item01__in {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.new_gall6 ul li .gall_div a .item01 iframe {
  width: 100%;
  height: 100%;
}

.new_gall6 ul li .gall_div a .item01 img {
  width: 100%;
  height: 100% !important;
  /* 비율 안깨지고 상하 가운데 정렬시
		 object-fit: cover;
		 position: absolute;
		 top: 50%;
		 left: 0;
		 transform: translateY(-50%);
		*/
}

.new_gall6 ul li .gall_div a .item01 img {
  object-fit: contain;
}

.new_gall6 ul li .gall_div a h3 {
  font-size: 20px;
  min-height: 31px;
  text-align: center;
  overflow: hidden;
  font-weight: 500;
}

.new_gall6 ul li .gall_div a .item02 {
  border-top: 1px solid #000;
  font-size: 15px;
  padding-top: 30px;
}

.new_gall6 ul li .gall_div a .item02__in {
  position: relative;
  padding-left: 75px;
  word-break: break-all;
  min-height: 25px;
}

.new_gall6 ul li .gall_div a .item02__in strong {
  position: absolute;
  top: 0;
  left: 0;
}

.new_gall6 ul li .gall_div a .item02 p {
  font-size: 16px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

@media screen and (min-width: 1px) and (max-width: 1350px) {
  .new_gall6 ul li .gall_div a {
    margin-left: 10px;
    padding: 15px;
  }
  .new_gall6 ul li .gall_div a:hover, .new_gall6 ul li .gall_div a:focus {
    border: 2px solid #db4651;
    padding: 14px;
  }
}

@media screen and (min-width: 1px) and (max-width: 460px) {
  .new_gall6 ul li .gall_div a .item01 {
    margin-bottom: 10px;
  }
  .new_gall6 ul li .gall_div a h3 {
    margin-bottom: 0px;
    font-size: 18px;
    height: 29px;
  }
}

.new_gall7 ul {
  display: flex;
  flex-wrap: wrap;
  margin-left: -40px;
}

@media screen and (min-width: 1px) and (max-width: 1350px) {
  .new_gall7 ul {
    margin-left: -10px;
  }
}

.new_gall7 ul li {
  flex: 1;
  min-width: 25%;
  max-width: 25%;
  margin-bottom: 40px;
}

@media screen and (min-width: 1px) and (max-width: 1350px) {
  .new_gall7 ul li {
    margin-bottom: 10px;
  }
}

@media screen and (min-width: 1px) and (max-width: 1050px) {
  .new_gall7 ul li {
    min-width: 33.33%;
    max-width: 33.33%;
  }
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .new_gall7 ul li {
    min-width: 50%;
    max-width: 50%;
  }
}

@media screen and (min-width: 1px) and (max-width: 460px) {
  .new_gall7 ul li {
    min-width: 100%;
    max-width: 100%;
  }
}

.new_gall7 ul li .gall_div {
  height: 100%;
}

.new_gall7 ul li .gall_div a {
  display: block;
  margin-left: 40px;
  padding: 25px;
  border: 1px solid #ddd;
  box-sizing: border-box;
  height: 100%;
}

.new_gall7 ul li .gall_div a:hover, .new_gall7 ul li .gall_div a:focus {
  border: 2px solid #db4651;
  padding: 24px;
}

.new_gall7 ul li .gall_div a .item01 {
  margin-bottom: 20px;
  text-align: center;
}

.new_gall7 ul li .gall_div a .item01 {
  position: relative;
  width: 100%;
  height: 0;
  overflow: hidden;
  padding-bottom: 100%;
  /*16:9*/
  background-color: #eee;
  color: #fff;
}

.new_gall7 ul li .gall_div a .item01__in {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.new_gall7 ul li .gall_div a .item01 iframe {
  width: 100%;
  height: 100%;
}

.new_gall7 ul li .gall_div a .item01 img {
  width: 100%;
  height: 100% !important;
  /* 비율 안깨지고 상하 가운데 정렬시
		 object-fit: cover;
		 position: absolute;
		 top: 50%;
		 left: 0;
		 transform: translateY(-50%);
		*/
}

.new_gall7 ul li .gall_div a .item01 img {
  object-fit: cover;
}

.new_gall7 ul li .gall_div a .item01 .item-ing {
  position: absolute;
  top: 0;
  right: 0;
  background-color: #db4651;
  color: #fff;
  border-radius: 0 0 0 10px;
  display: block;
  padding: 5px 10px;
}

.new_gall7 ul li .gall_div a .item01 .item-end {
  position: absolute;
  top: 0;
  right: 0;
  background-color: #eee;
  color: #000;
  border-radius: 0 0 0 10px;
  display: block;
  padding: 5px 10px;
}

.new_gall7 ul li .gall_div a h3 {
  font-size: 20px;
  max-height: 65px;
  overflow: hidden;
  font-weight: 500;
  word-break: break-all;
}

@media screen and (min-width: 1px) and (max-width: 1350px) {
  .new_gall7 ul li .gall_div a {
    margin-left: 10px;
    padding: 15px;
  }
  .new_gall7 ul li .gall_div a:hover, .new_gall7 ul li .gall_div a:focus {
    border: 2px solid #db4651;
    padding: 14px;
  }
}

@media screen and (min-width: 1px) and (max-width: 460px) {
  .new_gall7 ul li .gall_div a .item01 {
    margin-bottom: 10px;
  }
  .new_gall7 ul li .gall_div a h3 {
    font-size: 18px;
    height: 60px;
  }
}

.new-bn-box {
  display: flex;
  padding-top: 14px;
  padding-bottom: 14px;
  border-top: 1px solid #000;
  border-bottom: 1px solid #000;
  margin-bottom: 30px;
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .new-bn-box {
    display: block;
    padding-top: 4px;
    padding-bottom: 4px;
  }
}

.new-bn-box > p {
  flex: 1;
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .new-bn-box > p {
    flex: none;
    padding-top: 5px;
    padding-bottom: 5px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
  }
  .new-bn-box > p:last-child {
    border-top: 1px dotted #ddd;
  }
}

.new-bn-box > p a:hover,
.new-bn-box > p a:focus {
  text-decoration: underline;
}

.new-bn-box__prev {
  padding-left: 120px;
  position: relative;
}

.new-bn-box__prev strong {
  position: absolute;
  top: 0;
  left: 0;
  padding-left: 35px;
}

.new-bn-box__prev strong:after {
  position: absolute;
  top: 5px;
  left: 0;
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -963px -61px;
  background-image: url("../images/sprite.png");
  width: 8px;
  min-width: 8px;
  height: 16px;
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .new-bn-box__prev strong:after {
    display: none;
  }
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .new-bn-box__prev {
    padding-left: 0;
    text-align: left;
  }
  .new-bn-box__prev strong {
    position: relative;
    padding-right: 20px;
    padding-left: 10px;
  }
}

.new-bn-box__next {
  text-align: right;
  padding-right: 120px;
  position: relative;
}

.new-bn-box__next strong {
  position: absolute;
  top: 0;
  right: 0;
  padding-right: 35px;
}

.new-bn-box__next strong:after {
  position: absolute;
  top: 5px;
  right: 0;
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -901px -72px;
  background-image: url("../images/sprite.png");
  width: 9px;
  min-width: 9px;
  height: 16px;
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .new-bn-box__next strong:after {
    display: none;
  }
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .new-bn-box__next {
    padding-right: 0;
    text-align: left;
  }
  .new-bn-box__next strong {
    position: relative;
    padding-right: 20px;
    padding-left: 10px;
  }
}

.new-bbs-view__top {
  border-bottom: 1px solid #000;
}

.new-bbs-view__top .h3Type3 {
  border-bottom: 0px;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 0px;
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .new-bbs-view__top .h3Type3 {
    padding-left: 5px;
    padding-right: 5px;
  }
}

.new-bbs-view__top__list {
  display: flex;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 20px;
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .new-bbs-view__top__list {
    display: block;
    padding-left: 5px;
    padding-right: 5px;
    font-size: 16px;
  }
}

.new-bbs-view__top__list li {
  padding-right: 40px;
  color: #000;
}

.new-bbs-view__top__list li strong {
  font-weight: normal;
  color: #777;
}

.add-files {
  padding: 15px 0 15px 40px;
  border-bottom: 1px solid #ddd;
  position: relative;
}

.add-files::after {
  position: absolute;
  top: 19px;
  left: 15px;
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -63px -769px;
  background-image: url("../images/sprite.png");
  width: 14px;
  min-width: 14px;
  height: 17px;
}

.add-files a:focus,
.add-files a:hover {
  text-decoration: underline;
}

.new-bbs-cont {
  padding: 30px 15px;
}

.new-bbs-cont a:focus,
.new-bbs-cont a:hover {
  text-decoration: underline;
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .new-bbs-cont {
    padding: 20px 5px;
  }
}

.new-bbs-view {
  margin-bottom: 30px;
}

.new-bbs-cont .left_img {
  width: 62% !important;
}

.new-bbs-cont .left_img iframe {
  width: 100%;
  border: 0px;
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .new-bbs-cont .left_img {
    width: 100% !important;
  }
  .new-bbs-cont .left_img iframe {
    height: auto;
    min-height: 300px;
  }
}

@media screen and (min-width: 1px) and (max-width: 480px) {
  .new-bbs-cont .left_img iframe {
    min-height: 200px;
  }
}

.new-bbs-cont .right_table {
  width: 35% !important;
}

.new-bbs-cont .right_table textarea {
  width: 100% !important;
  box-sizing: border-box;
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .new-bbs-cont .right_table {
    width: 100% !important;
  }
}

.go-news-scrb {
  display: inline-block;
  padding: 10px 20px;
  font-size: 18px;
  border: 1px solid #000;
}

.go-news-scrb:focus {
  text-decoration: underline;
}

.news-letter-top {
  border: 2px solid #db4651;
  margin-bottom: 30px;
  padding: 30px 270px 30px 170px;
  background-image: url("../../resources/images/cont/newsletter-icon.png");
  background-position: 30px center;
  background-repeat: no-repeat;
  position: relative;
  min-height: 54px;
}

.news-letter-top .go-news-scrb {
  position: absolute;
  top: 30px;
  right: 40px;
}

@media screen and (min-width: 1px) and (max-width: 980px) {
  .news-letter-top br {
    display: none;
  }
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .news-letter-top {
    padding-right: 30px;
  }
  .news-letter-top p {
    margin-bottom: 10px;
  }
  .news-letter-top .go-news-scrb {
    position: relative;
    right: 0;
    top: 0;
  }
}

@media screen and (min-width: 1px) and (max-width: 530px) {
  .news-letter-top {
    background-position: center 30px;
    padding: 120px 30px 30px 30px;
    text-align: center;
  }
}

.basic-box {
  border: 1px solid #eee;
  padding: 30px;
  margin-bottom: 30px;
}

.openbox-btn {
  color: #fff;
  background-color: #000;
  height: 50px;
  line-height: 50px;
  display: inline-block;
  padding-left: 30px;
  padding-right: 30px;
  vertical-align: middle;
  box-sizing: border-box;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  border: 1px solid #000;
}

.openbox-btn:hover, .openbox-btn:focus {
  background-color: #444;
}

.open-box {
  height: 0;
  overflow: hidden;
  transition: all 0.3s ease 0s;
  padding-top: 0;
}

.open-box.on {
  height: auto;
  display: block;
  padding-top: 10px;
}

.privacy-box {
  border: 1px solid #ddd;
  padding: 20px;
  line-height: 1.8;
  color: #000;
  margin-bottom: 20px;
}

.cfrm-layer-box {
  position: relative;
  z-index: 99999999;
}

.cfrm-layer-box__bg {
  display: none;
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
}

.cfrm-layer-box__in {
  display: none;
  position: fixed;
  top: 50%;
  left: 50%;
  margin-left: -185px;
  margin-top: -150px;
  z-index: 999;
  background-color: #fff;
  min-width: 370px;
  box-sizing: border-box;
}

.cfrm-layer-box__in a:hover,
.cfrm-layer-box__in a:focus {
  border: 1px solid #ddd;
  border-radius: 5px;
}

.cfrm-layer-box__in__close {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 30px;
  text-align: center;
  width: 50px;
  font-weight: normal;
  font-family: -apple-system,Arial,sans-serif;
}

.cfrm-layer-box__in h3 {
  background-color: #f8f8f8;
  font-size: 23px;
  font-family: "scdream";
  font-weight: bold;
  padding: 15px 20px;
}

.cfrm-layer-box__in__cont {
  padding: 20px;
  text-align: center;
}

.cfrm-layer-box__in__cont .cfrm-item1 {
  font-size: 20px;
  font-weight: 600;
  padding-bottom: 20px;
}

.cfrm-layer-box.on .cfrm-layer-box__bg,
.cfrm-layer-box.on .cfrm-layer-box__in {
  display: block;
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .gongmojun .item01 {
    width: 100% !important;
    margin-right: 0 !important;
    float: none !important;
    margin-bottom: 30px;
  }
  .gongmojun .item02 {
    width: 100% !important;
    float: none !important;
  }
}

.icon-test {
  padding: 0px;
  background-color: #000;
  height: 28px;
  display: inline-block;
}

.icon-test:after {
  content: '';
  display: inline-block;
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -501.33333px -314px;
  background-image: url("../images/sprite.png");
  background-size: 650.66667px auto;
  width: 18.66667px;
  min-width: 18.66667px;
  height: 18.66667px;
}

.flex {
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 500px;
  height: 200px;
  border: 1px solid red;
}

.flex-item {
  flex: 1;
  order: 0;
  width: 100px;
  height: 100px;
  border: 1px solid blue;
  background-color: #ccc;
}

.flex-item:nth-child(3) {
  position: absolute;
  right: 0;
  top: 50%;
  width: 50px;
  height: auto;
  min-height: 40px;
  min-width: 40px;
  transform: translateY(-50%);
  flex: 1;
  order: 1;
  background-color: red;
}

.sample {
  font-size: 20px;
}

/*pages 가이드*/
.pages a {
  display: block;
  margin-bottom: 15px;
  position: relative;
  padding-top: 3px;
  padding-bottom: 3px;
}

.pages a span {
  position: absolute;
  left: 265px;
  top: 0px;
  border: 1px solid #666;
  display: inline-block;
  padding: 2px;
  margin-left: 10px;
  width: 400px;
  padding-left: 10px;
}

.pages a em {
  color: #da4453;
  font-weight: bold;
}

.pages a:hover span,
.pages a:focus span {
  background-color: #eee;
}

.pages a:hover, .pages a:focus {
  text-decoration: underline;
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .pages a span {
    width: auto;
    position: relative;
    left: 0;
    display: block;
  }
}

.box1 {
  background-color: #666;
  position: relative;
  padding: 10px;
  height: 200px;
}

.box1__in {
  background-color: #eee;
  position: absolute;
  z-index: 9999;
  top: 10%;
  left: 10%;
}

@media print {
  header,
  footer,
  .sidebar,
  .location-box,
  .special-box,
  .sub-visual,
  .new-lnb {
    display: none;
  }
  .sub-contents {
    width: 100%;
  }
  .sub-contents__top h2.sub-tit {
    height: auto;
    font-size: 20px;
    text-align: left;
    padding-top: 0;
    padding-bottom: 0;
    margin-bottom: 20px;
    border-bottom: 0;
  }
}

/*	footer
==========*/
.footer {
  font-size: 16px;
  background-color: #fff;
  color: #666;
  position: relative;
  border-top: 1px solid #e5e5e5;
  font-family: "scdream";
  /*background-image:url("../../commons/images/global/footer-bg.png");background-position:0 45px;background-repeat:repeat-x;*/
}

.footer a:hover,
.footer a:focus {
  text-decoration: underline;
}

.footer__in {
  width: 100%;
  max-width: 1760px;
  margin: 0 auto;
  position: relative;
  min-height: 180px;
}

.footer__in:after {
  content: ' ';
  clear: both;
  display: block;
  visibility: hidden;
}

* + html .footer__in {
  display: inline-block;
}

.foot_img {
  position: absolute;
  top: 35px;
  left: 0;
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: 0px -104px;
  background-image: url("../images/sprite.png");
  width: 181px;
  min-width: 181px;
  height: 50px;
  text-indent: -9999px;
  font-size: 0;
}

.copy_box {
  position: absolute;
  top: 30px;
  left: 260px;
}

.copy_box ul:after {
  content: ' ';
  clear: both;
  display: block;
  visibility: hidden;
}

* + html .copy_box ul {
  display: inline-block;
}

.copy_box li {
  float: left;
  position: relative;
  padding-left: 30px;
}

.copy_box li a {
  color: #000;
  vertical-align: top;
}

.copy_box li:before {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 15px;
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -963px -817px;
  background-image: url("../images/sprite.png");
  width: 1px;
  min-width: 1px;
  height: 13px;
}

.copy_box li:first-child:before {
  display: none;
}

.copy_box li:first-child {
  background-image: none;
  padding-left: 0;
}

.copy_box li:first-child a {
  background-image: none;
}

.copy_box li.persnal-rule a {
  color: #d93a47;
  font-weight: bold;
  text-decoration: underline;
}

.copy_box li.sns-li {
  background-image: none;
}

.copy_box2 {
  position: absolute;
  top: 50px;
  left: 260px;
  width: auto;
  padding-top: 20px;
}

.copy_box2 .item1 {
  margin-bottom: 5px;
  font-style: normal;
  line-height: 1.5;
  color: #666;
}

.copy_box2 .item1 ul:after {
  content: ' ';
  clear: both;
  display: block;
  visibility: hidden;
}

* + html .copy_box2 .item1 ul {
  display: inline-block;
}

.copy_box2 .item1 ul li {
  float: left;
  padding-left: 20px;
}

.copy_box2 .item1 ul li:first-child {
  padding-left: 0;
  background-image: none;
}

.copy_box2 .item2 {
  font-style: normal;
  margin-top: 5px;
  margin-bottom: 4px;
  line-height: 1.6;
  color: #666;
}

.copy_box3 {
  position: absolute;
  right: 0;
  top: 10px;
}

.copy_box3 a:hover,
.copy_box3 a:focus {
  display: block;
  border: 1px solid #000;
  border-radius: 100%;
}

.copy_box4 {
  position: absolute;
  right: 0;
  top: 75px;
}

/*통계*/
.total-count {
  background-color: #399e90;
  padding: 10px 30px 10px 10px;
  border-radius: 30px;
}

.total-count:after {
  content: ' ';
  clear: both;
  display: block;
  visibility: hidden;
}

* + html .total-count {
  display: inline-block;
}

.total-count__dt {
  display: inline-block;
  color: #fff;
  margin-right: 5px;
  padding-left: 20px;
}

.total-count__dt:before {
  content: '';
  padding-right: 10px;
  padding-left: 14px;
  display: inline-block;
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -901px -561px;
  background-image: url("../images/sprite.png");
  width: 1px;
  min-width: 1px;
  height: 10px;
}

.total-count__dt:first-child:before {
  display: none;
}

.total-count__dd {
  display: inline-block;
  color: #fff;
}

/*푸더 셀렉트*/
.footer-select {
  display: inline-block;
  margin-left: 10px;
}

.footer-select__item {
  overflow: hidden;
  display: inline-block;
  vertical-align: middle;
  position: relative;
  background-color: transparent;
  text-align: left;
  border-bottom: 2px solid #000;
}

.footer-select__item:after {
  position: absolute;
  top: 50%;
  right: 0;
  margin-right: 10px;
  transform: translateY(-50%);
  content: ' ';
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -963px -762px;
  background-image: url("../images/sprite.png");
  width: 9px;
  min-width: 9px;
  height: 5px;
}

.lte-ie9 .footer-select__item:after {
  display: none;
}

.footer-select__item select {
  width: 100%;
  background-color: transparent;
  appearance: none;
  min-width: 200px;
  vertical-align: middle;
  color: #000;
  font-size: 14px;
  height: 38px;
  margin-top: 0;
  padding: 2px 2px 2px 10px;
  position: relative;
  border: 0px;
  /*
			@media screen and (min-width:0\0){
				& {width:125%}
			}
			*/
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  /*ie10~11 에서 셀렉트 기본디자인 무력화*/
}

.footer-select__item select::-ms-expand {
  display: none;
}

.lte-ie9 .footer-select__item select {
  width: 100%;
  vertical-align: top;
  padding-right: 0px;
}

.footer-select__item select option {
  font-family: "scdream";
}

.sel_go_btn {
  display: inline-block;
  height: 38px;
  width: 43px;
  line-height: 38px;
  text-align: center;
  color: #fff;
  background-color: #272727;
  border-left: 1px solid #717171;
  border-radius: 4px;
}

* + html .sel_go_btn {
  display: inline;
}

@media screen and (min-width: 1px) and (max-width: 1550px) {
  /*footer*/
  .footer {
    height: auto;
    min-height: 100px;
    padding: 10px;
    background-image: none;
    position: relative;
    z-index: 3;
    padding-top: 24px;
  }
  .footer__in {
    width: 100%;
  }
  .foot_img {
    display: none;
  }
  .copy_box {
    position: relative;
    left: 0;
    top: 0;
    margin-bottom: 5px;
    background-color: #eee;
    padding: 5px;
  }
  .copy_box2 {
    position: relative;
    left: 0;
    top: 0;
    padding-top: 10px;
  }
  .copy_box li {
    background-image: none;
    padding: 4px 10px;
  }
  .copy_box li::before {
    display: none;
  }
  .select_bottom {
    position: relative;
    right: 0;
    top: 0;
  }
  .select_bottom p {
    float: left;
  }
  .copy_box2 .item1 li {
    padding-left: 0;
    background-image: none;
    float: none;
  }
  .copy_box3 {
    position: relative;
    top: 0;
    margin-bottom: 5px;
  }
  .copy_box4 {
    position: relative;
    top: 0;
  }
  .total-count {
    padding: 0;
    border-radius: 0;
    padding: 5px;
  }
  .total-count__dt:first-child {
    padding-left: 0;
  }
  .sel_go_btn {
    border-left: 0px;
  }
}

@media screen and (min-width: 851px) and (max-width: 1000px) {
  /*footer*/
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .copy_box2 .item1 li strong {
    display: block;
    margin-top: 5px;
  }
}

@media screen and (min-width: 1px) and (max-width: 470px) {
  .copy_box li {
    padding: 0;
    float: left;
    width: 50%;
    text-align: center;
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .copy_box2 .item1 li {
    padding-left: 0 !important;
    background-image: none;
    width: 100%;
  }
}

.foot-link {
  position: relative;
  z-index: 99;
}

.foot-link__btn {
  display: block;
  width: 200px;
  border: 1px solid #ddd;
  border-radius: 5px;
  height: 40px;
  line-height: 40px;
  box-sizing: border-box;
  padding: 0 10px;
  background-color: #fff;
  background-image: url("../../resources/images/icons/select-bul-n.png");
  background-position: 95% center;
  background-repeat: no-repeat;
}

.on > .foot-link__btn {
  border-radius: 0 0 5px 5px;
  z-index: 999;
}

.foot-link__layer {
  display: none;
  box-sizing: border-box;
  padding: 4px 10px 10px 10px;
  border: 1px solid #ddd;
  border-radius: 5px 5px 0 5px;
  background-color: #fff;
}

@media screen and (min-width: 1px) and (max-width: 1550px) {
  .foot-link__layer {
    border-radius: 5px 5px 5px 0;
  }
}

.on > .foot-link__layer {
  display: block;
  position: absolute;
  bottom: 38px;
  right: 0;
}

@media screen and (min-width: 1px) and (max-width: 1550px) {
  .on > .foot-link__layer {
    right: unset;
    left: 0;
  }
}

.foot-link__layer__item {
  border-top: 1px solid #ddd;
}

.foot-link__layer__item:first-child {
  border-top: 0;
}

.foot-link__layer__item > strong {
  display: block;
  min-height: 30px;
  line-height: 30px;
}

.foot-link__layer__item > strong a {
  display: block;
  padding: 3px 15px 3px 0px;
}

.foot-link__layer__item > a {
  display: block;
  padding: 3px 15px 3px 20px;
}
