// faq 리스트
.faq-box {

	margin-bottom:50px;
	border-top:2px solid #000;
	&__list {
		background-color:#fff;
		&__tit {
			padding:15px;
			padding-left:30px;
			padding-top:17px;
			box-sizing: border-box;
			min-height:60px;
			position: relative;
			font-size:18px;
			padding-right:100px;
			border-bottom:1px solid #333;
			padding-left:70px;
			position: relative;
			display: block;
			strong {font-weight: normal;}
			
			@media screen and (min-width:1px) and (max-width:768px) {
				padding-right:30px;
				padding-left:60px;
			}
			&:focus {
				text-decoration: underline;
				text-underline-position: under;
			}
			&:before {
				position: absolute;
				top:15px;
				left:15px;
				@include sprite($q-icon)
			}
			&:after {
				position: absolute;
				top:25px;
				right:15px;
				@include sprite($open-icon2)
			}
		}
		&__answer {
			display: none;
			border-bottom:1px solid #333;
			padding:30px;
			padding-top:25px;
			padding-left:70px;
			background-color:#f8f8f8;
			position: relative;
			font-size:17px;
			line-height: 1.6;
			&:before {
				position: absolute;
				top:25px;
				left:15px;
				@include sprite($a-icon)
			}
		}
	}

	&__list.on {
		.faq-box__list__tit {
			&:after {
				@include sprite($open-icon);
			}
		}
	}
}