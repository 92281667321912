
/*sub-page-container*/
.sub-page-container {
	font-family: $font1;
}
.sub-page-container__in {
	margin:0 auto;
	position: relative;
	background-color:#fff;
	@include floatWrap;
}
.sub-contents {float:right;width:900px;}

.cont-in {
	padding-bottom:40px;
	min-height:500px;
	font-size:17px;
    line-height: 1.6;
	color:#333;
	font-family: $font1;
}

.cont-in img {
	max-width: 100%;
}

@media screen and (min-width:1px) and (max-width:$siteSize) {

	.sub-page-container__in{width:auto;}
	.sub-contents {width:auto;float:none; padding:0 10px;}
	.cont-in img {
		height: auto !important;
	}
}
@media screen and (min-width:1px) and (max-width:768px) {
	.sub-page-container {padding-top:51px;}
}
.sub-contents__top {position:relative;border-bottom:1px solid #e0e0e0;font-family: 'Noto Sans KR';
	/*background-image:url("../../commons/images/global/sub-visual-bg1.jpg");*/
	background-position:right top;
	background-repeat:no-repeat;
	margin-bottom:40px;
	}
.sub-contents__top h2.sub-tit {
	font-size: 40px;
	color: #222;
	padding-top: 30px;
	
	padding-bottom: 20px;
	border-bottom:2px solid #db4651;
	margin-bottom: 90px;
	height: 70px;
	font-weight: 500;
	text-align: center;
	font-weight: 600;
	@media screen and (min-width:1px) and (max-width:768px) {
		border-bottom: 0;
		padding-bottom:0;
		text-align: left;
	}
}

/* location */
.location-box {position:relative;}
.location-box__cont {
	position: absolute;
		right: 0;
		text-align:center;
		top: 90px;

		span {display:inline-block;font-size:14px;font-size:1.4rem;height:28px;line-height:28px;

			&:before {
				content:'';
				display:inline-block;font-size:0;
				@include sprite($location-icon);
				margin:0 17px;
				@media screen and (min-width:1px) and (max-width:480px) {
					& {margin:0 10px;}
				}
			}

			color:#666;
			&:first-child::before {
				display:none;
			}
		}
	}
.location-box__cont .loc-home {display:block;padding-left:30px;font-size:14px;font-size:1.4rem;height:28px;line-height:27px;
	background-image:url("../../resources/images/icons/home.png");
	background-position:0 center;
	background-repeat:no-repeat;
	color:#666;
	&:hover,
	&:focus {text-decoration: underline;}
}

/* 프린트, url 버튼 등*/
.special-box {
	position: absolute;
	top:-46px;right:0;
    z-index: 9;
	a {
		border:1px solid #fff;
		margin-left:10px;
		&:focus {border:2px solid gold;}
	}

	&__print {
		@include sprite($icon-print);
		margin-right:4px;
		text-indent: -9999px;
	}
	&__url {
		@include sprite($icon-url);
		text-indent: -9999px;
	}

	&__sns {
		@include sprite($icon-url);
		text-indent: -9999px;
	}
	.special-openbox {

		&__cont {
			display: none;
			border:1px solid #ddd;
			white-space: nowrap;
			padding:10px;
			background-color: #fff;
			a {
				vertical-align: middle;
				display: inline-block;
				span {@include hiddenWord;}

				&.type1 {
					@include sprite($newsns-f);
				}
				&.type2 {
					@include sprite($newsns-tw);
				}
				&.type3 {
					@include sprite($newsns-ks);
				}
				&.type4 {
					@include sprite($newsns-kt);
				}
				&.type5 {
					@include sprite($newsns-b);
				}
				&:hover,
				&:focus {

					&.type1 {
						@include sprite($newsns-f-on);
					}
					&.type2 {
						@include sprite($newsns-tw-on);
					}
					&.type3 {
						@include sprite($newsns-ks-on);
					}
					&.type4 {
						@include sprite($newsns-kt-on);
					}
					&.type5 {
						@include sprite($newsns-b-on);
					}
				}


			}
		}

		&.on {
			.special-openbox__btn {
				border:1px solid #000;
				&:before {
					@include sprite($sns-on);
				}
			}
			.special-openbox__cont {
				display: block;
				position: absolute;
				top:65px;
				right:0;
			}

		}
	}
}

@media screen and (min-width:769px) and (max-width:1400px) {
	.location-box__cont {position:relative;top:3px;width:auto;text-align:right ;}
	// .sub-contents__top h2.sub-tit {padding-top:0px;}
	 .special-box {top:10px;}

}
@media screen and (min-width:1px) and (max-width:768px) {
		.sub-contents__top {background-image:none !important;margin-bottom:10px;display: block;}
		.sub-contents__top h2.sub-tit {font-size:21px;margin-bottom:8px;padding-top:10px;height:auto;}
		.location-box {position:relative;top:0;right:0;padding-bottom:0px;}
		.location-box__cont {position:relative;white-space:normal;width:auto;text-align: left;overflow:visible;top:0;left:0;padding-top:0px;}
		.special-box {display:none;}
}


/* checkbox */

input[class="check-type"] + label {
	display: inline-block;
	height: 25px;
	line-height: 25px;
	cursor: pointer;
	position: relative;

	padding-left:35px;
	&:after {
		display: inline-block;
		content: ' ';
		width: 25px;
		height: 25px;
		box-sizing: border-box;
		position: absolute;
		top:0px;left:0px;
		border: 2px solid #acacac;
		background-color:#acacac;
		border-radius: 25px;}
	&:before {
		content:' ';
		@include sprite($check-type);
		position: absolute;
		top:8px;left:7px;
		z-index: 9;
	}
}

input[class="check-type"]:checked + label {
	&:after {
		background-color: #fc4451;
	}
}

input[class="check-type"]:focus + label {
	&:after {
		border: 2px solid gold;
	}
}
input[class="check-type"] {
	position: absolute;
	left: -9999px;
}
/*

input[class="check-type"] + label {
	display: inline-block;
	width: 30px;
	height: 30px;
	border: 2px solid #cccccc;
	background-color:#dddddd;
	cursor: pointer;
	border-radius: 30px;
	position: relative;
	font-size: 0;
	text-indent: -9999px;
	&:before {
		content:' ';
		@include sprite($check-type);
		position: absolute;
		top:10px;left:10px;
	}
}

input[class="check-type"]:checked + label {
	background-color: #666666;
}

input[class="check-type"]:focus + label {
	border: 2px solid gold;
}
input[class="check-type"] {
	position: absolute;
	left: -9999px;
}
*/

/* 2 */
input[class="check-type2"] + label {
	display: inline-block;
	height: 18px;
	line-height: 18px;
	position: relative;
	padding-left:27px;

	&:after {
		display: inline-block;
		content: ' ';
		width: 18px;
		height: 18px;
		cursor: pointer;
		border:1px solid #ddd;
		position: absolute;
		top:0px;left:0px;
	}
}

input[class="check-type2"]:checked + label {
	&:before {
		content:' ';
		@include sprite($check-type2-on);
		position: absolute;
		top:4px;left:4px;
	}
}

input[class="check-type2"]:focus + label {
	&:after{
		border:1px solid #bbbbbb;
	}

}
input[class="check-type2"] {
	position: absolute;
	left: -9999px;
}



/* 3 */
input[class="check-type3"] + label {
	display: inline-block;
	height: 18px;
	line-height: 18px;
	cursor: pointer;
	position: relative;
	padding-left:27px;

	&:after {
		display: inline-block;
		content: ' ';
		width: 18px;
		height: 18px;
		cursor: pointer;
		border:1px solid #ddd;
		position: absolute;
		top:0px;left:0px;
	}
}

input[class="check-type3"]:checked + label {
	&:before {
		content:' ';
		@include sprite($check-type3-on);
		position: absolute;
		top:4px;left:4px;
	}
}

input[class="check-type3"]:focus + label {
	&:after{
		border:1px solid #ec6159;
	}
}
input[class="check-type3"] {
	position: absolute;
	left: -9999px;
}



/*라디오버튼*/
input[class="radio-type"] + label {
	display: inline-block;
	height: 17px;
	line-height: 17px;
	border: 0;
	cursor: pointer;
	position: relative;
	padding-left:27px;

	&:before {
		content:' ';
		@include sprite($radio-type-off);
		position: absolute;
		top:6px;left:5px;
	}

}

input[class="radio-type"]:checked + label {
	&:before {
		@include sprite($radio-type-on);
	}
}

input[class="radio-type"]:focus + label {}
input[class="radio-type"] {
	position: absolute;
	left: -9999px;
}



.choose-label-text {
	margin-left:0;
	color:#7d7d7d;
	font-weight:bold;
	font-size:16px;
	padding:5px;
}
.choose-textarea {
	display: block;
	box-sizing: border-box;
	border:1px solid #d5d9dd;
	background-color: #e9ecf0;
	padding:20px;
	height: 100px;
	font-size:16px;
	margin-top:10px;
	width:100%;
	color:#768393;
	&.long {height:150px;}
}

// 동영상 게시물 상세에서 아이프레임 있을경우
.editor_view.on {
	& .editor_view__cont {
		position: relative;
		width: 100%;
		height: 0;
		overflow: hidden;
		padding-bottom: 56.25%; /*16:9*/
		background-color:#00235d;
		color:#fff;
		> p {
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;

			iframe { width: 100%; height: 100%}
		}
	}
}

//test

.sub-slide {
	position: relative;
	margin-bottom: 10px;
	&__box {
		max-width:100%;
		margin:0 auto;
		img {width:100%}
	}

	&__nav {
		position: absolute;

		width:50px;
		display: block;
		top:50%;
		margin-top:-35px;
		height:70px;
		background-color:#000;
		opacity: 0.8;
		text-indent: -9999px;
		&:after {
			width:20px;
			height:34px;
			display:block;
			position: absolute;
			top:50%;
			transform: translateY(-50%);
			left:17px;
			color:#fff;
			text-indent: 0;
			font-size:22px;
			font-family: serif;
		}
		&:hover,
		&:focus {
			&:after {color:gold}
		}

	}
	&__prev {
		left:0;
		&:after {
			content:'<';
		}
	}
	&__next {
		right: 0;
		&:after {
			content:'>';
		}
	}
}
.sub-slide-cro {
	margin-bottom:40px;
	margin-left:-10px;
	overflow: hidden;
	img {
		height:100px;
		width:100%;
		padding-left:10px;
		box-sizing: border-box;
		opacity: 0.4;
		transition: all 0.3s ease 0s;
		@media screen and (min-width:1px) and (max-width:$siteSize) {
			height:100px !important;
		}
		@media screen and (min-width:1px) and (max-width:768px) {
			height:55px !important;
		}
	}
	.slick-current  {
		img {opacity: 1;}
	}
	a:focus {
		img {opacity: 1;}
	}
}


//서브페이지 팝업레이어

.layer-box {height:0;overflow: hidden;visibility: hidden;opacity: 0;}
.layer-box.on {height:100%;visibility: visible;opacity: 1;}

.layer-box {
	position: fixed;
	width:100%;
	top:0;
	left:0;
	z-index: 9999;
	height:100%;
	background-image: url('../../resources/images/basic/opa2.png');
	background-repeat: repeat;
	color:#fff;
}
.photo-slide {
	position: absolute;
	top:50%;
	transform: translateY(-50%);
	left:0;
	width:100%;

	.slick-prev {color:#fff;
		position: absolute;
		top:50%;
		transform: translateY(-50%);
		left:10%;
		z-index: 999;
		font-size:0;
		&.slick-disabled {display:none !important;}
		&:before {
			font-size:40px;
			content:'<';
			display:block;
			padding:20px;
		}
		&:hover,
		&:focus {
			&:before {color:gold}
		}
		@media screen and (min-width:1px) and (max-width:768px) {
			left:2%;
		}
	}
	.slick-next {color:#fff;
		position: absolute;
		top:50%;
		transform: translateY(-50%);
		right:10%;
		z-index: 999;
		font-size:0;
		&.slick-disabled {display:none !important;}
		&:before {
			font-size:40px;
			content:'>';
			display:block;
			padding:20px;
		}
		&:hover,
		&:focus {
			&:before {color:gold}
		}
		@media screen and (min-width:1px) and (max-width:768px) {
			right:2%;
		}
	}
}
.photo-slide__item {width:100%;text-align: center;
	img {margin:0 auto;}
}
.layer-box__close {font-size:30px;font-weight: bold;
	display: block;
	width:40px;
	height:40px;
	text-align: center;
	line-height: 40px;
	background-color:#ddd;
	border-radius: 40px;
	color:#000;
	position: absolute;
	top:30px;
	right:30px;
}


/* 다음맵 접근성 추가 .warp_map으로 감쌈 */
.wrap_map a:focus,
.wrap_map button:focus,
.roughmap_maker_label a:focus .roughmap_lebel_text,
.root_daum_roughmap .wrap_btn_zoom button:focus {border:2px solid gold;}
.wrap_controllers.hide {display:none;}

// 슬라이더
.slider-for {
	width:1200px !important;
	margin:0 auto;
	a {display: inline-block;}
	a:focus {border:1px solid black}
	&__item {
		background-color:cadetblue;
		min-height:200px;
	}
}
.slider-nav-outwrap {
	width:1278px;
	overflow:hidden;
	margin:0 auto;
}
.slider-nav {
	width:1200px !important;
	margin:0 auto;
	a {display: inline-block;text-align: center;}
	a:focus {background-color:#444}
	.slick-current {background-color: black;color:#fff;}
}
